import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteEndPointRequest,
  getEndPointInstancesRequest,
  getEndPointRequest,
  updateEndPointRequest,
} from "redux/endpoints/action";
import { getApprovalSequencesRequest } from "redux/approvalProcesses/action";
import { getWorkflowsRequest } from "redux/workflows/action";
import { getUserAccountsRequest } from "redux/user/action";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { sortByTitle } from "utility/utility";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader";
import EndPointContent from "components/endpoints/EndPointContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as EndPointsIcon } from "assets/icons/endpoints.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import DeleteConfirm from "../modals/DeleteConfirm";
import { useTranslation } from "react-i18next";

const UpdateEndPoint = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(handleUpdateEndPoint);
  const { t } = useTranslation();

  const {
    isGetEndPointInstancesError,
    instances,
    endPoint,
    isGetEndPointError,
    isUpdatedEndPointSuccess,
    isUpdatedEndPointError,
    isUpdatedEndPointSuccessKeyChanged,
    isCreatedEndPointLimitedFlowSuccess,
    isCreatedEndPointLimitedFlowError,
    isDeletedEndPointLimitedFlowSuccess,
    isDeletedEndPointLimitedFlowError,
    isCreatedEndPointUserNotificationSuccess,
    isCreatedEndPointUserNotificationError,
    isDeletedEndPointUserNotificationSuccess,
    isDeletedEndPointUserNotificationError,
    isGetUserAccountsError,
    isDeletedEndPointSuccess,
  } = useSelector((state) => state.endPoints);

  const { workflows, isGetWorkflowsSuccess } = useSelector(
    (state) => state.workflows
  );

  const { userAccounts, isGetUserAccountsSuccess } = useSelector(
    (state) => state.users
  );

  const { approvalSequences, isGetApprovalSequencesSuccess } = useSelector(
    (state) => state.approvalProcesses
  );

  const prevIsDeletedEndPointSuccess = usePrevious(isDeletedEndPointSuccess);
  const prevIsGetUserAccountsSuccess = usePrevious(isGetUserAccountsSuccess);
  const prevIsUpdatedEndPointSuccess = usePrevious(isUpdatedEndPointSuccess);
  const prevIsUpdatedEndPointSuccessKeyChanged = usePrevious(
    isUpdatedEndPointSuccessKeyChanged
  );
  const prevIsCreatedEndPointLimitedFlowError = usePrevious(
    isCreatedEndPointLimitedFlowError
  );
  const prevIsGetEndPointError = usePrevious(isGetEndPointError);
  const prevIsDeletedEndPointLimitedFlowError = usePrevious(
    isDeletedEndPointLimitedFlowError
  );
  const prevIsDeletedEndPointUserNotificationError = usePrevious(
    isDeletedEndPointUserNotificationError
  );
  const prevIsCreatedEndPointUserNotificationError = usePrevious(
    isCreatedEndPointUserNotificationError
  );
  const prevIsGetEndPointInstancesError = usePrevious(
    isGetEndPointInstancesError
  );
  const prevIsUpdatedEndPointError = usePrevious(isUpdatedEndPointError);
  const prevIsCreatedEndPointLimitedFlowSuccess = usePrevious(
    isCreatedEndPointLimitedFlowSuccess
  );
  const prevIsGetWorkflowsSuccess = usePrevious(isGetWorkflowsSuccess);
  const prevIsDeletedEndPointLimitedFlowSuccess = usePrevious(
    isDeletedEndPointLimitedFlowSuccess
  );
  const prevIsDeletedEndPointUserNotificationSuccess = usePrevious(
    isDeletedEndPointUserNotificationSuccess
  );
  const prevIsCreatedEndPointUserNotificationSuccess = usePrevious(
    isCreatedEndPointUserNotificationSuccess
  );

  const prevIsGetUserAccountsError = usePrevious(isGetUserAccountsError);
  const [selectedEndPoint, setSelectedEndPoint] = useState({});
  const [isEndPointDeleteModalOpen, setIsEndPointDeleteModalOpen] =
    useState(false);
  const [endpointName, setEndpointName] = useState("");
  const [workflowsClone, setWorkflowsClone] = useState([]);
  const [endPointsClone, setEndPointsClone] = useState([]);
  const [approvalSequencesClone, setApprovalSequencesClone] = useState([]);

  useEffect(() => {
    document.title = `${endPoint?.name || t("api_endpoint")} - Decisimo`;
    setIsLoading(true);
    dispatch(getEndPointInstancesRequest());
    dispatch(getEndPointRequest(id));
    dispatch(getWorkflowsRequest());
    dispatch(getUserAccountsRequest());
    dispatch(getApprovalSequencesRequest());
  }, []);

  useEffect(() => {
    if (isGetUserAccountsSuccess && prevIsGetUserAccountsSuccess === false) {
      setIsLoading(false);
    }
  }, [isGetUserAccountsSuccess]);

  useEffect(() => {
    if (
      isUpdatedEndPointSuccessKeyChanged &&
      prevIsUpdatedEndPointSuccessKeyChanged === false
    ) {
      setIsLoading(false);
      toast.info(
        t("api_key_changed_redeploy_last_release_to_update_key"),
        ToastOptions
      );
      dispatch(getEndPointRequest(id));
    }
  }, [isUpdatedEndPointSuccessKeyChanged]);

  useEffect(() => {
    if (isUpdatedEndPointSuccess && prevIsUpdatedEndPointSuccess === false) {
      setIsLoading(false);
      toast.info(t("changes_saved"), ToastOptions);
      dispatch(getEndPointRequest(id));
    }
  }, [isUpdatedEndPointSuccess]);

  useEffect(() => {
    if (
      (isUpdatedEndPointError && prevIsUpdatedEndPointError === false) ||
      (isGetEndPointInstancesError &&
        prevIsGetEndPointInstancesError === false) ||
      (isCreatedEndPointLimitedFlowError &&
        prevIsCreatedEndPointLimitedFlowError === false) ||
      (isGetEndPointError && prevIsGetEndPointError === false) ||
      (isDeletedEndPointLimitedFlowError &&
        prevIsDeletedEndPointLimitedFlowError === false) ||
      (isDeletedEndPointUserNotificationError &&
        prevIsDeletedEndPointUserNotificationError === false) ||
      (isCreatedEndPointUserNotificationError &&
        prevIsCreatedEndPointUserNotificationError === false) ||
      (isGetUserAccountsError && prevIsGetUserAccountsError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isUpdatedEndPointError,
    isGetEndPointInstancesError,
    isCreatedEndPointLimitedFlowError,
    isGetEndPointError,
    isDeletedEndPointLimitedFlowError,
    isDeletedEndPointUserNotificationError,
    isCreatedEndPointUserNotificationError,
    isGetUserAccountsError,
  ]);

  useEffect(() => {
    if (isGetWorkflowsSuccess && prevIsGetWorkflowsSuccess === false) {
      const workflowsClone = structuredClone(workflows);
      workflowsClone?.sort(sortByTitle);
      setWorkflowsClone(workflowsClone);
    }
  }, [isGetWorkflowsSuccess]);

  useEffect(() => {
    if (
      isCreatedEndPointLimitedFlowSuccess &&
      prevIsCreatedEndPointLimitedFlowSuccess === false
    ) {
      setIsLoading(false);
      toast.success(
        t("decision_flow_limitation_for_this_endpoint_added"),
        ToastOptions
      );
    }
  }, [isCreatedEndPointLimitedFlowSuccess]);

  useEffect(() => {
    if (
      isDeletedEndPointLimitedFlowSuccess &&
      prevIsDeletedEndPointLimitedFlowSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("decision_flow_limitation_deleted"), ToastOptions);
    }
  }, [isDeletedEndPointLimitedFlowSuccess]);

  useEffect(() => {
    if (
      isCreatedEndPointUserNotificationSuccess &&
      prevIsCreatedEndPointUserNotificationSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("notification_user_added"), ToastOptions);
    }
  }, [isCreatedEndPointUserNotificationSuccess]);

  useEffect(() => {
    if (
      isDeletedEndPointUserNotificationSuccess &&
      prevIsDeletedEndPointUserNotificationSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("user_deleted_from_notification_list"), ToastOptions);
    }
  }, [isDeletedEndPointUserNotificationSuccess]);

  useEffect(() => {
    if (isDeletedEndPointSuccess && prevIsDeletedEndPointSuccess === false) {
      setIsLoading(false);
      setEndPointsClone(
        endPointsClone.filter(
          (endPoint) =>
            endPoint.endpoint_placeholder_id !==
            selectedEndPoint.endpoint_placeholder_id
        )
      );
      handleClose();
      toast.warning(t("endpoint_deleted"), ToastOptions);
      navigate("/api-endpoints");
    }
  }, [isDeletedEndPointSuccess]);

  function handleUpdateEndPoint(target) {
    const data = new URLSearchParams(new FormData(target));
    const name = target.name.value;
    const apiKey = target.api_key.value;

    if (name.length === 0 || apiKey.length === 0) {
      return toast.error(t("please_fill_out_the_api_name_key"), ToastOptions);
    }

    setIsLoading(true);
    dispatch(updateEndPointRequest({ id, data }));
  }

  const handleShowDeleteModal = (endpoint) => {
    setSelectedEndPoint(endpoint);
    setIsEndPointDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsEndPointDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedEndPoint({});
    }, 100);
  };

  const handleConfirm = () => {
    if (selectedEndPoint.name !== endpointName) {
      toast.error(
        t("you_need_to_write_name_correctly_to_delete_endpoint"),
        ToastOptions
      );
    } else {
      setIsLoading(true);
      dispatch(deleteEndPointRequest(selectedEndPoint.endpoint_placeholder_id));
      setIsEndPointDeleteModalOpen(false);
    }
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          title={t("delete_endpoint")}
          className="dropdown-item"
          onClick={() => handleShowDeleteModal(endPoint)}
          type="button"
        >
          {t("delete")}
        </button>
      ),
    },
  ];

  //if there is block_deletion = 1 then make delete button disabled
  if (endPoint?.block_deletion == 1) {
    settings[0].content = (
      <button
        title={t("delete_endpoint")}
        className="dropdown-item"
        disabled
        type="button"
      >
        {t("delete")}
      </button>
    );
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdateEndPoint(e.target);
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between w-100">
            <SubHeader
              endpoint={true}
              class="w-170"
              alt={t("plus_api_endpoint")}
              title={`API Endpoint - ${endPoint?.name}`}
              icon={<EndPointsIcon />}
              actions=""
            />
          </div>
          <div className="d-flex">
            <button
              className="btn primary"
              type="submit"
              title={t("save_endpoint")}
            >
              <SaveIcon />
            </button>
            <button
              type="button"
              className="btn primary ml-1"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-expanded="false"
              title={t("settings_endpoint")}
            >
              <SettingsIcon />
            </button>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
              {settings.length > 0 &&
                settings.map((setting) => {
                  return (
                    <span key={setting.id}>
                      {setting.content}
                      {setting.divider && <div className="dropdown-divider" />}
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <EndPointContent
          instances={instances}
          editMode={true}
          data={endPoint}
          workflows={workflowsClone}
          users={userAccounts}
          approvalSequences={approvalSequences}
        />
      </form>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_endpoint")}
        message={
          <>
            <p>
              {t("do_you_really_want_to_delete")} <b>{selectedEndPoint.name}</b>
              ?
            </p>
            <p>
              {t("endpoint_system")} <strong>{t("getting_errors")}</strong>{" "}
              {t("call_endpoint")}
            </p>
            <label>
              {t("write_below")} <strong>{selectedEndPoint.name}</strong>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={selectedEndPoint.name}
              onChange={(e) => setEndpointName(e.target.value)}
            />
          </>
        }
        icon=""
        open={isEndPointDeleteModalOpen}
      />
    </>
  );
};

export default UpdateEndPoint;
