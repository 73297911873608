import React, { useContext, useEffect, useState } from "react";
import SubHeader from "components/SubHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkflowRequest,
  getWorkflowsRequest,
} from "redux/workflows/action";
import { changeIsSignUpSuccess } from "redux/auth/action";
import usePrevious from "utility/hooks/usePrevious";
import {
  generateWorkflowInstanceName,
  sortByTitle,
  TableNoItems,
} from "utility/utility";
import WorkflowRow from "components/workflow/WorkflowRow";
import { MainContext } from "context/contexts";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ToastOptions } from "../toastify";
import { toast } from "react-toastify";
import { getTemplatesRequest } from "redux/templates/action";
import TemplateModal from "../modals/Template";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Workflows = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    workflows,
    eTag,
    isGetWorkflowsSuccess,
    isGetWorkflowsError,
    isCreatedWorkflowSuccess,
    isCreatedWorkflowError,
  } = useSelector((state) => state.workflows);

  const { templates } = useSelector((state) => state.templates);

  const { isSignUpSuccess } = useSelector((state) => state.auth);

  const prevIsGetWorkflowsSuccess = usePrevious(isGetWorkflowsSuccess);
  const prevIsGetWorkflowsError = usePrevious(isGetWorkflowsError);
  const prevIsCreatedWorkflowSuccess = usePrevious(isCreatedWorkflowSuccess);
  const prevIsCreatedWorkflowError = usePrevious(isCreatedWorkflowError);

  const [filtered, setFiltered] = useState([]);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  useEffect(() => {
    document.title = `${t("decision_flows")} - Decisimo`;
    setIsLoading(true);
    dispatch(getWorkflowsRequest());
  }, []);

  useEffect(() => {
    if (
      (isGetWorkflowsError && prevIsGetWorkflowsError === false) ||
      (isCreatedWorkflowError && prevIsCreatedWorkflowError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetWorkflowsError, isCreatedWorkflowError]);

  useEffect(() => {
    if (isGetWorkflowsSuccess && prevIsGetWorkflowsSuccess === false) {
      setIsLoading(false);
      const workflowsClone = structuredClone(workflows);
      workflowsClone?.sort(sortByTitle);
      setFiltered(workflowsClone);
    }
  }, [isGetWorkflowsSuccess]);

  useEffect(() => {
    if (isCreatedWorkflowSuccess && prevIsCreatedWorkflowSuccess === false) {
      setIsLoading(false);
      toast.success(t("decision_flow_created"), ToastOptions);
      navigate(`/workflows/${eTag}`);
    }
  }, [isCreatedWorkflowSuccess]);

  useEffect(() => {
    if (isSignUpSuccess) {
      handleOpenTemplatesModal();
      dispatch(changeIsSignUpSuccess());
    }
  }, [isSignUpSuccess]);

  const handleCreateFlow = () => {
    const data = new URLSearchParams(new FormData());
    const randomData = generateWorkflowInstanceName();
    data.append("name", randomData.name);
    data.append("title", randomData.title);
    setIsLoading(true);
    dispatch(createWorkflowRequest({ data }));
  };

  const handleOpenTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
    if (templates.length === 0) {
      dispatch(
        getTemplatesRequest({
          entity_type: "",
          isGetAllTemplates: true,
        })
      );
    }
  };

  return (
    <>
      <SubHeader
        title={t("nav_bar_decision_flows")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <button
              title={t("create_decision_flow")}
              className="btn primary-header border-radius-left-4"
              type="button"
              onClick={handleCreateFlow}
            >
              <PlusIcon />
              <span className="ml-2">{t("nav_bar_decision_flow")}</span>
            </button>
            <div className="btn-group">
              <button
                className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
                title={t("create_decision_flow_template")}
              >
                <span className="sr-only">{t("toggle_dropdown")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                <span>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleOpenTemplatesModal}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("create_from_template")}</span>
                  </button>
                </span>
                <span>
                  <Link
                    to="/ai-decision-designs"
                    className="dropdown-item"
                    title={t("ai_decision_designs")}
                  >
                    <span className="ml-2">{t("ai_decision_designs")}</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        }
      />
      <div className="mt">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-4 py-3 ">
                {t("table_title")}
              </th>
              <th scope="col" className="th-flow col-lg-6 py-3">
                {t("table_description")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 ">
                {t("table_last_updated")}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {filtered?.length > 0
              ? filtered.map((workflow) => (
                  <WorkflowRow key={workflow.workflow_id} workflow={workflow} />
                ))
              : isGetWorkflowsSuccess &&
                filtered?.length === 0 && <TableNoItems colspan={3} />}
          </tbody>
        </table>
        {isTemplatesModalOpen && (
          <TemplateModal
            open={isTemplatesModalOpen}
            handleClose={() => setIsTemplatesModalOpen(false)}
            type={"use_case"}
            title={t("use_case")}
          />
        )}
      </div>
    </>
  );
};

export default Workflows;
