import React, { useContext, useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { CSS } from "@dnd-kit/utilities";
import Condition from "components/rulesets/rule/Condition";
import Action from "components/rulesets/rule/Action";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "context/contexts";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as GripVertical } from "assets/icons/grip-vertical.svg";
import { ReactComponent as PlusIcon } from "assets/icons/circle-plus.svg";
import { generateCloudInstanceName, getVectors } from "utility/utility";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ruleSetTypes } from "../utils";
import CustomSelect from "../../CustomSelect";
import AutoSuggestInput from "components/autosuggest";
import { pointerWithin } from "@dnd-kit/core";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as SparklesIcon } from "assets/icons/sparkles.svg";
import ExplanationModal from "components/modals/explain/ExplainModal";
import {
  getRuleExplanationRequest,
  getRuleExplanationSuccess,
  getRuleExplanationFailure,
} from "redux/rulesets/action";
import usePrevious from "utility/hooks/usePrevious";

const Rule = ({
  rule,
  setRuleSet,
  conditionTypesData,
  handleShowConditionDeleteModal,
  handleShowActionDeleteModal,
  handleShowDeleteModal,
  addCondition,
  addAction,
  index = "",
  importMode = false,
  revisionMode = false,
  id,
  ruleSetId = "",
}) => {
  const { t } = useTranslation();
  const { vectorsData, autoSuggestData } = useContext(MainContext);
  const [ruleTitle, setRuleTitle] = useState(rule.title);

  const {
    ruleExplanation,
    isGetRuleExplanationSuccess,
    isGetRuleExplanationFailure,
  } = useSelector((state) => state.ruleSets);

  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [conditionLabel, setConditionLabel] = useState("all");
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });
  const [isExecutionTypeSelectOpen, setIsExecutionTypeSelectOpen] = useState(
    {}
  );

  const dispatch = useDispatch();

  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [isLoadingExplanation, setIsLoadingExplanation] = useState(false);
  const [explanation, setExplanation] = useState("");

  const prevIsGetRuleExplanationSuccess = usePrevious(
    isGetRuleExplanationSuccess
  );
  const prevIsGetRuleExplanationFailure = usePrevious(
    isGetRuleExplanationFailure
  );

  const handleTitleChange = (e, rule) => {
    setRuleSet((prevState) => {
      const data = structuredClone(prevState);
      const foundRule = data.rules.find(
        (dataRule) => dataRule.rule_id === rule.rule_id
      );
      foundRule.title = e.target.value;
      setRuleTitle(e.target.value);

      return data;
    });
  };

  const style = {
    transform: CSS.Translate.toString(transform),
    touchAction: "none",
    zIndex: "auto",
    transition,
  };

  const executionTypes = ["standard", "array"];

  useEffect(() => {
    setConditionLabel(rule.type ? rule.type : "all");
  }, []);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  const handleChangeCond = (e) => {
    setConditionLabel(e.value);
  };

  const handleChange = (e, rule) => {
    setRuleSet((prevState) => {
      const data = structuredClone(prevState);
      const foundedRule = data.rules.find(
        (dataRule) => dataRule.rule_id === rule.rule_id
      );
      foundedRule.name = e.target.value;

      return data;
    });
  };

  const handleActiveChange = (e, rule) => {
    setRuleSet((prevState) => {
      const data = structuredClone(prevState);
      const foundedRule = data.rules.find(
        (dataRule) => dataRule.rule_id === rule.rule_id
      );
      foundedRule.active = e.target.checked ? 1 : 0;

      return data;
    });
  };

  const handleExecutionTypeChange = (e, rule) => {
    setRuleSet((prevState) => {
      const data = structuredClone(prevState);
      const foundedRule = data.rules.find(
        (dataRule) => dataRule.rule_id === rule.rule_id
      );
      foundedRule.execution_type = e.target.value;

      return data;
    });
  };

  const handleExplain = (rule) => {
    // Fetch or generate the explanation here and set it
    setExplanation("");
    dispatch(
      getRuleExplanationRequest({
        rule_set_id: ruleSetId,
        rule_id: rule.rule_id,
      })
    );
    //set loading
    setIsLoadingExplanation(true);

    setIsExplanationModalOpen(true);
  };

  //use effect, when success of loading explanation, update
  //the explanation text
  useEffect(() => {
    if (
      isGetRuleExplanationSuccess &&
      prevIsGetRuleExplanationSuccess === false
    ) {
      setExplanation(ruleExplanation);
      setIsLoadingExplanation(false);
    }
  }, [isGetRuleExplanationSuccess]);

  //use effect, when failure of loading explanation, update
  //the explanation text
  useEffect(() => {
    if (
      isGetRuleExplanationFailure &&
      prevIsGetRuleExplanationFailure === false
    ) {
      setExplanation(t("explanation_not_available"));

      setIsLoadingExplanation(false);
    }
  }, [isGetRuleExplanationFailure]);

  const handleTryAgain = () => {
    //if not loading, try again
    if (!isLoadingExplanation) {
      dispatch(
        getRuleExplanationRequest({
          rule_set_id: ruleSetId,
          rule_id: rule.rule_id,
        })
      );

      setIsLoadingExplanation(true);
    }
  };

  return (
    <>
      <div
        ref={setNodeRef}
        {...attributes}
        style={style}
        className="card cursor-auto rule-box-shadow"
        id={`rule-${rule.rule_id}`}
      >
        <div className="card-header rule-set-header">
          <h2 className="mb-0">
            <GripVertical {...listeners} className="cursor-move" />
            <Button
              variant=""
              title={ruleTitle ? ruleTitle : t("new_rule")}
              className="ml-2 new-rule-btn"
              type="button"
              data-toggle="collapse"
              data-target={`#rule-content-${rule.rule_id}${
                importMode ? `-${index}` : ""
              }`}
              aria-controls={`#rule-content-${rule.rule_id}${
                importMode ? `-${index}` : ""
              }`}
            >
              {ruleTitle ? ruleTitle : t("new_rule")}
            </Button>
          </h2>
          <div className="d-flex justify-content-between">
            <div className="custom-control custom-switch float-right align-middle">
              <input
                title={t("checked_rule")}
                type="checkbox"
                className="custom-control-input z-index-100 cursor-pointer"
                defaultChecked={+rule.active}
                name={`rule[${rule.rule_id}][active]`}
                onChange={(e) => handleActiveChange(e, rule)}
                disabled={revisionMode ? "disabled" : ""}
                key={+rule.active}
              />
              <label
                className="custom-control-label rule-switch cursor-pointer"
                htmlFor={`active-switch-${rule.rule_id}`}
              />
            </div>
            {!revisionMode && (
              <div>
                <button
                  type="button"
                  className="btn primary-text border-0 p-0 pb-1 pt-1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  title={t("delete_or_explain_rule")}
                >
                  <MoreVertical style={{ cursor: "pointer" }} />
                </button>
                <div
                  className="dropdown-menu table-dropdown"
                  aria-labelledby="dropdownMenuLink"
                >
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => handleExplain(rule)}
                  >
                    <SparklesIcon /> {t("explain")}
                  </button>
                  <div className="dropdown-divider" />
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={(e) => handleShowDeleteModal(e, rule)}
                  >
                    <TrashIcon /> {t("delete")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          id={`rule-content-${rule.rule_id}${importMode ? `-${index}` : ""}`}
          className="collapse"
          data-parent={`#rules-${rule.rule_id}`}
        >
          <div className="card-body card-border">
            <div className="card-body-content">
              <div className="row new-rule m-0">
                <div className="col-12 col-lg-6 pl-0">
                  <div className="form-group">
                    <label>{t("rule_title")}</label>
                    <input
                      type="text"
                      className="form-control border-radius-4"
                      name={`rule[${rule.rule_id}][title]`}
                      onChange={(e) => handleTitleChange(e, rule)}
                      defaultValue={rule.title ? rule.title : t("new_rule")}
                      disabled={revisionMode ? "disabled" : ""}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 pr-0">
                  <div className="form-group">
                    <label>{t("identifier")}</label>
                    <input
                      type="text"
                      className="form-control border-radius-4"
                      name={`rule[${rule.rule_id}][name]`}
                      onChange={(e) => handleChange(e, rule)}
                      defaultValue={
                        rule.name ? rule.name : generateCloudInstanceName()
                      }
                      disabled={revisionMode ? "disabled" : ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex">
                    <div
                      className="d-flex"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setIsExecutionTypeSelectOpen((prevState) => ({
                          ...prevState,
                          [rule.rule_id]: true,
                        }))
                      }
                    >
                      {isExecutionTypeSelectOpen[rule.rule_id] ? (
                        <select
                          className="form-control border-radius-4"
                          style={{ width: "200px" }}
                          name={`rule[${rule.rule_id}][execution_type]`}
                          onChange={(e) => handleExecutionTypeChange(e, rule)}
                          defaultValue={rule?.execution_type || "standard"}
                          onBlur={() =>
                            setIsExecutionTypeSelectOpen((prevState) => ({
                              ...prevState,
                              [rule.rule_id]: false,
                            }))
                          }
                          disabled={revisionMode ? "disabled" : ""}
                        >
                          <option value="standard">{t("apply_once")}</option>
                          <option value="array">
                            {t("apply_on_all_in_list")}
                          </option>
                        </select>
                      ) : (
                        <>
                          <span className="pt-2 mr-2">
                            {rule.execution_type === "standard" ||
                            !rule.execution_type
                              ? t("apply_once")
                              : t("apply_on_all_in_list")}
                          </span>
                          <input
                            type="hidden"
                            name={`rule[${rule.rule_id}][execution_type]`}
                            value={rule?.execution_type}
                          />
                        </>
                      )}
                    </div>
                    {rule?.execution_type === "array" ? (
                      <>
                        <span className="mr-2 pl-1" style={{ width: "500px" }}>
                          <AutoSuggestInput
                            vectorsData={vectorsDataClone}
                            defaultValue={rule?.attribute_path || ""}
                            inputName={`rule[${rule.rule_id}][attribute_path]`}
                            revisionMode={revisionMode}
                            isRuleArray={true}
                          />
                        </span>
                        <span className="pt-2 mr-2">that</span>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="text-center">
                <div
                  className="btn-group btn-group-toggle mb-3"
                  data-toggle="buttons"
                >
                  <ReactTooltip
                    type="dark"
                    place="top"
                    effect="solid"
                    id="rule-condition-hint"
                  >
                    {t("the_rule_condition")}
                  </ReactTooltip>
                  <ReactTooltip
                    type="dark"
                    place="top"
                    effect="solid"
                    id="rule-condition-hint-two"
                  >
                    {t("the_rule_condition_and")}
                  </ReactTooltip>
                </div>
              </div>
              <div className="condition-container">
                <div className="d-flex">
                  <span className="pt-2 mr-2">
                    {rule?.execution_type === "array" ? t("meet") : t("meets")}
                  </span>
                  <CustomSelect
                    name={`rule[${rule.rule_id}][type]`}
                    defaultValue={
                      ruleSetTypes.find((type) => rule?.type === type.value) ||
                      ruleSetTypes[0]
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    selectStyle={{
                      dropdownIndicator: (styles) => ({
                        ...styles,
                        marginRight: "0",
                      }),
                    }}
                    isDisabled={revisionMode ? "disabled" : ""}
                    options={ruleSetTypes}
                    handleChange={handleChangeCond}
                    getOptionLabel={(option) => t(option.label)}
                    key={ruleSetTypes.length}
                    isSearchable={false}
                    selectWidth="w-95"
                  />
                  <span className="pt-2 ml-3">{t("following_conditions")}</span>
                </div>
                <div className="row">
                  <div className="col">
                    <label>&nbsp;</label>
                  </div>
                  <div className="col">
                    <label>&nbsp;</label>
                  </div>
                </div>
                <div className="conditions">
                  {rule.condition &&
                    rule.condition.length > 0 &&
                    rule.condition.map((condition, index) => (
                      <Condition
                        meetLabel={conditionLabel}
                        rule={rule}
                        index={index}
                        addCondition={addCondition}
                        key={condition.rule_condition_id}
                        ruleId={rule.rule_id}
                        condition={condition}
                        groupConditionLabel={
                          condition.condition_type_name === "group" &&
                          condition.logical_operator
                        }
                        vectorsData={vectorsDataClone}
                        conditionTypesData={conditionTypesData}
                        deleteModal={handleShowConditionDeleteModal}
                        revisionMode={revisionMode}
                      />
                    ))}
                </div>
                {!revisionMode && (
                  <div className="ruleset-row">
                    <div className="d-flex">
                      <span className="condition-label">
                        {conditionLabel === "any" ? t("or") : t("and")}
                      </span>
                      <>
                        <button
                          className="btn outline h-38"
                          type="button"
                          title={t("add_condition")}
                          onClick={() =>
                            addCondition({ rule_id: rule.rule_id })
                          }
                        >
                          <PlusIcon
                            style={{
                              filter: "brightness(0.5)",
                            }}
                          />
                          <span className="ml-2">{t("add_condition")}</span>
                        </button>
                        <button
                          className="btn outline ml-2 h-38"
                          type="button"
                          title={t("add_group_condition")}
                          onClick={() =>
                            addCondition({
                              rule_id: rule.rule_id,
                              type: "group",
                            })
                          }
                        >
                          <PlusIcon
                            style={{
                              filter: "brightness(0.5)",
                            }}
                          />
                          <span className="ml-2">
                            {t("add_group_condition")}
                          </span>
                        </button>
                      </>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <label>&nbsp;</label>
                </div>
                <div className="col">
                  <label>&nbsp;</label>
                </div>
              </div>
              <div className="section-divider">
                <label className="font-size-18">{t("actions")}</label>
              </div>
              <div className="actions">
                {rule.action &&
                  rule.action.length > 0 &&
                  rule.action.map((action) => (
                    <Action
                      key={action.rule_action_id}
                      ruleId={rule.rule_id}
                      action={action}
                      vectorsData={vectorsDataClone}
                      deleteModal={handleShowActionDeleteModal}
                      revisionMode={revisionMode}
                    />
                  ))}
              </div>
              <div className="my-3 d-flex justify-content-center">
                {!revisionMode && (
                  <button
                    className="btn outline"
                    type="button"
                    title={t("add_action")}
                    onClick={() => addAction(rule.rule_id)}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("add_action")}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ExplanationModal
        open={isExplanationModalOpen}
        handleClose={() => setIsExplanationModalOpen(false)}
        handleTryAgain={handleTryAgain}
        explanation={explanation}
        isLoadingExplanation={isLoadingExplanation}
      />
    </>
  );
};

Rule.propTypes = {
  rule: PropTypes.any,
  setRuleSet: PropTypes.func,
  handleShowConditionDeleteModal: PropTypes.func,
  handleShowActionDeleteModal: PropTypes.func,
  handleShowDeleteModal: PropTypes.func,
  addCondition: PropTypes.func,
  addAction: PropTypes.func,
  conditionTypesData: PropTypes.any,
  deleteModal: PropTypes.func,
  revisionMode: PropTypes.bool,
  index: PropTypes.any,
  importMode: PropTypes.bool,
  id: PropTypes.any,
  ruleSetId: PropTypes.any,
};

export default Rule;
