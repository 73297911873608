import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReleaseRequest,
  getReleasesRequest,
} from "redux/releases/action";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import { Pagination, sortByTimeDesc, TableNoItems } from "utility/utility";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const Releases = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isGetReleasesSuccess,
    releases,
    isDeletedReleaseSuccess,
    isGetReleasesError,
    isDeletedReleaseError,
  } = useSelector((state) => state.releases);

  const prevIsDeletedReleaseSuccess = usePrevious(isDeletedReleaseSuccess);
  const prevIsDeletedReleaseError = usePrevious(isDeletedReleaseError);
  const prevIsGetReleasesSuccess = usePrevious(isGetReleasesSuccess);
  const prevIsGetReleasesError = usePrevious(isGetReleasesError);

  const [releasesClone, setReleasesClone] = useState([]);
  const [selectedRelease, setSelectedRelease] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isReleaseDeleteModalOpen, setIsReleaseDeleteModalOpen] =
    useState(false);

  useEffect(() => {
    document.title = t("releases_decisimo");
  }, []);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getReleasesRequest(currentPage.toString()));
    }
  }, [currentPage]);

  useEffect(() => {
    if (isGetReleasesSuccess && prevIsGetReleasesSuccess === false) {
      setIsLoading(false);
      const releasesClone = structuredClone(releases);
      releasesClone?.sort(sortByTimeDesc);
      setReleasesClone(releasesClone);
    }
  }, [isGetReleasesSuccess]);

  useEffect(() => {
    if (
      (isGetReleasesError && prevIsGetReleasesError === false) ||
      (isDeletedReleaseError && prevIsDeletedReleaseError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetReleasesError, isDeletedReleaseError]);

  useEffect(() => {
    if (isDeletedReleaseSuccess && prevIsDeletedReleaseSuccess === false) {
      setIsLoading(false);
      setReleasesClone(
        releasesClone.filter(
          (release) =>
            release.workflow_release_id !== selectedRelease.workflow_release_id
        )
      );
      handleClose();
      toast.warning(t("release_deleted"), ToastOptions);
    }
  }, [isDeletedReleaseSuccess]);

  const handleShowDeleteModal = (release) => {
    setSelectedRelease(release);
    setIsReleaseDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsReleaseDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedRelease({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteReleaseRequest(selectedRelease.workflow_release_id));
    setIsReleaseDeleteModalOpen(false);
  };

  return (
    <>
      <SubHeader title={t("nav_bar_releases")} actions="" />
      <div className="">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-4 py-3">
                {t("release_name")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-2">
                {t("decision_flow")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-2">
                {t("release_time")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-1" />
            </tr>
          </thead>
          <tbody className="">
            {releasesClone?.length > 0
              ? releasesClone.map((release) => {
                  return (
                    <tr key={release.workflow_release_id}>
                      <td
                        className="td-flow "
                        onClick={() =>
                          navigate(`/releases/${release.workflow_release_id}`)
                        }
                      >
                        {release.name}
                      </td>
                      <td className="td-flow ">
                        {release.workflow_id ? (
                          <Link to={`/workflows/${release.workflow_id}`}>
                            {release.workflow_title}
                          </Link>
                        ) : (
                          <em>deleted</em>
                        )}
                      </td>
                      <td className="td-flow ">
                        <div className="align-items-center d-flex">
                          {release.dtime_inserted}
                          <HelpCircle
                            data-tip={true}
                            data-for={`released-${release.workflow_release_id}`}
                            className="ml-2 cursor-pointer"
                          />
                          <ReactTooltip
                            type="dark"
                            place="right"
                            effect="solid"
                            id={`released-${release.workflow_release_id}`}
                          >
                            {t("released_by")} {release.full_name}
                          </ReactTooltip>
                        </div>
                      </td>
                      <td className="text-right border-bottom">
                        <button
                          type="button"
                          className="btn primary-text border-0 p-0 pb-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title={t("delete_release")}
                        >
                          <MoreVertical
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </button>
                        <div
                          className="dropdown-menu small-table-dropdown"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleShowDeleteModal(release)}
                          >
                            <TrashIcon /> {t("delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetReleasesSuccess &&
                releases?.length === 0 && <TableNoItems colspan={5} />}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        itemsLength={releasesClone?.length}
        setCurrentPage={setCurrentPage}
      />
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_release_small")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedRelease.name}</b>{" "}
            {t("release")}?
          </span>
        }
        open={isReleaseDeleteModalOpen}
      />
    </>
  );
};

export default Releases;
