import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SubHeader from "components/SubHeader";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { MainContext } from "context/contexts";
import {
  getApprovalSequencesRequest,
  deleteApprovalSequenceRequest,
} from "redux/approvalProcesses/action";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";

const ApprovalSequences = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setIsLoading } = useContext(MainContext);
  const {
    approvalSequences,
    isLoading,
    isDeleteApprovalSequenceSuccess,
    isDeleteApprovalSequenceError,
  } = useSelector((state) => state.approvalProcesses);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(null);

  const prevIsDeleteApprovalSequenceSuccess = usePrevious(
    isDeleteApprovalSequenceSuccess
  );
  const prevIsDeleteApprovalSequenceError = usePrevious(
    isDeleteApprovalSequenceError
  );

  useEffect(() => {
    document.title = `${t("approval_sequences")} - Decisimo`;
    dispatch(getApprovalSequencesRequest());
  }, [dispatch, t]);

  useEffect(() => {
    if (
      isDeleteApprovalSequenceSuccess &&
      prevIsDeleteApprovalSequenceSuccess === false
    ) {
      setIsDeleteModalOpen(false);
      setSelectedSequence(null);
      dispatch(getApprovalSequencesRequest()); // Refresh list after deletion
      toast.success(`${t("approval_sequence_deleted")}.`, ToastOptions);
    }
  }, [isDeleteApprovalSequenceSuccess, dispatch]);

  useEffect(() => {
    if (
      isDeleteApprovalSequenceError &&
      prevIsDeleteApprovalSequenceError === false
    ) {
      toast.error(`${t("failed_to_delete_approval_sequence")}.`, ToastOptions);
      setIsLoading(false);
    }
  }, [isDeleteApprovalSequenceError]);

  const handleShowDeleteModal = (sequence) => {
    setSelectedSequence(sequence);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedSequence(null);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      deleteApprovalSequenceRequest(selectedSequence.approval_sequence_id)
    );
  };

  return (
    <>
      <SubHeader
        alt={t("approval_sequences")}
        title={t("approval_sequences")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/approval-sequence"
              title={t("create_approval_sequence")}
              className="btn primary-header border-radius-left-4"
            >
              <PlusIcon />
              <span className="ml-2">{t("approval_sequence")}</span>
            </Link>
          </div>
        }
      />
      <div className="mb-4">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-8 py-3">
                {t("table_title")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-3">
                {t("last_change")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-3" />
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4">{t("loading")}</td>
              </tr>
            ) : approvalSequences.length > 0 ? (
              approvalSequences.map((sequence) => (
                <tr key={sequence.approval_sequence_id} className="td-flow">
                  <td>
                    <Link
                      to={`/approval-sequences/${sequence.approval_sequence_id}`}
                    >
                      {sequence.title}
                    </Link>
                  </td>
                  <td>
                    {sequence.dtime_modified}
                    <HelpCircle
                      title={`${t("modified_by")} ${sequence.full_name}`}
                      className="ml-2 cursor-pointer"
                      data-tip={true}
                      data-for={`modified-by-${sequence.approval_sequence_id}`}
                    />
                    <ReactTooltip
                      type="dark"
                      place="right"
                      effect="solid"
                      id={`modified-by-${sequence.approval_sequence_id}`}
                    >
                      {t("modified_by")} {sequence.full_name}
                    </ReactTooltip>
                  </td>
                  <td className="d-flex align-items-center justify-content-end border-0">
                    <div>
                      <button
                        title={t("copy_or_delete_approval_sequence")}
                        type="button"
                        className="btn primary-text border-0 p-0 pb-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <MoreVertical style={{ cursor: "pointer" }} />
                      </button>
                      <div
                        className="dropdown-menu table-dropdown"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          to={`/approval-sequences/${sequence.approval_sequence_id}`}
                          className="dropdown-item"
                          title="Edit approval sequence"
                        >
                          <EditIcon /> {t("edit")}
                        </Link>
                        <div className="dropdown-divider" />
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleShowDeleteModal(sequence)}
                        >
                          <TrashIcon /> {t("delete")}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">{t("no_items_found")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isDeleteModalOpen && (
        <DeleteConfirm
          handleClose={handleCloseDeleteModal}
          handleConfirm={handleDeleteConfirm}
          title={t("delete_approval_sequence")}
          message={
            <span>
              {t("do_you_want_delete")} <b>{selectedSequence?.title}</b>?
            </span>
          }
          open={isDeleteModalOpen}
        />
      )}
    </>
  );
};

export default ApprovalSequences;
