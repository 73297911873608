import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "assets/icons/close_modal.svg";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";

const ExplanationModal = ({
  open,
  handleClose,
  handleTryAgain,
  explanation,
  isLoadingExplanation,
}) => {
  const { t } = useTranslation();

  const [fullExplanation, setFullExplanation] = useState("");

  useEffect(() => {
    //if string then set
    if (typeof explanation === "string") {
      setFullExplanation(explanation);
    }
  }, [explanation]);

  return (
    <Modal dialogClassName="w-1300" show={open} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="modal-title">{t("ai_decision_designer")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <Close />
        </button>
      </Modal.Header>
      <Modal.Body>
        <ReactMarkdown>{fullExplanation}</ReactMarkdown>
        {isLoadingExplanation && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className={`d-flex `} onClick={handleTryAgain}>
          <button type="submit" className="workflows-modal-btn mr-1">
            {t("try_again")}
          </button>
        </div>
        <div className={`d-flex `} onClick={handleClose}>
          <button type="submit" className="workflows-modal-btn mr-1">
            {t("close")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ExplanationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleTryAgain: PropTypes.func.isRequired,
  explanation: PropTypes.any,
  isLoadingExplanation: PropTypes.bool,
};

export default ExplanationModal;
