// App.js
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "redux/store";
import "App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "assets/css/main.scss";
import "react-toastify/dist/ReactToastify.minimal.css";
import Index from "components/router";
import { Toast } from "components/toastify";

const App = () => {
  const expired = JSON.parse(localStorage.getItem("expired"));
  if (expired) {
    localStorage.removeItem("copiedStep");
    localStorage.removeItem("copiedWorkflow");
    localStorage.removeItem("token");
    localStorage.removeItem("expired");
  }

  useEffect(() => {
    const allowedLanguages = ["en", "es", "pt"];
    const browserLanguage = navigator.language || navigator.userLanguage;
    const userLanguage = localStorage.getItem("user_language");
    if (
      !userLanguage ||
      userLanguage === "null" ||
      !allowedLanguages.includes(userLanguage)
    ) {
      if (allowedLanguages.includes(browserLanguage)) {
        localStorage.setItem("user_language", browserLanguage);
      } else {
        if (window.location.pathname !== "/login") {
          localStorage.setItem("user_language", "en");
        }
      }
    }
  }, []);

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      console.error("Unhandled Promise Rejection:", event.reason);
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
    };
  }, []);

  return (
    <Provider store={store}>
      <Index />
      <Toast />
    </Provider>
  );
};

export default App;
