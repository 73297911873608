import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  dataMarketGetServicesRequest,
  dataMarketGetServicesSuccess,
  dataMarketGetServicesFailure,
  dataMarketGetServiceDetailsRequest,
  dataMarketGetServiceDetailsSuccess,
  dataMarketGetServiceDetailsFailure,
  dataMarketUpdateServiceDetailsRequest,
  dataMarketUpdateServiceDetailsSuccess,
  dataMarketUpdateServiceDetailsFailure,
  dataMarketActivateServiceRequest,
  dataMarketActivateServiceSuccess,
  dataMarketActivateServiceFailure,
  dataMarketDeactivateMyServiceRequest,
  dataMarketDeactivateMyServiceSuccess,
  dataMarketDeactivateMyServiceFailure,
} from "./action";

// Helper function to perform API calls
const fetchDataFromAPI = async (url, method = "GET", data = null) => {
  const response = await axios({ url, method, data });
  return response.data;
};

// Saga to fetch Data Market services
function* fetchDataMarketServices() {
  try {
    const data = yield call(fetchDataFromAPI, "/data-market-services");
    yield put(dataMarketGetServicesSuccess(data));
  } catch (error) {
    yield put(dataMarketGetServicesFailure(error.toString()));
  }
}

function* activateDataMarketService({ payload }) {
  try {
    let finalData;
    if (payload.serviceDetails) {
      finalData = payload.serviceDetails;
    } else {
      finalData = payload;
    }

    const response = yield call(
      axios.post,
      `/data-market-services-active/${payload.dama_service_id}`,
      finalData
    );

    if (response.status === 201) {
      //add the etag as dama_service_tenant_id into the payload copy
      let updatedData = {
        ...finalData,
        dama_service_tenant_id: response.headers.etag,
      };

      yield put(
        dataMarketActivateServiceSuccess({
          updatedData,
        })
      );
    }
  } catch (error) {
    yield put(
      dataMarketActivateServiceFailure({
        id: payload.id,
        error: error.message,
      })
    );
  }
}

//deactivate service - delete and 204 means success
function* deactivateDataMarketService({ payload }) {
  try {
    const response = yield call(
      axios.delete,
      `/data-market-services-active/${payload.dama_service_id}`
    );
    if (response.status === 204) {
      yield put(dataMarketDeactivateMyServiceSuccess(response.data));
    }
  } catch (error) {
    yield put(dataMarketDeactivateMyServiceFailure(error.toString()));
  }
}

//get service details
function* fetchDataMarketServiceDetails({ payload }) {
  try {
    const data = yield call(
      fetchDataFromAPI,
      `/data-market-services/${payload.dama_service_id}`
    );
    yield put(dataMarketGetServiceDetailsSuccess(data));
  } catch (error) {
    yield put(dataMarketGetServiceDetailsFailure(error.toString()));
  }
}

//update service details
function* updateDataServiceDetails({ payload }) {
  try {
    const response = yield call(
      axios.put,
      `/data-market-services/${payload.dama_service_id}`,
      payload
    );
    if (response.status === 200) {
      yield put(dataMarketUpdateServiceDetailsSuccess(response.data));
    }
  } catch (error) {
    yield put(dataMarketUpdateServiceDetailsFailure(error.toString()));
  }
}

// Watcher sagas
export default function* dataMarketSaga() {
  yield takeLatest(dataMarketGetServicesRequest, fetchDataMarketServices);
  yield takeLatest(dataMarketActivateServiceRequest, activateDataMarketService);
  yield takeLatest(
    dataMarketGetServiceDetailsRequest,
    fetchDataMarketServiceDetails
  );
  yield takeLatest(
    dataMarketUpdateServiceDetailsRequest,
    updateDataServiceDetails
  );
  yield takeLatest(
    dataMarketDeactivateMyServiceRequest,
    deactivateDataMarketService
  );
}
