import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useNavigate } from "react-router-dom";
import SubHeader from "components/SubHeader";
import ScorecardEditorContent from "components/scorecards/ScorecardEditorContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { MainContext } from "context/contexts";
import { toast } from "react-toastify";
import { ToastOptions } from "../toastify";
import { useTranslation } from "react-i18next";
import {
  createScorecardRequest,
  createScorecardSuccess,
} from "redux/scorecard/action"; // Adjust this import to your action
import usePrevious from "utility/hooks/usePrevious";

const CreateScorecard = () => {
  const [scorecardRef] = useCtrlSHandler(() => createScorecard());
  const { vectorsData, setIsLoading, setIsEdited } = useContext(MainContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newScorecardId, isCreateScorecardSuccess } = useSelector(
    (state) => state.scorecard
  );

  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const prevIsCreateScorecardSuccess = usePrevious(isCreateScorecardSuccess);

  const initialScorecardData = {
    scorecard_id: "new_" + Math.random().toString(36).substr(2, 9),
    title: t("new_scorecard"),
    name: "scorecard_" + Date.now(),
    predictors: [
      {
        scorecard_predictor_id:
          "new_" + Math.random().toString(36).substr(2, 9),
        name: "",
        weight: 1,
        bins: [
          {
            name: "",
            scorecard_bin_id: "new_" + Math.random().toString(36).substr(2, 9),
            value_a: "32",
            value_b: "",
            operator: "less_than",
            score: 0,
          },
        ],
      },
    ],
  };

  const scorecardIsValid = (scorecard) => {
    if (!scorecard.name) {
      return false;
    }
    if (!scorecard.predictors.length) {
      return false;
    }
    for (const predictor of scorecard.predictors) {
      if (!predictor.name) {
        return false;
      }
      if (!predictor.bins.length) {
        return false;
      }
      for (const bin of predictor.bins) {
        if (!bin.name) {
          return false;
        }
      }
    }
    return true;
  };

  const [scorecard, setScorecard] = useState(initialScorecardData);

  useEffect(() => {
    document.title = `${t("scorecard")} - Decisimo`;
  }, [t]);

  //onload, reset the scorecard
  useEffect(() => {
    setScorecard(initialScorecardData);
  }, []);

  useEffect(() => {
    setVectorsDataClone([...vectorsData]);
  }, [vectorsData]);

  // New function to handle form validation
  const formHasInvalidFields = () => {
    const target = scorecardRef.current;
    if (target) {
      const invalidFields = target.querySelectorAll(".is-invalid");
      if (invalidFields.length) {
        invalidFields[0].focus();
        return true;
      }
      return false;
    }
  };

  const createScorecard = () => {
    // First, check for any invalid fields in the form
    if (formHasInvalidFields()) {
      return toast.error(t("attribute_value_valid"), ToastOptions);
    }

    // Then check if all required scorecard fields are filled in
    if (!scorecardIsValid(scorecard)) {
      return toast.error(`${t("please_fill_out_all_fields")}.`, ToastOptions);
    }

    // If everything is valid, proceed with saving the scorecard
    setIsLoading(true);
    setIsEdited(false);
    dispatch(createScorecardRequest({ scorecard }));
  };

  useEffect(() => {
    if (
      isCreateScorecardSuccess &&
      newScorecardId &&
      prevIsCreateScorecardSuccess === false
    ) {
      setIsEdited(false);
      toast.success(t("scorecard_created"), ToastOptions);
      navigate(`/scorecards/${newScorecardId}`);
    }
  }, [isCreateScorecardSuccess, newScorecardId, navigate]);

  const handleScorecardChange = (updatedScorecard) => {
    setScorecard(updatedScorecard);
    setIsEdited(true);
  };

  return (
    <>
      <form
        ref={scorecardRef}
        onSubmit={(e) => {
          // Prevent form submission if the event came from an input field
          if (document.activeElement.tagName === "INPUT") {
            e.preventDefault();
            return;
          }
          e.preventDefault();
          createScorecard();
        }}
      >
        <SubHeader
          title={t("create_scorecard")}
          actions={
            <button className="btn primary mr-1" type="submit">
              <SaveIcon />
            </button>
          }
        />
        <ScorecardEditorContent
          scorecard={scorecard}
          onScorecardChange={handleScorecardChange}
        />
      </form>
    </>
  );
};

export default CreateScorecard;
