import React from "react";
import PropTypes from "prop-types";

const HiddenInputs = ({ data = [] }) => {
  return (
    <>
      {data.map((item) => (
        <input
          type="hidden"
          name={item.name}
          value={item.value}
          key={item.name}
        />
      ))}
    </>
  );
};

HiddenInputs.propTypes = {
  data: PropTypes.array,
};

export default HiddenInputs;
