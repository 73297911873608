import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";

const DeleteModal = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleConfirm,
    message = t("delete_decision_step_message"),
    title = t("delete_decision_step"),
    confirm_button = t("delete"),
    icon = <TrashIcon />,
  } = props;

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="" className="outline" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button
            variant=""
            className={`outline option-delete d-flex align-items-center ${
              !icon ? "text-danger" : ""
            }`}
            type="submit"
            onClick={handleConfirm}
          >
            {icon}{" "}
            <span className={`${icon ? "ml-2" : ""}`}>{confirm_button}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeleteModal.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  message: PropTypes.any,
  title: PropTypes.string,
  icon: PropTypes.any,
  outline: PropTypes.string,
  confirm_button: PropTypes.string,
};

export default DeleteModal;
