import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AutoSuggestInput from "../autosuggest";

const SubFlowAttributes = ({ attr, vectorsDataClone, stepOptionId }) => {
  const { revisionId } = useParams();
  const { t } = useTranslation();

  // Ensure attr.name is always a string to prevent runtime errors
  const attrName = attr.name || "";

  // State to hold the current execution type value
  const [executionType, setExecutionType] = useState(attr.value || "object");
  const [isSelectVisible, setIsSelectVisible] = useState(false);

  if (attrName === "execution_type") {
    // Render the execution_type attribute
    return (
      <div className="mr-0 ml-0 mb-1">
        <input
          type="hidden"
          value={attrName}
          name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][name]`}
        />
        <input
          type="hidden"
          value="0"
          name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][vector]`}
        />
        <input
          type="hidden"
          value={executionType}
          name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][value]`}
        />
        {!isSelectVisible && (
          <div
            onClick={() => setIsSelectVisible(true)}
            style={{ cursor: "pointer" }}
          >
            <span className="align-items-center d-flex font-size-14 pl-2 text-gray-66 border-0">
              {executionType === "object"
                ? t("subflow_execution_definition")
                : t("subflow_iteration_definition")}
            </span>
          </div>
        )}
        {isSelectVisible && (
          <div className="input-group">
            <div className="bg-white border border-radius-left-4 input-group-prepend w-150">
              {/* Hidden inputs for attribute name and vector */}

              <span
                className="align-items-center d-flex font-size-14 pl-2 text-gray-66"
                style={{ cursor: "pointer" }}
              >
                {t("execution_type")}
              </span>
            </div>
            <select
              className="form-control border-radius-4"
              value={executionType}
              name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][value]`}
              onChange={(e) => setExecutionType(e.target.value)}
              onBlur={() => setIsSelectVisible(false)}
            >
              <option value="object">{t("object")}</option>
              <option value="array">{t("list")}</option>
            </select>
          </div>
        )}
      </div>
    );
  } else {
    // Render input and output attributes as before
    return (
      <div className="col mr-0 ml-0 subflow-option">
        <div
          className={`input-group${
            attrName === "attribute_path_input" ? " mb-1" : ""
          }`}
        >
          <div className="bg-white border border-radius-left-4 input-group-prepend w-150">
            <span className="align-items-center d-flex font-size-14 pl-2 text-gray-66">
              {attrName.includes("input")
                ? t("input_attribute")
                : t("output_attribute")}
            </span>
          </div>
          <input
            type="hidden"
            value={attrName}
            name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][name]`}
          />
          <input
            type="hidden"
            value="1"
            name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][vector]`}
          />
          <AutoSuggestInput
            revisionMode={!!revisionId}
            vectorsData={vectorsDataClone}
            defaultValue={attr.path || ""}
            inputName={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][path]`}
          />
        </div>
      </div>
    );
  }
};

SubFlowAttributes.propTypes = {
  attr: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    path: PropTypes.string,
    wf_step_option_attribute_id: PropTypes.any.isRequired,
  }).isRequired,
  vectorsDataClone: PropTypes.array.isRequired,
  stepOptionId: PropTypes.any.isRequired,
};

export default SubFlowAttributes;
