import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createBatchFailure,
  createBatchRequest,
  createBatchSuccess,
  deleteBatchFailure,
  deleteBatchRequest,
  deleteBatchSuccess,
  deleteTaskFailure,
  deleteTaskRequest,
  deleteTaskSuccess,
  getBatchesFailure,
  getBatchesRequest,
  getBatchesSuccess,
  getBatchFailure,
  getBatchInstancesFailure,
  getBatchInstancesRequest,
  getBatchInstancesSuccess,
  getBatchRequest,
  getBatchSuccess,
  getBatchTasksFailure,
  getBatchTasksRequest,
  getBatchTasksSuccess,
  getBatchTypesFailure,
  getBatchTypesRequest,
  getBatchTypesSuccess,
  updateBatchFailure,
  updateBatchRequest,
  updateBatchSuccess,
  forceScheduleBatchTaskFailure,
  forceScheduleBatchTaskRequest,
  forceScheduleBatchTaskSuccess,
} from "./action";

function* getBatches({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/batches?page=${payload}` : "/batches"
    );
    if (response.status === 200) {
      yield put(getBatchesSuccess(response.data));
    }
  } catch (e) {
    yield put(getBatchesFailure("e.message"));
  }
}

function* getBatch({ payload }) {
  try {
    const response = yield call(axios.get, `/batches/${payload}`);
    if (response.status === 200) {
      yield put(getBatchSuccess(response.data));
    }
  } catch (e) {
    yield put(getBatchFailure("e.message"));
  }
}

function* deleteBatch({ payload }) {
  try {
    const response = yield call(axios.delete, `/batches/${payload}`);
    if (response.status === 204) {
      yield put(deleteBatchSuccess());
    }
  } catch (e) {
    yield put(deleteBatchFailure("e.message"));
  }
}

function* createBatch({ payload }) {
  try {
    const response = yield call(axios.post, `/batches`, payload);
    if (response.status === 201) {
      yield put(createBatchSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createBatchFailure("e.message"));
  }
}

function* updateBatch({ payload }) {
  try {
    const response = yield call(
      axios.put,
      `/batches/${payload.id}`,
      payload.data
    );
    if (response.status === 200) {
      yield put(updateBatchSuccess(response.data));
    }
  } catch (e) {
    yield put(updateBatchFailure("e.message"));
  }
}

function* getBatchTypes() {
  try {
    const response = yield call(axios.get, "/batch-types");
    if (response.status === 200) {
      yield put(getBatchTypesSuccess(response.data));
    }
  } catch (e) {
    yield put(getBatchTypesFailure("e.message"));
  }
}

function* getBatchInstances() {
  try {
    const response = yield call(axios.get, "/batch-instances");
    if (response.status === 200) {
      yield put(getBatchInstancesSuccess(response.data));
    }
  } catch (e) {
    yield put(getBatchInstancesFailure("e.message"));
  }
}

function* getBatchTasks({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `/batches/${payload.id}/tasks?page=${payload.page}`
    );
    if (response.status === 200) {
      yield put(getBatchTasksSuccess(response.data));
    }
  } catch (e) {
    yield put(getBatchTasksFailure("e.message"));
  }
}

function* deleteTask({ payload }) {
  try {
    const response = yield call(
      axios.delete,
      `/batches/${payload.id}/tasks/${payload.taskId}`
    );
    if (response.status === 204) {
      yield put(deleteTaskSuccess());
    }
  } catch (e) {
    yield put(deleteTaskFailure("e.message"));
  }
}

function* forceScheduleBatchTask({ payload }) {
  try {
    const response = yield call(axios.post, `/batches/${payload.id}/tasks/0`);
    if (response.status === 201) {
      yield put(forceScheduleBatchTaskSuccess());
    }
  } catch (e) {
    yield put(forceScheduleBatchTaskFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getBatchesRequest, getBatches);
  yield takeLatest(getBatchRequest, getBatch);
  yield takeLatest(deleteBatchRequest, deleteBatch);
  yield takeLatest(createBatchRequest, createBatch);
  yield takeLatest(updateBatchRequest, updateBatch);
  yield takeLatest(getBatchTypesRequest, getBatchTypes);
  yield takeLatest(getBatchInstancesRequest, getBatchInstances);
  yield takeLatest(getBatchTasksRequest, getBatchTasks);
  yield takeLatest(deleteTaskRequest, deleteTask);
  yield takeLatest(forceScheduleBatchTaskRequest, forceScheduleBatchTask);
}
