import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createFunctionRequest } from "redux/functions/action";
import ReactTooltip from "react-tooltip";
import usePrevious from "utility/hooks/usePrevious";
import { items } from "utility/utility";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import FunctionContent from "components/functions/content/FunctionContent";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { newAttribute } from "utility/utility";

const CreateFunction = () => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dataSourceRef] = useCtrlSHandler(createFunction);

  const { isCreatedFunctionSuccess, isCreatedFunctionError, newFunctionId } =
    useSelector((state) => state.functions);

  const prevIsCreatedFunctionSuccess = usePrevious(isCreatedFunctionSuccess);
  const prevIsCreatedFunctionError = usePrevious(isCreatedFunctionError);

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    document.title = t("function_decisimo");
  }, []);

  useEffect(() => {
    if (isCreatedFunctionSuccess && prevIsCreatedFunctionSuccess === false) {
      setIsLoading(false);
      toast.success(t("function_added"), ToastOptions);
      navigate(`/functions/${newFunctionId}`);
    }
  }, [isCreatedFunctionSuccess]);

  useEffect(() => {
    if (isCreatedFunctionError && prevIsCreatedFunctionError === false) {
      setIsLoading(false);
    }
  }, [isCreatedFunctionError]);

  useEffect(() => {
    setAttributes([
      {
        ...structuredClone(newAttribute),
        fce_attribute_id: `new_${Math.floor(Math.random() * 1000000)}`,
      },
    ]);
  }, []);

  function createFunction(target) {
    setIsEdited(false);
    if (target.querySelector(".is-invalid") || !isFormValid) {
      return toast.error(t("attribute_value_valid"), ToastOptions);
    }
    const data = new URLSearchParams(new FormData(target));
    const name = target.name.value;
    const title = target.title.value;

    if (name.length === 0 || title.length === 0) {
      return toast.error(t("please_fill_title"), ToastOptions);
    }

    setIsLoading(true);
    dispatch(createFunctionRequest(data));
  }

  const [isFormValid, setIsFormValid] = useState(false);
  //check if the form is valid

  const isValidJsonPath = (path) => {
    // Check if the path is a non-empty string and starts with '$'
    return typeof path === "string" && path.startsWith("$.");
  };

  useEffect(() => {
    const hasValidAttributes = attributes.every((attr) => {
      //if the attribute is default, it is valid
      if (attr?.isDefault) return true;
      //if the attribute is not default, it should have a name and a valid JSONPath

      return attr.name && isValidJsonPath(attr.attribute_path);
    });

    // Update form validity state
    setIsFormValid(hasValidAttributes);
  }, [attributes]);

  return (
    <form
      ref={dataSourceRef}
      onSubmit={(e) => {
        e.preventDefault();
        createFunction(e.target);
      }}
      className="h-100 overflow-hidden"
      onChange={() => setIsEdited(true)}
    >
      <SubHeader
        title={t("function_definition")}
        actions={
          <>
            <Link
              to="/functions"
              className="mr-2"
              data-tip={true}
              data-for="back-to-functions-create"
            >
              <button className="btn outline">
                <BackIcon />
              </button>
            </Link>
            <ReactTooltip
              type="dark"
              place="top"
              effect="solid"
              id="back-to-functions-create"
            >
              {t("back_to_functions")}
            </ReactTooltip>

            <button
              className="btn primary mr-1"
              type="submit"
              id="function-save-btn"
              data-tip={true}
              data-for="create-function"
            >
              <SaveIcon />
            </button>
            <ReactTooltip
              type="dark"
              place="top"
              effect="solid"
              id="create-function"
            >
              {t("save_function")}
            </ReactTooltip>
          </>
        }
      />
      <FunctionContent
        items={items}
        attributes={attributes}
        setAttributes={setAttributes}
      />
    </form>
  );
};

export default CreateFunction;
