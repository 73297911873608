import React, { useContext, useEffect, useState } from "react";
import { updateUserAccountPasswordRequest } from "redux/user/action";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import usePrevious from "utility/hooks/usePrevious";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = ({ userId, open, handleClose }) => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isChangeAccountPasswordSuccess, isChangeAccountPasswordFailure } =
    useSelector((state) => {
      return state.users;
    });

  const prevIsChangeAccountPasswordSuccess = usePrevious(
    isChangeAccountPasswordSuccess
  );

  const prevIsChangeAccountPasswordFailure = usePrevious(
    isChangeAccountPasswordFailure
  );

  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (
      (isChangeAccountPasswordSuccess &&
        prevIsChangeAccountPasswordSuccess === false) ||
      (isChangeAccountPasswordFailure &&
        prevIsChangeAccountPasswordFailure === false)
    ) {
      setIsLoading(false);
      handleClose();
    }
  }, [isChangeAccountPasswordSuccess, isChangeAccountPasswordFailure]);

  const handleConfirm = (e) => {
    e.preventDefault();
    const data = new URLSearchParams();
    data.append("password", e.target.new_password.value);
    data.append("user_account_id", userId);
    setIsLoading(true);
    dispatch(updateUserAccountPasswordRequest(data));
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("change_user_password")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form id="changePasswordForm" onSubmit={(e) => handleConfirm(e)}>
            <label>{t("new_password")}</label>
            <div className="input-group mb-3">
              <input
                className="form-control"
                name="new_password"
                type={passwordType}
                required
              />
              <div className="input-group-append">
                <button
                  className="btn outline"
                  type="button"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? <EyeIcon /> : <EyeOffIcon />}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            variant=""
            className="primary"
            form="changePasswordForm"
            type="submit"
          >
            {t("confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ChangePasswordModal.propTypes = {
  userId: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ChangePasswordModal;
