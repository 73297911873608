import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import React, { useState } from "react";
import PropTypes from "prop-types";

const StorageSourceItem = ({ option, batch }) => {
  const [secret, setSecret] = useState(option.secret);

  const togglePassword = () => {
    if (secret === 1) {
      setSecret(0);
      return;
    }
    setSecret(1);
  };

  return (
    <div>
      <label>{option.title || "null"}</label>
      <div className="input-group mb-3">
        <input
          type={secret ? "password" : "text"}
          className="form-control field"
          name={`batch_option[${option.batch_type_option_id}]`}
          defaultValue={
            (Object.keys(batch).length > 0 &&
              batch.options?.find(
                (batchOption) =>
                  batchOption.batch_type_option_id ===
                  option.batch_type_option_id
              )?.value) ||
            ""
          }
          key={option.batch_type_option_id}
        />

        {option.secret ? (
          <div className="input-group-append">
            <button
              className="btn outline border-radius-none-left-top-bottom"
              type="button"
              onClick={togglePassword}
            >
              {secret ? <EyeIcon /> : <EyeOffIcon />}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

StorageSourceItem.propTypes = {
  option: PropTypes.object,
  batch: PropTypes.object,
};

export default StorageSourceItem;
