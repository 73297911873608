import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MainContext } from "context/contexts";

const Import = (props) => {
  const { open, handleClose, handleConfirm } = props;
  const { t } = useTranslation();
  const { setIsLoading, isLoading } = useContext(MainContext); // Use context for loading state
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileValid, setFileValid] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelectedFile(null);
      setFileValid(false);
    }
  }, [open]);

  const handleFileChange = (e) => {
    setIsLoading(true); // Start loading indicator
    const file = e.target.files[0];

    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      setFileValid(true);
    } else {
      setSelectedFile(null);
      setFileValid(false);
    }

    setIsLoading(false); // Stop loading indicator after validation
  };

  const handleImport = () => {
    handleConfirm(selectedFile);
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title">{t("import")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <label>
          <em>{t("select_csv_file")}</em>
        </label>
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id="csv-file"
            onChange={handleFileChange}
            accept=".csv"
            disabled={isLoading}
          />
          <label
            className={`custom-file-label ${
              selectedFile ? "border-success text-success" : ""
            }`}
            htmlFor="csv-file"
          >
            {selectedFile?.name || t("file_import")}
          </label>
        </div>
        {isLoading && (
          <div className="d-flex justify-content-center my-3">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}</span>
            </div>
          </div>
        )}
        {!isLoading && selectedFile && !fileValid && (
          <div className="text-danger mt-2">{t("invalid_file_type")}</div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant=""
          onClick={handleClose}
          className="outline my-0"
          disabled={isLoading}
        >
          {t("close")}
        </Button>

        <Button
          variant=""
          onClick={handleImport}
          className="primary my-0"
          disabled={isLoading || !fileValid}
        >
          {t("import")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Import.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default Import;
