import { ToastContainer } from "react-toastify";
import React from "react";

export const ToastOptions = {
  position: "bottom-right",
  autoClose: 3000,
  closeOnClick: true,
  icon: false,
  hideProgressBar: true,
  closeButton: false,
};

export const Toast = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme="colored"
    />
  );
};
