import React, { useEffect, useState } from "react";
import SubHeader from "../SubHeader";
import { Link, useNavigate } from "react-router-dom";
import { getTemplateDecisionsRequest } from "redux/templates/action";
import { ReactComponent as IdentifierIcon } from "assets/icons/templateIcons/identifier_icon.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TemplateModal from "../modals/Template";

const DecisionDesigners = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  const { templatesDecisions } = useSelector((state) => state.templates);

  useEffect(() => {
    document.title = "AI Decision Designer - Decisimo";
    dispatch(getTemplateDecisionsRequest());
  }, []);

  const handleOpenTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
  };

  return (
    <>
      <SubHeader
        title={t("decision_strategies")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre">
            <button
              title={t("create_decision_designer")}
              className="btn primary-header border-right-0 border-radius-4"
              type="button"
              onClick={handleOpenTemplatesModal}
            >
              <PlusIcon />
              <span className="ml-2">{t("decision_designer")}</span>
            </button>
          </div>
        }
      />
      <div className="mb-3">
        <table className="table table-fixed border-top-0 decision-designer-table">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow py-3">
                {t("table_title")}
              </th>
              <th scope="col" className="th-flow py-3">
                {t("identifier")}
              </th>
              <th scope="col" className="th-flow py-3">
                {t("created")}
              </th>
            </tr>
          </thead>
          <tbody>
            {templatesDecisions?.length
              ? templatesDecisions.map((decision) => {
                  return (
                    <tr
                      key={decision.ai_decision_design_id}
                      className="td-flow"
                    >
                      <td className="strategies-col">
                        <Link
                          to={`/ai-decision-design/${decision.ai_decision_design_id}`}
                          className="function-item"
                        >
                          {decision.title}
                        </Link>
                      </td>
                      <td>
                        <Button
                          className="strategies-btn"
                          onClick={() =>
                            navigate(
                              `/ai-decision-design/${decision.ai_decision_design_id}`
                            )
                          }
                        >
                          <IdentifierIcon />
                          <span className="ml-2">{decision.name}</span>
                        </Button>
                      </td>
                      <td className="strategies-col">
                        {decision.dtime_inserted}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {isTemplatesModalOpen && (
          <TemplateModal
            open={isTemplatesModalOpen}
            handleClose={() => setIsTemplatesModalOpen(false)}
            type={"decision_designer"}
          />
        )}
      </div>
    </>
  );
};

export default DecisionDesigners;
