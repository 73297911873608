import React, { useEffect, useState } from "react";
import { createWorkflowRequest } from "redux/workflows/action";
import PropTypes from "prop-types";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-blue.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete-small.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/error.svg";
import { ReactComponent as DataSourceIcon } from "assets/icons/node-types/data-source-large.svg";
import { ReactComponent as ForkIcon } from "assets/icons/node-types/fork-large.svg";
import { ReactComponent as ModelIcon } from "assets/icons/model-large.svg";
import { ReactComponent as RuleSetIcon } from "assets/icons/ruleset-large.svg";
import { ReactComponent as DecisionTableIcon } from "assets/icons/decision-table-large.svg";
import { ReactComponent as FunctionIcon } from "assets/icons/function-large.svg";
import { ReactComponent as SubFlowIcon } from "assets/icons/subflow-large.svg";
import { ReactComponent as StartIcon } from "assets/icons/start.svg";
import { ReactComponent as ScorecardIcon } from "assets/icons/d-scorecard-small.svg";

export const createWorkflowCopy = (content, dispatch, isUseCase = false) => {
  const data = new URLSearchParams(new FormData());

  data.append("name", !isUseCase ? `Copy of ${content.name}` : content.name);
  data.append("title", !isUseCase ? `Copy of ${content.title}` : content.title);
  data.append("description", content.description);
  data.append("block_deletion", content.block_deletion || 0);

  dispatch(
    createWorkflowRequest({
      data,
      isUseCase,
    })
  );
};

export const getTypeIdKey = (type = "", key = "") => {
  switch (type) {
    case "fork":
      key = "fork";
      break;
    case "external_data_call":
      key = "external_data_id";
      break;
    case "subflow":
      key = "sub_workflow_id";
      break;
    case "decision_table":
      key = "decision_table_id";
      break;
    case "model":
      key = "model_id";
      break;
    case "fce":
      key = "fce_id";
      break;
    case "rule_set":
      key = "rule_set_id";
      break;
    case "scorecard":
      key = "scorecard_id";
      break;
  }

  return key;
};

const Button = ({ icon, text }) => {
  const [hidden, setHidden] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setHidden("hidden");
    }, 3000);
  }, []);
  return (
    <span
      className={`btn mr-2 d-flex align-items-center font-size-14 transition shadow bg-white ${hidden}`}
      onMouseEnter={() => setHidden("hidden")}
    >
      {icon} <span className="ml-3">{text}</span>
    </span>
  );
};

Button.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
};

const types = {
  updated: <Button icon={<CheckIcon />} text={"Workflow saved"} />,
  "saved-step": <Button icon={<CheckIcon />} text={"Step saved"} />,
  released: <Button icon={<CheckIcon />} text={"Workflow released"} />,
  "new-step": <Button icon={<InfoIcon />} text={"New step added"} />,
  "deleted-step": <Button icon={<DeleteIcon />} text={"Step deleted"} />,
  error: <Button icon={<ErrorIcon />} text={"Unable to process"} />,
  "link-deleted": (
    <Button icon={<DeleteIcon />} text={"Workflow link deleted"} />
  ),
};

const Title = ({ icon, title }) => {
  return (
    <>
      {icon} {title}
    </>
  );
};

export const stepTitles = (t) => ({
  external_data_call: (
    <Title
      icon={<DataSourceIcon height={"24px"} className="mr-3" />}
      title={t("data_source")}
    />
  ),
  fork: (
    <Title
      icon={<ForkIcon height={"24px"} className="mr-3" />}
      title={t("fork")}
    />
  ),
  decision_table: (
    <Title
      icon={<DecisionTableIcon height={"24px"} className="mr-3" />}
      title={t("decision_table")}
    />
  ),
  scorecard: (
    <Title
      icon={<ScorecardIcon height={"24px"} className="mr-3" />}
      title={t("scorecard")}
    />
  ),
  subflow: (
    <Title
      icon={<SubFlowIcon height={"24px"} className="mr-3" />}
      title={t("subflow")}
    />
  ),
  rule_set: (
    <Title
      icon={<RuleSetIcon height={"24px"} className="mr-3" />}
      title={t("rule_set_step")}
    />
  ),
  model: (
    <Title
      icon={<ModelIcon height={"24px"} className="mr-3" />}
      title={t("model")}
    />
  ),
  fce: (
    <Title
      icon={<FunctionIcon height={"24px"} className="mr-3" />}
      title={t("function")}
    />
  ),
  start: (
    <Title
      icon={<StartIcon height={"24px"} className="mr-3" />}
      title={t("start")}
    />
  ),
});

export const Notification = ({ type = "updated" }) => {
  return types[type];
};

Notification.propTypes = {
  type: PropTypes.string,
};

Title.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
};
