import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import usePrevious from "utility/hooks/usePrevious";
import { Loader } from "utility/utility";
import { ReactComponent as Logo } from "assets/images/decisimo-logo-monochrome.svg";
import { ReactComponent as NewSettingsIcon } from "assets/icons/account-setting-icon.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { signOutRequest } from "../../redux/auth/action";

const Header = ({ isSideBarOpen, isLoading }) => {
  const dispatch = useDispatch();

  const { isMobile } = useContext(MainContext);
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const { isUpdateSubscriptionsSuccess } = useSelector(
    (state) => state.subscriptions
  );

  const prevIsUpdateSubscriptionsSuccess = usePrevious(
    isUpdateSubscriptionsSuccess
  );

  const [tenant, setTenant] = useState(localStorage.getItem("tenant"));

  useEffect(() => {
    if (
      isUpdateSubscriptionsSuccess &&
      prevIsUpdateSubscriptionsSuccess === false
    ) {
      setTenant(localStorage.getItem("tenant"));
    }
  }, [isUpdateSubscriptionsSuccess]);

  const onHandleLogout = () => {
    const token = localStorage.getItem("token");
    dispatch(signOutRequest(token));
  };

  return (
    <nav
      className={`navbar navbar-dark fixed-top bg-dark shadow flex-nowrap p-0 ${
        isMobile ? "px-2" : ""
      }`}
    >
      {isMobile ? (
        <button
          className="navbar-toggler p-0 text-sm font-size-18"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      ) : null}
      <Link
        className={`${
          isSideBarOpen && !isMobile
            ? "col-sm-3 col-lg-2 col-md-3"
            : isMobile
            ? ""
            : "col-auto mr-auto header-logo-link-sidebar"
        } ${!isMobile ? "navbar-brand" : ""}  mr-0 p-0 pl-1`}
        to="/dashboard"
      >
        <span
          className={`${
            !isSideBarOpen && !isMobile ? "header-logo-sidebar" : ""
          }`}
        >
          <Logo height="40" />
        </span>
      </Link>
      {isLoading && <Loader />}
      <div className="d-flex align-items-center">
        <ul className="ml-md-auto mr-0 mr-md-4 navbar-nav">
          <li className="nav-item dropdown">
            <Link
              className="nav-item nav-link md-2"
              to="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <button
                title={t("header_user_setting")}
                className="btn primary btn-sm"
                id="user-info"
              >
                <NewSettingsIcon />
              </button>
            </Link>
            <div
              className={`dropdown-menu dropdown-menu-md-right position-absolute ${
                isMobile ? "right-0" : ""
              }`}
              aria-labelledby="dropdownMenuLink"
            >
              <h6 className="dropdown-header d-flex">
                <UserIcon />
                <span className="ml-3">
                  {user?.full_name} {tenant && `(${tenant})`}
                </span>
              </h6>
              <div className="dropdown-divider" />
              <Link
                className="dropdown-item"
                to="/change-password"
                title={t("header_change_password")}
              >
                {t("header_change_password")}
              </Link>
              <Link
                className="dropdown-item"
                to="/settings"
                title={t("header_change_settings")}
              >
                {t("header_change_settings")}
              </Link>
              <div className="dropdown-divider" />
              <Link
                className="dropdown-item"
                to="/admin-center"
                title={t("admin_center")}
              >
                {t("admin_center")}
              </Link>
              <div className="dropdown-divider" />
              <button
                className="dropdown-item"
                type="button"
                onClick={onHandleLogout}
                title={t("header_change_sign_out")}
              >
                {t("header_change_sign_out")}
                <LogoutIcon />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

Header.propTypes = {
  isSideBarOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Header;
