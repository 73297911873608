import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as DeploymentIcon } from "assets/icons/deployments.svg";
import { useTranslation } from "react-i18next";
import { toInteger } from "lodash";

const DeployRelease = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleConfirm,
    title = t("deploy_to_endpoint"),
    data,
    isRequestLoading,
    finishedSequences,
    startApprovalProcess = () => {},
  } = props;

  const [selectedEndpoint, setSelectedEndpoint] = React.useState(null);
  const [cannotDeploy, setCannotDeploy] = React.useState(false);
  const [showApprovalMessage, setShowApprovalMessage] = React.useState(false);

  const handleEndpointChange = (e) => {
    const selectedId = e.target.value;
    const endpoint = data.find(
      (item) => item.endpoint_placeholder_id === selectedId
    );
    setSelectedEndpoint(endpoint);
  };

  // Check if deployment is allowed for the selected endpoint
  React.useEffect(() => {
    if (selectedEndpoint) {
      if (
        selectedEndpoint.approval_sequence_id &&
        !finishedSequences.includes(
          toInteger(selectedEndpoint.approval_sequence_id)
        )
      ) {
        setCannotDeploy(true);
        setShowApprovalMessage(true);
      } else {
        setCannotDeploy(false);
        setShowApprovalMessage(false);
      }
    } else {
      setCannotDeploy(true);
      setShowApprovalMessage(false);
    }
  }, [selectedEndpoint, finishedSequences]);

  const handleClickStartApprovalProcess = (approvalSequenceId) => {
    startApprovalProcess(approvalSequenceId);
  };

  return (
    <>
      <Modal size="md" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <Modal.Body>
            {data?.length > 0 && Array.isArray(data) ? (
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text mr-0">
                    {t("where_to_deploy")}:{" "}
                  </label>
                </div>
                <select
                  name="endpoint_placeholder_id"
                  className="form-control"
                  onChange={handleEndpointChange}
                >
                  <option value="">{t("select_endpoint")}</option>
                  {data?.map((item) => (
                    <option
                      key={item.endpoint_placeholder_id}
                      value={item.endpoint_placeholder_id}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="alert alert-danger mb-0 mt-3 p-1" role="alert">
                {t("first_add_an_endpoint")}
              </div>
            )}
            {showApprovalMessage && (
              <div className="alert alert-warning mb-0 mt-3 p-1" role="alert">
                {t("first_need_to_run_approval_sequence")}
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() =>
                    handleClickStartApprovalProcess(
                      selectedEndpoint.approval_sequence_id
                    )
                  }
                >
                  {t("run_approval_sequence")}
                </Button>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="outline deploy-btn"
              onClick={handleClose}
              type="button"
            >
              {t("close")}
            </Button>
            {!cannotDeploy && (
              <Button
                variant=""
                type="submit"
                className="btn primary deploy-btn w-auto"
                disabled={isRequestLoading}
              >
                {isRequestLoading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">{t("loading")}</span>
                  </div>
                ) : (
                  <>
                    <DeploymentIcon className="mr-2" /> {t("deploy")}
                  </>
                )}
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

DeployRelease.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.array,
  isRequestLoading: PropTypes.bool,
  finishedSequences: PropTypes.arrayOf(PropTypes.any),
  startApprovalProcess: PropTypes.func,
};

export default DeployRelease;
