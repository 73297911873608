import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  getTimeZonesFailure,
  getTimeZonesRequest,
  getTimeZonesSuccess,
} from "./action";

function* getTimeZones() {
  try {
    const response = yield call(axios.get, "/timezones");
    if (response.status === 200) {
      yield put(getTimeZonesSuccess(response.data));
    }
  } catch (e) {
    yield put(getTimeZonesFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getTimeZonesRequest, getTimeZones);
}
