import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { arrayMove } from "@dnd-kit/sortable";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDecisionTableRowRequest,
  getConditionsRequest,
} from "redux/decisionTables/action";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ToastOptions } from "components/toastify";
import { compareColumns, sortByOrder } from "utility/utility";
import { Link, useParams } from "react-router-dom";
import { DecisionTableContext, MainContext } from "context/contexts";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import Table from "./Table";
import { useTranslation } from "react-i18next";

const DecisionTableRows = React.forwardRef(
  (
    {
      decisionTableColsRoles,
      decisionTableCols,
      decisionTableRowsData,
      setDecisionTableRowsData,
      tableId,
      revisionMode = false,
    },
    ref
  ) => {
    const { setIsLoading, setIsEdited } = useContext(MainContext);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const { sortableMode } = useContext(DecisionTableContext);

    const {
      isRemovedDecisionTableRowSuccess,
      isGetConditionsSuccess,
      conditions,
    } = useSelector((state) => state.decisionTables);

    const prevIsRemovedDecisionTableRowSuccess = usePrevious(
      isRemovedDecisionTableRowSuccess
    );
    const prevIsGetConditionsSuccess = usePrevious(isGetConditionsSuccess);

    const [contextMenuIds, setContextMenuIds] = useState({});
    const [isRowDeleteModalOpen, setIsRowDeleteModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [colsClone, setColsClone] = useState([]);
    const [conditionsClone, setConditionsClone] = useState([]);
    const [finalRowsData, setFinalRowsData] = useState([]);
    const [rowColumns, setRowColumns] = useState([]);

    useEffect(() => {
      setIsLoading(true);
      dispatch(getConditionsRequest(id));

      return () => {
        setIsEdited(false);
      };
    }, []);

    useEffect(() => {
      if (isGetConditionsSuccess && prevIsGetConditionsSuccess === false) {
        setIsLoading(false);
        const conditionsData = structuredClone(conditions);
        let data = structuredClone(Object.values(conditionsData));
        data = data.filter((item) => {
          return (
            (item.title = item.title.replace("&ne;", "≠")),
            (item.title = item.title.replace("$", t("ends_with_$"))),
            (item.title = item.title.replace("^", t("starts_with_^")))
          );
        });
        setConditionsClone(data);
      }
    }, [isGetConditionsSuccess]);

    useEffect(() => {
      if (decisionTableCols?.length > 0) {
        setIsLoading(false);
        const sortedCols = decisionTableCols.sort(compareColumns);
        setColsClone(sortedCols);

        if (decisionTableRowsData?.length > 0) {
          const finalRows = decisionTableRowsData.map((row, index) => {
            const finalRow = {
              decision_table_row_id: row.decision_table_row_id,
              row_index: row.row_index,
              cols: [],
              is_new: !!row.is_new,
            };

            sortedCols.map((column) => {
              const columnId = column.decision_table_column_id;
              const columnIndex = row.cols.findIndex((rowCol) => {
                return rowCol.decision_table_column_id === columnId;
              });

              if (columnIndex !== -1) {
                finalRow["cols"].push(row.cols[columnIndex]);
              } else {
                finalRow["cols"].push({
                  decision_table_column_id: columnId,
                  dt_row_column_id: `new_${columnId}_${index}`,
                  operator: "any_value",
                  role: column.role,
                  value_a: "",
                  value_b: "",
                });
              }
            });

            return finalRow;
          });
          setFinalRowsData(finalRows);
        } else {
          setFinalRowsData([]);
        }
      }
    }, [decisionTableCols, decisionTableRowsData]);

    useEffect(() => {
      if (
        isRemovedDecisionTableRowSuccess &&
        prevIsRemovedDecisionTableRowSuccess === false
      ) {
        setIsLoading(false);
        toast.warning(t("row_deleted"), ToastOptions);
      }
    }, [isRemovedDecisionTableRowSuccess]);

    useEffect(() => {
      if (decisionTableColsRoles?.length > 0 && rowColumns.length === 0) {
        const decisionTableColsRolesClone = structuredClone(
          decisionTableColsRoles
        );
        const newRowClone = [];
        decisionTableColsRolesClone
          .sort(sortByOrder)
          .map(({ role, decision_table_column_id }) => {
            newRowClone.push({
              operator: "any_value",
              value_a: "",
              value_b: "",
              decision_table_column_id,
              dt_row_column_id: `new_${Math.round(Math.random() * 10000)}`,
              role,
            });
          });
        setRowColumns(newRowClone);
      }
    }, [decisionTableColsRoles]);

    const handleClose = () => {
      setIsRowDeleteModalOpen(false);
    };

    const handleShow = useCallback((id) => {
      const row = finalRowsData.find(
        (rowData) => rowData.decision_table_row_id === id
      );
      setSelectedRow(row);
      setIsRowDeleteModalOpen(true);
    });

    const handleConfirm = useCallback(() => {
      const rowId = selectedRow.decision_table_row_id;
      const filteredRows = finalRowsData.filter(
        (row) => row.decision_table_row_id !== selectedRow.decision_table_row_id
      );
      setIsEdited(true);
      setFinalRowsData(filteredRows);
      setDecisionTableRowsData(filteredRows);
      if (!selectedRow.is_new) {
        setIsLoading(true);
        dispatch(deleteDecisionTableRowRequest({ tableId, rowId }));
      } else {
        toast.warning(t("row_deleted"), ToastOptions);
      }
      handleClose();
    });

    const changeColumnType = useCallback((type, rowId, columnId) => {
      setIsEdited(true);
      const rowsClone = structuredClone(finalRowsData);
      const rowClone = rowsClone.find(
        (row) => row.decision_table_row_id === rowId
      );
      const column = rowClone.cols.find(
        (col) => col.dt_row_column_id === columnId
      );
      column.operator = type;
      setDecisionTableRowsData(rowsClone);
    });

    const handleDragEnd = useCallback((event) => {
      const { active, over } = event;
      if (active && over && active?.id !== over?.id) {
        const data = () => {
          const oldIndex = finalRowsData.findIndex(
            (row) => row.decision_table_row_id === active.id
          );
          const newIndex = finalRowsData.findIndex(
            (row) => row.decision_table_row_id === over.id
          );

          const newItemsArray = arrayMove(finalRowsData, oldIndex, newIndex);

          return newItemsArray;
        };
        setIsEdited(true);
        setFinalRowsData(data());
      }
    });

    const operatorTypeChange = useCallback(
      (value, decision_table_row_id, dt_row_column_id) => {
        changeColumnType(value, decision_table_row_id, dt_row_column_id);
      }
    );

    useImperativeHandle(
      ref,
      () => ({
        getFinalRowsData() {
          return finalRowsData;
        },
      }),
      [finalRowsData]
    );

    const addRow = useCallback(() => {
      if (Object.keys(contextMenuIds)?.length > 0) {
        setContextMenuIds({});
      }
      setIsEdited(true);
      setFinalRowsData((prevState) => {
        const newRowClone = {
          cols: [],
          decision_table_row_id: `new_${parseInt(Date.now() * Math.random())}`,
          row_index: `${finalRowsData?.length}`,
          is_new: true,
        };

        if (rowColumns?.length > 0) {
          const columnsClone = structuredClone(rowColumns);
          columnsClone.sort(compareColumns).map((col) => {
            col.dt_row_column_id = `new_${parseInt(
              Date.now() * Math.random()
            )}`;
          });
          newRowClone.cols = columnsClone;
        }
        return [...prevState, newRowClone];
      });
    }, [rowColumns]);

    return (
      <>
        <div style={{ overflowX: "hidden", width: "100%" }}>
          <div
            style={{
              width: "calc(100vw - 100px)", // Account for sidebar/padding
              maxWidth: "100%",
              overflowX: "auto",
            }}
            className="table-responsive decision-table border-top-radius-left-right-3"
          >
            <Table
              handleShow={handleShow}
              revisionMode={revisionMode}
              contextMenuIds={contextMenuIds}
              setContextMenuIds={setContextMenuIds}
              finalRowsData={finalRowsData}
              colsClone={colsClone}
              decisionTableRowsData={decisionTableRowsData}
              handleDragEnd={handleDragEnd}
            />
            {!revisionMode && contextMenuIds?.dt_row_column_id ? (
              <>
                <ContextMenu
                  id={`${contextMenuIds?.dt_row_column_id}`}
                  className={`dropdown-menu cursor-pointer ${
                    contextMenuIds?.dt_row_column_id ? "d-block" : "d-none"
                  }`}
                >
                  <div className="p-0">
                    {conditionsClone?.map((item) => {
                      return (
                        <MenuItem
                          key={item.dt_condition_type_id}
                          className="dropdown-item"
                          onClick={() =>
                            operatorTypeChange(
                              item.name,
                              contextMenuIds.decision_table_row_id,
                              contextMenuIds.dt_row_column_id
                            )
                          }
                        >
                          <Link to="#" tabIndex="-1" value={item.name}>
                            {item.title}
                          </Link>
                        </MenuItem>
                      );
                    })}
                  </div>
                </ContextMenu>
              </>
            ) : null}

            {!revisionMode && !sortableMode && (
              <div className="d-flex justify-content-center add-row-bg py-3 border-bottom-radius-left-right-5 border">
                <button className="btn outline" onClick={addRow} type="button">
                  <PlusIcon
                    style={{
                      filter: "brightness(0.5)",
                    }}
                  />
                  <span className="ml-2">{t("add_row")}</span>
                </button>
              </div>
            )}
          </div>

          <DeleteConfirm
            handleClose={handleClose}
            handleConfirm={() => handleConfirm()}
            title={t("delete_row")}
            message={t("delete_row_message")}
            open={isRowDeleteModalOpen}
          />
        </div>
      </>
    );
  }
);

DecisionTableRows.displayName = "DecisionTableRows";

DecisionTableRows.propTypes = {
  decisionTableColsRoles: PropTypes.array,
  decisionTableCols: PropTypes.any,
  decisionTableRowsData: PropTypes.array,
  setDecisionTableRowsData: PropTypes.func,
  tableId: PropTypes.number,
  revisionMode: PropTypes.bool,
};

export default memo(DecisionTableRows);
