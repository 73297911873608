import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import React from "react";
import PropTypes from "prop-types";

export const JsonStructure = ({ jsonData }) => {
  return (
    <CodeMirror
      value={JSON.stringify(jsonData, null, 2)}
      extensions={[json(), EditorView.lineWrapping]}
      cursor={{
        line: 1,
        ch: 1,
      }}
      options={{
        mode: "application/json",
        theme: "material",
        lineNumbers: true,
      }}
      readOnly
      height="628px"
      width="100"
    />
  );
};

JsonStructure.propTypes = {
  jsonData: PropTypes.any,
};
