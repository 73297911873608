import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Constants = ({ item }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="input-group-text mb-1 border-radius-0 border-radius-right-0"
        data-tip={true}
        data-for="right-click-to-change"
      >
        {item.operator === "pi"
          ? "𝝅"
          : item.operator === "rand"
          ? "random"
          : item.operator}
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][parent_fce_item_id]`}
          defaultValue={
            item.parent_fce_item_id ? item.parent_fce_item_id : "null"
          }
        />
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][item_type]`}
          defaultValue={item.item_type}
        />
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][operand]`}
          defaultValue={item.operator}
        />
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][fce_item_id]`}
          defaultValue={item.fce_item_id}
        />
      </div>
      <ReactTooltip
        type="dark"
        place="top"
        effect="solid"
        id="right-click-to-change"
      >
        {t("right_click")}
      </ReactTooltip>
    </>
  );
};

Constants.propTypes = {
  item: PropTypes.object,
};

export default Constants;
