import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDataSourceRequest } from "redux/dataSources/action";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useNavigate } from "react-router-dom";
import SubHeader from "components/SubHeader";
import DataSourceContent from "components/dataSources/DataSourceContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { MainContext } from "context/contexts";
import { toast } from "react-toastify";
import { ToastOptions } from "../toastify";
import { useTranslation } from "react-i18next";

const CreateDataSource = () => {
  const [dataSourceRef] = useCtrlSHandler(createDataSource);
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isCreatedDataSourceSuccess, newDataSourceId } = useSelector(
    (state) => state.dataSources
  );

  const prevIsCreatedDataSourceSuccess = usePrevious(
    isCreatedDataSourceSuccess
  );

  useEffect(() => {
    document.title = `${t("data_source")} - Decisimo`;
  }, []);

  useEffect(() => {
    if (
      isCreatedDataSourceSuccess &&
      prevIsCreatedDataSourceSuccess === false
    ) {
      setIsLoading(false);
      toast.success(`${t("data_source_created")}.`, ToastOptions);
      navigate(`/external-data-source/${newDataSourceId}`);
    }
  }, [isCreatedDataSourceSuccess]);

  function createDataSource(target) {
    setIsEdited(false);
    const data = new FormData(target);
    const title = target.title.value;
    const name = target.name.value;

    if (title?.length === 0 || name?.length === 0) {
      return toast.error(
        `${t("please_fill_out_the_title_or_id")}.`,
        ToastOptions
      );
    }

    for (const [key, value] of data) {
      data.set(key, value);
      if (key.includes("push_to_flow")) {
        if (value === "on" || value === "1") {
          data.set(key, 1);
        }
      }
    }

    setIsLoading(true);
    dispatch(createDataSourceRequest({ data }));
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          createDataSource(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          title={t("external_data")}
          actions={
            <button className="btn primary mr-1" type="submit">
              <SaveIcon />
            </button>
          }
        />
        <DataSourceContent isNew={true} />
      </form>
    </>
  );
};

export default CreateDataSource;
