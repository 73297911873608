import { handleActions } from "redux-actions";
import {
  createWorkflowFailure,
  createWorkflowReleaseFailure,
  createWorkflowReleaseRequest,
  createWorkflowReleaseSuccess,
  createWorkflowRequest,
  createWorkflowStepFailure,
  createWorkflowStepRequest,
  createWorkflowStepSuccess,
  createWorkflowSuccess,
  deleteLinkFailure,
  deleteLinkRequest,
  deleteLinkSuccess,
  deleteStepOptionFailure,
  deleteStepOptionParamFailure,
  deleteStepOptionParamRequest,
  deleteStepOptionParamSuccess,
  deleteStepOptionRequest,
  deleteStepOptionSuccess,
  deleteWorkflowFailure,
  deleteWorkflowRequest,
  deleteWorkflowStepFailure,
  deleteWorkflowStepRequest,
  deleteWorkflowStepSuccess,
  deleteWorkflowSuccess,
  getWorkflowFailure,
  getWorkflowOptionConditionTypesFailure,
  getWorkflowOptionConditionTypesRequest,
  getWorkflowOptionConditionTypesSuccess,
  getWorkflowRequest,
  getWorkflowRevisionFailure,
  getWorkflowRevisionRequest,
  getWorkflowRevisionsFailure,
  getWorkflowRevisionsRequest,
  getWorkflowRevisionsSuccess,
  getWorkflowRevisionSuccess,
  getWorkflowsFailure,
  getWorkflowsRequest,
  getWorkflowsSuccess,
  getWorkflowStepTypesFailure,
  getWorkflowStepTypesRequest,
  getWorkflowStepTypesSuccess,
  getWorkflowSuccess,
  updateWorkflowFailure,
  updateWorkflowRequest,
  updateWorkflowStepFailure,
  updateWorkflowStepRequest,
  updateWorkflowStepSuccess,
  updateWorkflowSuccess,
  useCaseWorkflowRequest,
} from "redux/workflows/action";

const initialState = {
  workflows: [],
  workflow: {},
  useCaseWorkflow: {},
  workflowRevisions: [],
  workflowRevision: {},
  workflowStepTypes: [],
  workflowOptionConditionTypes: [],
  eTag: null,
  releaseETag: null,
  createdStepId: null,
  isGetWorkflowsSuccess: false,
  isGetWorkflowsError: false,
  isGetWorkflowSuccess: false,
  isGetWorkflowError: false,
  isGetWorkflowStepTypesSuccess: false,
  isGetWorkflowStepTypesError: false,
  isGetWorkflowOptionConditionTypesSuccess: false,
  isGetWorkflowOptionConditionTypesError: false,
  isGetWorkflowRevisionsSuccess: false,
  isGetWorkflowRevisionsError: false,
  isGetWorkflowRevisionSuccess: false,
  isGetWorkflowRevisionError: false,
  isCreatedWorkflowSuccess: false,
  isCreatedWorkflowError: false,
  isCreatedWorkflowReleaseSuccess: false,
  isCreatedWorkflowReleaseError: false,
  isCreatedWorkflowStepSuccess: false,
  isCreatedWorkflowStepError: false,
  isUpdatedWorkflowSuccess: false,
  isUpdatedWorkflowError: false,
  isUpdatedWorkflowStepSuccess: false,
  isUpdatedWorkflowStepError: false,
  isDeletedWorkflowSuccess: false,
  isDeletedWorkflowError: false,
  isDeletedWorkflowStepSuccess: false,
  isDeletedWorkflowStepError: false,
  isDeletedStepOptionSuccess: false,
  isDeletedStepOptionError: false,
  isDeletedStepOptionParamSuccess: false,
  isDeletedStepOptionParamError: false,
  isDeletedLinkSuccess: false,
  isDeletedLinkError: false,
};

const reducer = handleActions(
  {
    // get workflows
    [getWorkflowsRequest]: (state) => ({
      ...state,
      isGetWorkflowsSuccess: false,
      isGetWorkflowsError: false,
    }),
    [getWorkflowsSuccess]: (state, { payload }) => ({
      ...state,
      workflows: payload ?? [],
      isGetWorkflowsSuccess: true,
      isGetWorkflowsError: false,
    }),
    [getWorkflowsFailure]: (state) => ({
      ...state,
      isGetWorkflowsSuccess: false,
      isGetWorkflowsError: true,
    }),
    // get workflow
    [getWorkflowRequest]: (state) => ({
      ...state,
      isGetWorkflowSuccess: false,
      isGetWorkflowError: false,
    }),
    [getWorkflowSuccess]: (state, { payload }) => ({
      ...state,
      workflow: payload,
      isGetWorkflowSuccess: true,
      isGetWorkflowError: false,
    }),
    [getWorkflowFailure]: (state) => ({
      ...state,
      isGetWorkflowSuccess: false,
      isGetWorkflowError: true,
    }),
    // get workflow step types
    [getWorkflowStepTypesRequest]: (state) => ({
      ...state,
      isGetWorkflowStepTypesSuccess: false,
      isGetWorkflowStepTypesError: false,
    }),
    [getWorkflowStepTypesSuccess]: (state, { payload }) => ({
      ...state,
      workflowStepTypes: payload,
      isGetWorkflowStepTypesSuccess: true,
      isGetWorkflowStepTypesError: false,
    }),
    [getWorkflowStepTypesFailure]: (state) => ({
      ...state,
      isGetWorkflowStepTypesSuccess: false,
      isGetWorkflowStepTypesError: true,
    }),
    // get workflow option condition types
    [getWorkflowOptionConditionTypesRequest]: (state) => ({
      ...state,
      isGetWorkflowOptionConditionTypesSuccess: false,
      isGetWorkflowOptionConditionTypesError: false,
    }),
    [getWorkflowOptionConditionTypesSuccess]: (state, { payload }) => ({
      ...state,
      workflowOptionConditionTypes: payload,
      isGetWorkflowOptionConditionTypesSuccess: true,
      isGetWorkflowOptionConditionTypesError: false,
    }),
    [getWorkflowOptionConditionTypesFailure]: (state) => ({
      ...state,
      isGetWorkflowOptionConditionTypesSuccess: false,
      isGetWorkflowOptionConditionTypesError: true,
    }),
    // get workflow revisions
    [getWorkflowRevisionsRequest]: (state) => ({
      ...state,
      isGetWorkflowRevisionsSuccess: false,
      isGetWorkflowRevisionsError: false,
    }),
    [getWorkflowRevisionsSuccess]: (state, { payload }) => ({
      ...state,
      workflowRevisions: payload ?? [],
      isGetWorkflowRevisionsSuccess: true,
      isGetWorkflowRevisionsError: false,
    }),
    [getWorkflowRevisionsFailure]: (state) => ({
      ...state,
      isGetWorkflowRevisionsSuccess: false,
      isGetWorkflowRevisionsError: true,
    }),
    // get workflow revision
    [getWorkflowRevisionRequest]: (state) => ({
      ...state,
      isGetWorkflowRevisionSuccess: false,
      isGetWorkflowRevisionError: false,
    }),
    [getWorkflowRevisionSuccess]: (state, { payload }) => ({
      ...state,
      workflowRevision: payload ?? [],
      isGetWorkflowRevisionSuccess: true,
      isGetWorkflowRevisionError: false,
    }),
    [getWorkflowRevisionFailure]: (state) => ({
      ...state,
      isGetWorkflowRevisionSuccess: false,
      isGetWorkflowRevisionError: true,
    }),
    // create workflow
    [createWorkflowRequest]: (state) => ({
      ...state,
      isCreatedWorkflowSuccess: false,
      isCreatedWorkflowError: false,
    }),
    [createWorkflowSuccess]: (state, { payload }) => ({
      ...state,
      workflow: payload.data,
      eTag: payload.eTag ?? "",
      isCreatedWorkflowSuccess: true,
      isCreatedWorkflowError: false,
    }),
    [createWorkflowFailure]: (state) => ({
      ...state,
      isCreatedWorkflowSuccess: false,
      isCreatedWorkflowError: true,
    }),
    // create workflow release
    [createWorkflowReleaseRequest]: (state) => ({
      ...state,
      isCreatedWorkflowReleaseSuccess: false,
      isCreatedWorkflowReleaseError: false,
    }),
    [createWorkflowReleaseSuccess]: (state, { payload }) => ({
      ...state,
      releaseETag: payload ?? "",
      isCreatedWorkflowReleaseSuccess: true,
      isCreatedWorkflowReleaseError: false,
    }),
    [createWorkflowReleaseFailure]: (state) => ({
      ...state,
      isCreatedWorkflowReleaseSuccess: false,
      isCreatedWorkflowReleaseError: true,
    }),
    // create workflow step
    [createWorkflowStepRequest]: (state) => ({
      ...state,
      isCreatedWorkflowStepSuccess: false,
      isCreatedWorkflowStepError: false,
    }),
    [createWorkflowStepSuccess]: (state, { payload }) => ({
      ...state,
      createdStepId: payload,
      isCreatedWorkflowStepSuccess: true,
      isCreatedWorkflowStepError: false,
    }),
    [createWorkflowStepFailure]: (state) => ({
      ...state,
      isCreatedWorkflowStepSuccess: false,
      isCreatedWorkflowStepError: true,
    }),
    // update workflow
    [updateWorkflowRequest]: (state) => ({
      ...state,
      isUpdatedWorkflowSuccess: false,
      isUpdatedWorkflowError: false,
    }),
    [updateWorkflowSuccess]: (state, { payload }) => ({
      ...state,
      workflow: payload,
      isUpdatedWorkflowSuccess: true,
      isUpdatedWorkflowError: false,
    }),
    [updateWorkflowFailure]: (state) => ({
      ...state,
      isUpdatedWorkflowSuccess: false,
      isUpdatedWorkflowError: true,
    }),
    // update workflow step
    [updateWorkflowStepRequest]: (state) => ({
      ...state,
      isUpdatedWorkflowStepSuccess: false,
      isUpdatedWorkflowStepError: false,
    }),
    [updateWorkflowStepSuccess]: (state) => ({
      ...state,
      isUpdatedWorkflowStepSuccess: true,
      isUpdatedWorkflowStepError: false,
    }),
    [updateWorkflowStepFailure]: (state) => ({
      ...state,
      isUpdatedWorkflowStepSuccess: false,
      isUpdatedWorkflowStepError: true,
    }),
    // delete workflow
    [deleteWorkflowRequest]: (state) => ({
      ...state,
      isDeletedWorkflowSuccess: false,
      isDeletedWorkflowError: false,
    }),
    [deleteWorkflowSuccess]: (state) => ({
      ...state,
      isDeletedWorkflowSuccess: true,
      isDeletedWorkflowError: false,
    }),
    [deleteWorkflowFailure]: (state) => ({
      ...state,
      isDeletedWorkflowSuccess: false,
      isDeletedWorkflowError: true,
    }),
    // delete workflow step
    [deleteWorkflowStepRequest]: (state) => ({
      ...state,
      isDeletedWorkflowStepSuccess: false,
      isDeletedWorkflowStepError: false,
    }),
    [deleteWorkflowStepSuccess]: (state) => ({
      ...state,
      isDeletedWorkflowStepSuccess: true,
      isDeletedWorkflowStepError: false,
    }),
    [deleteWorkflowStepFailure]: (state) => ({
      ...state,
      isDeletedWorkflowStepSuccess: false,
      isDeletedWorkflowStepError: true,
    }),
    // delete workflow step option
    [deleteStepOptionRequest]: (state) => ({
      ...state,
      isDeletedStepOptionSuccess: false,
      isDeletedStepOptionError: false,
    }),
    [deleteStepOptionSuccess]: (state, { payload }) => {
      const workflow = structuredClone(state.workflow);

      workflow.workflow_step.forEach((step) => {
        step.options = step.options?.filter(
          (option) => option.wf_step_option_id !== payload.optionId
        );
      });

      return {
        ...state,
        workflow: structuredClone(workflow),
        isDeletedStepOptionSuccess: true,
        isDeletedStepOptionError: false,
      };
    },
    [deleteStepOptionFailure]: (state) => ({
      ...state,
      isDeletedStepOptionSuccess: false,
      isDeletedStepOptionError: true,
    }),
    // delete workflow step option param
    [deleteStepOptionParamRequest]: (state) => ({
      ...state,
      isDeletedStepOptionParamSuccess: false,
      isDeletedStepOptionParamError: false,
    }),
    [deleteStepOptionParamSuccess]: (state, { payload }) => {
      const workflow = structuredClone(state.workflow);

      workflow.workflow_step.forEach((step) => {
        step.options?.filter((option) => {
          option.attributes = option.attributes?.filter(
            (attr) => attr.wf_step_option_attribute_id !== payload.paramId
          );
        });
      });

      return {
        ...state,
        workflow: structuredClone(workflow),
        isDeletedStepOptionParamSuccess: true,
        isDeletedStepOptionParamError: false,
      };
    },
    [deleteStepOptionParamFailure]: (state) => ({
      ...state,
      isDeletedStepOptionParamSuccess: false,
      isDeletedStepOptionParamError: true,
    }),
    // delete workflow link
    [deleteLinkRequest]: (state) => ({
      ...state,
      isDeletedLinkSuccess: false,
      isDeletedLinkError: false,
    }),
    [deleteLinkSuccess]: (state) => ({
      ...state,
      isDeletedLinkSuccess: true,
      isDeletedLinkError: false,
    }),
    [deleteLinkFailure]: (state) => ({
      ...state,
      isDeletedLinkSuccess: false,
      isDeletedLinkError: true,
    }),
    [useCaseWorkflowRequest]: (state, { payload }) => ({
      ...state,
      useCaseWorkflow: payload || {},
    }),
  },
  initialState
);

export default reducer;
