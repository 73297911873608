import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MainContext } from "context/contexts";

export class CustomLinkWidget extends React.PureComponent {
  static contextType = MainContext;

  static defaultProps = {
    color: "white",
    width: 3,
    link: null,
    engine: null,
    smooth: true,
    diagramEngine: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLinkDeleteModalOpen: false,
      selected: false,
      setLinkId: props.setLinkId,
    };
  }

  generatePoint(pointIndex) {
    const { link } = this.props;
    const uiCircleProps = {
      className: `point pointui${
        link.points[pointIndex].isSelected() ? " selected" : ""
      }`,
      cx: link.points[pointIndex].x,
      cy: link.points[pointIndex].y,
      r: 5,
    };
    const circleProps = {
      className: "point",
      "data-linkid": link.id,
      "data-id": link.points[pointIndex].id,
      cx: link.points[pointIndex].x,
      cy: link.points[pointIndex].y,
      r: 15,
      opacity: 0,
      onMouseLeave: () => this.setState({ selected: false }),
      onMouseEnter: () => this.setState({ selected: true }),
    };

    return (
      <g key={`point-${link.points[pointIndex].id}`}>
        <circle {...uiCircleProps} />
        <circle {...circleProps} />
      </g>
    );
  }

  onLinkDoubleClick({ nativeEvent }, link) {
    this.setState({
      isLinkDeleteModalOpen: true,
    });

    const linkId = link.linkId;
    const id = this.props.diagramEngine.workflow_id;

    this.state.setLinkId({ linkId, id });
    nativeEvent.stopImmediatePropagation();
    nativeEvent.stopPropagation();
  }

  generateLink(extraProps) {
    const { link, width, color } = this.props;
    const portRight = document.querySelector(
      `.port[data-nodeid="${link.sourcePort.parentNode.id}"][data-name="right"]`
    );
    const portRightDown = document.querySelector(
      `.port[data-nodeid="${link.sourcePort.parentNode.id}"][data-name="right-down"]`
    );
    const portLeft = document.querySelector(
      `.port[data-nodeid="${link.targetPort.parentNode.id}"][data-name="left"]`
    );

    portLeft.classList.add("port-border-left");
    if (link.sourcePort.name === "right-down") {
      portRightDown.classList.add("port-border-left");
    } else {
      portRight.classList.add("port-border-left");
    }

    const handleDeleteKeyPress = (event) => {
      if (event.key === "Delete") {
        this.setState({
          isLinkDeleteModalOpen: true,
        });
      }
      const linkId = link.linkId;
      const id = this.props.diagramEngine.workflow_id;

      this.state.setLinkId({ linkId, id });
    };

    const { selected } = this.state;
    const bottom = (
      <path
        className={selected || link.isSelected() ? "selected" : ""}
        strokeWidth={width}
        stroke={color}
        {...extraProps}
      />
    );

    const top = (
      <path
        key={link.linkId}
        className="outline-none"
        tabIndex={1}
        onKeyDown={handleDeleteKeyPress}
        strokeLinecap={"round"}
        data-linkid={link.getID()}
        stroke={color}
        strokeOpacity={selected ? 0.1 : 0}
        strokeWidth={10}
        onDoubleClick={(e) =>
          !window.location.href.includes("revisions")
            ? this.onLinkDoubleClick(e, link)
            : void 0
        }
        {...extraProps}
      >
        <title>Path Link</title>
      </path>
    );

    return (
      <g key={`link-${extraProps.id}`}>
        {bottom}
        {top}
      </g>
    );
  }

  drawLine() {
    const { link } = this.props;
    const { points } = link;
    const paths = [];

    // If the points are too close, just draw a straight line
    const margin = Math.abs(points[0].x - points[1].x) < 50 ? 5 : 50;

    let pointLeft = points[0];
    let pointRight = points[1];

    // Some defensive programming to make sure the smoothing is
    // Always in the right direction
    if (pointLeft.x > pointRight.x) {
      pointLeft = points[1];
      pointRight = points[0];
    }

    paths.push(
      this.generateLink({
        d: ` M${pointLeft.x} ${pointLeft.y} C${pointLeft.x + margin} ${
          pointLeft.y
        } ${pointRight.x - margin} ${pointRight.y} ${pointRight.x} ${
          pointRight.y
        }`, // eslint-disable-line
      })
    );

    if (link.targetPort === null) {
      paths.push(this.generatePoint(1));
    }

    return paths;
  }

  render() {
    const { points } = this.props.link;
    let paths = [];
    // Draw the line
    if (points.length === 2) {
      paths = this.drawLine();
    }

    return <g>{paths}</g>;
  }
}

CustomLinkWidget.propTypes = {
  link: PropTypes.object,
  points: PropTypes.array,
  id: PropTypes.string,
  width: PropTypes.number,
  color: PropTypes.string,
  diagramEngine: PropTypes.object,
  pointAdded: PropTypes.func,
  getRelativeMousePoint: PropTypes.string,
  smooth: PropTypes.bool,
  isSelected: PropTypes.bool,
  getID: PropTypes.bool,
  remove: PropTypes.bool,
  setLinkId: PropTypes.func,
};

export default connect(null)(CustomLinkWidget);
