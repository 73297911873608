import axios from "axios";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";

const axiosApiInstance = axios.create();
let toastifyInterval = 0;

axiosApiInstance.interceptors.request.use(
  (config) => {
    const Authorization = localStorage.getItem("token");
    config.baseURL = window.location.origin.includes("localhost")
      ? process.env.REACT_APP_API_URL
      : window.location.origin + "/api/v1";
    if (Authorization) {
      config.headers.Authorization = Authorization;
    }
    return config;
  },
  (e) => {
    Promise.reject(e);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    try {
      if (
        error.response.status === 401 &&
        !window.location.pathname.includes("login")
      ) {
        localStorage.setItem("expired", true);
        localStorage.removeItem("copiedStep");
        localStorage.removeItem("copiedWorkflow");
      } else if (error.response.status === 403) {
        toast.error(
          "You do not have enough rights to perform this action.",
          ToastOptions
        );
      } else if (
        Number(error.response.status.toString().substring(0, 2)) === 40 ||
        error.response.status === 500
      ) {
        //if signup page and 405
        if (
          error.response.status === 405 &&
          window.location.pathname.includes("signup")
        ) {
          toastifyInterval = setTimeout(() => {
            toast.error(
              "Either you are not using work email, or we identified you as a bot. \n" +
                "We cannot open accounts for bots. \n If we have identified you incorrectly as a bot, email us at hello@decisimo.com.",
              ToastOptions
            );
          }, 100);
        } else if (!window.location.pathname.includes("login")) {
          toast.error("Something went wrong...", ToastOptions);
        }
      }
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export default axiosApiInstance;
