import { createAction } from "redux-actions";

export const getDeploymentsRequest = createAction("GET_DEPLOYMENTS_REQUEST");
export const getDeploymentsSuccess = createAction("GET_DEPLOYMENTS_SUCCESS");
export const getDeploymentsFailure = createAction("GET_DEPLOYMENTS_FAILURE");

export const getDeploymentByIdRequest = createAction(
  "GET_DEPLOYMENT_BY_ID_REQUEST"
);
export const getDeploymentByIdSuccess = createAction(
  "GET_DEPLOYMENT_BY_ID_SUCCESS"
);
export const getDeploymentByIdFailure = createAction(
  "GET_DEPLOYMENT_BY_ID_FAILURE"
);

export const deleteDeploymentRequest = createAction(
  "DELETE_DEPLOYMENT_REQUEST"
);
export const deleteDeploymentSuccess = createAction(
  "DELETE_DEPLOYMENT_SUCCESS"
);
export const deleteDeploymentFailure = createAction(
  "DELETE_DEPLOYMENT_FAILURE"
);
