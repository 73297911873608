import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDecisionTreeRevisionRequest } from "redux/decisionTrees/action";
import { MainContext } from "context/contexts";
import DecisionTreeContent from "components/decisionTrees/DecisionTreeContent"; // Adjust the import path as needed
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import decisionTrees from "redux/decisionTrees/saga";
import {
  DecisionTreeProvider,
  DecisionTreeContext,
} from "context/DecisionTreeContext";

const DecisionTreeRevision = () => {
  const { id, revisionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(MainContext);

  const { t } = useTranslation();

  const { revision, isLoading, error, isFetchRevisionSuccess, decisionTree } =
    useSelector((state) => state.decisionTrees);

  const { revisionMode, setRevisionMode } = useContext(DecisionTreeContext);

  useEffect(() => {
    setIsLoading(true);

    dispatch(getDecisionTreeRevisionRequest({ id, revisionId }));
  }, [dispatch, id, revisionId, setIsLoading]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (isFetchRevisionSuccess) {
      setIsLoading(false);
    }
  }, [isFetchRevisionSuccess, setIsLoading]);

  // Optionally handle error state
  useEffect(() => {
    if (error) {
      // Handle the error state, e.g., show a notification
      console.error("Failed to fetch decision tree revision:", error);
    }
  }, [error]);

  // Handle the title for the revision
  useEffect(() => {
    if (revision && decisionTree.name) {
      document.title = `${decisionTree?.title} - Revision - Decisimo`;
    } else {
      document.title = `${t("decision_tree_revision")} - Decisimo`;
    }
  }, [revision, t]);

  return (
    <>
      {decisionTree ? (
        <DecisionTreeProvider>
          <DecisionTreeContent decisionTreeId={id} isRevision={true} />
        </DecisionTreeProvider>
      ) : (
        <div>{t("loading_revision")}</div>
      )}
    </>
  );
};

export default DecisionTreeRevision;
