import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DraggableFormulaContext } from "context/contexts";

const DraggableItem = ({ item, isDragging, ...props }) => {
  const { attributes } = useContext(DraggableFormulaContext);

  return ["b", "f"].includes(item.item_type) ? (
    <div id={`item-${item.fce_item_id}`} className="d-flex">
      {item.item_type === "f" && (
        <div className="input-group-prepend pb-1">
          <span className="input-group-text">{item.operator}</span>
        </div>
      )}
      <div
        style={{ maxWidth: "32px" }}
        className={`input-group-prepend pb-1`}
        {...props}
      >
        <span className="input-group-text">(</span>
      </div>
      {isDragging && item.item_type === "o" ? (
        <div className={`input-group-text mb-1`} style={{ maxWidth: "32px" }}>
          {item.operator === "pi" ? "𝝅" : item.operator}
        </div>
      ) : (
        ""
      )}
    </div>
  ) : ["o", "c"].includes(item.item_type) ? (
    <div className={`input-group-text mb-1`} style={{ maxWidth: "32px" }}>
      {item.operator === "pi" ? "𝝅" : item.operator}
    </div>
  ) : ["b_c", "f_c"].includes(item.item_type) ? (
    <div
      style={{ maxWidth: "32px" }}
      className={`input-group-prepend pb-1`}
      {...props}
    >
      <span className="input-group-text">)</span>
    </div>
  ) : (
    <div
      className="input-group-prepend formula-draggable-section"
      style={{ height: "100%" }}
      {...props}
    >
      {["v", "a"].includes(item.item_type) ? (
        <>
          <div className={`input-group-prepend`} style={{ height: "42px" }}>
            {item.item_type === "a" ? (
              <div className="input-styles">
                {
                  attributes.find(
                    (attr) => attr.fce_attribute_id === item.fce_attribute_id
                  )?.name
                }
              </div>
            ) : (
              <div className="input-styles">{item.numeric_value}</div>
            )}
          </div>
          {isDragging && item.item_type === "o" ? (
            <div
              className={`input-group-text mb-1`}
              style={{ maxWidth: "32px" }}
            >
              {item.operator}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

DraggableItem.propTypes = {
  item: PropTypes.object,
  attributes: PropTypes.array,
  isDragging: PropTypes.bool,
};

export default DraggableItem;
