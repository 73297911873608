import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader";
import ApprovalSequenceContent from "components/approvalProcess/ApprovalSequenceContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { createApprovalSequenceRequest } from "redux/approvalProcesses/action";
import usePrevious from "utility/hooks/usePrevious";
import { useTranslation } from "react-i18next";

const CreateApprovalSequence = () => {
  const [sequenceData, setSequenceData] = useState({
    title: "",
    description: "",
    steps: [],
  });
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isCreateApprovalSequenceSuccess, newApprovalSequenceId } =
    useSelector((state) => state.approvalProcesses);

  const prevIsCreatedApprovalSequenceSuccess = usePrevious(
    isCreateApprovalSequenceSuccess
  );

  useEffect(() => {
    document.title = `${t("approval_sequence")} - Decisimo`;
  }, [t]);

  useEffect(() => {
    if (
      isCreateApprovalSequenceSuccess &&
      prevIsCreatedApprovalSequenceSuccess === false
    ) {
      setIsLoading(false);
      toast.success(`${t("approval_sequence_created")}.`, ToastOptions);
      navigate(`/approval-sequences/${newApprovalSequenceId}`);
    }
  }, [
    isCreateApprovalSequenceSuccess,
    prevIsCreatedApprovalSequenceSuccess,
    t,
    setIsLoading,
    navigate,
    newApprovalSequenceId,
  ]);

  const createApprovalSequence = () => {
    if (!sequenceData.title.trim()) {
      toast.error(`${t("please_fill_out_the_title")}.`, ToastOptions);
      return;
    }

    const hasEmptyStep = sequenceData.steps.some(
      (step) => !step.title.trim() || !step.access_role_id
    );

    if (hasEmptyStep) {
      toast.error(`${t("please_fill_out_all_steps")}.`, ToastOptions);
      return;
    }

    setIsLoading(true);
    dispatch(createApprovalSequenceRequest(sequenceData));
  };

  useCtrlSHandler(createApprovalSequence);

  const handleFormChange = (updatedData) => {
    setSequenceData(updatedData);
    setIsEdited(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEdited(false);
    createApprovalSequence();
  };

  return (
    <form onSubmit={handleSubmit} onChange={() => setIsEdited(true)}>
      <SubHeader
        title={t("approval_sequence")}
        actions={
          <button className="btn primary" type="submit">
            <SaveIcon />
          </button>
        }
      />
      <ApprovalSequenceContent
        isNew={true}
        initialData={sequenceData}
        onChange={handleFormChange}
      />
    </form>
  );
};

export default CreateApprovalSequence;
