import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequest } from "redux/auth/action";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card, CardsBottomSide } from "components/auth/Card";
import { emailRegex } from "utility/utility";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hash = params.get("hash");

  const { isForgotPasswordSuccess, isForgotPasswordFailure } = useSelector(
    (state) => state.auth
  );
  const prevIsForgotPasswordSuccess = usePrevious(isForgotPasswordSuccess);
  const prevIsForgotPasswordFailure = usePrevious(isForgotPasswordFailure);

  useEffect(() => {
    document.title = "Reset password - Decisimo";
  }, []);

  useEffect(() => {
    if (isForgotPasswordSuccess && prevIsForgotPasswordSuccess === false) {
      toast.success("Password changed", ToastOptions);
    }
  }, [isForgotPasswordSuccess]);

  useEffect(() => {
    if (isForgotPasswordFailure && prevIsForgotPasswordFailure === false) {
      toast.error("Unable to change password.", ToastOptions);
    }
  }, [isForgotPasswordFailure]);

  const onHandleForgot = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const email = e.target.email.value;
    const password = e.target.password.value;
    const passwordAgain = e.target.new_password.value;

    if (email.length > 0 && email.match(emailRegex)) {
      if (password.length > 4 && password === passwordAgain) {
        data.delete("password");
        data.append("hash", hash);
        dispatch(forgotPasswordRequest(data));
      } else {
        return toast.error("Do not match passwords.", ToastOptions);
      }
    } else {
      return toast.error("Invalid email address!", ToastOptions);
    }

    if (
      password.length === 0 ||
      passwordAgain.length === 0 ||
      email.length === 0
    ) {
      return toast.error("Fill out the field/fields.", ToastOptions);
    }
  };

  return (
    <>
      {!isForgotPasswordSuccess ? (
        <Card>
          <form className="form-signin" onSubmit={onHandleForgot}>
            <h1 className="h3 mb-4">Set up your new password</h1>
            <div>
              <label htmlFor="inputEmail" className="sr-only mt-2">
                Email address
              </label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email address"
                required
                autoFocus
              />
              <label className="sr-only">Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                required
              />
              <label className="sr-only">Password again ...</label>
              <input
                type="password"
                name="new_password"
                className="form-control"
                placeholder="Password again"
                required
              />
              <button
                className="btn btn-lg btn-outline-dark btn-block w-100"
                type="submit"
              >
                Set new password
              </button>
              <hr />
            </div>
            <CardsBottomSide />
          </form>
        </Card>
      ) : (
        <Card>
          <div>
            <h1 className="h3 mb-4 px-3 pt-4">
              Your password has been changed.
            </h1>
            <CardsBottomSide />
          </div>
        </Card>
      )}
    </>
  );
};

export default ResetPassword;
