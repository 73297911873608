import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getScorecardRevisionRequest,
  getScorecardRevisionsRequest,
} from "redux/scorecard/action";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { useTranslation } from "react-i18next";
import SubHeader from "components/SubHeader";
import ScorecardEditorContent from "components/scorecards/ScorecardEditorContent";
import Revisions from "components/modals/scorecards/Revisions";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import ReactTooltip from "react-tooltip";

const ScorecardRevision = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    revision,
    revisions,
    isGetScorecardRevisionSuccess,
    isGetScorecardRevisionError,
  } = useSelector((state) => state.scorecard);

  const [isScorecardRevisionsModalOpen, setIsScorecardRevisionsModalOpen] =
    useState(false);
  const [revisionContent, setRevisionContent] = useState({}); // Initialize state correctly

  const prevIsGetScorecardRevisionSuccess = usePrevious(
    isGetScorecardRevisionSuccess
  );
  const prevIsGetScorecardRevisionError = usePrevious(
    isGetScorecardRevisionError
  );

  useEffect(() => {
    if (isGetScorecardRevisionSuccess && revision?.content) {
      try {
        if (typeof revision.content === "object") {
          setRevisionContent(revision.content);
        } else {
          const scorecard = JSON.parse(revision.content);
          setRevisionContent(scorecard);
        }
      } catch (error) {
        console.error("Failed to parse revision content:", error);
      }
    }
    // finish loading
    setIsLoading(false);
  }, [isGetScorecardRevisionSuccess, revision?.content]);

  useEffect(() => {
    document.title = t("scorecard_revision");
  }, [t]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getScorecardRevisionRequest({ id, revisionId }));
  }, [id, revisionId, dispatch, setIsLoading]);

  useEffect(() => {
    if (
      (isGetScorecardRevisionSuccess &&
        prevIsGetScorecardRevisionSuccess === false) ||
      (isGetScorecardRevisionError && prevIsGetScorecardRevisionError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetScorecardRevisionSuccess, isGetScorecardRevisionError]);

  const handleToggleScorecardRevisionsModal = (isOpen = true) => {
    if (isOpen) {
      setIsLoading(true);
      dispatch(getScorecardRevisionsRequest(parseInt(id)));
    }
    setIsScorecardRevisionsModalOpen(isOpen);
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={() => handleToggleScorecardRevisionsModal()}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: true,
    },
  ];

  return (
    <>
      <SubHeader
        title={"[" + t("revision") + "] " + revisionContent?.name}
        actions={
          <>
            <Link
              to={`/scorecards/${id}`}
              className="mr-2"
              data-tip={true}
              data-for="back-to-scorecards-rev"
            >
              <button className="btn outline h-100">
                <BackIcon />
              </button>
            </Link>
            <ReactTooltip
              type="dark"
              place="top"
              effect="solid"
              id="back-to-scorecards-rev"
            >
              {t("back_to_scorecard")}
            </ReactTooltip>
            <div>
              <button
                type="button"
                className="btn primary mr12 h-38"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingsIcon />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                {settings.length > 0 &&
                  settings.map((setting) => {
                    return (
                      <span key={setting.id}>
                        {setting.content}
                        {setting.divider && (
                          <div className="dropdown-divider" />
                        )}
                      </span>
                    );
                  })}
              </div>
            </div>
          </>
        }
      />
      {isGetScorecardRevisionSuccess && (
        <ScorecardEditorContent
          scorecard={revisionContent}
          onScorecardChange={() => {}}
          vectorsData={[]}
          isRevision={true}
        />
      )}
      <Revisions
        handleClose={() => handleToggleScorecardRevisionsModal(false)}
        revisionsData={revisions}
        linkTo={"scorecards"}
        open={isScorecardRevisionsModalOpen}
        elemId={parseInt(id)}
      />
    </>
  );
};

export default ScorecardRevision;
