import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createFunctionFailure,
  createFunctionRequest,
  createFunctionSuccess,
  deleteFunctionFailure,
  deleteFunctionRequest,
  deleteFunctionSuccess,
  getFunctionFailure,
  getFunctionRequest,
  getFunctionRevisionFailure,
  getFunctionRevisionRequest,
  getFunctionRevisionsFailure,
  getFunctionRevisionsRequest,
  getFunctionRevisionsSuccess,
  getFunctionRevisionSuccess,
  getFunctionsFailure,
  getFunctionsRequest,
  getFunctionsSuccess,
  getFunctionSuccess,
  getFunctionTestFailure,
  getFunctionTestRequest,
  getFunctionTestSuccess,
  getRunTestResultDetailsFunctionFailure,
  getRunTestResultDetailsFunctionRequest,
  getRunTestResultDetailsFunctionSuccess,
  getRunTestResultStatusFunctionFailure,
  getRunTestResultStatusFunctionRequest,
  getRunTestResultStatusFunctionSuccess,
  updateFunctionFailure,
  updateFunctionRequest,
  updateFunctionSuccess,
  getFunctionExplanationRequest,
  getFunctionExplanationSuccess,
  getFunctionExplanationFailure,
} from "redux/functions/action";

function* getFunctions({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/functions?page=${payload}` : "/functions"
    );
    if (response.status === 200) {
      yield put(getFunctionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getFunctionsFailure("e.message"));
  }
}

function* getFunction({ payload }) {
  try {
    const url = `functions/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getFunctionSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getFunctionFailure("e.message"));
  }
}

function* getFunctionRevisions({ payload }) {
  try {
    const url = `functions/${payload}/revisions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getFunctionRevisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getFunctionRevisionsFailure("e.message"));
  }
}

function* getFunctionRevision({ payload }) {
  try {
    const url = `functions/${payload.id}/revisions/${payload.revisionId}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getFunctionRevisionSuccess(response.data));
    }
  } catch (e) {
    yield put(getFunctionRevisionFailure("e.message"));
  }
}

function* getFunctionTest({ payload }) {
  try {
    const url = `functions/${payload}/test`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getFunctionTestSuccess(response.data));
    }
  } catch (e) {
    yield put(getFunctionTestFailure("e.message"));
  }
}

function* getRunTestResultDetailsFunction({ payload }) {
  try {
    const url = `functions/${payload.id}/test`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultDetailsFunctionSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultDetailsFunctionFailure("e.message"));
  }
}

function* getRunTestResultStatusFunction({ payload }) {
  try {
    const url = `functions/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url);
    if (response.status === 200) {
      yield put(getRunTestResultStatusFunctionSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultStatusFunctionFailure("e.message"));
  }
}

function* updateFunction({ payload }) {
  try {
    const url = `functions/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateFunctionSuccess());
    }
  } catch (e) {
    yield put(updateFunctionFailure("e.message"));
  }
}

function* createFunction({ payload }) {
  try {
    const response = yield call(axios.post, "functions", payload);
    if (response.status === 201) {
      yield put(createFunctionSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createFunctionFailure("e.message"));
  }
}

function* deleteFunction({ payload }) {
  try {
    const url = `functions/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteFunctionSuccess());
    }
  } catch (e) {
    yield put(deleteFunctionFailure("e.message"));
  }
}

function* getFunctionExplanation({ payload }) {
  try {
    const url = `functions/${payload.fce_id}/explain`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getFunctionExplanationSuccess(response.data.explanation));
    }
  } catch (e) {
    yield put(getFunctionExplanationFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getFunctionsRequest, getFunctions);
  yield takeLatest(getFunctionRequest, getFunction);
  yield takeLatest(getFunctionRevisionsRequest, getFunctionRevisions);
  yield takeLatest(getFunctionRevisionRequest, getFunctionRevision);
  yield takeLatest(getFunctionTestRequest, getFunctionTest);
  yield takeLatest(
    getRunTestResultDetailsFunctionRequest,
    getRunTestResultDetailsFunction
  );
  yield takeLatest(
    getRunTestResultStatusFunctionRequest,
    getRunTestResultStatusFunction
  );
  yield takeLatest(updateFunctionRequest, updateFunction);
  yield takeLatest(createFunctionRequest, createFunction);
  yield takeLatest(deleteFunctionRequest, deleteFunction);
  yield takeLatest(getFunctionExplanationRequest, getFunctionExplanation);
}
