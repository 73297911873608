import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./translations/en.json";
import esTranslations from "./translations/es.json";
import ptTranslations from "./translations/pt.json";
import frTranslations from "./translations/fr.json";
import zhTranslations from "./translations/zh.json";

const container = document.getElementById("root");
const root = createRoot(container);

const allowedLanguages = ["en", "es", "pt", "fr", "zh"]; // add more languages as needed
const userLanguage = localStorage.getItem("user_language");
const browserLanguage = navigator.language || navigator.userLanguage;
const languageToUse = allowedLanguages.includes(userLanguage)
  ? userLanguage
  : allowedLanguages.includes(browserLanguage)
  ? browserLanguage
  : "en";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    es: { translation: esTranslations },
    pt: { translation: ptTranslations },
    fr: { translation: frTranslations },
    zh: { translation: zhTranslations },
  },
  lng: languageToUse,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

root.render(
  //<React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
