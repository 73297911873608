import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { set } from "lodash";

export const DecisionTreeContext = createContext({});

export const useDecisionTreeContext = () => useContext(DecisionTreeContext);

export const DecisionTreeProvider = ({ children }) => {
  const [isCanvasDragging, setIsCanvasDragging] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatedStep, setIsUpdatedStep] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [decisionTreeClone, setDecisionTreeClone] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [revisionMode, setRevisionMode] = useState(false);

  const operators = [
    "greater_than",
    "greater_than_or_equal_to",
    "less_than",
    "less_than_or_equal_to",
    "equal_to",
    "not_equal_to",
    "closed_interval",
    "open_interval",
    "left_closed_right_open",
    "left_open_right_closed",
    "any_value",
    "is_null",
  ];

  const operatorSymbols = {
    greater_than: ">",
    greater_than_or_equal_to: ">=",
    less_than: "<",
    less_than_or_equal_to: "<=",
    starts_with: "^",
    ends_with: "$",
    equal_to: "=",
    not_equal_to: "≠",
    closed_interval: "[",
    open_interval: "(",
    left_closed_right_open: "[",
    left_open_right_closed: "(",
    is_null: "∅",
  };

  const newNode = {
    name: "",
    attribute_path: "",
    attribute_name: "",
    conditions: [],
    decision_tree_node_id: "new_" + Math.round(Math.random() * 1000),
    parent_decision_tree_node_id: "",
    conditions: [],
    actions: [],
  };

  function handleNodeDelete(nodeId) {
    // Clone the current state of the decision tree to avoid direct mutations
    const updatedDecisionTree = structuredClone(decisionTreeClone);

    // Find the node by nodeId
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );

    //parent node activity for deleting condition that leads to node
    const parentId =
      updatedDecisionTree.nodes[nodeIndex].parent_decision_tree_node_id;
    const parentNode = updatedDecisionTree.nodes.find(
      (node) => node.decision_tree_node_id === parentId
    );

    if (parentNode) {
      const conditionIndex = parentNode.conditions.findIndex(
        (condition) => condition.leads_to_decision_tree_node_id === nodeId
      );
      if (conditionIndex !== -1) {
        parentNode.conditions.splice(conditionIndex, 1);
      }
    }

    if (nodeIndex === -1) return; // Node not found, exit function

    // Get the node to delete
    getNodeIdsToDelete(nodeId).forEach((id) => {
      const nodeIndex = updatedDecisionTree.nodes.findIndex(
        (node) => node.decision_tree_node_id === id
      );
      updatedDecisionTree.nodes.splice(nodeIndex, 1);
    });

    // Update the decision tree context state
    setDecisionTreeClone(updatedDecisionTree);

    // Set the decision tree as edited
    setIsEdited(true);
  }

  function getNodeIdsToDelete(nodeId) {
    const nodeIds = [nodeId];
    const children = decisionTreeClone.nodes.filter(
      (node) => node.parent_decision_tree_node_id === nodeId
    );
    children.forEach((child) => {
      nodeIds.push(...getNodeIdsToDelete(child.decision_tree_node_id));
    });
    return nodeIds;
  }

  const handleConditionDelete = (nodeId, conditionId) => {
    // Clone the current state of the decision tree to avoid direct mutations
    const updatedDecisionTree = structuredClone(decisionTreeClone);

    // Find the node by nodeId
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );

    if (nodeIndex === -1) return; // Node not found, exit function

    // Find the condition within the node by conditionId
    const conditionIndex = updatedDecisionTree.nodes[
      nodeIndex
    ].conditions.findIndex(
      (condition) => condition.decision_tree_node_condition_id === conditionId
    );
    if (conditionIndex === -1) return; // Condition not found, exit function

    //get the condition and the leads_to_decision_tree_node_id
    const condition =
      updatedDecisionTree.nodes[nodeIndex].conditions[conditionIndex];
    const leads_to_decision_tree_node_id =
      condition.leads_to_decision_tree_node_id;

    // Remove the condition from the node
    updatedDecisionTree.nodes[nodeIndex].conditions.splice(conditionIndex, 1);

    // Update the decision tree context state
    setDecisionTreeClone(updatedDecisionTree);

    // Remove the child node(s) associated with the condition
    handleNodeDelete(leads_to_decision_tree_node_id);
  };

  return (
    <DecisionTreeContext.Provider
      value={{
        isCanvasDragging,
        setIsCanvasDragging,
        isLoading,
        setIsLoading,
        isUpdatedStep,
        setIsUpdatedStep,
        isEdited,
        setIsEdited,
        decisionTreeClone,
        setDecisionTreeClone,
        isFirstLoad,
        setIsFirstLoad,
        operators,
        operatorSymbols,
        handleNodeDelete,
        handleConditionDelete,
        revisionMode,
        setRevisionMode,
        newNode,
      }}
    >
      {children}
    </DecisionTreeContext.Provider>
  );
};

DecisionTreeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
