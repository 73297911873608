import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Parameter = ({
  param,
  setParams,
  handleShowParamDeleteModal,
  isLast,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(param.name);
  const [value, setValue] = useState(param.value);

  const handleChange = (e, setData, id) => {
    if (isLast) {
      setParams((prevState) => {
        const prevStateParam = prevState.find(
          (param) => param.external_data_param_id === id
        );
        prevStateParam.isDefault = false;

        return [...prevState, getNewParam()];
      });
    }

    setData(e.target.value);
  };

  return (
    <tr className={param.isDefault ? "bg-gray" : ""}>
      <td>
        <input
          className="form-control form-control-sm field form-control-plaintext"
          type="text"
          placeholder={t("parameter_name")}
          onChange={(e) =>
            handleChange(e, setName, param.external_data_param_id)
          }
          name={
            !param.isDefault
              ? `params[${param.external_data_param_id}][name]`
              : null
          }
          defaultValue={name}
        />
      </td>
      <td>
        <input
          className="form-control form-control-sm form-control-plaintext"
          type="text"
          placeholder={t("value")}
          onChange={(e) =>
            handleChange(e, setValue, param.external_data_param_id)
          }
          name={
            !param.isDefault
              ? `params[${param.external_data_param_id}][value]`
              : null
          }
          defaultValue={param.value}
        />
      </td>
      <td>
        {value || name ? (
          <div className="custom-control custom-switch float-right align-middle text-center">
            <input
              type="checkbox"
              className="custom-control-input w-100 z-index-100 cursor-pointer"
              name={
                !param.isDefault
                  ? `params[${param.external_data_param_id}][push_to_flow]`
                  : null
              }
              defaultChecked={param.push_to_flow}
            />
            <label className="custom-control-label" />
          </div>
        ) : null}
      </td>
      <td className="text-center">
        {value || name ? (
          <DeleteIcon
            className="cursor-pointer"
            title="Delete parameter"
            style={{
              width: "15px",
              height: "16px",
            }}
            onClick={() =>
              handleShowParamDeleteModal(param.external_data_param_id)
            }
          />
        ) : null}
      </td>
    </tr>
  );
};

export const newParameter = {
  name: "",
  value: "",
  push_to_flow: 0,
  isDefault: true,
};

export function getNewParam() {
  const newParameterClone = structuredClone(newParameter);
  newParameterClone.external_data_param_id = `new_${parseInt(
    Date.now() * Math.random()
  )}`;

  return newParameterClone;
}

Parameter.propTypes = {
  param: PropTypes.object,
  handleShowParamDeleteModal: PropTypes.func,
  setParams: PropTypes.func,
  isLast: PropTypes.bool,
};

export default Parameter;
