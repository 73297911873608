// utils/compareRulesets.js
export function compareRulesets(revision1, revision2) {
  const r1 = revision1.content;
  const r2 = revision2.content;

  const topChanges = getTopLevelChanges(r1, r2);
  const { rules } = compareRules(r1.rules || [], r2.rules || []);

  return {
    topChanges,
    rules,
  };
}

function getTopLevelChanges(r1, r2) {
  const fields = ["title", "name", "verbose"];
  const changes = [];
  fields.forEach((field) => {
    if (r1[field] !== r2[field]) {
      changes.push({
        field,
        oldValue: r1[field],
        newValue: r2[field],
      });
    }
  });
  return changes;
}

function compareRules(rules1, rules2) {
  const ruleMap1 = Object.fromEntries(rules1.map((r) => [r.rule_id, r]));
  const ruleMap2 = Object.fromEntries(rules2.map((r) => [r.rule_id, r]));

  const allRuleIds = new Set([
    ...Object.keys(ruleMap1),
    ...Object.keys(ruleMap2),
  ]);

  const results = [];

  allRuleIds.forEach((ruleId) => {
    const r1 = ruleMap1[ruleId] || null;
    const r2 = ruleMap2[ruleId] || null;

    let ruleStatus = "unchanged";
    if (r1 && !r2) ruleStatus = "deleted";
    else if (!r1 && r2) ruleStatus = "added";
    else if (r1 && r2 && !rulesEqual(r1, r2)) ruleStatus = "modified";

    const conditionChanges = compareConditions(
      r1?.condition || [],
      r2?.condition || []
    );
    const actionChanges = compareActions(r1?.action || [], r2?.action || []);

    results.push({
      ruleStatus,
      oldRule: r1,
      newRule: r2,
      conditionChanges,
      actionChanges,
    });
  });

  return { rules: results };
}

function rulesEqual(r1, r2) {
  return (
    r1.title === r2.title &&
    r1.name === r2.name &&
    r1.type === r2.type &&
    r1.execution_type === r2.execution_type &&
    r1.attribute_path === r2.attribute_path
  );
}

function compareConditions(conditions1, conditions2) {
  const tree1 = buildConditionTree(conditions1);
  const tree2 = buildConditionTree(conditions2);
  return compareConditionTrees(tree1, tree2);
}

function buildConditionTree(conditions) {
  const map = new Map();
  conditions.forEach((c) => {
    map.set(c.rule_condition_id, { ...c, children: [] });
  });

  const roots = [];
  map.forEach((c) => {
    if (c.parent_condition_id && map.has(c.parent_condition_id)) {
      map.get(c.parent_condition_id).children.push(c);
    } else if (!c.parent_condition_id) {
      roots.push(c);
    }
  });

  return roots;
}

function compareConditionTrees(tree1, tree2) {
  const cMap1 = Object.fromEntries(
    (tree1 || []).map((c) => [c.rule_condition_id, c])
  );
  const cMap2 = Object.fromEntries(
    (tree2 || []).map((c) => [c.rule_condition_id, c])
  );

  const allIds = new Set([...Object.keys(cMap1), ...Object.keys(cMap2)]);
  const changes = [];

  allIds.forEach((cid) => {
    const c1 = cMap1[cid] || null;
    const c2 = cMap2[cid] || null;

    let status = "unchanged";
    if (c1 && !c2) status = "deleted";
    else if (!c1 && c2) status = "added";
    else if (c1 && c2 && !conditionsEqual(c1, c2)) status = "modified";

    const children =
      c1?.condition_type_name === "group" || c2?.condition_type_name === "group"
        ? compareConditionTrees(c1?.children || [], c2?.children || [])
        : [];

    changes.push({
      status,
      oldCondition: c1,
      newCondition: c2,
      children,
    });
  });

  return changes;
}

function conditionsEqual(c1, c2) {
  return (
    c1.condition_type_name === c2.condition_type_name &&
    c1.logical_operator === c2.logical_operator &&
    c1.attribute1_path === c2.attribute1_path &&
    c1.attribute2_path === c2.attribute2_path &&
    c1.attribute1_value === c2.attribute1_value &&
    c1.attribute2_value === c2.attribute2_value &&
    c1.attribute1_vector === c2.attribute1_vector &&
    c1.attribute2_vector === c2.attribute2_vector
  );
}

function compareActions(actions1, actions2) {
  const aMap1 = Object.fromEntries(actions1.map((a) => [a.rule_action_id, a]));
  const aMap2 = Object.fromEntries(actions2.map((a) => [a.rule_action_id, a]));

  const allActionIds = new Set([...Object.keys(aMap1), ...Object.keys(aMap2)]);
  const changes = [];

  allActionIds.forEach((aid) => {
    const a1 = aMap1[aid] || null;
    const a2 = aMap2[aid] || null;

    let status = "unchanged";
    if (a1 && !a2) status = "deleted";
    else if (!a1 && a2) status = "added";
    else if (a1 && a2 && !actionsEqual(a1, a2)) status = "modified";

    changes.push({
      status,
      oldAction: a1,
      newAction: a2,
    });
  });

  return changes;
}

function actionsEqual(a1, a2) {
  return (
    a1.action_path === a2.action_path &&
    a1.action_type === a2.action_type &&
    a1.action_value === a2.action_value &&
    a1.action_vector === a2.action_vector &&
    a1.attribute_path === a2.attribute_path
  );
}
// Add a mapping from condition_type_name to a human-readable operator or expression
const conditionOperatorMap = {
  greater_than: ">",
  greater_than_or_equal_to: ">=",
  less_than: "<",
  less_than_or_equal_to: "<=",
  equal_to: "=",
  not_equal_to: "!=",

  // For string conditions, just print them as stated in the blueprint
  // e.g. '$.value contains "abc"'
  string_contains: "contains",
  string_does_not_contain: "does not contain",
  string_contains_insensitive: "contains (case insensitive)",
  string_does_not_contain_insensitive: "does not contain (case insensitive)",
  starts_with: "starts with",
  starts_with_insensitive: "starts with (case insensitive)",
  ends_with: "ends with",
  ends_with_insensitive: "ends with (case insensitive)",

  // Time conditions just map similarly
  time_greater_than: ">",
  time_greater_than_or_equal_to: ">=",
  time_less_than: "<",
  time_less_than_or_equal_to: "<=",
  time_equal_to: "=",
  time_not_equal_to: "!=",

  regex_match: "matches",

  is_null: "is null",
  is_not_null: "is not null",

  equals_insensitive: "equals (case insensitive)",
};
