import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthPasswordRequest } from "redux/auth/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isChangePasswordSuccess, isChangePasswordFailure } = useSelector(
    (state) => state.auth
  );

  const [formValues, setFormValues] = useState({
    old_password: "",
    new_password: "",
    new_password_repeat: "",
  });
  const [visibility, setVisibility] = useState({
    old_password: false,
    new_password: false,
    new_password_repeat: false,
  });

  useEffect(() => {
    document.title = t("change_password_decisimo");
  }, [t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const toggleVisibility = (field) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const onHandleChangePassword = (e) => {
    e.preventDefault();
    if (formValues.new_password === formValues.new_password_repeat) {
      setIsLoading(true);
      dispatch(updateAuthPasswordRequest(new FormData(e.target)));
    } else {
      toast.error(t("passwords_do_not_match"), ToastOptions);
    }
  };

  useEffect(() => {
    if (isChangePasswordSuccess) {
      setIsLoading(false);
      toast.success(t("password_changed"), ToastOptions);
      setFormValues({
        old_password: "",
        new_password: "",
        new_password_repeat: "",
      });
    }
  }, [isChangePasswordSuccess, setIsLoading, t]);

  useEffect(() => {
    if (isChangePasswordFailure) {
      setIsLoading(false);
      toast.error(t("please_fill_in_your_old_password"), ToastOptions);
      setFormValues({
        old_password: "",
        new_password: "",
        new_password_repeat: "",
      });
    }
  }, [isChangePasswordFailure, setIsLoading, t]);

  return (
    <>
      <SubHeader title={t("change_your_password")} />
      <div className="row">
        <form
          className="col-lg-4 col-md-6 col-10"
          onSubmit={onHandleChangePassword}
        >
          {["old_password", "new_password", "new_password_repeat"].map(
            (field, index) => (
              <div className="mb-2" key={index}>
                <label>{t(field)}</label>
                <div className="input-group">
                  <input
                    type={visibility[field] ? "text" : "password"}
                    name={field}
                    value={formValues[field]}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="form-control"
                      type="button"
                      onClick={() => toggleVisibility(field)}
                      style={{
                        borderLeft: "none",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                    >
                      {visibility[field] ? <EyeOffIcon /> : <EyeIcon />}
                    </button>
                  </div>
                </div>
              </div>
            )
          )}
          <button className="btn primary">
            <SaveIcon />
            <span className="ml-2">{t("change_password")}</span>
          </button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
