import { createAction } from "redux-actions";

// Approval Processes
export const getApprovalProcessesRequest = createAction(
  "GET_APPROVAL_PROCESSES_REQUEST"
);
export const getApprovalProcessesSuccess = createAction(
  "GET_APPROVAL_PROCESSES_SUCCESS"
);
export const getApprovalProcessesFailure = createAction(
  "GET_APPROVAL_PROCESSES_FAILURE"
);

export const getApprovalProcessRequest = createAction(
  "GET_APPROVAL_PROCESS_REQUEST"
);
export const getApprovalProcessSuccess = createAction(
  "GET_APPROVAL_PROCESS_SUCCESS"
);
export const getApprovalProcessFailure = createAction(
  "GET_APPROVAL_PROCESS_FAILURE"
);

export const startApprovalProcessRequest = createAction(
  "START_APPROVAL_PROCESS_REQUEST"
);
export const startApprovalProcessSuccess = createAction(
  "START_APPROVAL_PROCESS_SUCCESS"
);
export const startApprovalProcessFailure = createAction(
  "START_APPROVAL_PROCESS_FAILURE"
);
export const startApprovalProcessConflict = createAction(
  "START_APPROVAL_PROCESS_CONFLICT"
);

export const signApprovalProcessStepRequest = createAction(
  "SIGN_APPROVAL_PROCESS_STEP_REQUEST"
);
export const signApprovalProcessStepSuccess = createAction(
  "SIGN_APPROVAL_PROCESS_STEP_SUCCESS"
);
export const signApprovalProcessStepFailure = createAction(
  "SIGN_APPROVAL_PROCESS_STEP_FAILURE"
);

export const deleteApprovalProcessRequest = createAction(
  "DELETE_APPROVAL_PROCESS_REQUEST"
);
export const deleteApprovalProcessSuccess = createAction(
  "DELETE_APPROVAL_PROCESS_SUCCESS"
);
export const deleteApprovalProcessFailure = createAction(
  "DELETE_APPROVAL_PROCESS_FAILURE"
);

// Approval Sequences
export const getApprovalSequencesRequest = createAction(
  "GET_APPROVAL_SEQUENCES_REQUEST"
);
export const getApprovalSequencesSuccess = createAction(
  "GET_APPROVAL_SEQUENCES_SUCCESS"
);
export const getApprovalSequencesFailure = createAction(
  "GET_APPROVAL_SEQUENCES_FAILURE"
);

export const getApprovalSequenceRequest = createAction(
  "GET_APPROVAL_SEQUENCE_REQUEST"
);
export const getApprovalSequenceSuccess = createAction(
  "GET_APPROVAL_SEQUENCE_SUCCESS"
);
export const getApprovalSequenceFailure = createAction(
  "GET_APPROVAL_SEQUENCE_FAILURE"
);

export const deleteApprovalSequenceRequest = createAction(
  "DELETE_APPROVAL_SEQUENCE_REQUEST"
);
export const deleteApprovalSequenceSuccess = createAction(
  "DELETE_APPROVAL_SEQUENCE_SUCCESS"
);
export const deleteApprovalSequenceFailure = createAction(
  "DELETE_APPROVAL_SEQUENCE_FAILURE"
);
//create approval sequence
export const createApprovalSequenceRequest = createAction(
  "CREATE_APPROVAL_SEQUENCE_REQUEST"
);
export const createApprovalSequenceSuccess = createAction(
  "CREATE_APPROVAL_SEQUENCE_SUCCESS"
);
export const createApprovalSequenceFailure = createAction(
  "CREATE_APPROVAL_SEQUENCE_FAILURE"
);
//update approval sequence
export const updateApprovalSequenceRequest = createAction(
  "UPDATE_APPROVAL_SEQUENCE_REQUEST"
);
export const updateApprovalSequenceSuccess = createAction(
  "UPDATE_APPROVAL_SEQUENCE_SUCCESS"
);
export const updateApprovalSequenceFailure = createAction(
  "UPDATE_APPROVAL_SEQUENCE_FAILURE"
);

// Approval Step
export const getApprovalStepRequest = createAction("GET_APPROVAL_STEP_REQUEST");
export const getApprovalStepSuccess = createAction("GET_APPROVAL_STEP_SUCCESS");
export const getApprovalStepFailure = createAction("GET_APPROVAL_STEP_FAILURE");

export const updateApprovalStepRequest = createAction(
  "UPDATE_APPROVAL_STEP_REQUEST"
);
export const updateApprovalStepSuccess = createAction(
  "UPDATE_APPROVAL_STEP_SUCCESS"
);
export const updateApprovalStepFailure = createAction(
  "UPDATE_APPROVAL_STEP_FAILURE"
);
