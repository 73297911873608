import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card, CardsBottomSide } from "components/auth/Card";
import "react-toastify/dist/ReactToastify.css";
import "components/auth/login.scss";
import { emailRegex } from "utility/utility";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

const SSOLogin = () => {
  const dispatch = useDispatch();
  const { isGetLoginFailure } = useSelector((state) => state.auth);
  const prevIsGetLoginFailure = usePrevious(isGetLoginFailure);
  const { t } = useTranslation();
  //whether waiting for the response from the server - button login was hit - default false. when response from server is received, it will be set to false
  const [isWaiting, setIsWaiting] = useState(false);
  const [secret, setSecret] = useState(false);

  const onHandleLogin = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (email.length > 0 && email.match(emailRegex)) {
      toast.success("SSO Login Successful", ToastOptions);
    }
  };

  useEffect(() => {
    document.title = "Sing in SSO - Decisimo";
  }, []);

  useEffect(() => {
    if (isGetLoginFailure && prevIsGetLoginFailure === false) {
      toast.error(t("unable_login"), ToastOptions);
      setIsWaiting(false);
    }
  }, [isGetLoginFailure]);

  return (
    <Card>
      <form className="form-pretty" onSubmit={onHandleLogin}>
        <h1 className="form-pretty__title my-4">{t("please_sign_in")}</h1>
        <label className="sr-only mt-2">{t("email")}</label>
        <input
          type="email"
          name="email"
          className="form-control mb-2"
          placeholder={t("email")}
          required
          autoFocus
        />
        <button className="form-pretty__btn" type="submit">
          {isWaiting ? (
            <div
              className="spinner-border"
              style={{
                width: "1rem",
                height: "1rem",
              }}
              role="status"
            >
              <span className="sr-only">{t("loading")}</span>
            </div>
          ) : (
            t("log_in")
          )}
        </button>
        <hr />
        <div>
          <p className="mb-3">
            <Link to="/login" className="text-decoration-none">
              {t("back_to_login")}
            </Link>
          </p>
        </div>
      </form>
    </Card>
  );
};

export default SSOLogin;
