import { call, put, select, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createWorkflowFailure,
  createWorkflowReleaseFailure,
  createWorkflowReleaseRequest,
  createWorkflowReleaseSuccess,
  createWorkflowRequest,
  createWorkflowStepFailure,
  createWorkflowStepRequest,
  createWorkflowStepSuccess,
  createWorkflowSuccess,
  deleteLinkFailure,
  deleteLinkRequest,
  deleteLinkSuccess,
  deleteStepOptionFailure,
  deleteStepOptionParamFailure,
  deleteStepOptionParamRequest,
  deleteStepOptionParamSuccess,
  deleteStepOptionRequest,
  deleteStepOptionSuccess,
  deleteWorkflowFailure,
  deleteWorkflowRequest,
  deleteWorkflowStepFailure,
  deleteWorkflowStepRequest,
  deleteWorkflowStepSuccess,
  deleteWorkflowSuccess,
  getWorkflowFailure,
  getWorkflowOptionConditionTypesFailure,
  getWorkflowOptionConditionTypesRequest,
  getWorkflowOptionConditionTypesSuccess,
  getWorkflowRequest,
  getWorkflowRevisionFailure,
  getWorkflowRevisionRequest,
  getWorkflowRevisionsFailure,
  getWorkflowRevisionsRequest,
  getWorkflowRevisionsSuccess,
  getWorkflowRevisionSuccess,
  getWorkflowsFailure,
  getWorkflowsRequest,
  getWorkflowsSuccess,
  getWorkflowStepTypesFailure,
  getWorkflowStepTypesRequest,
  getWorkflowStepTypesSuccess,
  getWorkflowSuccess,
  updateWorkflowFailure,
  updateWorkflowRequest,
  updateWorkflowStepFailure,
  updateWorkflowStepRequest,
  updateWorkflowStepSuccess,
  updateWorkflowSuccess,
  useCaseWorkflowRequest,
} from "redux/workflows/action";
import { sortById } from "../../utility/utility";

const useCaseWorkflowSelector = (state) => state.workflows.useCaseWorkflow;

function* getWorkflows() {
  try {
    const response = yield call(axios.get, "/workflows");
    if (response.status === 200) {
      yield put(getWorkflowsSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowsFailure("e.message"));
  }
}

function* getWorkflow({ payload }) {
  try {
    const url = `workflows/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getWorkflowFailure("e.message"));
  }
}

function* getWorkflowStepTypes() {
  try {
    const response = yield call(axios.get, "workflow-step-types");
    if (response.status === 200) {
      yield put(getWorkflowStepTypesSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowStepTypesFailure("e.message"));
  }
}

function* getWorkflowOptionConditionTypes({ payload }) {
  try {
    const url = `workflows/${payload}/option-condition-types`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowOptionConditionTypesSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowOptionConditionTypesFailure("e.message"));
  }
}

function* getWorkflowRevisions({ payload }) {
  try {
    const url = `workflows/${payload}/revisions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowRevisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowRevisionsFailure("e.message"));
  }
}

function* getWorkflowRevision({ payload }) {
  try {
    const url = `workflows/${payload.id}/revisions/${payload.revisionId}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowRevisionSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowRevisionFailure("e.message"));
  }
}

function* createWorkflow({ payload }) {
  try {
    const response = yield call(axios.post, "workflows", payload.data);
    if (response.status === 201) {
      if (payload.isUseCase) {
        const useCaseWorkflow = yield select(useCaseWorkflowSelector);

        const useCaseSteps = structuredClone(useCaseWorkflow?.workflow_step);
        useCaseSteps.map((step) => {
          step.workflow_step_id = `new_${step.workflow_step_id}`;
          step.links = [];
          step.options = [];
        });

        const data = JSON.stringify({
          steps: useCaseSteps,
          title: useCaseWorkflow?.title,
          name: useCaseWorkflow?.name,
          description: useCaseWorkflow?.description,
          block_deletion: useCaseWorkflow?.block_deletion || 0,
        });

        yield put(
          updateWorkflowRequest({
            id: response.data.workflow_id,
            data,
            isUseCase: true,
          })
        );

        yield put(
          createWorkflowSuccess({
            eTag: response.data.workflow_id,
            data: {
              steps: useCaseSteps,
              title: useCaseWorkflow?.title,
              name: useCaseWorkflow?.name,
              description: useCaseWorkflow?.description,
              block_deletion: useCaseWorkflow?.block_deletion || 0,
            },
          })
        );
      } else {
        yield put(
          createWorkflowSuccess({
            eTag: response.data.workflow_id,
            data: {},
          })
        );
      }
    }
  } catch (e) {
    yield put(createWorkflowFailure("e.message"));
  }
}

function* createWorkflowRelease({ payload }) {
  try {
    const response = yield call(axios.post, "releases", payload);
    if (response.status === 201) {
      yield put(createWorkflowReleaseSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createWorkflowReleaseFailure("e.message"));
  }
}

function* createWorkflowStep({ payload }) {
  try {
    const url = `workflows/${payload.id}/steps`;
    const response = yield call(axios.post, url, payload.formData);
    if (response.status === 201) {
      yield put(createWorkflowStepSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createWorkflowStepFailure("e.message"));
  }
}

function* updateWorkflow({ payload }) {
  try {
    const url = `workflows/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      if (payload.isUseCase) {
        const steps = structuredClone(response.data.workflow_step);
        const useCaseWorkflow = yield select(useCaseWorkflowSelector);

        useCaseWorkflow?.workflow_step.sort(sortById);
        if (steps?.length > 0) {
          steps.sort(sortById);
          useCaseWorkflow?.workflow_step?.map((step, index) => {
            step?.links?.map((link) => {
              const linkToIndex = useCaseWorkflow?.workflow_step?.findIndex(
                (workflowStep) =>
                  workflowStep.workflow_step_id === link.workflow_step_id_to
              );
              const linkFromIndex = useCaseWorkflow?.workflow_step?.findIndex(
                (workflowStep) =>
                  workflowStep.workflow_step_id === link.workflow_step_id_from
              );

              if (linkToIndex !== -1 && linkFromIndex !== -1) {
                link.workflow_step_id_to = steps[linkToIndex].workflow_step_id;
                link.workflow_step_id_from =
                  steps[linkFromIndex].workflow_step_id;
              }

              if (steps[index]) {
                steps[index].links = step.links;
              }
            });
          });
        }

        const dataWorkflow = JSON.stringify({
          steps,
          name: useCaseWorkflow.name,
          title: useCaseWorkflow.title,
          description: useCaseWorkflow.description,
          block_deletion: useCaseWorkflow.block_deletion || 0,
        });

        yield put(
          updateWorkflowRequest({
            id: response.data.workflow_id,
            data: dataWorkflow,
          })
        );

        yield put(useCaseWorkflowRequest({}));
      }

      const res = structuredClone(response.data);
      res.workflow_step.sort(sortById);

      yield put(updateWorkflowSuccess(res));
    }
  } catch (e) {
    yield put(updateWorkflowFailure("e.message"));
  }
}

function* updateWorkflowStep({ payload }) {
  try {
    const url = `workflows/${payload.workflowId}/steps/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateWorkflowStepSuccess());
    }
  } catch (e) {
    yield put(updateWorkflowStepFailure("e.message"));
  }
}

function* deleteWorkflow({ payload }) {
  try {
    const url = `workflows/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteWorkflowSuccess());
    }
  } catch (e) {
    yield put(deleteWorkflowFailure("e.message"));
  }
}

function* deleteWorkflowStep({ payload }) {
  try {
    const url = `workflows/${payload.workflowId}/steps/${payload.id}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteWorkflowStepSuccess());
    }
  } catch (e) {
    yield put(deleteWorkflowStepFailure("e.message"));
  }
}

function* deleteStepOption({ payload }) {
  try {
    const url = `workflows/${payload.id}/options/${payload.optionId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteStepOptionSuccess({ optionId: payload.optionId }));
    }
  } catch (e) {
    yield put(deleteStepOptionFailure("e.message"));
  }
}

function* deleteStepOptionParam({ payload }) {
  try {
    const url = `workflows/${payload.id}/option-attributes/${payload.paramId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteStepOptionParamSuccess({ paramId: payload.paramId }));
    }
  } catch (e) {
    yield put(deleteStepOptionParamFailure("e.message"));
  }
}

function* deleteLink({ payload }) {
  try {
    const url = `workflows/${payload.id}/links/${payload.linkId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteLinkSuccess());
    }
  } catch (e) {
    yield put(deleteLinkFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getWorkflowsRequest, getWorkflows);
  yield takeLatest(getWorkflowRequest, getWorkflow);
  yield takeLatest(getWorkflowStepTypesRequest, getWorkflowStepTypes);
  yield takeLatest(
    getWorkflowOptionConditionTypesRequest,
    getWorkflowOptionConditionTypes
  );
  yield takeLatest(getWorkflowRevisionsRequest, getWorkflowRevisions);
  yield takeLatest(getWorkflowRevisionRequest, getWorkflowRevision);
  yield takeLatest(createWorkflowRequest, createWorkflow);
  yield takeLatest(createWorkflowReleaseRequest, createWorkflowRelease);
  yield takeLatest(createWorkflowStepRequest, createWorkflowStep);
  yield takeLatest(updateWorkflowRequest, updateWorkflow);
  yield takeLatest(updateWorkflowStepRequest, updateWorkflowStep);
  yield takeLatest(deleteWorkflowRequest, deleteWorkflow);
  yield takeLatest(deleteWorkflowStepRequest, deleteWorkflowStep);
  yield takeLatest(deleteStepOptionRequest, deleteStepOption);
  yield takeLatest(deleteStepOptionParamRequest, deleteStepOptionParam);
  yield takeLatest(deleteLinkRequest, deleteLink);
}
