// src/redux/analysisRecipes/actions.js

import { createAction } from "redux-actions";

// Get all analysis recipes
export const getAnalysisRecipesRequest = createAction(
  "GET_ANALYSIS_RECIPES_REQUEST"
);
export const getAnalysisRecipesSuccess = createAction(
  "GET_ANALYSIS_RECIPES_SUCCESS"
);
export const getAnalysisRecipesFailure = createAction(
  "GET_ANALYSIS_RECIPES_FAILURE"
);

// Get a single analysis recipe
export const getAnalysisRecipeRequest = createAction(
  "GET_ANALYSIS_RECIPE_REQUEST"
);
export const getAnalysisRecipeSuccess = createAction(
  "GET_ANALYSIS_RECIPE_SUCCESS"
);
export const getAnalysisRecipeFailure = createAction(
  "GET_ANALYSIS_RECIPE_FAILURE"
);

// Create a new analysis recipe
export const createAnalysisRecipeRequest = createAction(
  "CREATE_ANALYSIS_RECIPE_REQUEST"
);
export const createAnalysisRecipeSuccess = createAction(
  "CREATE_ANALYSIS_RECIPE_SUCCESS"
);
export const createAnalysisRecipeFailure = createAction(
  "CREATE_ANALYSIS_RECIPE_FAILURE"
);

// Update an existing analysis recipe
export const updateAnalysisRecipeRequest = createAction(
  "UPDATE_ANALYSIS_RECIPE_REQUEST"
);
export const updateAnalysisRecipeSuccess = createAction(
  "UPDATE_ANALYSIS_RECIPE_SUCCESS"
);
export const updateAnalysisRecipeFailure = createAction(
  "UPDATE_ANALYSIS_RECIPE_FAILURE"
);

// Delete an analysis recipe
export const deleteAnalysisRecipeRequest = createAction(
  "DELETE_ANALYSIS_RECIPE_REQUEST"
);
export const deleteAnalysisRecipeSuccess = createAction(
  "DELETE_ANALYSIS_RECIPE_SUCCESS"
);
export const deleteAnalysisRecipeFailure = createAction(
  "DELETE_ANALYSIS_RECIPE_FAILURE"
);
