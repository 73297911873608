import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Formula = ({ open, handleClose, formula }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal centered={true} size="xl" onHide={handleClose} show={open}>
        <Modal.Header>
          <h3 className="modal-title">{t("visualized_formula")}</h3>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="p-5 overflow-auto">{formula}</Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            onClick={handleClose}
            className="my-0 outline d-inline-block"
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Formula.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  formula: PropTypes.any,
};

export default Formula;
