import * as SRD from "react-js-diagrams/lib/main";
import { DTCustomNodeWidgetFactory } from "components/decisionTrees/drawflow/CustomNode/DTCustomNodeWidget";

export class DTCustomWidgetFactory extends SRD.NodeWidgetFactory {
  constructor() {
    super("custom");
  }

  generateReactWidget(diagramEngine, node) {
    return DTCustomNodeWidgetFactory({ node });
  }
}
