// src/redux/analysisRecipes/reducer.js

import { handleActions } from "redux-actions";
import {
  getAnalysisRecipesRequest,
  getAnalysisRecipesSuccess,
  getAnalysisRecipesFailure,
  getAnalysisRecipeRequest,
  getAnalysisRecipeSuccess,
  getAnalysisRecipeFailure,
  createAnalysisRecipeRequest,
  createAnalysisRecipeSuccess,
  createAnalysisRecipeFailure,
  updateAnalysisRecipeRequest,
  updateAnalysisRecipeSuccess,
  updateAnalysisRecipeFailure,
  deleteAnalysisRecipeRequest,
  deleteAnalysisRecipeSuccess,
  deleteAnalysisRecipeFailure,
} from "./actions";

const initialState = {
  analysisRecipes: [],
  analysisRecipe: {},
  eTag: null,
  isGetAnalysisRecipesSuccess: false,
  isGetAnalysisRecipesError: false,
  isGetAnalysisRecipeSuccess: false,
  isGetAnalysisRecipeError: false,
  isCreatedAnalysisRecipeSuccess: false,
  isCreatedAnalysisRecipeError: false,
  isUpdatedAnalysisRecipeSuccess: false,
  isUpdatedAnalysisRecipeError: false,
  isDeletedAnalysisRecipeSuccess: false,
  isDeletedAnalysisRecipeError: false,
};

const reducer = handleActions(
  {
    // Get all analysis recipes
    [getAnalysisRecipesRequest]: (state) => ({
      ...state,
      isGetAnalysisRecipesSuccess: false,
      isGetAnalysisRecipesError: false,
    }),
    [getAnalysisRecipesSuccess]: (state, { payload }) => ({
      ...state,
      analysisRecipes: payload || [],
      isGetAnalysisRecipesSuccess: true,
      isGetAnalysisRecipesError: false,
    }),
    [getAnalysisRecipesFailure]: (state) => ({
      ...state,
      isGetAnalysisRecipesSuccess: false,
      isGetAnalysisRecipesError: true,
    }),

    // Get a single analysis recipe
    [getAnalysisRecipeRequest]: (state) => ({
      ...state,
      isGetAnalysisRecipeSuccess: false,
      isGetAnalysisRecipeError: false,
    }),
    [getAnalysisRecipeSuccess]: (state, { payload }) => ({
      ...state,
      analysisRecipe: payload,
      isGetAnalysisRecipeSuccess: true,
      isGetAnalysisRecipeError: false,
    }),
    [getAnalysisRecipeFailure]: (state) => ({
      ...state,
      isGetAnalysisRecipeSuccess: false,
      isGetAnalysisRecipeError: true,
    }),

    // Create analysis recipe
    [createAnalysisRecipeRequest]: (state) => ({
      ...state,
      isCreatedAnalysisRecipeSuccess: false,
      isCreatedAnalysisRecipeError: false,
    }),
    [createAnalysisRecipeSuccess]: (state, { payload }) => ({
      ...state,
      analysisRecipes: [...state.analysisRecipes, payload],
      eTag: payload.release_analysis_recipe_id ?? "",
      isCreatedAnalysisRecipeSuccess: true,
      isCreatedAnalysisRecipeError: false,
    }),
    [createAnalysisRecipeFailure]: (state) => ({
      ...state,
      isCreatedAnalysisRecipeSuccess: false,
      isCreatedAnalysisRecipeError: true,
    }),

    // Update analysis recipe
    [updateAnalysisRecipeRequest]: (state) => ({
      ...state,
      isUpdatedAnalysisRecipeSuccess: false,
      isUpdatedAnalysisRecipeError: false,
    }),
    [updateAnalysisRecipeSuccess]: (state, { payload }) => ({
      ...state,
      analysisRecipes: state.analysisRecipes.map((recipe) =>
        recipe.release_analysis_recipe_id === payload.release_analysis_recipe_id
          ? payload
          : recipe
      ),
      analysisRecipe: payload,
      isUpdatedAnalysisRecipeSuccess: true,
      isUpdatedAnalysisRecipeError: false,
    }),
    [updateAnalysisRecipeFailure]: (state) => ({
      ...state,
      isUpdatedAnalysisRecipeSuccess: false,
      isUpdatedAnalysisRecipeError: true,
    }),

    // Delete analysis recipe
    [deleteAnalysisRecipeRequest]: (state) => ({
      ...state,
      isDeletedAnalysisRecipeSuccess: false,
      isDeletedAnalysisRecipeError: false,
    }),
    [deleteAnalysisRecipeSuccess]: (state, { payload }) => ({
      ...state,
      analysisRecipes: state.analysisRecipes.filter(
        (recipe) => recipe.release_analysis_recipe_id !== payload
      ),
      isDeletedAnalysisRecipeSuccess: true,
      isDeletedAnalysisRecipeError: false,
    }),
    [deleteAnalysisRecipeFailure]: (state) => ({
      ...state,
      isDeletedAnalysisRecipeSuccess: false,
      isDeletedAnalysisRecipeError: true,
    }),
  },
  initialState
);

export default reducer;
