import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnalysisRecipeForm from "./AnalysisRecipeForm";
import {
  getAnalysisRecipeRequest,
  updateAnalysisRecipeRequest,
} from "redux/analysisRecipes/actions";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom"; // Added useNavigate
import usePrevious from "utility/hooks/usePrevious"; // Ensure this hook exists
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader"; // Assuming you have a SubHeader component
import { ReactComponent as SaveIcon } from "assets/icons/save.svg"; // Example icon

const EditAnalysisRecipe = () => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams(); // Recipe ID from URL
  const navigate = useNavigate(); // For navigation after update

  const {
    analysisRecipe,
    isGetAnalysisRecipeSuccess,
    isGetAnalysisRecipeError,
    isUpdatedAnalysisRecipeSuccess,
    isUpdatedAnalysisRecipeError,
  } = useSelector((state) => state.analysisRecipes);

  useEffect(() => {
    document.title = `${t("analysis_recipe")} - Decisimo`;
  }, [t]);

  // Custom hook to track previous success state
  const prevIsUpdatedAnalysisRecipeSuccess = usePrevious(
    isUpdatedAnalysisRecipeSuccess
  );

  // Initialize the cloned data object
  const [clonedData, setClonedData] = useState({
    name: "",
    title: "",
    tech_information: "",
    slices: [],
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getAnalysisRecipeRequest(id));
    }
  }, [id, dispatch, setIsLoading]);

  useEffect(() => {
    if (isGetAnalysisRecipeSuccess && analysisRecipe) {
      setIsLoading(false);
      // Initialize clonedData with fetched analysisRecipe
      setClonedData({
        name: analysisRecipe.name || "",
        title: analysisRecipe.title || "",
        tech_information: analysisRecipe.tech_information || "",
        slices: analysisRecipe.slices || [],
      });
    }

    if (isGetAnalysisRecipeError) {
      setIsLoading(false);
      toast.error(`${t("error_fetching_analysis_recipe")}.`, ToastOptions);
      // Optionally redirect or show error
      navigate("/analysis-recipes"); // Redirecting to list page
    }
  }, [
    isGetAnalysisRecipeSuccess,
    analysisRecipe,
    isGetAnalysisRecipeError,
    setIsLoading,
    t,
    navigate,
  ]);

  useEffect(() => {
    if (
      isUpdatedAnalysisRecipeSuccess &&
      prevIsUpdatedAnalysisRecipeSuccess === false
    ) {
      setIsLoading(false);
      setIsEdited(false);
      toast.success(`${t("analysis_recipe_updated")}`, ToastOptions);
    }

    if (
      isUpdatedAnalysisRecipeError &&
      prevIsUpdatedAnalysisRecipeSuccess === false
    ) {
      setIsLoading(false);
      toast.error(`${t("error_updating_analysis_recipe")}.`, ToastOptions);
    }
  }, [
    isUpdatedAnalysisRecipeSuccess,
    isUpdatedAnalysisRecipeError,
    prevIsUpdatedAnalysisRecipeSuccess,
    setIsLoading,
    setIsEdited,
    t,
    navigate,
    id,
  ]);

  // Handler for the external Save button
  const handleSave = () => {
    // Optional: Add any pre-submit validation here
    setIsLoading(true);
    dispatch(updateAnalysisRecipeRequest({ id, data: clonedData }));
  };

  // Optional: Handler for form submission via internal form button (if kept)
  const handleFormSubmit = (formData) => {
    setIsLoading(true);
    dispatch(updateAnalysisRecipeRequest({ id, data: formData }));
  };

  return (
    <>
      <SubHeader
        title={t("edit_analysis_recipe")}
        actions={
          <>
            <button
              className="btn primary mr-2"
              onClick={handleSave}
              title={t("save_data_source")}
            >
              <SaveIcon />
            </button>
          </>
        }
      />
      <div className="card p-4 mt-3">
        {isGetAnalysisRecipeSuccess && (
          <AnalysisRecipeForm
            formData={clonedData}
            setFormData={setClonedData}
            onSubmit={handleFormSubmit} // Optional: Use if you keep the form's submit button
            id="recipe-form"
          />
        )}
        {/* Optionally handle loading and error states */}
      </div>
    </>
  );
};

export default EditAnalysisRecipe;
