import React from "react";
import * as RJD from "react-js-diagrams/lib/main";

import CustomLinkWidget from "components/workflow/drawflow/CustomNode/CustomLinkWidget";

export class CustomLinkFactory extends RJD.LinkWidgetFactory {
  constructor(props) {
    super("CustomLink");
    this.state = {
      setLinkId: props,
    };
  }

  generateReactWidget(diagramEngine, link) {
    return (
      <CustomLinkWidget
        link={link}
        diagramEngine={diagramEngine}
        setLinkId={this.state.setLinkId}
      />
    );
  }
}
