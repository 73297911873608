import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

const SubHeader = ({
  release = false,
  workflowMode = false,
  title,
  icon,
  actions = "",
  isThereActionsWithoutMainElement = false,
  alt,
  endpoint = false,
}) => {
  const { id } = useParams();
  return (
    <>
      <div
        className={`align-items-center ${
          !["/deployments"].includes(window.location.pathname) ? "d-flex" : ""
        } ${
          ["/workflows"].includes(window.location.pathname)
            ? "flex-column flex-md-row"
            : id && ![`/models/${id}`].includes(window.location.pathname)
            ? "flex-column flex-sm-row"
            : ""
        } mb-3 pb-2 pt-3`}
      >
        <div
          className={`${icon ? "d-flex align-items-center w-100" : "col pl-0"}`}
        >
          {icon && (
            <span title={alt} className="mr-3 cursor-pointer">
              {icon}
            </span>
          )}
          <h1
            title={id ? `${alt} Title` : null}
            className={`cursor-pointer mt-2 ${
              workflowMode
                ? "workflow-header"
                : ["/workflows"].includes(window.location.pathname)
                ? "col"
                : window.location.pathname.includes("releases")
                ? "col"
                : ["/dashboard"].includes(window.location.pathname)
                ? "dashboard-title"
                : ""
            } ${release || endpoint ? "col-12" : "col-md-8"} h2 p-0`}
          >
            {title}
          </h1>
        </div>
        {!isThereActionsWithoutMainElement ? (
          <div className="col d-flex justify-content-end">{actions}</div>
        ) : (
          actions
        )}
      </div>
    </>
  );
};

SubHeader.propTypes = {
  isThereActionsWithoutMainElement: PropTypes.bool,
  title: PropTypes.any,
  icon: PropTypes.any,
  release: PropTypes.bool,
  workflowMode: PropTypes.bool,
  alt: PropTypes.string,
  endpoint: PropTypes.bool,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default SubHeader;
