import { useEffect, useRef } from "react";

export const useCtrlSHandler = (dispatch, data) => {
  const formRef = useRef(null);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  const handleKeyPress = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "s") {
      event.preventDefault();

      if (data) {
        dispatch(data);
      } else {
        dispatch(formRef.current);
      }
    }
  };
  return [formRef];
};

export const useCtrlQHandler = (dispatch, data) => {
  const formRef = useRef(null);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "q") {
      event.preventDefault();

      if (data) {
        dispatch(data);
      } else {
        dispatch(formRef.current);
      }
    }
  };
  return [formRef];
};
