import {
  createDecisionTableRequest,
  updateDecisionTableRequest,
} from "redux/decisionTables/action";

export const createItemCopy = (
  content,
  dispatch,
  templateMode = false,
  copy_of = "Copy of",
  copy
) => {
  const decisionTableDefinition = structuredClone(content);
  const data = new URLSearchParams(new FormData());
  data.set(
    "title",
    !templateMode
      ? `${copy_of} ${decisionTableDefinition.title}`
      : decisionTableDefinition.title
  );
  data.set(
    "name",
    !templateMode
      ? `${copy} -${decisionTableDefinition.name}`
      : decisionTableDefinition.name
  );

  if (
    decisionTableDefinition?.cols?.length > 0 &&
    decisionTableDefinition?.cols[0].decision_table_column_id !== null
  ) {
    decisionTableDefinition?.cols?.forEach((col) => {
      const randomNumber = `new_${Math.round(Math.random() * 10000)}`;
      data.set(`column[${randomNumber}][title]`, col.title);
      data.set(`column[${randomNumber}][attribute_path]`, col.attribute_path);
      data.set(`column[${randomNumber}][role]`, col.role);
    });
  }

  setTimeout(() => {
    dispatch(createDecisionTableRequest({ data }));
  }, 1000);
};

export const updateDecisionTable = (
  decisionTable,
  rows,
  newDecisionTableId,
  dispatch
) => {
  const decisionTableRows = structuredClone(rows);
  const dataRows = new URLSearchParams(new FormData());

  const cloneDecisionTable = structuredClone(decisionTable);
  if (decisionTableRows?.length > 0) {
    decisionTableRows?.forEach((row) => {
      const rowId = `new_${Math.round(Math.random() * 10000)}`;
      if (row.cols?.length > 0) {
        row.cols.forEach((revisionCol, index) => {
          const dtRowColumnId = `new_${Math.round(Math.random() * 10000)}`;
          dataRows.set(`field[${dtRowColumnId}][value_a]`, revisionCol.value_a);
          dataRows.set(`field[${dtRowColumnId}][value_b]`, revisionCol.value_b);
          dataRows.set(
            `field[${dtRowColumnId}][decision_table_column_id]`,
            cloneDecisionTable?.cols[index]?.decision_table_column_id
          );
          dataRows.set(
            `field[${dtRowColumnId}][dt_row_column_id]`,
            dtRowColumnId
          );
          dataRows.set(`field[${dtRowColumnId}][decision_table_row_id]`, rowId);
          dataRows.set(
            `field[${dtRowColumnId}][operator]`,
            revisionCol.operator
          );
        });
      }
      dataRows.set(`row[${rowId}][index]`, row.row_index);
    });
  }

  dispatch(
    updateDecisionTableRequest({ id: newDecisionTableId, data: dataRows })
  );
};

// Utility to find differences between two decision tables
export function compareDecisionTables(revision1, revision2) {
  const changes = {
    definitionChanges: [],
    rowChanges: [],
  };

  // 1. Check for Definition Changes
  const keysToCheck = ["verbose", "stop_on_first_hit", "title", "name"];
  keysToCheck.forEach((key) => {
    if (
      revision1.content.definition[key] !== revision2.content.definition[key]
    ) {
      changes.definitionChanges.push({
        key,
        from: revision1.content.definition[key],
        to: revision2.content.definition[key],
      });
    }
  });

  // 2. Check for Column Changes (like column index or attribute path)
  const columns1 = revision1.content.definition.cols;
  const columns2 = revision2.content.definition.cols;
  const allColumns = new Map();

  columns1.forEach((col) => {
    allColumns.set(col.decision_table_column_id, { from: col, to: null });
  });

  columns2.forEach((col) => {
    if (allColumns.has(col.decision_table_column_id)) {
      allColumns.get(col.decision_table_column_id).to = col;
    } else {
      allColumns.set(col.decision_table_column_id, { from: null, to: col });
    }
  });

  allColumns.forEach((value, key) => {
    const { from, to } = value;
    if (from && !to) {
      changes.definitionChanges.push({
        type: "column_removed",
        column: from,
      });
    } else if (!from && to) {
      changes.definitionChanges.push({
        type: "column_added",
        column: to,
      });
    } else if (from && to && JSON.stringify(from) !== JSON.stringify(to)) {
      changes.definitionChanges.push({
        type: "column_modified",
        from,
        to,
      });
    }
  });

  // 3. Check for Row Changes
  const rows1 = revision1.content.rows;
  const rows2 = revision2.content.rows;

  const rowMap1 = {};
  rows1.forEach((row) => {
    rowMap1[row.decision_table_row_id] = row;
  });

  const rowMap2 = {};
  rows2.forEach((row) => {
    rowMap2[row.decision_table_row_id] = row;
  });

  const allRowIds = new Set([...Object.keys(rowMap1), ...Object.keys(rowMap2)]);

  allRowIds.forEach((rowId) => {
    const row1 = rowMap1[rowId] || null;
    const row2 = rowMap2[rowId] || null;

    if (row1 && !row2) {
      changes.rowChanges.push({ type: "deleted", row: row1 });
    } else if (!row1 && row2) {
      changes.rowChanges.push({ type: "added", row: row2 });
    } else if (row1 && row2) {
      const changesInRow = [];
      row1.cols.forEach((col1, index) => {
        const col2 = row2.cols[index] || {};
        if (JSON.stringify(col1) !== JSON.stringify(col2)) {
          changesInRow.push({
            index,
            from: col1,
            to: col2,
          });
        }
      });

      if (changesInRow.length > 0) {
        changes.rowChanges.push({
          type: "modified",
          rowId: rowId,
          changes: changesInRow,
        });
      }
    }
  });

  return changes;
}
