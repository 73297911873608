import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DraggableFormulaContext } from "context/contexts";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import DraggableItem from "./DraggableItem";
import { handleLayers } from "../utils";
import { defaultFormulaItem, getStructuredItems } from "utility/utility";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DraggableFormula = ({
  itemsData = {},
  attributes = [],
  setItemsData,
  setIsDraggable,
}) => {
  const [items, setItems] = useState([]);
  const [draggingItems, setDraggingItems] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const finalData = [];
    handleLayers(itemsData.children, finalData);
    setItems(finalData);
  }, [itemsData]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor)
  );

  const handleDragStart = (event) => {
    const { active } = event;
    const activeIndex = items?.findIndex(
      ({ fce_item_id }) => active.id === fce_item_id
    );

    if (activeIndex !== -1 && items.length > 0) {
      if (["b", "f"].includes(items[activeIndex].item_type)) {
        const endIndex = items.findIndex(
          (item) => item.bracket_end === active.id
        );

        setDraggingItems(items.slice(activeIndex, endIndex + 1));
      } else if (["b_c", "f_c"].includes(items[activeIndex].item_type)) {
        const startId = items[activeIndex].bracket_end;
        const startIndex = items.findIndex(
          ({ fce_item_id }) => startId === fce_item_id
        );
        setDraggingItems(items.slice(startIndex, activeIndex + 1));
      } else {
        setDraggingItems([items[activeIndex]]);
      }
    }
  };

  const handleDragOver = ({ over, active }) => {
    const draggingIds = draggingItems?.map((item) => item.fce_item_id);
    if (over?.id && over.id !== active.id && !draggingIds?.includes(over.id)) {
      setDraggingItems((draggingItems) => {
        const draggingItemsClone = structuredClone(draggingItems);

        setItems((items) => {
          const filteredItems = items.filter(
            (item) => !draggingIds.includes(item.fce_item_id)
          );

          const oldOverIndex = items.findIndex(
            (item) => item.fce_item_id === over.id
          );

          const oldActiveIndex = items.findIndex(
            (item) => item.fce_item_id === active.id
          );

          const item = draggingItemsClone[0];
          const lastItem = draggingItemsClone.at(-1);
          if (
            (oldActiveIndex < oldOverIndex &&
              ["b", "f"].includes(items[oldOverIndex].item_type)) ||
            (oldActiveIndex > oldOverIndex &&
              ["b_c", "f_c"].includes(items[oldOverIndex].item_type))
          ) {
            item.parent_fce_item_id =
              items[oldOverIndex].bracket_end ??
              items[oldOverIndex].fce_item_id;
          } else {
            item.parent_fce_item_id = items[oldOverIndex].parent_fce_item_id;
          }

          lastItem.parent_fce_item_id = item.parent_fce_item_id;

          filteredItems.splice(oldOverIndex, 0, draggingItemsClone);

          return filteredItems.flat();
        });

        return draggingItemsClone;
      });
    }
  };

  const saveDraggableFormula = () => {
    const itemsClone = structuredClone(items);
    let error = false;
    items?.forEach((item, index) => {
      if (
        index + 1 !== items.length &&
        ((item.item_type === "o" && items[index + 1].item_type === "o") ||
          (index === 0 &&
            item.item_type === "o" &&
            items[index + 1].item_type === "c") ||
          (index === 0 &&
            item.item_type === "o" &&
            ["b", "f"].includes(items[index + 1].item_type)) ||
          (item.item_type === "a" && items[index + 1].item_type === "a") ||
          (item.item_type === "v" && items[index + 1].item_type === "v") ||
          (item.item_type === "a" && items[index + 1].item_type === "v") ||
          (item.item_type === "v" && items[index + 1].item_type === "a") ||
          (item.item_type === "v" &&
            ["b", "f"].includes(items[index + 1].item_type)) ||
          (["b", "f"].includes(item.item_type) &&
            ["b_c", "f_c"].includes(items[index + 1].item_type)) ||
          (["b", "f"].includes(item.item_type) &&
            items[index + 1].item_type === "o") ||
          (["b_c", "f_c"].includes(item.item_type) &&
            ["b", "f"].includes(items[index + 1].item_type)) ||
          (item.item_type === "o" && items[index + 1].item_type === "b_c"))
      ) {
        return (error = true);
      }
    });

    if (error) {
      return toast.error(t("invalid_formula"), ToastOptions);
    }

    const filtered = itemsClone?.filter(
      (item) => !["b_c", "f_c"].includes(item.item_type)
    );
    let structuredItems = getStructuredItems(filtered);

    const defaultFormulaItemClone = structuredClone(defaultFormulaItem);
    defaultFormulaItemClone.children = structuredItems;
    setItemsData(defaultFormulaItemClone);
    setIsDraggable(false);
  };

  return (
    <>
      <DraggableFormulaContext.Provider
        value={{
          attributes,
          draggingItems,
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={() => setDraggingItems([])}
        >
          <SortableContext
            items={items?.map((item) => item.fce_item_id)}
            strategy={horizontalListSortingStrategy}
          >
            <div className={`input-group position-static`}>
              {items?.map((item) => (
                <SortableItem
                  key={item.fce_item_id}
                  id={item.fce_item_id}
                  item={item}
                />
              ))}
            </div>
          </SortableContext>
          <DragOverlay>
            <div className="d-flex">
              {draggingItems?.map((item) => (
                <DraggableItem
                  item={item}
                  key={item.fce_item_id}
                  isDragging={true}
                />
              ))}
            </div>
          </DragOverlay>
        </DndContext>
      </DraggableFormulaContext.Provider>

      <div className="mt-3 text-right">
        <button
          className="btn primary"
          type="button"
          onClick={saveDraggableFormula}
        >
          {t("finish_dragging")}
        </button>
      </div>
    </>
  );
};

DraggableFormula.propTypes = {
  itemsData: PropTypes.object,
  attributes: PropTypes.array,
  setItemsData: PropTypes.func,
  setIsDraggable: PropTypes.func,
};

export default DraggableFormula;
