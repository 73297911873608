import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createEndPointRequest,
  getEndPointInstancesRequest,
} from "redux/endpoints/action";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import EndPointContent from "components/endpoints/EndPointContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const CreateEndPoint = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(handleCreateEndPoint);
  const { t } = useTranslation();

  const {
    isGetEndPointInstancesSuccess,
    instances,
    isCreatedEndPointSuccess,
    isCreatedEndPointError,
    eTag,
  } = useSelector((state) => state.endPoints);

  const prevIsGetEndPointInstancesSuccess = usePrevious(
    isGetEndPointInstancesSuccess
  );
  const prevIsCreatedEndPointSuccess = usePrevious(isCreatedEndPointSuccess);
  const prevIsCreatedEndPointError = usePrevious(isCreatedEndPointError);

  useEffect(() => {
    document.title = `${t("plus_api_endpoint")} - Decisimo`;
    setIsLoading(true);
    dispatch(getEndPointInstancesRequest());
  }, []);

  useEffect(() => {
    if (
      isGetEndPointInstancesSuccess &&
      prevIsGetEndPointInstancesSuccess === false
    ) {
      setIsLoading(false);
    }
  }, [isGetEndPointInstancesSuccess]);

  useEffect(() => {
    if (isCreatedEndPointSuccess && prevIsCreatedEndPointSuccess === false) {
      setIsLoading(false);
      toast.success(t("endpoint_created"), ToastOptions);
      navigate(`/api-endpoints/${eTag}`);
    }
  }, [isCreatedEndPointSuccess]);

  useEffect(() => {
    if (isCreatedEndPointError && prevIsCreatedEndPointError === false) {
      setIsLoading(false);
      toast.error(t("failed_to_add_new_endpoint"), ToastOptions);
    }
  }, [isCreatedEndPointError]);

  function handleCreateEndPoint(target) {
    const data = new FormData(target);
    const name = target.name.value;
    const apiKey = target.api_key.value;

    if (name.length === 0 || apiKey.length === 0) {
      return toast.error(
        t("please_fill_out_the_api_name_or_key"),
        ToastOptions
      );
    }

    setIsLoading(true);
    dispatch(createEndPointRequest(data));
  }

  return (
    <>
      <SubHeader title={t("plus_api_endpoint")} actions="" />
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleCreateEndPoint(e.target);
        }}
      >
        <EndPointContent instances={instances} />
        <div className="mb-5 mt-5">
          <button className="btn primary" type="submit">
            <SaveIcon /> {t("save")}
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateEndPoint;
