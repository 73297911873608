import { useNavigate } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const WorkflowRow = ({ workflow }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onHandleClick = () => {
    navigate(`/workflows/${workflow.workflow_id}`);
  };

  return (
    <tr key={workflow.workflow_id} className="" onClick={onHandleClick}>
      <th title={workflow.name} className="td-flow ">
        {workflow.title}{" "}
      </th>
      <td className="td-flow ">
        {workflow.description === ""
          ? t("decision_flow_text")
          : workflow.description}
      </td>
      <td className="td-flow ">
        {workflow.dtime_modified}
        <HelpCircle
          data-tip={true}
          data-for={`modified-${workflow.workflow_id}`}
          className="ml-2 cursor-pointer"
        />
        <ReactTooltip
          type="dark"
          place="right"
          effect="solid"
          id={`modified-${workflow.workflow_id}`}
        >
          {t("modified_by")} {workflow.full_name}
        </ReactTooltip>
      </td>
    </tr>
  );
};

WorkflowRow.propTypes = {
  workflow: PropTypes.object,
};

export default WorkflowRow;
