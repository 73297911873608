import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CreateDataObject = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleConfirm,
    title = t("add_data_object"),
    editMode = false,
    isLoading,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    if (!open) setSelectedFile(null);
  }, [open]);

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form onSubmit={(e) => handleConfirm(e, selectedFile)}>
          <Modal.Body>
            <div className="card-body">
              {!editMode && (
                <div className="row mb-3">
                  <label>{t("table_title")}</label>
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    required
                  />
                </div>
              )}

              <div className="row">
                <label>
                  <em>
                    {t("select_file_with_example")}
                    {!editMode ? t("optional") : ""}
                  </em>
                </label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    name="json_vector"
                    id="json-file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    accept=".json, .js, .txt"
                    required={editMode ? "required" : ""}
                  />
                  <label
                    className={`custom-file-label ${
                      selectedFile ? "border-success text-success" : ""
                    }`}
                    htmlFor="json-file"
                  >
                    {selectedFile
                      ? selectedFile.name
                      : t("choose_json_file_example_for_your_data_object")}
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="" onClick={handleClose} className="outline my-0">
              {t("close")}
            </Button>

            <Button
              disabled={isLoading}
              variant=""
              type="submit"
              className="primary my-0 w-70"
            >
              {isLoading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">{t("loading")}</span>
                </div>
              ) : editMode ? (
                t("upload")
              ) : (
                t("create")
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

CreateDataObject.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  editMode: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default CreateDataObject;
