import { createAction } from "redux-actions";

export const getReleasesRequest = createAction("GET_RELEASES_REQUEST");
export const getReleasesSuccess = createAction("GET_RELEASES_SUCCESS");
export const getReleasesFailure = createAction("GET_RELEASES_FAILURE");

export const getReleaseRequest = createAction("GET_RELEASE_REQUEST");
export const getReleaseSuccess = createAction("GET_RELEASE_SUCCESS");
export const getReleaseFailure = createAction("GET_RELEASE_FAILURE");

export const getWorkflowReleasesRequest = createAction(
  "GET_WORKFLOW_RELEASES_REQUEST"
);
export const getWorkflowReleasesSuccess = createAction(
  "GET_WORKFLOW_RELEASES_SUCCESS"
);
export const getWorkflowReleasesFailure = createAction(
  "GET_WORKFLOW_RELEASES_FAILURE"
);

export const getTestReleaseRequest = createAction("GET_TEST_RELEASE_REQUEST");
export const getTestReleaseSuccess = createAction("GET_TEST_RELEASE_SUCCESS");
export const getTestReleaseFailure = createAction("GET_TEST_RELEASE_FAILURE");

export const deleteReleaseRequest = createAction("DELETE_RELEASE_REQUEST");
export const deleteReleaseSuccess = createAction("DELETE_RELEASE_SUCCESS");
export const deleteReleaseFailure = createAction("DELETE_RELEASE_FAILURE");

export const getReleaseApiEndPointsRequest = createAction(
  "GET_RELEASE_API_ENDPOINTS_REQUEST"
);
export const getReleaseApiEndPointsSuccess = createAction(
  "GET_RELEASE_API_ENDPOINTS_SUCCESS"
);
export const getReleaseApiEndPointsFailure = createAction(
  "GET_RELEASE_API_ENDPOINTS_FAILURE"
);

export const createDeployReleaseRequest = createAction(
  "CREATE_DEPLOY_RELEASE_REQUEST"
);
export const createDeployReleaseSuccess = createAction(
  "CREATE_DEPLOY_RELEASE_SUCCESS"
);
export const createDeployReleaseFailure = createAction(
  "CREATE_DEPLOY_RELEASE_FAILURE"
);

export const updateReleaseNotesRequest = createAction(
  "UPDATE_RELEASE_NOTES_REQUEST"
);
export const updateReleaseNotesSuccess = createAction(
  "UPDATE_RELEASE_NOTES_SUCCESS"
);
export const updateReleaseNotesFailure = createAction(
  "UPDATE_RELEASE_NOTES_FAILURE"
);
// Blueprint PDF Download actions
export const downloadBlueprintRequest = createAction(
  "DOWNLOAD_BLUEPRINT_REQUEST"
);
export const downloadBlueprintSuccess = createAction(
  "DOWNLOAD_BLUEPRINT_SUCCESS"
);
export const downloadBlueprintFailure = createAction(
  "DOWNLOAD_BLUEPRINT_FAILURE"
);
// Validation Sets
export const createValidationSetRequest = createAction(
  "CREATE_VALIDATION_SET_REQUEST"
);
export const createValidationSetSuccess = createAction(
  "CREATE_VALIDATION_SET_SUCCESS"
);
export const createValidationSetFailure = createAction(
  "CREATE_VALIDATION_SET_FAILURE"
);

//notify validation set upload success
export const notifyValidationSetUploadRequest = createAction(
  "NOTIFY_VALIDATION_SET_UPLOAD_REQUEST"
);
export const notifyValidationSetUploadSuccess = createAction(
  "NOTIFY_VALIDATION_SET_UPLOAD_SUCCESS"
);
export const notifyValidationSetUploadFailure = createAction(
  "NOTIFY_VALIDATION_SET_UPLOAD_FAILURE"
);
