import React from "react";
import * as RJD from "react-js-diagrams/lib/main";
import PropTypes from "prop-types";
import { updateWorkflowRequest } from "redux/workflows/action";
import { connect } from "react-redux";
import { CustomNodeModel } from "components/workflow/drawflow/CustomNode/CustomNodeModel";
import { CustomWidgetFactory } from "components/workflow/drawflow/CustomNode/CustomWidgetFactory";
import { CustomLinkFactory } from "components/workflow/drawflow/CustomNode/CustomLinkFactory";
import { CustomLinkModel } from "components/workflow/drawflow/CustomNode/CustomLinkModel";
import DiagramDraw from "components/workflow/drawflow/DiagramDraw";
import "assets/css/diagrams.scss";

class Diagram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      engine: null,
      workflow: {},
      setLinkId: props.setLinkId,
    };

    this.engine = new RJD.DiagramEngine();
    this.engine.registerLinkFactory(new RJD.DefaultLinkFactory());
    this.engine.registerNodeFactory(new RJD.DefaultNodeFactory());
    this.engine.registerLinkFactory(
      new CustomLinkFactory(this.state.setLinkId)
    );
    this.engine.registerNodeFactory(new CustomWidgetFactory());
    this.engine.registerInstanceFactory(new RJD.DefaultNodeInstanceFactory());
    this.engine.registerInstanceFactory(new RJD.DefaultPortInstanceFactory());
    this.engine.registerInstanceFactory(new RJD.LinkInstanceFactory());
  }

  componentDidUpdate() {
    this.setState({
      workflow: this.props.workflowClone,
    });
    this.engine.workflow_id = this.props.workflowClone?.workflow_id;
  }

  linkNodes(linkId, port1, port2) {
    const link = new CustomLinkModel();
    link.setSourcePort(port1);
    link.setTargetPort(port2);
    link.linkId = linkId;
    return link;
  }

  render() {
    const { engine } = this;
    let nodeForLinks = {};
    const links = [];
    const nodes = [];

    this.props.initialNodes.forEach((node, index) => {
      const customNode = new CustomNodeModel(node, index);

      nodeForLinks = {
        ...nodeForLinks,
        [node.workflow_step_id]: customNode,
      };

      nodes.push(customNode);
    });

    this.props.initialNodes.forEach((node) => {
      node.links &&
        node.links.forEach((link) => {
          const toNode = nodeForLinks[link.workflow_step_id_to];
          const fromNode = nodeForLinks[link.workflow_step_id_from];
          if (
            node.links[0]?.order_index === 0 &&
            node.links[1]?.order_index === 0
          ) {
            node.links[1].order_index = 1;
          } else if (node.links[0] && node.links[1]) {
            node.links[0].order_index = node.links[0].order_index ?? 0;
            node.links[1].order_index = node.links[1].order_index ?? 1;
          }

          if (toNode && fromNode) {
            if (toNode.ports["right"] && fromNode.ports["left"]) {
              if (fromNode.data.type === "fork") {
                links.push(
                  this.linkNodes(
                    link.workflow_step_link_id,
                    fromNode.ports["right"],
                    toNode.ports["left"]
                  )
                );
              } else {
                links.push(
                  this.linkNodes(
                    link.workflow_step_link_id,
                    fromNode.ports["right"],
                    toNode.ports["left"]
                  )
                );
              }
            } else if (toNode.ports["left"] && fromNode.ports["right"]) {
              links.push(
                this.linkNodes(
                  link.workflow_step_link_id,
                  fromNode.ports["right"],
                  toNode.ports["left"]
                )
              );
            }
          }
        });
    });

    return (
      <DiagramDraw
        nodes={nodes}
        links={links}
        engine={engine}
        workflow={this.state.workflow}
        ref={this.props.workflowRef}
      />
    );
  }
}

Diagram.propTypes = {
  initialNodes: PropTypes.array,
  initialEdges: PropTypes.array,
  workflowClone: PropTypes.object,
  workflowRef: PropTypes.any,
  updateWorkflowRequest: PropTypes.func,
  setLinkId: PropTypes.func,
};

export default connect(null, { updateWorkflowRequest })(Diagram);
