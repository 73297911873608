import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnalysisRecipeForm from "./AnalysisRecipeForm";
import { createAnalysisRecipeRequest } from "redux/analysisRecipes/actions";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import usePrevious from "utility/hooks/usePrevious"; // Ensure this hook exists
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader"; // Assuming you have a SubHeader component
import { ReactComponent as SaveIcon } from "assets/icons/save.svg"; // Example icon

const CreateAnalysisRecipe = () => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  //title
  useEffect(() => {
    document.title = `${t("analysis_recipes")} - Decisimo`;
  }, [t]);

  const {
    isCreatedAnalysisRecipeSuccess,
    isCreatedAnalysisRecipeError,
    analysisRecipes,
  } = useSelector((state) => state.analysisRecipes);

  // Custom hook to track previous success state
  const prevIsCreatedAnalysisRecipeSuccess = usePrevious(
    isCreatedAnalysisRecipeSuccess
  );

  useEffect(() => {
    // Detect transition from false to true for creation success
    if (
      isCreatedAnalysisRecipeSuccess &&
      prevIsCreatedAnalysisRecipeSuccess === false
    ) {
      setIsLoading(false);
      setIsEdited(false);
      toast.success(`${t("analysis_recipe_created")}`, ToastOptions);

      // Assuming the last recipe in analysisRecipes is the newly created one
      const createdRecipe = analysisRecipes[analysisRecipes.length - 1];
      if (createdRecipe && createdRecipe.release_analysis_recipe_id) {
        navigate(
          `/analysis-recipes/${createdRecipe.release_analysis_recipe_id}`
        );
      } else {
        // Fallback navigation if recipe ID is unavailable
        navigate("/analysis-recipes");
      }
    }

    // Handle creation error
    if (
      isCreatedAnalysisRecipeError &&
      prevIsCreatedAnalysisRecipeSuccess === false
    ) {
      setIsLoading(false);
      toast.error(`${t("error_creating_analysis_recipe")}.`, ToastOptions);
    }
  }, [
    isCreatedAnalysisRecipeSuccess,
    prevIsCreatedAnalysisRecipeSuccess,
    isCreatedAnalysisRecipeError,
    t,
    setIsLoading,
    setIsEdited,
    navigate,
    analysisRecipes,
  ]);

  // Initialize the cloned data object
  const [clonedData, setClonedData] = useState({
    name: "",
    title: "",
    tech_information: "",
    slices: [],
  });

  // Handler for the external Save button
  const handleSave = () => {
    // Optional: Add any pre-submit validation here
    setIsLoading(true);
    dispatch(createAnalysisRecipeRequest(clonedData));
  };

  // Handler for form submission via internal form button (if needed)
  const handleFormSubmit = (formData) => {
    setIsLoading(true);
    dispatch(createAnalysisRecipeRequest(formData));
  };

  return (
    <>
      <SubHeader
        title={t("create_analysis_recipe")}
        actions={
          <>
            <button
              className="btn primary mr-2"
              onClick={handleSave}
              title={t("save_data_source")}
            >
              <SaveIcon />
            </button>
          </>
        }
      />
      <div className="card p-4 mt-3">
        <AnalysisRecipeForm
          formData={clonedData}
          setFormData={setClonedData}
          onSubmit={handleFormSubmit} // Optional: Use if you keep the form's submit button
          id="recipe-form"
        />
      </div>
    </>
  );
};

export default CreateAnalysisRecipe;
