import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { MainContext } from "context/contexts";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import SubHeader from "components/SubHeader";
import Revisions from "components/modals/decisionTables/Revisions";
import { useTranslation } from "react-i18next";
import { compareRulesets } from "./compareRulesets";
import "./RulesetRevisionComparison.css";

import {
  getRuleRevisionRequest,
  getRuleRevision2Request,
  getRuleRevisionsRequest,
} from "redux/rulesets/action";

// Map from condition_type_name to a human-readable operator or expression
const conditionOperatorMap = {
  greater_than: ">",
  greater_than_or_equal_to: ">=",
  less_than: "<",
  less_than_or_equal_to: "<=",
  equal_to: "=",
  not_equal_to: "!=",

  string_contains: "contains",
  string_does_not_contain: "does not contain",
  string_contains_insensitive: "contains (case insensitive)",
  string_does_not_contain_insensitive: "does not contain (case insensitive)",
  starts_with: "starts with",
  starts_with_insensitive: "starts with (case insensitive)",
  ends_with: "ends with",
  ends_with_insensitive: "ends with (case insensitive)",

  time_greater_than: ">",
  time_greater_than_or_equal_to: ">=",
  time_less_than: "<",
  time_less_than_or_equal_to: "<=",
  time_equal_to: "=",
  time_not_equal_to: "!=",

  regex_match: "matches",

  is_null: "is null",
  is_not_null: "is not null",

  equals_insensitive: "equals (case insensitive)",
};

const actionOperatorMap = {
  // Basic operations - match PHP single letter codes
  s: "set to", // direct set
  p: "+", // plus
  m: "-", // minus
  x: "*", // multiply
  d: "/", // divide

  // Array operations
  a: "add to list", // add to array

  // Aggregation operations
  h: "max", // high (max)
  l: "min", // low (min)
  c: "count", // count
  g: "sum", // sum (from sigma)
  v: "average", // average value
};

const RulesetRevisionComparison = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId, revisionId2 } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    ruleRevision,
    ruleRevision2,
    isGetRuleRevisionSuccess,
    isGetRuleRevision2Success,
    isGetRuleRevisionError,
    isGetRuleRevision2Error,
    revisions,
    isGetRuleRevisionsSuccess,
  } = useSelector((state) => state.ruleSets);

  const [comparison, setComparison] = useState(null);
  const [isRevisionsModalOpen, setIsRevisionsModalOpen] = useState(false);

  useEffect(() => {
    document.title = t("compare_ruleset_revisions");
  }, [t]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getRuleRevisionRequest([id, revisionId]));
    dispatch(getRuleRevision2Request([id, revisionId2]));
  }, [dispatch, id, revisionId, revisionId2, setIsLoading]);

  useEffect(() => {
    if (
      (isGetRuleRevisionError || isGetRuleRevision2Error) &&
      !(isGetRuleRevisionSuccess && isGetRuleRevision2Success)
    ) {
      setIsLoading(false);
    }

    if (
      isGetRuleRevisionSuccess &&
      isGetRuleRevision2Success &&
      ruleRevision &&
      ruleRevision2
    ) {
      setIsLoading(false);
      const result = compareRulesets(ruleRevision, ruleRevision2);
      setComparison(result);
    }
  }, [
    isGetRuleRevisionSuccess,
    isGetRuleRevision2Success,
    isGetRuleRevisionError,
    isGetRuleRevision2Error,
    ruleRevision,
    ruleRevision2,
    setIsLoading,
  ]);

  const handleShowRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getRuleRevisionsRequest(parseInt(id)));
    setIsRevisionsModalOpen(true);
  };

  const handleCloseRevisions = () => {
    setIsRevisionsModalOpen(false);
  };

  if (!isGetRuleRevisionSuccess || !isGetRuleRevision2Success) {
    return (
      <div className="align-items-center col d-flex justify-content-center">
        <span>{t("loading")}...</span>
      </div>
    );
  }

  if (!comparison) {
    return null;
  }

  const { topChanges, rules } = comparison;

  return (
    <>
      <SubHeader
        title={t("compare_ruleset_revisions")}
        actions={
          <Link to={`/rule-sets/${id}`} className="mr-2">
            <button className="btn outline" title={t("back_to_ruleset")}>
              <BackIcon />
            </button>
          </Link>
        }
      />

      <div className="comparison-table-container p-4">
        {topChanges.length > 0 && (
          <div className="mb-4">
            <h3>{t("top_level_changes")}</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{t("property")}</th>
                  <th>{t("from")}</th>
                  <th>{t("to")}</th>
                </tr>
              </thead>
              <tbody>
                {topChanges.map((change, i) => (
                  <tr key={i}>
                    <td>{change.field}</td>
                    <td>
                      {renderChangedCell(change.oldValue, change.newValue).old}
                    </td>
                    <td>
                      {renderChangedCell(change.oldValue, change.newValue).new}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <h3>{t("rule_changes")}</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t("rule")}</th>
              <th>{t("details")}</th>
            </tr>
          </thead>
          <tbody>
            {rules.map((r, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={2}>
                    <strong>{t("rule")}:</strong>{" "}
                    {renderRuleName(r.oldRule, r.newRule, r.ruleStatus)}
                  </td>
                </tr>

                {r.conditionChanges.length > 0 && (
                  <tr>
                    <td style={{ width: "200px" }}>
                      <strong>{t("conditions")}</strong>
                    </td>
                    <td>
                      {r.conditionChanges.map((c, cIndex) =>
                        renderConditionRow(
                          c,
                          0,
                          `${index}-${cIndex}`,
                          r.ruleType
                        )
                      )}
                    </td>
                  </tr>
                )}

                {r.actionChanges.length > 0 && (
                  <tr>
                    <td>
                      <strong>{t("actions")}</strong>
                    </td>
                    <td>
                      {r.actionChanges.map((a, aIndex) => (
                        <div key={`${index}-a-${aIndex}`}>
                          {renderAction(a)}
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={revisions}
        linkTo="rule-sets"
        open={isRevisionsModalOpen}
        elemId={parseInt(id)}
        leftRevisionId={parseInt(revisionId)}
        rightRevisionId={parseInt(revisionId2)}
      />
    </>
  );
};

function renderRuleName(oldRule, newRule, status) {
  const title = renderFieldDiff(oldRule?.title, newRule?.title, status);
  const name = renderFieldDiff(oldRule?.name, newRule?.name, status);
  let extra = "";
  if (status === "added") extra = " [ADDED]";
  else if (status === "deleted") extra = " [DELETED]";
  else if (status === "modified") extra = " [MODIFIED]";

  return (
    <>
      {title} ({name}){extra}
    </>
  );
}

// Update render function
function renderConditionRow(conditionChange, level, key, ruleType = "all") {
  function renderSingleCondition(c) {
    const { oldCondition, newCondition, status } = c;
    const condition = status === "deleted" ? oldCondition : newCondition;
    const logicalOp =
      level === 0
        ? ruleType
        : condition?.logical_operator?.toLowerCase() || "all";

    return (
      <div className={`condition-tree-line ${status}`}>
        <span className="condition-content">
          {formatConditionLine(
            condition?.attribute1_path || condition?.attribute1_value || "",
            conditionOperatorMap[condition?.condition_type_name] ||
              condition?.condition_type_name,
            condition?.attribute2_path || condition?.attribute2_value || ""
          )}
          {level > 0 && (
            <span className="logical-operator">
              {logicalOp === "any" ? "OR" : "AND"}
            </span>
          )}
        </span>
      </div>
    );
  }

  if (level === 0) {
    return (
      <div className="condition-tree-wrapper" key={key}>
        {conditionChange.oldCondition?.condition_type_name === "group" ||
        conditionChange.newCondition?.condition_type_name === "group" ? (
          <div className="condition-tree-group">
            <div className="condition-tree-header">
              {ruleType === "any" ? "ANY OF:" : "ALL OF:"}
            </div>
            <div className="condition-tree-children">
              {conditionChange.children?.map((child, index) =>
                renderConditionRow(
                  child,
                  level + 1,
                  `${key}-child-${index}`,
                  ruleType
                )
              )}
            </div>
          </div>
        ) : (
          renderSingleCondition(conditionChange)
        )}
      </div>
    );
  }

  if (
    conditionChange.oldCondition?.condition_type_name === "group" ||
    conditionChange.newCondition?.condition_type_name === "group"
  ) {
    const condition =
      conditionChange.oldCondition || conditionChange.newCondition;
    return (
      <div className="condition-tree-group" key={key}>
        <div className="condition-tree-header">
          {condition?.logical_operator?.toLowerCase() === "any"
            ? "ANY OF:"
            : "ALL OF:"}
        </div>
        <div className="condition-tree-children">
          {conditionChange.children?.map((child, index) =>
            renderConditionRow(child, level + 1, `${key}-child-${index}`)
          )}
        </div>
      </div>
    );
  }

  return renderSingleCondition(conditionChange);
}

function renderCondition(c) {
  // If group condition
  if (
    c.oldCondition?.condition_type_name === "group" ||
    c.newCondition?.condition_type_name === "group"
  ) {
    const op =
      c.oldCondition?.logical_operator || c.newCondition?.logical_operator;
    return (
      <div>
        <strong>Group Condition ({op})</strong>
      </div>
    );
  }

  const status = c.status;

  const oldType = c.oldCondition?.condition_type_name;
  const newType = c.newCondition?.condition_type_name;

  const effectiveType =
    status === "added"
      ? newType
      : status === "deleted"
      ? oldType
      : newType || oldType;
  const operator = conditionOperatorMap[effectiveType] || effectiveType;

  const oldA1 =
    c.oldCondition?.attribute1_path || c.oldCondition?.attribute1_value || "";
  const oldA2 =
    c.oldCondition?.attribute2_path || c.oldCondition?.attribute2_value || "";
  const newA1 =
    c.newCondition?.attribute1_path || c.newCondition?.attribute1_value || "";
  const newA2 =
    c.newCondition?.attribute2_path || c.newCondition?.attribute2_value || "";

  if (status === "added") {
    return (
      <div style={{ color: "green" }}>
        {formatConditionLine(newA1, operator, newA2)}
      </div>
    );
  } else if (status === "deleted") {
    return (
      <div style={{ color: "red", textDecoration: "line-through" }}>
        {formatConditionLine(oldA1, operator, oldA2)}
      </div>
    );
  } else if (status === "modified") {
    return (
      <div>
        <div
          style={{
            color: "red",
            textDecoration: "line-through",
            marginBottom: "5px",
          }}
        >
          {formatConditionLine(
            oldA1,
            conditionOperatorMap[oldType] || oldType,
            oldA2
          )}
        </div>
        <div style={{ color: "green" }}>
          {formatConditionLine(
            newA1,
            conditionOperatorMap[newType] || newType,
            newA2
          )}
        </div>
      </div>
    );
  }

  // unchanged
  return (
    <div>{formatConditionLine(oldA1 || newA1, operator, oldA2 || newA2)}</div>
  );
}

// Helper to format a single condition line
function formatConditionLine(a1, op, a2) {
  if (op === "is null" || op === "is not null") {
    return `${a1} ${op}`;
  }

  if (
    op.includes("contains") ||
    op.includes("starts with") ||
    op.includes("ends with") ||
    op.includes("matches") ||
    op.includes("equals (case insensitive)")
  ) {
    return `${a1} ${op} ${a2}`;
  }

  if (["<", ">", "=", "!=", "<=", ">="].includes(op)) {
    return `${a1} ${op} ${a2}`;
  }

  // fallback
  return `${a1} ${op} ${a2}`;
}

// Helper function for formatting actions
function formatActionLine(path, type, value) {
  const operator = actionOperatorMap[type] || type;

  // Handle array aggregations
  if (["h", "l", "c", "g", "v"].includes(type)) {
    return `${path} = ${operator}(${value})`;
  }

  // Handle array add
  if (type === "a") {
    return `${operator} ${value} to ${path}`;
  }

  // Handle direct set
  if (type === "s") {
    return `${path} = ${value}`;
  }

  // Default arithmetic operations (+,-,*,/)
  return `${path} = ${path} ${operator} ${value}`;
}

// Updated renderAction to match PHP backend logic
function renderAction(a) {
  const status = a.status;

  const oldPath = a.oldAction?.attribute_path || "";
  const oldType = a.oldAction?.action_type || "";
  const oldValue = a.oldAction?.action_value || a.oldAction?.action_path || "";

  const newPath = a.newAction?.attribute_path || "";
  const newType = a.newAction?.action_type || "";
  const newValue = a.newAction?.action_value || a.newAction?.action_path || "";

  if (status === "added") {
    return (
      <div style={{ color: "green" }}>
        {formatActionLine(newPath, newType, newValue)}
      </div>
    );
  } else if (status === "deleted") {
    return (
      <div style={{ color: "red", textDecoration: "line-through" }}>
        {formatActionLine(oldPath, oldType, oldValue)}
      </div>
    );
  } else if (status === "modified") {
    return (
      <div>
        <div
          style={{
            color: "red",
            textDecoration: "line-through",
            marginBottom: "5px",
          }}
        >
          {formatActionLine(oldPath, oldType, oldValue)}
        </div>
        <div style={{ color: "green" }}>
          {formatActionLine(newPath, newType, newValue)}
        </div>
      </div>
    );
  }

  // Unchanged
  return (
    <div>
      {formatActionLine(
        oldPath || newPath,
        oldType || newType,
        oldValue || newValue
      )}
    </div>
  );
}

function renderModifiedField(label, oldVal, newVal) {
  if (oldVal !== newVal) {
    return (
      <div>
        <strong>{label}:</strong> {renderFieldDiff(oldVal, newVal, "modified")}
      </div>
    );
  }
  return null;
}

function renderFieldDiff(oldVal, newVal, status) {
  if (status === "added") {
    return <span style={{ color: "green" }}>{newVal || "''"}</span>;
  } else if (status === "deleted") {
    return (
      <span style={{ color: "red", textDecoration: "line-through" }}>
        {oldVal || "''"}
      </span>
    );
  } else if (status === "modified" && oldVal !== newVal) {
    return (
      <>
        <span
          style={{
            color: "red",
            textDecoration: "line-through",
            marginRight: "5px",
          }}
        >
          {oldVal || "''"}
        </span>
        <span style={{ color: "green" }}>{newVal || "''"}</span>
      </>
    );
  }
  return oldVal || newVal || "";
}

function renderChangedCell(oldVal, newVal) {
  if (oldVal !== newVal) {
    return {
      old: (
        <span style={{ color: "red", textDecoration: "line-through" }}>
          {oldVal || "''"}
        </span>
      ),
      new: <span style={{ color: "green" }}>{newVal || "''"}</span>,
    };
  }
  return { old: oldVal || "", new: newVal || "" };
}

export default RulesetRevisionComparison;
