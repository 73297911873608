import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import {
  deleteModelVariableRequest,
  getContainerImagesRequest,
  getContainerPluginsRequest,
} from "redux/models/action";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DeleteConfirm from "components/modals/DeleteConfirm";
import Variable from "components/models/Variable";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import {
  generateCloudInstanceName,
  getModelStatus,
  getVectors,
  sortByTitle,
} from "utility/utility";
import { useTranslation } from "react-i18next";
import UploadOverlay from "./UploadOverlay";
const variable = {
  data_type: "STRING",
  mapping: "",
  name: "",
};

const ModelContent = ({
  model,
  handleSelectFile,
  selectedFile,
  variables,
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const { autoSuggestData, setIsLoading, setIsEdited } =
    useContext(MainContext);

  const {
    containerImages,
    containerPlugins,
    isGetContainerImagesSuccess,
    isGetContainerPluginsSuccess,
    isDeletedModelVariableSuccess,
    isDeletedModelVariableError,
    isGetContainerImagesError,
    isGetContainerPluginsError,
    uploadStatus,
  } = useSelector((state) => state.models);

  const prevIsGetContainerImagesSuccess = usePrevious(
    isGetContainerImagesSuccess
  );
  const prevIsGetContainerPluginsSuccess = usePrevious(
    isGetContainerPluginsSuccess
  );
  const prevIsDeletedModelVariableSuccess = usePrevious(
    isDeletedModelVariableSuccess
  );
  const prevIsDeletedModelVariableError = usePrevious(
    isDeletedModelVariableError
  );
  const prevIsGetContainerPluginsError = usePrevious(
    isGetContainerPluginsError
  );
  const prevIsGetContainerImagesError = usePrevious(isGetContainerImagesError);
  const [variablesData, setVariablesData] = useState([]);
  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [containerImagesClone, setContainerImagesClone] = useState([]);
  const [containerPluginsClone, setContainerPluginsClone] = useState([]);
  const [isModelDeleteModalOpen, setIsModelDeleteModalOpen] = useState(false);
  const [variableId, setVariableId] = useState(undefined);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    setIsLoading(true);
    dispatch(getContainerImagesRequest());
    dispatch(getContainerPluginsRequest());

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (model) {
      setTitle(model.title);
      setName(model.name);
    }
  }, [model]);

  useEffect(() => {
    if (variables) {
      setVariablesData(structuredClone(variables));
    }
  }, [variables]);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  useEffect(() => {
    if (
      isGetContainerImagesSuccess &&
      prevIsGetContainerImagesSuccess === false
    ) {
      const containerImagesClone = structuredClone(containerImages);
      containerImagesClone?.sort(sortByTitle);
      setContainerImagesClone(containerImagesClone);
    }
  }, [isGetContainerImagesSuccess]);

  useEffect(() => {
    if (
      isGetContainerPluginsSuccess &&
      prevIsGetContainerPluginsSuccess === false
    ) {
      setIsLoading(false);
      setContainerPluginsClone(structuredClone(containerPlugins));
    }
  }, [isGetContainerPluginsSuccess]);

  useEffect(() => {
    if (
      isDeletedModelVariableSuccess &&
      prevIsDeletedModelVariableSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("variable_deleted"), ToastOptions);
    }
  }, [isDeletedModelVariableSuccess]);

  useEffect(() => {
    if (
      (isDeletedModelVariableError &&
        prevIsDeletedModelVariableError === false) ||
      (isGetContainerImagesError && prevIsGetContainerImagesError === false) ||
      (isGetContainerPluginsError && prevIsGetContainerPluginsError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isDeletedModelVariableError,
    isGetContainerImagesError,
    isGetContainerPluginsError,
  ]);

  const addVariable = () => {
    setIsEdited(true);
    const variablesClone = structuredClone(variablesData);
    const newVariable = structuredClone(variable);
    newVariable.model_variable_id = `new_${Math.round(Math.random() * 10000)}`;
    variablesClone.push(newVariable);
    setVariablesData(variablesClone);
  };

  const deleteVariable = (id) => {
    setIsEdited(true);
    const variablesClone = structuredClone(variablesData);
    const filteredVariables = variablesClone.filter(
      (variable) => variable.model_variable_id !== id
    );
    setVariablesData(filteredVariables);
  };

  useEffect(() => {
    // Check if the model is new
    if (!model?.model_id) {
      // Add a default variable
      addVariable();
    }
  }, [model]);

  const handleShowVariableDeleteModal = (id) => {
    setVariableId(id);
    setIsModelDeleteModalOpen(true);
  };

  const handleVariableDeleteModalClose = () => {
    setVariableId(undefined);
    setIsModelDeleteModalOpen(false);
  };

  const handleVariableDeleteConfirm = () => {
    deleteVariable(variableId);
    handleVariableDeleteModalClose();
    if (!variableId.includes("new")) {
      setIsLoading(true);
      dispatch(deleteModelVariableRequest({ id, variableId }));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  const [model_type, setModelType] = useState("Python"); // default value
  const [function_name, setFunctionName] = useState("predict_proba"); // default value

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const data = () => {
        const oldIndex = variablesData.findIndex(
          (variable) => variable.model_variable_id === active.id
        );
        const newIndex = variablesData.findIndex(
          (rule) => rule.model_variable_id === over.id
        );
        const newItemsArray = arrayMove(variablesData, oldIndex, newIndex);

        return newItemsArray;
      };
      setIsEdited(true);
      setVariablesData(data());
    }
  };

  const changeValue = (e, title = false) => {
    setIsEdited(true);
    if (!editMode) {
      if (title) {
        setTitle(e.target.value);
      } else {
        setName(e.target.value);
      }
      if (e.target.value === "0") {
        e.target.classList.add("is-invalid");
      } else if (e.target.classList.contains("is-invalid")) {
        e.target.classList.remove("is-invalid");
      }
    }
  };

  const getStatusClass = (status = "d") => {
    const statuses = [];
    statuses["n"] = "badge-info";
    statuses["s"] = "badge-info";
    statuses["d"] = "badge-success";
    statuses["f"] = "badge-danger";

    return statuses[status];
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-4">
        <div className="col-12">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12 col-lg">
                  <label>{t("table_title")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={(e) => changeValue(e, true)}
                    defaultValue={title}
                    required
                  />
                </div>
                <div className="col-12 col-lg">
                  <label>{t("identifier")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={(e) => changeValue(e)}
                    defaultValue={
                      name ? name : !id ? generateCloudInstanceName() : ""
                    }
                    required
                  />
                </div>
                {editMode && model.status && (
                  <div className="align-items-center align-items-lg-baseline col-12 col-lg-1 d-flex flex-column py-3 py-lg-0">
                    <label>{t("status")}</label>
                    <span
                      className={`badge p-2 ${getStatusClass(model.status)}`}
                    >
                      {getModelStatus(model.status, true)}
                    </span>
                  </div>
                )}
                {uploadStatus.isUploading && (
                  <UploadOverlay status={uploadStatus} />
                )}
              </div>

              {!editMode && (
                <div className="row mt-4">
                  <div className="row mt-2">
                    <div className="col-lg col-12">
                      <label>{t("model_type")}</label>
                      <select
                        className="form-control"
                        name="model_type"
                        onChange={(e) => {
                          setModelType(e.target.value);
                          setContainerImagesClone(
                            containerImages.filter(
                              (img) => img.model_type === e.target.value
                            )
                          );
                          setContainerPluginsClone(
                            containerPlugins.filter(
                              (plugin) => plugin.model_type === e.target.value
                            )
                          );
                        }}
                      >
                        <option value="python">Python</option>
                        <option value="julia">Julia</option>
                        <option value="r">R</option>
                      </select>
                    </div>
                    <div className="col-lg col-12">
                      <label>
                        {model_type === "python"
                          ? t("python_version")
                          : model_type === "julia"
                          ? t("julia_version")
                          : model_type === "r"
                          ? t("r_version")
                          : t("python_version")}
                      </label>
                      <select
                        name="model_container_image_id"
                        className="form-control"
                      >
                        {containerImagesClone?.length > 0 &&
                          containerImagesClone.map((img) => (
                            <option
                              key={img.model_container_image_id}
                              value={img.model_container_image_id}
                            >
                              {img.title}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-lg col-12">
                      <label>{t("execution_function")}</label>
                      <select
                        name="function_name"
                        className="form-control"
                        onChange={(e) => setFunctionName(e.target.value)}
                      >
                        <option value="predict_proba">predict_proba()</option>
                        <option value="predict">predict()</option>
                      </select>
                    </div>
                    <div className="col-lg col-12">
                      <label>{t("select_pickle")}</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="pickle-file"
                          name="model"
                          accept=".pkl, .pickle, .rdata, .r, .rds"
                          onChange={(e) => handleSelectFile(e.target.files[0])}
                        />
                        <label
                          className={`custom-file-label ${
                            selectedFile ? "border-success text-success" : ""
                          }`}
                          htmlFor="csv-file"
                        >
                          {selectedFile
                            ? selectedFile.name
                            : t("choose_pickle_file")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label>{t("select_python")}</label>
                      <div className="btn-group-toggle" data-toggle="buttons">
                        {containerPluginsClone?.length > 0 &&
                          containerPluginsClone.map((plugin) => (
                            <label
                              key={plugin.model_container_plugin_id}
                              className="btn outline mr-1"
                            >
                              <input
                                type="checkbox"
                                name="plugin[]"
                                value={plugin.name}
                              />
                              {plugin.name}
                            </label>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3 className="mt-md-2 mt-4">{t("model_variables")}</h3>
          <div className="table-responsive mb-3 decision-table-settings border-radius-4">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <table className="table table-pretty vertical-align-middle">
                <colgroup>
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "33%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "5%" }} />
                </colgroup>
                <thead className="bg-transparent border-0">
                  <tr>
                    <th></th>
                    <th>{t("variable_name")}</th>
                    <th>{t("data_type")}</th>
                    <th>{t("value_mapping")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <SortableContext
                    items={variablesData.map((v) => v.model_variable_id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {variablesData.map((variable, index) => (
                      <Variable
                        key={variable.model_variable_id}
                        index={index}
                        variable={variable}
                        vectorsData={vectorsDataClone}
                        handleShowVariableDeleteModal={
                          handleShowVariableDeleteModal
                        }
                      />
                    ))}
                  </SortableContext>
                  <tr>
                    <td colSpan="5" className="p-0">
                      <div className="d-flex justify-content-center add-row-bg py-3">
                        <button
                          className="btn outline"
                          onClick={addVariable}
                          title={t("add_variable")}
                          type="button"
                        >
                          <PlusIcon
                            style={{
                              filter: "brightness(0.5)",
                            }}
                          />
                          <span className="ml-2">{t("add_variable")}</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </DndContext>
          </div>
        </div>
      </div>

      <DeleteConfirm
        handleClose={handleVariableDeleteModalClose}
        handleConfirm={handleVariableDeleteConfirm}
        title={t("delete_model_variable")}
        message={t("delete_model_variable_message")}
        open={isModelDeleteModalOpen}
        variantDelete="primary"
        icon=""
      />
    </div>
  );
};

ModelContent.propTypes = {
  model: PropTypes.object,
  handleSelectFile: PropTypes.func,
  selectedFile: PropTypes.object,
  variables: PropTypes.array,
  editMode: PropTypes.bool,
};

export default ModelContent;
