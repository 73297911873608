import { createAction } from "redux-actions";

export const getLoginRequest = createAction("GET_LOGIN_REQUEST");
export const getLoginSuccess = createAction("GET_LOGIN_SUCCESS");
export const getLoginFailure = createAction("GET_LOGIN_FAILURE");

export const requireOTP = createAction("REQUIRE_OTP");
export const resetOTPRequirement = createAction("RESET_OTP_REQUIREMENT");

export const resendOTPRequest = createAction("RESEND_OTP_REQUEST");
export const resendOTPSuccess = createAction("RESEND_OTP_SUCCESS");
export const resendOTPFailure = createAction("RESEND_OTP_FAILURE");

export const signUsingOTPRequest = createAction("SIGN_USING_OTP_REQUEST");
export const signUsingOTPSuccess = createAction("SIGN_USING_OTP_SUCCESS");
export const signUsingOTPFailure = createAction("SIGN_USING_OTP_FAILURE");

export const forgotPasswordRequest = createAction("FORGOT_PASSWORD_REQUEST");
export const forgotPasswordSuccess = createAction("FORGOT_PASSWORD_SUCCESS");
export const forgotPasswordFailure = createAction("FORGOT_PASSWORD_FAILURE");

export const updateAuthPasswordRequest = createAction(
  "UPDATE_AUTH_PASSWORD_REQUEST"
);
export const updateAuthPasswordSuccess = createAction(
  "UPDATE_AUTH_PASSWORD_SUCCESS"
);
export const updateAuthPasswordFailure = createAction(
  "UPDATE_AUTH_PASSWORD_FAILURE"
);

export const updateSettingsRequest = createAction("UPDATE_SETTINGS_REQUEST");
export const updateSettingsSuccess = createAction("UPDATE_SETTINGS_SUCCESS");
export const updateSettingsFailure = createAction("UPDATE_SETTINGS_FAILURE");

export const signUpRequest = createAction("SING_UP_REQUEST");
export const signUpSuccess = createAction("SING_UP_SUCCESS");
export const signUpFailure = createAction("SING_UP_FAILURE");

export const signOutRequest = createAction("SING_OUT_REQUEST");
export const signOutSuccess = createAction("SING_OUT_SUCCESS");
export const signOutFailure = createAction("SING_OUT_FAILURE");

export const getAuthUserRequest = createAction("GET_AUTH_USER_REQUEST");
export const getAuthUserSuccess = createAction("GET_AUTH_USER_SUCCESS");
export const getAuthUserFailure = createAction("GET_AUTH_USER_FAILURE");

export const getLanguagesRequest = createAction("GET_LANGUAGES_REQUEST");
export const getLanguagesSuccess = createAction("GET_LANGUAGES_SUCCESS");
export const getLanguagesFailure = createAction("GET_LANGUAGES_FAILURE");

export const changeIsSignUpSuccess = createAction("CHANGE_IS_SIGNUP_SUCCESS");
