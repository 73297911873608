import { createAction } from "redux-actions";

export const getRoleRightsRequest = createAction(
  "GET_ROLE_RIGHTS_USER_REQUEST"
);
export const getRoleRightsSuccess = createAction("GET_ROLE_RIGHTS_SUCCESS");
export const getRoleRightsFailure = createAction("GET_ROLE_RIGHTS_FAILURE");

export const createRoleRightsRequest = createAction(
  "CREATE_ROLE_RIGHTS_USER_REQUEST"
);
export const createRoleRightsSuccess = createAction(
  "CREATE_ROLE_RIGHTS_SUCCESS"
);
export const createRoleRightsFailure = createAction(
  "CREATE_ROLE_RIGHTS_FAILURE"
);

export const getRoleRequest = createAction("GET_ROLE_REQUEST");
export const getRoleSuccess = createAction("GET_ROLE_SUCCESS");
export const getRoleFailure = createAction("GET_ROLE_FAILURE");

export const updateRoleRequest = createAction("UPDATE_ROLE_REQUEST");
export const updateRoleSuccess = createAction("UPDATE_ROLE_SUCCESS");
export const updateRoleFailure = createAction("UPDATE_ROLE_FAILURE");

export const getUserRolesRequest = createAction("GET_USER_ROLES_REQUEST");
export const getUserRolesSuccess = createAction("GET_USER_ROLES_SUCCESS");
export const getUserRolesFailure = createAction("GET_USER_ROLES_FAILURE");

export const deleteUserRoleRequest = createAction("DELETE_USER_ROLE_REQUEST");
export const deleteUserRoleSuccess = createAction("DELETE_USER_ROLE_SUCCESS");
export const deleteUserRoleFailure = createAction("DELETE_USER_ROLE_FAILURE");
