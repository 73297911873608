import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";

const EditWorkflow = (props) => {
  const { open, handleClose, handleConfirm, data } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("edit_decision_flow")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  defaultValue={data?.title}
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="decision-flow-name"
                  defaultValue={data?.name}
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <textarea
                  placeholder="Some fabulous decision flow description ..."
                  name="description"
                  defaultValue={data?.description}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col pt-2">
                <label>
                  <input
                    type="checkbox"
                    name="block_deletion"
                    className="mr-2"
                    defaultChecked={data?.block_deletion !== "0"}
                  />
                  {t("block_deletion")}
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              onClick={handleClose}
              type="button"
              className="outline my-0"
            >
              {t("close")}
            </Button>
            <Button variant="" type="submit" className="primary my-0">
              <SaveIcon /> <span className="ml-2">{t("save")}</span>
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

EditWorkflow.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  data: PropTypes.object,
};

export default EditWorkflow;
