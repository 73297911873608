import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import ApprovalSequenceContent from "components/approvalProcess/ApprovalSequenceContent";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ToastOptions } from "components/toastify";
import usePrevious from "utility/hooks/usePrevious";
import {
  getApprovalSequenceRequest,
  updateApprovalSequenceRequest,
  deleteApprovalSequenceRequest,
} from "redux/approvalProcesses/action";
import { toast } from "react-toastify";
import DeleteConfirm from "components/modals/DeleteConfirm"; // Make sure this component exists and handles delete confirmation
import { set } from "lodash";

const ApprovalSequence = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const { t } = useTranslation();
  const {
    approvalSequence,
    isLoading,
    error,
    isGetApprovalSequenceSuccess,
    isUpdatedApprovalSequenceSuccess,
    isUpdatedApprovalSequenceError,
    isDeleteApprovalSequenceSuccess,
    isDeleteApprovalSequenceError,
  } = useSelector((state) => state.approvalProcesses);

  const prevIsUpdatedApprovalSequenceError = usePrevious(
    isUpdatedApprovalSequenceError
  );
  const prevIsUpdatedApprovalSequenceSuccess = usePrevious(
    isUpdatedApprovalSequenceSuccess
  );

  const prevIsDeleteApprovalSequenceSuccess = usePrevious(
    isDeleteApprovalSequenceSuccess
  );
  const prevIsDeleteApprovalSequenceError = usePrevious(
    isDeleteApprovalSequenceError
  );

  const [sequenceData, setSequenceData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getApprovalSequenceRequest(id));
  }, []);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (isGetApprovalSequenceSuccess && approvalSequence) {
      setSequenceData(approvalSequence);
      setIsLoading(false);
    }
  }, [isGetApprovalSequenceSuccess, approvalSequence, setIsLoading]);

  useEffect(() => {
    if (error) {
      console.error("Failed to fetch approval sequence:", error);
    }
  }, [error]);

  useEffect(() => {
    if (approvalSequence && approvalSequence.title) {
      document.title = `${approvalSequence.title} - Decisimo`;
    } else {
      document.title = t("approval_sequence") + " - Decisimo";
    }
  }, [approvalSequence, t]);

  useEffect(() => {
    if (
      isUpdatedApprovalSequenceSuccess &&
      prevIsUpdatedApprovalSequenceSuccess === false
    ) {
      toast.success(t("approval_sequence_updated"), ToastOptions);
      setIsLoading(false);
      setIsEdited(false);
    }
  }, [isUpdatedApprovalSequenceSuccess]);

  useEffect(() => {
    if (
      isUpdatedApprovalSequenceError &&
      prevIsUpdatedApprovalSequenceError === false
    ) {
      toast.error(t("failed_to_update_approval_sequence"), ToastOptions);
      setIsLoading(false);
    }
  }, [isUpdatedApprovalSequenceError]);

  const handleFormChange = (updatedData) => {
    setSequenceData(updatedData);
    setIsEdited(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!sequenceData.title.trim()) {
      toast.error(t("please_fill_out_the_title"));
      return;
    }

    const hasEmptyStep = sequenceData.steps.some(
      (step) => !step.title.trim() || !step.access_role_id
    );

    if (hasEmptyStep) {
      toast.error(t("please_fill_out_all_steps"));
      return;
    }

    setIsLoading(true);
    setIsEdited(false);
    dispatch(updateApprovalSequenceRequest({ id, data: sequenceData }));
  };

  const handleShowDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    // Dispatch delete action
    setIsLoading(true);
    // Replace with actual delete dispatch
    dispatch(deleteApprovalSequenceRequest(id));
    handleCloseDeleteModal();
  };

  useEffect(() => {
    if (
      isDeleteApprovalSequenceSuccess &&
      prevIsDeleteApprovalSequenceSuccess === false
    ) {
      setIsDeleteModalOpen(false);
      toast.success(`${t("approval_sequence_deleted")}.`, ToastOptions);
      setIsLoading(false);
      setIsEdited(false);
      navigate("/approval-sequences");
    }
  }, [isDeleteApprovalSequenceSuccess, dispatch]);

  useEffect(() => {
    if (
      isDeleteApprovalSequenceError &&
      prevIsDeleteApprovalSequenceError === false
    ) {
      toast.error(`${t("failed_to_delete_approval_sequence")}.`, ToastOptions);
      setIsLoading(false);
    }
  }, [isDeleteApprovalSequenceError]);

  return (
    <>
      {isLoading && <div>{t("loading")}</div>}
      {!isLoading && sequenceData && (
        <form onSubmit={handleSubmit}>
          <SubHeader
            title={sequenceData.title}
            actions={
              <>
                <Link
                  to="/approval-sequences"
                  title={t("back_to_approval_sequences")}
                >
                  <button className="btn outline-header">
                    <BackIcon />{" "}
                    <span className="ml-2">
                      {t("back_to_approval_sequences")}
                    </span>
                  </button>
                </Link>
                <button
                  className="btn primary ml-2"
                  type="submit"
                  title={t("save")}
                >
                  <SaveIcon />
                </button>
                <div className="ml-2">
                  <button
                    type="button"
                    className="btn primary"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    title={t("settings")}
                  >
                    <SettingsIcon />
                  </button>

                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                    <button
                      className="dropdown-item"
                      onClick={handleShowDeleteModal}
                      type="button"
                      title={t("delete_approval_sequence")}
                    >
                      <TrashIcon /> {t("delete")}
                    </button>
                  </div>
                </div>
              </>
            }
          />
          <ApprovalSequenceContent
            isNew={false}
            initialData={sequenceData}
            onChange={handleFormChange}
          />
        </form>
      )}
      <DeleteConfirm
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteConfirm}
        title={t("delete_approval_sequence")}
        message={
          <span>
            {t("do_you_want_delete")} <b>{approvalSequence?.title}</b>?
          </span>
        }
        open={isDeleteModalOpen}
      />
    </>
  );
};

export default ApprovalSequence;
