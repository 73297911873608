import * as React from "react";
import {
  deleteWorkflowStepRequest,
  updateWorkflowStepRequest,
} from "redux/workflows/action";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DOM from "react-dom-factories";
import DeleteConfirm from "components/modals/DeleteConfirm";
import StepWorkflow from "components/modals/workflow/Step";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import "assets/css/ReactFlow/custom-node.scss";
import { WorkflowContext } from "context/contexts";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as EditSvg } from "assets/icons/edit_step_flow.svg";
import { ReactComponent as DeleteSvg } from "assets/icons/delete_step_flow.svg";
import { $CombinedState } from "redux";

class CustomNodeWidget extends React.PureComponent {
  static contextType = WorkflowContext;

  constructor(props) {
    super(props);
    this.state = {
      size: 150,
      node: null,
      isFlowStepModalOpen: false,
      isFlowStepDeleteModalOpen: false,
      id: this.props.node.data.id,
      data: this.props.node.data,
      workflowId: this.props.node.data.workflowId,
    };
  }

  render() {
    const workflowId = this.state.workflowId;
    const {
      setIsLoading,
      setCopiedStep,
      revisionId,
      workflowClone,
      setIsCanvasDragging,
      setIsEdited,
    } = this.context;

    const handleClose = () => {
      setIsCanvasDragging(true);
      this.setState({
        isFlowStepModalOpen: false,
      });
    };

    const handleConfirm = (e) => {
      e.preventDefault();
      if (e.target.querySelector(".is-invalid")) {
        return toast.error("Attribute value is invalid.", ToastOptions);
      }
      const name = e.target.name.value;
      const title = e.target.title.value;

      if (name.length === 0 || title.length === 0) {
        return toast.error("Please fill out the Title/ID.", ToastOptions);
      }

      setIsEdited(false);
      setIsLoading(true);
      const data = new URLSearchParams(new FormData(e.target));
      const id = this.state.id;
      const { updateWorkflowStepRequest } = this.props;
      updateWorkflowStepRequest({ workflowId, id, data });
      handleClose();
    };

    const handleOpenStepModal = () => {
      setIsCanvasDragging(false);
      this.setState({
        isFlowStepModalOpen: true,
      });
    };

    const keyDownFunc = (event) => {
      if (event.key === "Delete") {
        handleOpenStepDeleteModal();
      } else if (event.ctrlKey && event.key === "c") {
        handleCopyStep();
      }
    };

    const handleOpenStepDeleteModal = () => {
      this.setState({
        isFlowStepDeleteModalOpen: true,
      });
    };

    const handleCloseDelete = () => {
      this.setState({
        isFlowStepDeleteModalOpen: false,
      });
    };

    const handleConfirmDelete = (e) => {
      e.preventDefault();
      const id = this.state.id;
      const { deleteWorkflowStepRequest } = this.props;
      deleteWorkflowStepRequest({ workflowId, id });
      handleClose();
      handleCloseDelete();
    };

    const handleCopyStep = () => {
      setCopiedStep(this.props.node.data);
      toast.success("Copied.", ToastOptions);
      localStorage.setItem("copiedStep", JSON.stringify(this.props.node.data));
      localStorage.setItem("copiedWorkflow", JSON.stringify(workflowClone));
    };

    const renderNode = () => {
      if (this.props.node.data.type === "start") {
        return DOM.div(
          {
            className:
              "align-items-center d-flex flowchart-operator-connector-label h-100 ml-2",
          },
          <>
            <div className={`circle ${this.props.node.icon}`} />
            <span className="flowchart-operator-connector-label-start">
              {this.props.node.name}
            </span>
          </>,
          React.createElement("div", {
            "data-name": "right",
            "data-nodeid": this.props.node.getID(),
            className: `port w-auto ${
              window.location.href.includes("revisions")
                ? "pointer-events-none"
                : ""
            }`,
          })
        );
      } else if (this.props.node.data.type === "end") {
        return DOM.div(
          {
            className:
              "align-items-center d-flex flowchart-operator-connector-label h-100 ml-2",
          },
          <>
            <div className={`circle ${this.props.node.icon}`} />
            <span className="flowchart-operator-connector-label-end">
              {this.props.node.name}
            </span>
          </>,
          React.createElement("div", {
            "data-name": "left",
            "data-nodeid": this.props.node.getID(),
            className: `port w-auto ${
              window.location.href.includes("revisions")
                ? "pointer-events-none"
                : ""
            }`,
          })
        );
      } else if (
        this.props.node.data.type !== "start" &&
        this.props.node.data.type !== "fork" &&
        this.props.node.data.type !== "end"
      ) {
        return DOM.div(
          {
            className:
              "align-items-center d-flex flowchart-operator-connector-label h-100 w-max-content",
          },
          <>
            <div className={`circle ${this.props.node.icon}`} />
            {this.props.node.name}
          </>,
          React.createElement("div", {
            "data-name": "right",
            "data-nodeid": this.props.node.getID(),
            className: `port w-auto ${
              window.location.href.includes("revisions")
                ? "pointer-events-none"
                : ""
            }`,
          }),
          React.createElement("div", {
            "data-name": "left",
            "data-nodeid": this.props.node.getID(),
            className: `port w-auto ${
              window.location.href.includes("revisions")
                ? "pointer-events-none"
                : ""
            }`,
          })
        );
      } else {
        return DOM.div(
          {
            className:
              "align-items-center d-flex flowchart-operator-connector-label h-100 w-max-content",
          },
          <>
            <div className={`circle ${this.props.node.icon}`} />
            {this.props.node.name}
          </>,
          React.createElement("div", {
            "data-name": "left",
            "data-nodeid": this.props.node.getID(),
            className: `port w-auto ${
              window.location.href.includes("revisions")
                ? "pointer-events-none"
                : ""
            }`,
          }),
          React.createElement("div", {
            "data-name": "right",
            "data-nodeid": this.props.node.getID(),
            className: `port w-auto ${
              window.location.href.includes("revisions")
                ? "pointer-events-none"
                : ""
            }`,
          })
        );
      }
    };

    return (
      <>
        {!revisionId && (
          <div className="step-flow-tooltip">
            <div className="step-flow-tooltip__wrapper">
              <div
                title="Edit step"
                className={`step-flow-tooltip__edit ${
                  this.props.node?.data?.type === "start"
                    ? "border-radius-4"
                    : ""
                }`}
                onClick={handleOpenStepModal}
              >
                <EditSvg />
                <p>Edit</p>
              </div>
              {this.props.node?.data?.type !== "start" && (
                <>
                  <div
                    title=" Copy step"
                    data-tip={true}
                    data-for="copy-step"
                    tabIndex={0}
                    className="step-flow-tooltip__copy"
                    onClick={handleCopyStep}
                  >
                    <CopyIcon />
                  </div>
                  <div
                    title="Delete step"
                    className="step-flow-tooltip__delete"
                    onClick={handleOpenStepDeleteModal}
                  >
                    <DeleteSvg />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {this.props.node?.data?.type === "start" &&
          DOM.div(
            {
              tabIndex: "0",
              className: `flowchart-operator operator-start`,
              onDoubleClick: handleOpenStepModal,
              onKeyDown: keyDownFunc,
              "data-testid": `node-${this.props.node.index}`,
            },
            renderNode()
          )}
        {this.props.node?.data?.type === "end" &&
          DOM.div(
            {
              tabIndex: "0",
              className: `flowchart-operator operator-end`,
              onDoubleClick: handleOpenStepModal,
              onKeyDown: keyDownFunc,
              "data-testid": `node-${this.props.node.index}`,
            },
            renderNode()
          )}
        {this.props.node?.data?.type !== "start" &&
          this.props.node?.data?.type !== "end" &&
          DOM.div(
            {
              tabIndex: "0",
              className: `flowchart-operator ${
                this.props.node.icon === "start"
                  ? "operator-start"
                  : this.props.node.icon === "fork"
                  ? "label-fork"
                  : ""
              }`,
              onDoubleClick: handleOpenStepModal,
              onKeyDown: keyDownFunc,
              "data-testid": `node-${this.props.node.index}`,
            },
            renderNode()
          )}
        <StepWorkflow
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          handleDelete={handleOpenStepDeleteModal}
          open={this.state.isFlowStepModalOpen}
          data={this.state.data}
        />
        <DeleteConfirm
          handleClose={handleCloseDelete}
          handleConfirm={handleConfirmDelete}
          open={this.state.isFlowStepDeleteModalOpen}
        />
      </>
    );
  }
}

CustomNodeWidget.propTypes = {
  size: PropTypes.number,
  node: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  match: PropTypes.any,
  params: PropTypes.any,
  updateWorkflowStepRequest: PropTypes.any,
  deleteWorkflowStepRequest: PropTypes.any,
};

export var CustomNodeWidgetFactory = React.createFactory(
  connect(null, { updateWorkflowStepRequest, deleteWorkflowStepRequest })(
    CustomNodeWidget
  )
);
