import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { useTranslation } from "react-i18next";

const Revisions = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { open, handleClose, revisionsData, elemId, linkTo } = props;

  const navigateToRevision = (revisionId) => {
    navigate(`/${linkTo}/${elemId}/revisions/${revisionId}`);
    handleClose();
  };

  return (
    <>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("revision")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t("revision")}</th>
                <th scope="col">{t("time")}</th>
                <th scope="col">{t("revisions_user")}</th>
                <th scope="col">{t("view")}</th>
              </tr>
            </thead>
            <tbody id="revisions-list">
              {revisionsData?.length > 0 &&
                revisionsData.map((revision) => {
                  return (
                    <tr key={revision.revision_index}>
                      <th scope="row">{revision.revision_index}</th>
                      <td>{revision.dtime_inserted}</td>
                      <td>{revision.full_name}</td>
                      <td>
                        <button
                          title={t("revision")}
                          className="bg-transparent border-0 primary-text"
                          onClick={() =>
                            navigateToRevision(revision.revision_id)
                          }
                        >
                          <EyeIcon />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={handleClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Revisions.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  revisionsData: PropTypes.array,
  elemId: PropTypes.number,
  linkTo: PropTypes.string,
};

export default Revisions;
