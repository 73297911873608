import { handleActions } from "redux-actions";
import {
  createFunctionFailure,
  createFunctionRequest,
  createFunctionSuccess,
  deleteFunctionFailure,
  deleteFunctionRequest,
  deleteFunctionSuccess,
  getFunctionFailure,
  getFunctionRequest,
  getFunctionRevisionFailure,
  getFunctionRevisionRequest,
  getFunctionRevisionsFailure,
  getFunctionRevisionsRequest,
  getFunctionRevisionsSuccess,
  getFunctionRevisionSuccess,
  getFunctionsFailure,
  getFunctionsRequest,
  getFunctionsSuccess,
  getFunctionSuccess,
  getFunctionTestFailure,
  getFunctionTestRequest,
  getFunctionTestSuccess,
  getRunTestResultDetailsFunctionFailure,
  getRunTestResultDetailsFunctionRequest,
  getRunTestResultDetailsFunctionSuccess,
  getRunTestResultStatusFunctionFailure,
  getRunTestResultStatusFunctionRequest,
  getRunTestResultStatusFunctionSuccess,
  updateFunctionFailure,
  updateFunctionRequest,
  updateFunctionSuccess,
  getFunctionExplanationRequest,
  getFunctionExplanationSuccess,
  getFunctionExplanationFailure,
} from "redux/functions/action";

const initialState = {
  functions: [],
  functionData: {},
  revisions: [],
  revision: {},
  testFunction: [],
  resultDetails: {},
  resultStatus: {},
  isGetFunctionsSuccess: false,
  isGetFunctionsError: false,
  isGetFunctionSuccess: false,
  isGetFunctionError: false,
  isGetFunctionRevisionsSuccess: false,
  isGetFunctionRevisionsError: false,
  isGetFunctionRevisionSuccess: false,
  isGetFunctionRevisionError: false,
  isGetFunctionTestSuccess: false,
  isGetFunctionTestError: false,
  isGetTestResultDetailsSuccess: false,
  isGetTestResultDetailsError: false,
  isGetTestResultStatusSuccess: false,
  isGetTestResultStatusError: false,
  isUpdatedFunctionSuccess: false,
  isUpdatedFunctionError: false,
  isCreatedFunctionSuccess: false,
  isCreatedFunctionError: false,
  isDeletedFunctionSuccess: false,
  isDeletedFunctionError: false,
  newFunctionId: "",
  functionExplanation: "",
  isGetFunctionExplanationSuccess: false,
  isGetFunctionExplanationError: false,
};

const reducer = handleActions(
  {
    // get functions
    [getFunctionsRequest]: (state) => ({
      ...state,
      isGetFunctionsSuccess: false,
      isGetFunctionsError: false,
    }),
    [getFunctionsSuccess]: (state, { payload }) => ({
      ...state,
      functions: payload || [],
      isGetFunctionsSuccess: true,
      isGetFunctionsError: false,
    }),
    [getFunctionsFailure]: (state) => ({
      ...state,
      isGetFunctionsSuccess: false,
      isGetFunctionsError: true,
    }),
    // update function
    [updateFunctionRequest]: (state) => ({
      ...state,
      isUpdatedFunctionSuccess: false,
      isUpdatedFunctionError: false,
    }),
    [updateFunctionSuccess]: (state) => ({
      ...state,
      isUpdatedFunctionSuccess: true,
      isUpdatedFunctionError: false,
    }),
    [updateFunctionFailure]: (state) => ({
      ...state,
      isUpdatedFunctionSuccess: false,
      isUpdatedFunctionError: true,
    }),
    // create function
    [createFunctionRequest]: (state) => ({
      ...state,
      isCreatedFunctionSuccess: false,
      isCreatedFunctionError: false,
    }),
    [createFunctionSuccess]: (state, { payload }) => ({
      ...state,
      newFunctionId: payload ?? "",
      isCreatedFunctionSuccess: true,
      isCreatedFunctionError: false,
    }),
    [createFunctionFailure]: (state) => ({
      ...state,
      isCreatedFunctionSuccess: false,
      isCreatedFunctionError: true,
    }),
    // get function
    [getFunctionRequest]: (state) => ({
      ...state,
      isGetFunctionSuccess: false,
      isGetFunctionError: false,
    }),
    [getFunctionSuccess]: (state, { payload }) => ({
      ...state,
      functionData: payload,
      isGetFunctionSuccess: true,
      isGetFunctionError: false,
    }),
    [getFunctionFailure]: (state) => ({
      ...state,
      isGetFunctionSuccess: false,
      isGetFunctionError: true,
    }),
    // get function revisions
    [getFunctionRevisionsRequest]: (state) => ({
      ...state,
      isGetFunctionRevisionsSuccess: false,
      isGetFunctionRevisionsError: false,
    }),
    [getFunctionRevisionsSuccess]: (state, { payload }) => ({
      ...state,
      revisions: payload,
      isGetFunctionRevisionsSuccess: true,
      isGetFunctionRevisionsError: false,
    }),
    [getFunctionRevisionsFailure]: (state) => ({
      ...state,
      isGetFunctionRevisionsSuccess: false,
      isGetFunctionRevisionsError: true,
    }),
    // get function revision
    [getFunctionRevisionRequest]: (state) => ({
      ...state,
      isGetFunctionRevisionSuccess: false,
      isGetFunctionRevisionError: false,
    }),
    [getFunctionRevisionSuccess]: (state, { payload }) => ({
      ...state,
      revision: payload,
      isGetFunctionRevisionSuccess: true,
      isGetFunctionRevisionError: false,
    }),
    [getFunctionRevisionFailure]: (state) => ({
      ...state,
      isGetFunctionRevisionSuccess: false,
      isGetFunctionRevisionError: true,
    }),
    // get function test
    [getFunctionTestRequest]: (state) => ({
      ...state,
      isGetFunctionTestSuccess: false,
      isGetFunctionTestError: false,
    }),
    [getFunctionTestSuccess]: (state, { payload }) => ({
      ...state,
      testFunction: payload,
      isGetFunctionTestSuccess: true,
      isGetFunctionTestError: false,
    }),
    [getFunctionTestFailure]: (state) => ({
      ...state,
      isGetFunctionTestSuccess: false,
      isGetFunctionTestError: true,
    }),
    // get run test result details
    [getRunTestResultDetailsFunctionRequest]: (state) => ({
      ...state,
      isGetTestResultDetailsSuccess: false,
      isGetTestResultDetailsError: false,
    }),
    [getRunTestResultDetailsFunctionSuccess]: (state, { payload }) => ({
      ...state,
      resultDetails: payload,
      isGetTestResultDetailsSuccess: true,
      isGetTestResultDetailsError: false,
    }),
    [getRunTestResultDetailsFunctionFailure]: (state) => ({
      ...state,
      isGetTestResultDetailsSuccess: false,
      isGetTestResultDetailsError: true,
    }),
    // get run test result status
    [getRunTestResultStatusFunctionRequest]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusFunctionSuccess]: (state, { payload }) => ({
      ...state,
      resultStatus: payload,
      isGetTestResultStatusSuccess: true,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusFunctionFailure]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: true,
    }),
    // delete function
    [deleteFunctionRequest]: (state) => ({
      ...state,
      isDeletedFunctionSuccess: false,
      isDeletedFunctionError: false,
    }),
    [deleteFunctionSuccess]: (state) => ({
      ...state,
      isDeletedFunctionSuccess: true,
      isDeletedFunctionError: false,
    }),
    [deleteFunctionFailure]: (state) => ({
      ...state,
      isDeletedFunctionSuccess: false,
      isDeletedFunctionError: true,
    }),
    //explanation
    [getFunctionExplanationRequest]: (state) => ({
      ...state,
      isGetFunctionExplanationSuccess: false,
      isGetFunctionExplanationError: false,
    }),
    [getFunctionExplanationSuccess]: (state, { payload }) => ({
      ...state,
      functionExplanation: payload,
      isGetFunctionExplanationSuccess: true,
      isGetFunctionExplanationError: false,
    }),
    [getFunctionExplanationFailure]: (state) => ({
      ...state,
      isGetFunctionExplanationSuccess: false,
      isGetFunctionExplanationError: true,
    }),
  },
  initialState
);

export default reducer;
