import React, { useContext, useEffect, useState } from "react";
import SubHeader from "components/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createDataSourceRequest,
  deleteDataSourceRequest,
  getDataSourceRequest,
  getDataSourcesRequest,
} from "redux/dataSources/action";
import usePrevious from "utility/hooks/usePrevious";
import { Pagination, sortByTitle, TableNoItems } from "utility/utility";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import TemplateModal from "components/modals/Template";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import CreateNewItem from "components/modals/CreateNewItem";
import { clearTemplates, getTemplatesRequest } from "redux/templates/action";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const DataSources = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    dataSource,
    dataSources,
    isGetDataSourcesSuccess,
    isGetDataSourcesError,
    isDeletedDataSourceSuccess,
    isCreatedDataSourceSuccess,
    isCreatedDataSourceError,
    newDataSourceId,
    isGetDataSourceSuccess,
    isGetDataSourceError,
  } = useSelector((state) => state.dataSources);

  const { templates } = useSelector((state) => state.templates);

  const prevIsGetDataSourcesSuccess = usePrevious(isGetDataSourcesSuccess);
  const prevIsGetDataSourcesError = usePrevious(isGetDataSourcesError);
  const prevIsCreatedDataSourceError = usePrevious(isCreatedDataSourceError);
  const prevIsGetDataSourceSuccess = usePrevious(isGetDataSourceSuccess);
  const prevIsGetDataSourceError = usePrevious(isGetDataSourceError);
  const prevIsDeletedDataSourceSuccess = usePrevious(
    isDeletedDataSourceSuccess
  );
  const prevIsCreatedDataSourceSuccess = usePrevious(
    isCreatedDataSourceSuccess
  );

  const [dataSourcesClone, setDataSourcesClone] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState({});
  const [isDataSourceDeleteModalOpen, setIsDataSourceDeleteModalOpen] =
    useState(false);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isCreateNewFromListOpen, setIsCreateNewFromListOpen] = useState(false);
  const [selectedItemFromList, setSelectedItemFromList] = useState({});
  const [isCopy, setIsCopy] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    document.title = `${t("nav_bar_data_sources")} - Decisimo`;

    return () => {
      dispatch(clearTemplates());
    };
  }, []);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getDataSourcesRequest(currentPage.toString()));
    }
  }, [currentPage]);

  useEffect(() => {
    if (isGetDataSourcesSuccess && prevIsGetDataSourcesSuccess === false) {
      setIsLoading(false);
      const dataSourcesClone = structuredClone(dataSources);
      dataSourcesClone?.sort(sortByTitle);
      setDataSourcesClone(dataSourcesClone);
    }
  }, [isGetDataSourcesSuccess]);

  useEffect(() => {
    if (
      (isGetDataSourcesError && prevIsGetDataSourcesError === false) ||
      (isCreatedDataSourceError && prevIsCreatedDataSourceError === false) ||
      (isGetDataSourceError && prevIsGetDataSourceError === false)
    ) {
      setIsLoading(false);
      if (isGetDataSourceError || isCreatedDataSourceError) {
        setSelectedItemFromList({});
        setIsCreateNewFromListOpen(false);
      }
    }
  }, [isGetDataSourceError, isGetDataSourcesError, isCreatedDataSourceError]);

  useEffect(() => {
    if (
      isDeletedDataSourceSuccess &&
      prevIsDeletedDataSourceSuccess === false
    ) {
      setIsLoading(false);
      setDataSourcesClone(
        dataSourcesClone.filter(
          (source) =>
            source.external_data_id !== selectedDataSource.external_data_id
        )
      );
      toast.warning(`${t("data_source")} ${t("deleted")}.`, ToastOptions);
    }
  }, [isDeletedDataSourceSuccess]);

  useEffect(() => {
    if (
      isCreatedDataSourceSuccess &&
      prevIsCreatedDataSourceSuccess === false
    ) {
      if (isCopy) {
        setIsCreateNewFromListOpen(false);
        setSelectedItemFromList({});
        setIsCopy(false);
        dispatch(getDataSourcesRequest());
      } else {
        setIsLoading(false);
        navigate(`/external-data-source/${newDataSourceId}`);
      }

      toast.success(`${t("data_source_created")}.`, ToastOptions);
    }
  }, [isCreatedDataSourceSuccess]);

  useEffect(() => {
    if (isGetDataSourceSuccess && prevIsGetDataSourceSuccess === false) {
      const cloneOfDataSource = structuredClone(dataSource);
      const data = new URLSearchParams(new FormData());

      data.set("title", `${t("copy_of")} ${cloneOfDataSource.title}`);
      data.set("name", `${t("copy")}-${cloneOfDataSource.name}`);
      data.set("endpoint_url", cloneOfDataSource.endpoint_url);
      data.set("method", cloneOfDataSource.method);
      data.set("response_type", cloneOfDataSource.response_type);
      data.set("timeout", cloneOfDataSource.timeout);
      data.set("http_authentication", cloneOfDataSource.http_auth);
      data.set("auth_username", cloneOfDataSource.auth_username);
      data.set("auth_password", cloneOfDataSource.auth_password);

      if (cloneOfDataSource?.params?.length > 0) {
        cloneOfDataSource.params?.forEach((param) => {
          data.set(
            `params[new_${param.external_data_param_id}][name]`,
            param.name
          );
          data.set(
            `params[new_${param.external_data_param_id}][value]`,
            param.value
          );
          if (param.push_to_flow) {
            data.set(
              `params[new_${param.external_data_param_id}][push_to_flow]`,
              param.push_to_flow
            );
          }
        });
      }

      if (cloneOfDataSource?.headers?.length > 0) {
        cloneOfDataSource.headers?.forEach((header) => {
          data.set(
            `headers[new_${header.external_data_header_id}][content]`,
            header.content
          );
        });
      }
      setIsCopy(true);
      setTimeout(() => {
        dispatch(createDataSourceRequest({ data }));
      }, 1000);
    }
  }, [isGetDataSourceSuccess]);

  const handleShowDeleteModal = (selectedData) => {
    setSelectedDataSource(selectedData);
    setIsDataSourceDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsDataSourceDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedDataSource({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteDataSourceRequest(selectedDataSource.external_data_id));
    setIsDataSourceDeleteModalOpen(false);
  };

  const handleShowTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
    if (templates.length === 0) {
      dispatch(getTemplatesRequest({ entity_type: "ext_data" }));
    }
  };

  const handleCreateNewFromListConfirm = (id) => {
    dispatch(getDataSourceRequest(id));
  };

  const SubHeaderActions = () => (
    <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
      <Link
        to="/external-data-source"
        title={t("add_source")}
        className="btn primary-header border-radius-left-4"
      >
        <PlusIcon /> <span className="ml-2">{t("data_source")}</span>
      </Link>
      <div className="btn-group">
        <button
          className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-expanded="false"
          title={t("create_data_source_template")}
        >
          <span className="sr-only">{t("toggle_dropdown")}</span>
        </button>
        <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
          <span>
            <button
              className="dropdown-item"
              type="button"
              onClick={handleShowTemplatesModal}
              data-tip={true}
              data-for="data-source-template"
            >
              <PlusIcon
                style={{
                  filter: "brightness(0.5)",
                }}
              />
              <span className="ml-2">{t("create_from_template")}</span>
              <ReactTooltip
                type="dark"
                place="top"
                effect="solid"
                id="data-source-template"
              >
                {t("create_data_source_template")}
              </ReactTooltip>
            </button>
            <Link
              to="/data-hub"
              className="dropdown-item pl-5"
              title={t("data_hub")}
            >
              <span>{t("data_hub")}</span>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SubHeader
        title={t("nav_bar_data_sources")}
        actions={<SubHeaderActions />}
      />
      <div className="mb-3">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-4 py-3 ">
                {t("table_title")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-3 ">
                {t("identifier")}
              </th>
              {!isMobile && (
                <th scope="col" className="th-flow col-lg-4 py-3 ">
                  {t("last_change")}
                </th>
              )}
              <th className="function-col text-right border-top-0 th-flow" />
            </tr>
          </thead>
          <tbody className="">
            {dataSourcesClone?.length > 0
              ? dataSourcesClone.map((source) => {
                  return (
                    <tr key={source.external_data_id} className="td-flow">
                      <td className=" ">
                        <Link
                          to={`/external-data-source/${source.external_data_id}`}
                        >
                          {source.title}
                        </Link>
                      </td>
                      <td className="">{source.name}</td>
                      {!isMobile && (
                        <td className=" ">
                          {source.dtime_modified}
                          <HelpCircle
                            data-tip={true}
                            data-for={`modified-${source.external_data_id}`}
                            className="ml-2 cursor-pointer"
                          />
                          <ReactTooltip
                            type="dark"
                            place="right"
                            effect="solid"
                            id={`modified-${source.external_data_id}`}
                          >
                            {t("modified_by")} {source.full_name}
                          </ReactTooltip>
                        </td>
                      )}
                      <td className="d-flex align-items-center justify-content-end border-top-0">
                        <div>
                          <button
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title={t("copy_or_delete_data_source")}
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>
                          <div
                            className="dropdown-menu table-dropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                const { title, external_data_id } = source;
                                setIsCreateNewFromListOpen(true);
                                setSelectedItemFromList({
                                  title,
                                  external_data_id,
                                });
                              }}
                            >
                              <CopyIcon /> {t("make_a_copy")}
                            </button>
                            {isMobile && (
                              <Link
                                to={`/external-data-source/${source.external_data_id}`}
                                className="dropdown-item"
                                title={t("edit_external_data")}
                              >
                                <EditIcon /> {t("edit")}
                              </Link>
                            )}
                            <div className="dropdown-divider" />
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleShowDeleteModal(source)}
                            >
                              <TrashIcon /> {t("delete")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetDataSourcesSuccess &&
                dataSources?.length === 0 && <TableNoItems />}
          </tbody>
        </table>
      </div>
      <div className="pagination justify-content-end mb-5">
        <Pagination
          currentPage={currentPage}
          itemsLength={dataSourcesClone?.length}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_data_source")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedDataSource.title}</b>{" "}
            {t("data_source_small")}?
          </span>
        }
        open={isDataSourceDeleteModalOpen}
      />
      {isTemplatesModalOpen && (
        <TemplateModal
          open={isTemplatesModalOpen}
          handleClose={() => setIsTemplatesModalOpen(false)}
          type={"ext_data"}
          title={t("data_source")}
        />
      )}
      <CreateNewItem
        handleClose={() => setIsCreateNewFromListOpen(false)}
        handleConfirm={handleCreateNewFromListConfirm}
        open={isCreateNewFromListOpen}
        listMode={true}
        keyItemId="external_data_id"
        item={selectedItemFromList}
      />
    </>
  );
};

export default DataSources;
