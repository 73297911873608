import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { MainContext } from "context/contexts";
import { getVectors } from "utility/utility";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubFlowStepOptionAttribute = ({
  option,
  attr,
  handleDelete,
  isFork,
  conditionTypes,
}) => {
  const { t } = useTranslation();
  const { revisionId } = useParams();
  const { vectorsData, autoSuggestData } = useContext(MainContext);

  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [type, setType] = useState(
    attr.attribute_vector ? parseInt(attr.attribute_vector) : 0
  );
  const [name, setName] = useState(attr.name || "");
  const [value, setValue] = useState(attr.value || "");
  const [path, setPath] = useState(attr.path || "");

  const [title, setTitle] = useState(attr.title || attr.name);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  useEffect(() => {
    if (!attr.wf_step_option_attribute_id) {
      attr.wf_step_option_attribute_id = `new_${Math.round(
        Math.random() * 10000
      )}`;
    }
  }, [attr]);

  const handleChangeOptionType = (value) => {
    setType(parseInt(value));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  // Determine if the option is associated with a dama_service_id
  const isExternalDataWithDamaService = !!option.dama_service_id;

  return (
    <div>
      <div className="d-flex">
        <div className="col-5 attribute1 pr-0 mr-0">
          {isFork ? (
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text mr-0">{t("attribute")}</span>
              </div>
              <AutoSuggestInput
                revisionMode={!!revisionId}
                vectorsData={vectorsDataClone}
                defaultValue={name}
                inputName={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][name]`}
                isFork={isFork}
              />
            </div>
          ) : (
            <input
              disabled={!!revisionId || isExternalDataWithDamaService}
              type="text"
              className="form-control border-radius-4"
              name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][name]`}
              value={isExternalDataWithDamaService ? title : name}
              onChange={handleNameChange}
            />
          )}
          {isExternalDataWithDamaService && (
            //hidden input fields for dama_service_param_id and name
            <>
              <input
                type="hidden"
                name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][name]`}
                value={name}
              />
              <input
                type="hidden"
                name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][dama_service_param_id]`}
                value={attr.dama_service_param_id}
              />
            </>
          )}
        </div>
        <div className="col attribute2 pl-1 mr-0 ml-0">
          <div className="input-group mb-3">
            {isFork && (
              <div className="input-group-prepend mr-1 pl-0 pr-0">
                <select
                  style={{ width: "140px" }}
                  disabled={!!revisionId}
                  className="form-control opt-attr wf_step_opt_condition_type_id border-radius-4"
                  name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][wf_step_opt_condition_type_id]`}
                  defaultValue={attr.wf_step_opt_condition_type_id || ""}
                  key={attr.wf_step_opt_condition_type_id}
                >
                  {conditionTypes.map((type) => (
                    <option
                      key={type.wf_step_opt_condition_type_id}
                      value={type.wf_step_opt_condition_type_id}
                    >
                      {type.title === "&ne;" ? "≠" : type.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {!isFork && (
              <div className="input-group-prepend mr-1">
                <span className="border border-radius-4 input-group-text">
                  =
                </span>
              </div>
            )}

            <div className="input-group-prepend mr-0">
              <input
                type="hidden"
                name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][vector]`}
                value={type}
              />
              <button
                disabled={revisionId ? "disabled" : ""}
                className="btn outline border-radius-left-4 dropdown-toggle border-radius-none-right-top-bottom"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {type === 0 ? t("value") : t("attribute")}
              </button>

              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item selector-value"
                  onClick={() => handleChangeOptionType(0)}
                >
                  {t("value")}
                </button>
                <button
                  type="button"
                  className="dropdown-item selector-vector-attribute"
                  onClick={() => handleChangeOptionType(1)}
                >
                  {t("attribute")}
                </button>
              </div>
            </div>
            <input
              type="hidden"
              value={type}
              name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][vector]`}
            />
            {type === 1 ? (
              <AutoSuggestInput
                revisionMode={!!revisionId}
                vectorsData={vectorsDataClone}
                className="border-radius-right-4"
                defaultValue={path}
                inputName={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][path]`}
                isFork={isFork}
                isDataSource={true}
              />
            ) : (
              <input
                disabled={!!revisionId}
                type="text"
                className="border-left-0 form-control border-radius-right-4"
                name={`attribute[${option.wf_step_option_id}][${attr.wf_step_option_attribute_id}][value]`}
                value={value}
                onChange={handleValueChange}
              />
            )}
          </div>
        </div>
        <div className="ml-1 pl-0 pr-0 mr-0">
          {!revisionId && !isExternalDataWithDamaService && (
            <button
              className="border border-radius-4 btn option-delete text-gray"
              type="button"
              onClick={() =>
                handleDelete(
                  attr.wf_step_option_attribute_id,
                  option.wf_step_option_id
                )
              }
            >
              <DeleteIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

SubFlowStepOptionAttribute.propTypes = {
  attr: PropTypes.object,
  option: PropTypes.object,
  handleDelete: PropTypes.func,
  conditionTypes: PropTypes.array,
  isFork: PropTypes.bool,
};

export default SubFlowStepOptionAttribute;
