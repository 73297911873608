import { handleActions } from "redux-actions";
import {
  createEndPointFailure,
  createEndPointLimitedFlowFailure,
  createEndPointLimitedFlowRequest,
  createEndPointLimitedFlowSuccess,
  createEndPointRequest,
  createEndPointSuccess,
  createEndPointUserNotificationFailure,
  createEndPointUserNotificationRequest,
  createEndPointUserNotificationSuccess,
  deleteEndPointFailure,
  deleteEndPointLimitedFlowFailure,
  deleteEndPointLimitedFlowRequest,
  deleteEndPointLimitedFlowSuccess,
  deleteEndPointRequest,
  deleteEndPointSuccess,
  deleteEndPointUserNotificationFailure,
  deleteEndPointUserNotificationRequest,
  deleteEndPointUserNotificationSuccess,
  getEndPointFailure,
  getEndPointInstancesFailure,
  getEndPointInstancesRequest,
  getEndPointInstancesSuccess,
  getEndPointRequest,
  getEndPointsFailure,
  getEndPointsRequest,
  getEndPointsSuccess,
  getEndPointSuccess,
  updateEndPointFailure,
  updateEndPointRequest,
  updateEndPointSuccess,
  updateEndPointSuccessKeyChanged,
} from "redux/endpoints/action";

const initialState = {
  endPoints: [],
  endPoint: {},
  eTag: null,
  instances: [],
  isGetEndPointsSuccess: false,
  isGetEndPointsError: false,
  isGetEndPointSuccess: false,
  isGetEndPointError: false,
  isGetEndPointInstancesSuccess: false,
  isGetEndPointInstancesError: false,
  isCreatedEndPointSuccess: false,
  isCreatedEndPointError: false,
  isUpdatedEndPointSuccess: false,
  isUpdatedEndPointError: false,
  isUpdatedEndPointSuccessKeyChanged: false,
  isDeletedEndPointSuccess: false,
  isDeletedEndPointError: false,
  isCreatedEndPointLimitedFlowSuccess: false,
  isCreatedEndPointLimitedFlowError: false,
  isDeletedEndPointLimitedFlowSuccess: false,
  isDeletedEndPointLimitedFlowError: false,
  isCreatedEndPointUserNotificationSuccess: false,
  isCreatedEndPointUserNotificationError: false,
  isDeletedEndPointUserNotificationSuccess: false,
  isDeletedEndPointUserNotificationError: false,
};

const reducer = handleActions(
  {
    // get end points
    [getEndPointsRequest]: (state) => ({
      ...state,
      isGetEndPointsSuccess: false,
      isGetEndPointsError: false,
    }),
    [getEndPointsSuccess]: (state, { payload }) => ({
      ...state,
      endPoints: payload || [],
      isGetEndPointsSuccess: true,
      isGetEndPointsError: false,
    }),
    [getEndPointsFailure]: (state) => ({
      ...state,
      isGetEndPointsSuccess: false,
      isGetEndPointsError: true,
    }),
    // get end point
    [getEndPointRequest]: (state) => ({
      ...state,
      isGetEndPointSuccess: false,
      isGetEndPointError: false,
    }),
    [getEndPointSuccess]: (state, { payload }) => ({
      ...state,
      endPoint: payload,
      isGetEndPointSuccess: true,
      isGetEndPointError: false,
    }),
    [getEndPointFailure]: (state) => ({
      ...state,
      isGetEndPointSuccess: false,
      isGetEndPointError: true,
    }),
    // get end point instances
    [getEndPointInstancesRequest]: (state) => ({
      ...state,
      isGetEndPointInstancesSuccess: false,
      isGetEndPointInstancesError: false,
    }),
    [getEndPointInstancesSuccess]: (state, { payload }) => ({
      ...state,
      instances: payload,
      isGetEndPointInstancesSuccess: true,
      isGetEndPointInstancesError: false,
    }),
    [getEndPointInstancesFailure]: (state) => ({
      ...state,
      isGetEndPointInstancesSuccess: false,
      isGetEndPointInstancesError: true,
    }),
    // create end point
    [createEndPointRequest]: (state) => ({
      ...state,
      isCreatedEndPointSuccess: false,
      isCreatedEndPointError: false,
    }),
    [createEndPointSuccess]: (state, { payload }) => ({
      ...state,
      eTag: payload ?? "",
      isCreatedEndPointSuccess: true,
      isCreatedEndPointError: false,
    }),
    [createEndPointFailure]: (state) => ({
      ...state,
      isCreatedEndPointSuccess: false,
      isCreatedEndPointError: true,
    }),
    // update end point
    [updateEndPointRequest]: (state) => ({
      ...state,
      isUpdatedEndPointSuccess: false,
      isUpdatedEndPointError: false,
    }),
    [updateEndPointSuccess]: (state) => ({
      ...state,
      isUpdatedEndPointSuccess: true,
      isUpdatedEndPointError: false,
    }),
    [updateEndPointFailure]: (state) => ({
      ...state,
      isUpdatedEndPointSuccess: false,
      isUpdatedEndPointError: true,
    }),
    [updateEndPointSuccessKeyChanged]: (state) => ({
      ...state,
      isUpdatedEndPointSuccessKeyChanged: true,
      isUpdatedEndPointError: false,
    }),
    // delete end point
    [deleteEndPointRequest]: (state) => ({
      ...state,
      isDeletedEndPointSuccess: false,
      isDeletedEndPointError: false,
    }),
    [deleteEndPointSuccess]: (state) => ({
      ...state,
      isDeletedEndPointSuccess: true,
      isDeletedEndPointError: false,
    }),
    [deleteEndPointFailure]: (state) => ({
      ...state,
      isDeletedEndPointSuccess: false,
      isDeletedEndPointError: true,
    }),
    // create end point limited flow
    [createEndPointLimitedFlowRequest]: (state) => ({
      ...state,
      isCreatedEndPointLimitedFlowSuccess: false,
      isCreatedEndPointLimitedFlowError: false,
    }),
    [createEndPointLimitedFlowSuccess]: (state) => ({
      ...state,
      isCreatedEndPointLimitedFlowSuccess: true,
      isCreatedEndPointLimitedFlowError: false,
    }),
    [createEndPointLimitedFlowFailure]: (state) => ({
      ...state,
      isCreatedEndPointLimitedFlowSuccess: false,
      isCreatedEndPointLimitedFlowError: true,
    }),
    // delete end point limited flow
    [deleteEndPointLimitedFlowRequest]: (state) => ({
      ...state,
      isDeletedEndPointLimitedFlowSuccess: false,
      isDeletedEndPointLimitedFlowError: false,
    }),
    [deleteEndPointLimitedFlowSuccess]: (state) => ({
      ...state,
      isDeletedEndPointLimitedFlowSuccess: true,
      isDeletedEndPointLimitedFlowError: false,
    }),
    [deleteEndPointLimitedFlowFailure]: (state) => ({
      ...state,
      isDeletedEndPointLimitedFlowSuccess: false,
      isDeletedEndPointLimitedFlowError: true,
    }),
    // create end point user notification
    [createEndPointUserNotificationRequest]: (state) => ({
      ...state,
      isCreatedEndPointUserNotificationSuccess: false,
      isCreatedEndPointUserNotificationError: false,
    }),
    [createEndPointUserNotificationSuccess]: (state) => ({
      ...state,
      isCreatedEndPointUserNotificationSuccess: true,
      isCreatedEndPointUserNotificationError: false,
    }),
    [createEndPointUserNotificationFailure]: (state) => ({
      ...state,
      isCreatedEndPointUserNotificationSuccess: false,
      isCreatedEndPointUserNotificationError: true,
    }),
    // delete end point limited flow
    [deleteEndPointUserNotificationRequest]: (state) => ({
      ...state,
      isDeletedEndPointUserNotificationSuccess: false,
      isDeletedEndPointUserNotificationError: false,
    }),
    [deleteEndPointUserNotificationSuccess]: (state) => ({
      ...state,
      isDeletedEndPointUserNotificationSuccess: true,
      isDeletedEndPointUserNotificationError: false,
    }),
    [deleteEndPointUserNotificationFailure]: (state) => ({
      ...state,
      isDeletedEndPointUserNotificationSuccess: false,
      isDeletedEndPointUserNotificationError: true,
    }),
  },
  initialState
);

export default reducer;
