import * as SRD from "react-js-diagrams/lib/main";
import * as _ from "lodash";

export class CustomPortModel extends SRD.PortModel {
  constructor(props) {
    super(props);
    const { isInput, id, name } = props;
    this.isInput = isInput;
    this.id = id;
    this.name = name;
  }

  serialize() {
    return _.merge(super.serialize(), {
      id: this.id,
      isInput: this.isInput,
      name: this.name,
    });
  }

  deSerialize(data) {
    super.deSerialize(data);
    this.isInput = data.isInput;
    this.id = data.id;
    this.name = data.name;
  }
}
