import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { deleteBatchRequest, getBatchesRequest } from "redux/batches/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import { TableNoItems } from "utility/utility";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const Batches = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    batches,
    isGetBatchesSuccess,
    isGetBatchesError,
    isDeletedBatchSuccess,
    isDeletedBatchError,
  } = useSelector((state) => state.batches);

  const prevIsGetBatchesSuccess = usePrevious(isGetBatchesSuccess);
  const prevIsGetBatchesError = usePrevious(isGetBatchesError);
  const prevIsDeletedBatchSuccess = usePrevious(isDeletedBatchSuccess);
  const prevIsDeletedBatchError = usePrevious(isDeletedBatchError);

  const [batchesData, setBatchesData] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({});
  const [isBatchDeleteModalOpen, setIsBatchDeleteModalOpen] = useState(false);

  useEffect(() => {
    document.title = `${t("batch_executions")} - Decisimo`;
    setIsLoading(true);
    dispatch(getBatchesRequest());
  }, []);

  useEffect(() => {
    if (isGetBatchesSuccess && prevIsGetBatchesSuccess === false) {
      setIsLoading(false);
      setBatchesData(structuredClone(batches));
    }
  }, [isGetBatchesSuccess]);

  useEffect(() => {
    if (isDeletedBatchSuccess && prevIsDeletedBatchSuccess === false) {
      setIsLoading(false);
      let cloneOfBatchesData = structuredClone(batchesData);
      cloneOfBatchesData = cloneOfBatchesData.filter(
        (batch) => batch.batch_id !== selectedBatch.batch_id
      );
      setBatchesData(cloneOfBatchesData);
      toast.warning(t("batch_deleted"), ToastOptions);
      handleClose();
    }
  }, [isDeletedBatchSuccess]);

  useEffect(() => {
    if (
      (isGetBatchesError && prevIsGetBatchesError === false) ||
      (isDeletedBatchError && prevIsDeletedBatchError === false)
    ) {
      if (isDeletedBatchError) {
        handleClose();
      }
      setIsLoading(false);
    }
  }, [isGetBatchesError, isDeletedBatchError]);

  const handleShowDeleteModal = (batch) => {
    setSelectedBatch(batch);
    setIsBatchDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsBatchDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedBatch({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteBatchRequest(selectedBatch.batch_id));
  };

  return (
    <>
      <SubHeader
        title={t("nav_bar_batches")}
        actions={
          <div className="d-flex mb-3 mt-2 text-right">
            <Link to="/batch">
              <button className="btn primary-header" title={t("new_batch")}>
                <PlusIcon /> <span className="ml-2">{t("batch")}</span>
              </button>
            </Link>
          </div>
        }
      />
      <div className="mb-5 ">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-3 py-3 ">
                {t("batch_name")}
              </th>
              <th scope="col" className="th-flow col-lg-3 py-3 ">
                {t("decision_flow_release")}
              </th>
              <th scope="col" className="th-flow col-lg-3 py-3 ">
                {t("next_run")}
              </th>
              {!isMobile && (
                <th scope="col" className="th-flow col-lg-3 py-3 ">
                  {t("last_modified")}
                </th>
              )}
              <th scope="col" className="th-flow col-lg-3 py-3 " />
            </tr>
          </thead>
          <tbody className="">
            {batchesData?.length > 0
              ? batchesData.map((batch) => {
                  return (
                    <tr key={batch.batch_id} className="td-flow">
                      <td>
                        <Link to={`/batches/${batch.batch_id}`}>
                          {batch.title}
                        </Link>
                      </td>
                      <td className=" ">
                        <a href={`workflows/${batch.workflow_id}`}>
                          {batch.workflow_title}
                        </a>{" "}
                        /{" "}
                        <a href={`releases/${batch.workflow_release_id}`}>
                          {batch.workflow_release_name}
                        </a>
                      </td>
                      <td>{batch.next_run ?? <i>no future run</i>}</td>
                      {!isMobile && (
                        <td>
                          <div className="align-items-center d-flex">
                            {batch.dtime_modified}
                            <HelpCircle
                              data-tip={true}
                              data-for={`modified-${batch.batch_id}`}
                              className="ml-2 cursor-pointer"
                            />
                            <ReactTooltip
                              type="dark"
                              place="right"
                              effect="solid"
                              id={`modified-${batch.batch_id}`}
                            >
                              {t("modified_by")} {batch.full_name}
                            </ReactTooltip>
                          </div>
                        </td>
                      )}
                      <td className="text-right">
                        <button
                          type="button"
                          className="btn primary-text border-0 p-0 pb-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title={t("delete_batch")}
                        >
                          <MoreVertical
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {isMobile && (
                            <>
                              <Link
                                to={`/batches/${batch.batch_id}`}
                                className="dropdown-item"
                                title={t("edit_batch")}
                              >
                                <EditIcon /> {t("edit")}
                              </Link>
                              <div className="dropdown-divider" />
                            </>
                          )}
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleShowDeleteModal(batch)}
                          >
                            <TrashIcon /> {t("delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetBatchesSuccess &&
                batches?.length === 0 && <TableNoItems colspan={6} />}
          </tbody>
        </table>
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("batch_delete")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedBatch?.title}</b>{" "}
            {t("batch_small")}?
          </span>
        }
        open={isBatchDeleteModalOpen}
      />
    </>
  );
};

export default Batches;
