import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeploymentByIdRequest } from "redux/deployments/action";
import SubHeader from "../SubHeader";
import { ReactComponent as DeploymentsIcon } from "assets/icons/deployments.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { Link } from "react-router-dom";

const UpdateDeployments = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { deployment } = useSelector((state) => state.deployments);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getDeploymentByIdRequest(id));
  }, []);

  return (
    <>
      {Object.keys(deployment).length && (
        <>
          <SubHeader
            alt={t("nav_bar_deployments")}
            title={deployment.workflow_release_name}
            icon={<DeploymentsIcon />}
            actions={
              <>
                <Link
                  to="/deployments"
                  className="mr12"
                  title={t("back_to_deployments")}
                >
                  <button className="h-100 btn outline">
                    <BackIcon />
                  </button>
                </Link>
              </>
            }
          />
          <div className="row mt-4">
            <div className="col">
              <strong>{t("decision_flow_release_name")} </strong>
              <a
                href={`/releases/${deployment.workflow_release_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {deployment.workflow_release_name}
              </a>
              <br />
              <strong>{t("deployed_endpoint_name")}: </strong>
              {deployment.deployed_endpoint_name}
              <br />
              <strong>{t("user_deployed")}: </strong>
              <a
                href={`/user-accounts/${deployment.user_id}`}
                target="_blank"
                rel="noreferrer"
                className="ml-2 cursor-pointer"
              >
                {deployment.full_name}
              </a>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <strong>{t("deployment_time")}</strong>
              {deployment.dtime_deployment_started ? (
                <div className="mt-3">
                  <span className="font-weight-bold">{t("start_time")}:</span>
                  <label className="ml-2">
                    {deployment.dtime_deployment_started}
                  </label>
                </div>
              ) : null}
              {deployment.dtime_deployment_finished ? (
                <div>
                  <span className="font-weight-bold">{t("end_time")}:</span>
                  <label className="ml-2">
                    {deployment.dtime_deployment_finished}
                  </label>
                </div>
              ) : deployment.dtime_deployment_failed ? (
                <div className="mt-2">
                  <span className="font-weight-bold">{t("failed_time")}:</span>
                  <label className="ml-2">
                    {deployment.dtime_deployment_failed}
                  </label>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <strong>{t("metadata")}</strong>
              <div className="mt-3">
                <span className="font-weight-bold">{t("ip_address")}:</span>
                <label className="ml-2">
                  {deployment.metadata?.ip_address}
                </label>
                <div>
                  <span className="font-weight-bold">{t("browser")}:</span>
                  <label className="ml-2">
                    {deployment.metadata?.user_agent}
                  </label>
                </div>
                {deployment.metadata?.notification_emails ? (
                  <div className="mt-3">
                    <span className="font-weight-bold">
                      {t("notified_users")}:
                    </span>
                    <ul className="ml-2">
                      {deployment.metadata.notification_emails.map((user) => (
                        <li key={user.user_id}>
                          <a href={`/user-accounts/${user.user_id}`}>
                            {user.full_name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateDeployments;
