import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDataSourceRequest,
  getDataSourceRequest,
  getExternalDataTestingRequest,
  getRunTestResultRequest,
  updateDataSourceRequest,
} from "redux/dataSources/action";
import usePrevious from "utility/hooks/usePrevious";
import {
  useCtrlSHandler,
  useCtrlQHandler,
} from "utility/hooks/useCtrlSHandler";

import { dataMarketGetServiceDetailsRequest } from "redux/dataMarket/action";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader";
import DataSourceContent from "components/dataSources/DataSourceContent";
import DeleteConfirm from "components/modals/DeleteConfirm";
import TestingModal from "components/modals/Testing";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ExternalDataIcon } from "assets/icons/external_data.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";

const UpdateDataSource = () => {
  const { setIsLoading, setIsEdited, isEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataSourceRef] = useCtrlSHandler(updateDataSource);
  useCtrlQHandler(() => handleToggleTestingModal(true));
  const { t } = useTranslation();

  const {
    dataSource,
    isGetDataSourceSuccess,
    isGetRunTestResultError,
    isGetDataSourceError,
    isUpdatedDataSourceError,
    isUpdatedDataSourceSuccess,
    isDeletedDataSourceSuccess,
    isDeletedDataSourceError,
    isGetRunTestResultSuccess,
    runTestResult,
    isGetExternalDataTestingError,
    isGetExternalDataTestingSuccess,
    externalDataTestingResult,
  } = useSelector((state) => state.dataSources);

  const prevIsGetDataSourceSuccess = usePrevious(isGetDataSourceSuccess);
  const prevIsGetExternalDataTestingError = usePrevious(
    isGetExternalDataTestingError
  );
  const prevIsGetExternalDataTestingSuccess = usePrevious(
    isGetExternalDataTestingSuccess
  );
  const prevIsGetRunTestResultError = usePrevious(isGetRunTestResultError);
  const prevIsGetDataSourceError = usePrevious(isGetDataSourceError);
  const prevIsGetRunTestResultSuccess = usePrevious(isGetRunTestResultSuccess);
  const prevIsDeletedDataSourceSuccess = usePrevious(
    isDeletedDataSourceSuccess
  );
  const prevIsDeletedDataSourceError = usePrevious(isDeletedDataSourceError);
  const prevIsUpdatedDataSourceSuccess = usePrevious(
    isUpdatedDataSourceSuccess
  );
  const prevIsUpdatedDataSourceError = usePrevious(isUpdatedDataSourceError);

  const [dataSourceClone, setDataSourceClone] = useState({});
  const [runTestResultDetailsData, setRunTestResultDetailsData] = useState();
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false);
  const [externalDataTestingResultClone, setExternalDataTestingResultClone] =
    useState(false);
  const [isDataSourceDeleteModalOpen, setIsDataSourceDeleteModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `${t("data_source")} - Decisimo`;
    setIsLoading(true);
    dispatch(getDataSourceRequest(id));
  }, []);

  useEffect(() => {
    if (isGetDataSourceSuccess && prevIsGetDataSourceSuccess === false) {
      setIsLoading(false);
      setDataSourceClone(structuredClone(dataSource));
    }
  }, [isGetDataSourceSuccess]);

  useEffect(() => {
    if (
      isGetExternalDataTestingSuccess &&
      prevIsGetExternalDataTestingSuccess === false
    ) {
      setIsLoading(false);
      setExternalDataTestingResultClone(
        structuredClone(externalDataTestingResult)
      );
    }
  }, [isGetExternalDataTestingSuccess]);

  useEffect(() => {
    if (
      (isGetDataSourceError && prevIsGetDataSourceError === false) ||
      (isUpdatedDataSourceError && prevIsUpdatedDataSourceError === false) ||
      (isDeletedDataSourceError && prevIsDeletedDataSourceError === false) ||
      (isGetRunTestResultError && prevIsGetRunTestResultError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetDataSourceError,
    isUpdatedDataSourceError,
    isDeletedDataSourceError,
    isGetRunTestResultError,
  ]);

  useEffect(() => {
    if (
      isGetExternalDataTestingError &&
      prevIsGetExternalDataTestingError === false
    ) {
      setIsLoading(false);
      toast.error(`${t("failed_to_get_testing_data_source")}.`, ToastOptions);
    }
  }, [isGetExternalDataTestingError]);

  useEffect(() => {
    if (
      isUpdatedDataSourceSuccess &&
      prevIsUpdatedDataSourceSuccess === false
    ) {
      setIsLoading(false);
      toast.success(`${t("data_source")} ${t("updated")}.`, ToastOptions);
      dispatch(getDataSourceRequest(id));
    }
  }, [isUpdatedDataSourceSuccess]);

  useEffect(() => {
    if (
      isDeletedDataSourceSuccess &&
      prevIsDeletedDataSourceSuccess === false
    ) {
      setIsLoading(false);
      toast.success(`${t("data_source")} ${t("deleted")}.`, ToastOptions);
      navigate("/external-data");
    }
  }, [isDeletedDataSourceSuccess]);

  useEffect(() => {
    if (isGetRunTestResultSuccess && prevIsGetRunTestResultSuccess === false) {
      setLoading(false);
      setRunTestResultDetailsData(structuredClone(runTestResult));
    }
  }, [isGetRunTestResultSuccess]);

  //if dama_service_id defined, then load details for it
  useEffect(() => {
    if (dataSourceClone && Object.keys(dataSourceClone).length > 0) {
      if (dataSourceClone.dama_service_id) {
        dispatch(
          dataMarketGetServiceDetailsRequest({
            dama_service_id: dataSourceClone.dama_service_id,
          })
        );
      }
    }
  }, [isGetDataSourceSuccess, dataSourceClone]);

  function updateDataSource(target) {
    setIsEdited(false);
    const data = new URLSearchParams(new FormData(target));
    const title = target.title.value;
    const name = target.name.value;
    const isShowToken = data.get("is_show_token");

    if (title?.length === 0 || name?.length === 0) {
      return toast.error(
        `${t("please_fill_out_the_title_or_id")}.`,
        ToastOptions
      );
    }

    for (const [key, value] of data) {
      data.set(key, value);
      if (!isShowToken && key === "external_data_oauth2_id") {
        data.set(key, "");
      }
      if (
        key.includes("push_to_flow") ||
        key === "http_authentication" ||
        key === "response_type"
      ) {
        if (value === "on" || value === "1") {
          data.set(key, 1);
        }
      }
    }
    setIsLoading(true);
    dispatch(updateDataSourceRequest({ id, data }));
  }

  const handleShowDataSourceDeleteModal = () => {
    setIsDataSourceDeleteModalOpen(true);
  };

  const handleDataSourceDeleteModalClose = () => {
    setIsDataSourceDeleteModalOpen(false);
  };

  const handleDataSourceDeleteConfirm = () => {
    setIsLoading(true);
    setIsEdited(false);
    dispatch(deleteDataSourceRequest(id));
    handleDataSourceDeleteModalClose();
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowDataSourceDeleteModal}
          type="button"
          title={t("delete_data_source")}
        >
          <TrashIcon /> {t("delete")}
        </button>
      ),
    },
  ];

  const handleToggleTestingModal = (isOpen = false) => {
    //if is edited, we will ask to save  first
    if (isEdited) {
      return toast.error(t("please_save_data_source_first"), ToastOptions);
    }

    setIsTestingModalOpen(isOpen);
    if (!isOpen) {
      setRunTestResultDetailsData();
    } else {
      dispatch(getExternalDataTestingRequest(id));
    }
  };

  const handleRunTestResultConfirm = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));
    dispatch(getRunTestResultRequest({ data, id }));
  };

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          updateDataSource(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          alt={t("data_source")}
          icon={<ExternalDataIcon />}
          title={dataSourceClone.title}
          actions={
            <>
              <button
                title={t("test_data_source")}
                className="btn outline mr-2"
                type="button"
                onClick={() => handleToggleTestingModal(true)}
              >
                <WindIcon />
              </button>

              <button
                className="btn primary mr-2"
                type="submit"
                title={t("save_data_source")}
              >
                <SaveIcon />
              </button>

              <div>
                <button
                  type="button"
                  className="btn primary"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  title={t("delete_data_source")}
                >
                  <SettingsIcon />
                </button>

                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          }
        />
        <DataSourceContent
          data={dataSourceClone}
          dama_service_id={dataSourceClone.dama_service_id}
        />
      </form>
      <DeleteConfirm
        handleClose={handleDataSourceDeleteModalClose}
        handleConfirm={handleDataSourceDeleteConfirm}
        title={t("delete_external_data_source")}
        message={t("delete_external_data_source_message")}
        open={isDataSourceDeleteModalOpen}
      />
      <TestingModal
        title={t("testing_data_source")}
        size={"lg"}
        loading={loading}
        testingData={externalDataTestingResultClone}
        handleClose={() => handleToggleTestingModal()}
        handleRunTestResultDetailsConfirm={handleRunTestResultConfirm}
        runTestResultDetailsData={runTestResultDetailsData}
        open={isTestingModalOpen}
        isDataSource={true}
      />
    </>
  );
};

export default UpdateDataSource;
