import React from "react";
import Login from "components/auth/Login";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";
import Register from "../components/auth/Register";
import SSOLogin from "../components/auth/SSOLogin";
import OTPLogin from "../components/auth/OTPLogin";

export const publicRoutes = [
  {
    id: 1,
    path: "/login",
    element: <Login />,
    exact: true,
  },
  {
    id: 2,
    path: "/new-password",
    element: <ForgotPassword />,
    exact: true,
  },
  {
    id: 3,
    path: "/reset-password",
    element: <ResetPassword />,
    exact: true,
  },
  {
    id: 4,
    path: "/signup",
    element: <Register />,
    exact: true,
  },
  {
    id: 5,
    path: "/sso-login",
    element: <SSOLogin />,
    exact: true,
  },
  {
    id: 6,
    path: "/otp-login",
    element: <OTPLogin />,
    exact: true,
  },
];
