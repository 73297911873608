import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Prompt = (props) => {
  const { open, handleClose, handleConfirm, message } = props;

  const { t } = useTranslation();

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          {t("warning")}
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>{message ? t("message") : t("default_message")}</Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            variant=""
            className="primary"
            type="button"
            onClick={handleConfirm}
          >
            {t("leave")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Prompt.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  message: PropTypes.any,
};

export default Prompt;
