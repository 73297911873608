import { createAction } from "redux-actions";

export const getWorkflowsRequest = createAction("GET_WORKFLOWS_REQUEST");
export const getWorkflowsSuccess = createAction("GET_WORKFLOWS_SUCCESS");
export const getWorkflowsFailure = createAction("GET_WORKFLOWS_FAILURE");

export const getWorkflowRequest = createAction("GET_WORKFLOW_REQUEST");
export const getWorkflowSuccess = createAction("GET_WORKFLOW_SUCCESS");
export const getWorkflowFailure = createAction("GET_WORKFLOW_FAILURE");

export const getWorkflowStepTypesRequest = createAction(
  "GET_WORKFLOW_STEP_TYPES_REQUEST"
);
export const getWorkflowStepTypesSuccess = createAction(
  "GET_WORKFLOW_STEP_TYPES_SUCCESS"
);
export const getWorkflowStepTypesFailure = createAction(
  "GET_WORKFLOW_STEP_TYPES_FAILURE"
);

export const getWorkflowOptionConditionTypesRequest = createAction(
  "GET_WORKFLOW_OPTION_CONDITION_TYPES_REQUEST"
);
export const getWorkflowOptionConditionTypesSuccess = createAction(
  "GET_WORKFLOW_OPTION_CONDITION_TYPES_SUCCESS"
);
export const getWorkflowOptionConditionTypesFailure = createAction(
  "GET_WORKFLOW_OPTION_CONDITION_TYPES_FAILURE"
);

export const getWorkflowRevisionsRequest = createAction(
  "GET_WORKFLOW_REVISIONS_REQUEST"
);
export const getWorkflowRevisionsSuccess = createAction(
  "GET_WORKFLOW_REVISIONS_SUCCESS"
);
export const getWorkflowRevisionsFailure = createAction(
  "GET_WORKFLOW_REVISIONS_FAILURE"
);

export const getWorkflowRevisionRequest = createAction(
  "GET_WORKFLOW_REVISION_REQUEST"
);
export const getWorkflowRevisionSuccess = createAction(
  "GET_WORKFLOW_REVISION_SUCCESS"
);
export const getWorkflowRevisionFailure = createAction(
  "GET_WORKFLOW_REVISIONS_FAILURE"
);

export const createWorkflowRequest = createAction("CREATE_WORKFLOW_REQUEST");
export const createWorkflowSuccess = createAction("CREATE_WORKFLOW_SUCCESS");
export const createWorkflowFailure = createAction("CREATE_WORKFLOW_FAILURE");

export const updateWorkflowRequest = createAction("UPDATE_WORKFLOW_REQUEST");
export const updateWorkflowSuccess = createAction("UPDATE_WORKFLOW_SUCCESS");
export const updateWorkflowFailure = createAction("UPDATE_WORKFLOW_FAILURE");

export const updateWorkflowStepRequest = createAction(
  "UPDATE_WORKFLOW_STEP_REQUEST"
);
export const updateWorkflowStepSuccess = createAction(
  "UPDATE_WORKFLOW_STEP_SUCCESS"
);
export const updateWorkflowStepFailure = createAction(
  "UPDATE_WORKFLOW_STEP_FAILURE"
);

export const createWorkflowReleaseRequest = createAction(
  "CREATE_WORKFLOW_RELEASE_REQUEST"
);
export const createWorkflowReleaseSuccess = createAction(
  "CREATE_WORKFLOW_RELEASE_SUCCESS"
);
export const createWorkflowReleaseFailure = createAction(
  "CREATE_WORKFLOW_RELEASE_FAILURE"
);

export const createWorkflowStepRequest = createAction(
  "CREATE_WORKFLOW_STEP_REQUEST"
);
export const createWorkflowStepSuccess = createAction(
  "CREATE_WORKFLOW_STEP_SUCCESS"
);
export const createWorkflowStepFailure = createAction(
  "CREATE_WORKFLOW_STEP_FAILURE"
);

export const deleteWorkflowRequest = createAction("DELETE_WORKFLOW_REQUEST");
export const deleteWorkflowSuccess = createAction("DELETE_WORKFLOW_SUCCESS");
export const deleteWorkflowFailure = createAction("DELETE_WORKFLOW_FAILURE");

export const deleteWorkflowStepRequest = createAction(
  "DELETE_WORKFLOW_STEP_REQUEST"
);
export const deleteWorkflowStepSuccess = createAction(
  "DELETE_WORKFLOW_STEP_SUCCESS"
);
export const deleteWorkflowStepFailure = createAction(
  "DELETE_WORKFLOW_STEP_FAILURE"
);

export const deleteStepOptionRequest = createAction(
  "DELETE_STEP_OPTION_REQUEST"
);
export const deleteStepOptionSuccess = createAction(
  "DELETE_STEP_OPTION_SUCCESS"
);
export const deleteStepOptionFailure = createAction(
  "DELETE_STEP_OPTION_FAILURE"
);

export const deleteStepOptionParamRequest = createAction(
  "DELETE_STEP_OPTION_PARAM_REQUEST"
);
export const deleteStepOptionParamSuccess = createAction(
  "DELETE_STEP_OPTION_PARAM_SUCCESS"
);
export const deleteStepOptionParamFailure = createAction(
  "DELETE_STEP_OPTION_PARAM_FAILURE"
);

export const deleteLinkRequest = createAction("DELETE_LINK_REQUEST");
export const deleteLinkSuccess = createAction("DELETE_LINK_SUCCESS");
export const deleteLinkFailure = createAction("DELETE_LINK_FAILURE");

export const useCaseWorkflowRequest = createAction(
  "USE_CASE_WORK_FLOW_REQUEST"
);
