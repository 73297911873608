import { handleActions } from "redux-actions";
import {
  dataMarketGetServicesRequest,
  dataMarketGetServicesSuccess,
  dataMarketGetServicesFailure,
  dataMarketActivateServiceRequest,
  dataMarketActivateServiceSuccess,
  dataMarketActivateServiceFailure,
  dataMarketGetServiceDetailsRequest,
  dataMarketGetServiceDetailsSuccess,
  dataMarketGetServiceDetailsFailure,
  dataMarketUpdateServiceDetailsFailure,
  dataMarketUpdateServiceDetailsRequest,
  dataMarketUpdateServiceDetailsSuccess,
  dataMarketDeactivateMyServiceRequest,
  dataMarketDeactivateMyServiceSuccess,
  dataMarketDeactivateMyServiceFailure,
} from "./action";

const initialState = {
  services: [],
  providers: [],
  myServices: [],
  isLoading: false,
  error: null,
  activationStatus: {},
  dataServiceDetails: {},
  isDataMarketGetServicesSuccess: false,
  isDataMarketGetServicesError: false,
  isDataMarketActivateServiceSuccess: false,
  isDataMarketActivateServiceError: false,
  isDataMarketGetServiceDetailsSuccess: false,
  isDataMarketGetServiceDetailsError: false,
};

const reducer = handleActions(
  {
    [dataMarketGetServicesRequest]: (state) => ({
      ...state,
      isDataMarketGetServicesSuccess: false,
      isDataMarketGetServicesError: false,
    }),
    [dataMarketGetServicesSuccess]: (state, { payload }) => ({
      ...state,
      services: payload,
      isDataMarketGetServicesSuccess: true,
      isDataMarketGetServicesError: false,
    }),
    [dataMarketGetServicesFailure]: (state) => ({
      ...state,
      isDataMarketGetServicesSuccess: false,
      isDataMarketGetServicesError: true,
    }),
    //activate service -
    [dataMarketActivateServiceRequest]: (state) => ({
      ...state,
      isDataMarketActivateServiceSuccess: false,
      isDataMarketActivateServiceError: false,
    }),
    [dataMarketActivateServiceSuccess]: (state, { payload }) => {
      const updatedServices = state.services.map((dama_service) => {
        if (
          dama_service.dama_service_id == payload.updatedData.dama_service_id
        ) {
          return {
            ...dama_service,
            isActive: true,
            dama_service_tenant_id: payload.updatedData.dama_service_tenant_id,
          };
        }
        return dama_service;
      });
      return {
        ...state,
        services: updatedServices,
        isDataMarketActivateServiceSuccess: true,
        isDataMarketActivateServiceError: false,
      };
    },
    [dataMarketActivateServiceFailure]: (state) => ({
      ...state,
      isDataMarketActivateServiceSuccess: false,
      isDataMarketActivateServiceError: true,
    }),
    //deactivate service
    [dataMarketDeactivateMyServiceRequest]: (state) => ({
      ...state,
      isDataMarketDeactivateMyServiceSuccess: false,
      isDataMarketDeactivateMyServiceError: false,
    }),
    [dataMarketDeactivateMyServiceSuccess]: (state, { payload }) => {
      const updatedServices = state.services.map((dama_service) => {
        if (dama_service.dama_service_id == payload.dama_service_id) {
          return {
            ...dama_service,
            isActive: false,
          };
        }
        return dama_service;
      });
      return {
        ...state,
        services: updatedServices,
        isDataMarketDeactivateMyServiceSuccess: true,
        isDataMarketDeactivateMyServiceError: false,
      };
    },
    [dataMarketDeactivateMyServiceFailure]: (state) => ({
      ...state,
      isDataMarketDeactivateMyServiceSuccess: false,
      isDataMarketDeactivateMyServiceError: true,
    }),
    //get service details
    [dataMarketGetServiceDetailsRequest]: (state) => ({
      ...state,
      isDataMarketGetServiceDetailsSuccess: false,
      isDataMarketGetServiceDetailsError: false,
    }),
    [dataMarketGetServiceDetailsSuccess]: (state, { payload }) => ({
      ...state,
      dataServiceDetails: payload,
      isDataMarketGetServiceDetailsSuccess: true,
      isDataMarketGetServiceDetailsError: false,
    }),
    [dataMarketGetServiceDetailsFailure]: (state) => ({
      ...state,
      isDataMarketGetServiceDetailsSuccess: false,
      isDataMarketGetServiceDetailsError: true,
    }),
    //update service details
    [dataMarketUpdateServiceDetailsRequest]: (state) => ({
      ...state,
      isDataMarketUpdateServiceDetailsSuccess: false,
      isDataMarketUpdateServiceDetailsError: false,
    }),
    [dataMarketUpdateServiceDetailsSuccess]: (state) => ({
      ...state,
      isDataMarketUpdateServiceDetailsSuccess: true,
      isDataMarketUpdateServiceDetailsError: false,
    }),
    [dataMarketUpdateServiceDetailsFailure]: (state) => ({
      ...state,
      isDataMarketUpdateServiceDetailsSuccess: false,
      isDataMarketUpdateServiceDetailsError: true,
    }),
  },
  initialState
);

export default reducer;
