import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import usePrevious from "utility/hooks/usePrevious";
import { useTranslation } from "react-i18next";

const ContactUs = (props) => {
  const { open, handleClose, handleConfirm } = props;
  const { t } = useTranslation();

  const {
    serviceDeskLink,
    isCreatedContactUsSuccess,
    isCreatedContactUsFailure,
    isCreatedContactUsRequest,
  } = useSelector((state) => state.contactUs);

  const prevIsCreatedContactUsSuccess = usePrevious(isCreatedContactUsSuccess);
  const prevIsCreatedContactUsFailure = usePrevious(isCreatedContactUsFailure);

  useEffect(() => {
    if (isCreatedContactUsFailure && prevIsCreatedContactUsFailure === false) {
      handleClose();
    }
  }, [isCreatedContactUsFailure]);

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("contact_us")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <Modal.Body>
            {isCreatedContactUsSuccess &&
            prevIsCreatedContactUsSuccess === false ? (
              <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">
                  {t("thank_you_for_your_contact")}
                </h4>
                <p>{t("find_your_link_below")}</p>
                <hr />
                <p className="mb-0">
                  <a
                    href={serviceDeskLink}
                    target="_blank"
                    rel="noreferrer"
                    className="alert-heading"
                  >
                    <ins>{t("click_here")}</ins>
                  </a>
                  <span
                    className="col cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(serviceDeskLink);
                      toast.success(t("copied"), ToastOptions);
                    }}
                  >
                    <CopyIcon />
                  </span>
                </p>
              </div>
            ) : (
              <>
                <div className="col-md col-12 px-0 mb-3">
                  <label>{t("table_title")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    required
                  />
                </div>
                <div className="col-md col-12 px-0">
                  <label>{t("table_description")}</label>
                  <textarea
                    className="form-control"
                    name="description"
                    required
                  />
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="" className="outline" onClick={handleClose}>
              {t("close")}
            </Button>
            {!(
              isCreatedContactUsSuccess &&
              prevIsCreatedContactUsSuccess === false
            ) && (
              <Button
                variant=""
                className="primary"
                type="submit"
                style={{
                  width: "85.69px",
                }}
                disabled={isCreatedContactUsRequest}
              >
                {isCreatedContactUsRequest ? (
                  <div
                    className="spinner-border"
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                    role="status"
                  >
                    <span className="sr-only">{t("loading")}</span>
                  </div>
                ) : (
                  t("submit")
                )}
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

ContactUs.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default ContactUs;
