import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { FormulaContext, RevisionContext } from "context/contexts";
import Variable from "components/functions/content/items/Variable";
import Value from "components/functions/content/items/Value";
import Operator from "components/functions/content/items/Operator";
import Constants from "./content/items/Constants";
import Brackets from "./content/items/Brackets";
import { ContextMenuTrigger } from "react-contextmenu";

const FormulaItem = ({ attributes, item, path = [], setItem }) => {
  const revisionMode = useContext(RevisionContext);
  const { contextMenuItem, setContextMenuItem } = useContext(FormulaContext);

  const inputProps = {
    item,
    path,
  };

  useEffect(() => {
    if (Object.keys(contextMenuItem).length > 0) {
      setContextMenuItem({});
    }
  }, []);

  return (
    <>
      {item.item_type === "o" && (
        <ContextMenuTrigger id={`operators-${item.fce_item_id}`}>
          <Operator key={item.fce_item_id} {...inputProps} />
        </ContextMenuTrigger>
      )}
      {["v", "a", "c"].includes(item.item_type) ? (
        <ContextMenuTrigger id={`context-menu-${item.fce_item_id}`}>
          <div
            className="input-group-prepend"
            onContextMenuCapture={() => setContextMenuItem(inputProps)}
          >
            {item.item_type === "v" ? (
              <Value item={item} setItem={setItem} />
            ) : item.item_type === "c" ? (
              <Constants item={item} />
            ) : (
              item.item_type === "a" && (
                <Variable item={item} attributes={attributes} />
              )
            )}
          </div>
        </ContextMenuTrigger>
      ) : (
        ["b", "f"].includes(item.item_type) && (
          <ContextMenuTrigger id={`context-menu-${item.fce_item_id}`}>
            {item.item_type === "f" && (
              <div
                className="input-group-prepend pb-1"
                onContextMenuCapture={() => setContextMenuItem(inputProps)}
              >
                <span className="input-group-text">{item.operator} (</span>
              </div>
            )}
            <Brackets
              item={item}
              setItem={setItem}
              attributes={attributes}
              path={path}
              revisionMode={revisionMode}
            />
          </ContextMenuTrigger>
        )
      )}
    </>
  );
};

FormulaItem.propTypes = {
  attributes: PropTypes.array,
  path: PropTypes.array,
  item: PropTypes.object,
  setItem: PropTypes.func,
};

export default FormulaItem;
