import { createAction } from "redux-actions";

export const getVectorsRequest = createAction("GET_VECTORS_REQUEST");
export const getVectorsSuccess = createAction("GET_VECTORS_SUCCESS");
export const getVectorsFailure = createAction("GET_VECTORS_FAILURE");

export const getVectorsExtraRequest = createAction("GET_VECTORS_EXTRA_REQUEST");
export const getVectorsExtraSuccess = createAction("GET_VECTORS_EXTRA_SUCCESS");
export const getVectorsExtraFailure = createAction("GET_VECTORS_EXTRA_FAILURE");

export const getVectorAttributesRequest = createAction(
  "GET_VECTOR_ATTRIBUTES_REQUEST"
);
export const getVectorAttributesSuccess = createAction(
  "GET_VECTOR_ATTRIBUTES_SUCCESS"
);
export const getVectorAttributesFailure = createAction(
  "GET_VECTOR_ATTRIBUTES_FAILURE"
);

export const getVectorRequest = createAction("GET_VECTOR_REQUEST");
export const getVectorSuccess = createAction("GET_VECTOR_SUCCESS");
export const getVectorFailure = createAction("GET_VECTOR_FAILURE");

export const createVectorRequest = createAction("CREATE_VECTOR_REQUEST");
export const createVectorSuccess = createAction("CREATE_VECTOR_SUCCESS");
export const createVectorFailure = createAction("CREATE_VECTOR_FAILURE");

export const createVectorAttributeRequest = createAction(
  "CREATE_VECTOR_ATTRIBUTE_REQUEST"
);
export const createVectorAttributeSuccess = createAction(
  "CREATE_VECTOR_ATTRIBUTE_SUCCESS"
);
export const createVectorAttributeFailure = createAction(
  "CREATE_VECTOR_ATTRIBUTE_FAILURE"
);

export const updateVectorRequest = createAction("UPDATE_VECTOR_REQUEST");
export const updateVectorSuccess = createAction("UPDATE_VECTOR_SUCCESS");
export const updateVectorFailure = createAction("UPDATE_VECTOR_FAILURE");

export const updateVectorAttributeRequest = createAction(
  "UPDATE_VECTOR_ATTRIBUTE_REQUEST"
);
export const updateVectorAttributeSuccess = createAction(
  "UPDATE_VECTOR_ATTRIBUTE_SUCCESS"
);
export const updateVectorAttributeFailure = createAction(
  "UPDATE_VECTOR_ATTRIBUTE_FAILURE"
);

export const deleteVectorAttributeRequest = createAction(
  "DELETE_VECTOR_ATTRIBUTE_REQUEST"
);
export const deleteVectorAttributeSuccess = createAction(
  "DELETE_VECTOR_ATTRIBUTE_SUCCESS"
);
export const deleteVectorAttributeFailure = createAction(
  "DELETE_VECTOR_ATTRIBUTE_FAILURE"
);

export const deleteVectorRequest = createAction("DELETE_VECTOR_REQUEST");
export const deleteVectorSuccess = createAction("DELETE_VECTOR_SUCCESS");
export const deleteVectorFailure = createAction("DELETE_VECTOR_FAILURE");
