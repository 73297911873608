import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getTemplateDecisionRequest } from "redux/templates/action";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as IdentifierIcon } from "assets/icons/templateIcons/identifier_icon.svg";
import { ReactComponent as ReadTextIcon } from "assets/icons/templateIcons/read_text.svg";
import { ReactComponent as CloseSmallIcon } from "assets/icons/templateIcons/close_icon_small.svg";
import { ReactComponent as CloseIcon } from "assets/icons/templateIcons/close_icon.svg";
import { ReactComponent as DecisionFlowIcon } from "assets/icons/templateIcons/decision_flow_icon.svg";
import { ReactComponent as EditIcon } from "assets/icons/templateIcons/edit_small.svg";
import { ReactComponent as RuleSetIcon } from "assets/icons/templateIcons/rule_set.svg";
import { ReactComponent as DecisionIcon } from "assets/icons/templateIcons/decision_tables.svg";
import { ReactComponent as FunctionIcon } from "assets/icons/templateIcons/function_small.svg";
import { ReactComponent as DataObjectIcon } from "assets/icons/templateIcons/data_object_small.svg";
import { useTranslation } from "react-i18next";

const DecisionDesigner = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { decision } = useSelector((state) => state.templates);

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    document.title = `${decision?.title || "AI Decision Design "}- Decisimo`;
    dispatch(getTemplateDecisionRequest({ id }));
  }, [id]);

  const groupBy = (xs, key) => {
    return xs?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const artifactsGroup = groupBy(decision?.artifacts, "entity_type");

  const renderDecisionFlowItems = (name, items, icon, url) => {
    return (
      <>
        <div className="d-flex align-items-center mb-3">
          {icon}
          <span className="ml-3">{name}</span>
        </div>
        {items.map((item) => (
          <div className="template-item-decision" key={item.entity_id}>
            <div>{item.title}</div>
            <Link to={`/${url}/${item.entity_id}`} title={t("edit")}>
              <EditIcon />
            </Link>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="d-flex mt-5 strategy-data align-items-center">
        <h2 className="mb-0">
          {t("report_for")} {decision?.title}
        </h2>
        <Button className="strategies-btn ml-3">
          <IdentifierIcon />
          <span className="ml-2">{decision?.name}</span>
        </Button>
      </div>
      <div className="d-flex justify-content-between mt-5">
        <div className="w-50 strategy-date">
          <p>
            {t("time_started")}: {decision?.dtime_inserted}
          </p>
          <p>{t("this_decision_strategy_is_based_on_your_text_input")}</p>
          <Button
            variant=""
            className="read-text-btn"
            onClick={() => setIsOpenModal(true)}
          >
            <ReadTextIcon />
            <span className="ml-2">{t("read_the_initial_prompt_text")}</span>
          </Button>
        </div>
        <Modal
          show={isOpenModal}
          onHide={() => setIsOpenModal(false)}
          size="xl"
          className="custom-scrollable-modal-body"
        >
          <Modal.Header style={{ border: "none" }}>
            <p className="d-flex align-items-center">
              <ReadTextIcon />
              <span className="modal-header-title ml-3">
                {t("initial_prompt_text")}
              </span>
            </p>
            <button
              type="button bg-gray mr-5"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              <CloseIcon />
            </button>
          </Modal.Header>
          <Modal.Body>
            {decision?.initial_prompt.split("\n").map(function (line, index) {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant=""
              className="outline"
              onClick={() => setIsOpenModal(false)}
            >
              <CloseSmallIcon /> <span className="ml-2">{t("close")}</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="artifact-group mt-5">
        <h2>{t("created_objects")}</h2>
        {artifactsGroup?.workflow ? (
          <div className="artifact-group-item">
            {renderDecisionFlowItems(
              t("decision_flows"),
              artifactsGroup?.workflow,
              <DecisionFlowIcon />,
              "workflows"
            )}
          </div>
        ) : null}
        {artifactsGroup?.rule_set ? (
          <div className="artifact-group-item">
            {renderDecisionFlowItems(
              t("rule_sets"),
              artifactsGroup?.rule_set,
              <RuleSetIcon />,
              "rule-sets"
            )}
          </div>
        ) : null}
        {artifactsGroup?.decision_table ? (
          <div className="artifact-group-item">
            {renderDecisionFlowItems(
              t("decision_tables"),
              artifactsGroup?.decision_table,
              <DecisionIcon />,
              "decision-tables"
            )}
          </div>
        ) : null}
        {artifactsGroup?.fce ? (
          <div className="artifact-group-item">
            {renderDecisionFlowItems(
              t("functions"),
              artifactsGroup?.fce,
              <FunctionIcon />,
              "functions"
            )}
          </div>
        ) : null}
        {artifactsGroup?.data_object ? (
          <div className="artifact-group-item">
            {renderDecisionFlowItems(
              t("data_objects"),
              artifactsGroup?.data_object,
              <DataObjectIcon />,
              "data-objects"
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DecisionDesigner;
