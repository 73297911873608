import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { RevisionContext } from "context/contexts";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";
import { newAttribute } from "utility/utility";

const Attribute = ({
  attribute,
  vectorsData,
  fceAttrId,
  handleDeleteAttributeModal,
  setAttributes,
  attributes,
  isLast,
}) => {
  const { t } = useTranslation();
  const revisionMode = useContext(RevisionContext);
  const [attrInputValue, setAttrInputValue] = useState(attribute.name);

  const handleChange = (value, id, isAttr) => {
    if (isLast) {
      const newAttr = { ...newAttribute };
      const attributesData = [...attributes];
      const prevStateParam = attributesData.find(
        (attr) => attr.fce_attribute_id === id
      );
      prevStateParam.isDefault = false;
      newAttr.fce_attribute_id = `new_${Math.round(Math.random() * 10000)}`;
      attributesData.push(newAttr);
      setAttributes(attributesData);
    } else {
      const attributesClone = structuredClone(attributes);
      const attribute = attributesClone.find(
        (attr) => attr.fce_attribute_id === id
      );

      if (isAttr) {
        attribute.name = value;
      } else {
        attribute.attribute_path = value;
      }

      setAttributes(attributesClone);
    }
    if (isAttr) {
      setAttrInputValue(value);
    }
  };

  return (
    <div className="border-sm cursor-move flex-column flex-md-row p-3 p-md-0 row m-0 attribute-row">
      <div className="col-12 col-md-6 p-0">
        <input
          type="text"
          name={`fce_attribute[${fceAttrId}][name]`}
          placeholder={t("add_attribute_name")}
          className={`form-control form-data fce_attribute font-size-1rem attribute-name-input ${
            isLast ? "bg-gray" : ""
          }`}
          onChange={(e) =>
            handleChange(e.target.value, attribute.fce_attribute_id, true)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          value={attrInputValue}
          disabled={revisionMode}
        />
        <input
          type="hidden"
          name={`fce_attribute[${fceAttrId}][fce_attribute_id]`}
          className="form-control form-data fce_attribute"
          value={fceAttrId}
        />
      </div>
      <div className="col p-0">
        <AutoSuggestInput
          isLast={isLast}
          placeholder={t("value_mapping")}
          vectorsData={vectorsData}
          isColumn={true}
          onInputChange={(e) => {
            handleChange(e.target.value, attribute.fce_attribute_id, false);
          }}
          defaultValue={attribute.attribute_path || ""}
          inputName={`fce_attribute[${fceAttrId}][attribute_path]`}
          revisionMode={revisionMode}
          isFunc={true}
        />
      </div>

      <div className="col column-delete p-0 text-md-left text-right">
        {revisionMode ? (
          <div
            className="border-left-0 outline h-100 function-delete-icon w-42"
            style={{ cursor: `default` }}
          />
        ) : (
          <button
            className={`border-left-0 btn outline h-100 ${
              isLast ? "w-42" : "function-delete-icon"
            }`}
            type="button"
            onClick={
              !isLast ? () => handleDeleteAttributeModal(fceAttrId) : undefined
            }
            disabled={attribute.isDefault}
          >
            {!isLast ? <TrashIcon /> : null}
          </button>
        )}
      </div>
    </div>
  );
};

Attribute.propTypes = {
  attribute: PropTypes.object.isRequired,
  vectorsData: PropTypes.array.isRequired,
  fceAttrId: PropTypes.any.isRequired,
  handleDeleteAttributeModal: PropTypes.func.isRequired,
  attributes: PropTypes.array.isRequired,
  setAttributes: PropTypes.func.isRequired,
  addAttribute: PropTypes.func,
  isLast: PropTypes.bool.isRequired,
};

export default Attribute;
