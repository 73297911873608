import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const CreateDataObjectAttribute = (props) => {
  const { open, handleClose, handleConfirm, data, editMode = false } = props;
  const { t } = useTranslation();
  const [dataSourceRef] = useCtrlSHandler(handleConfirm);

  const [mapping, setMapping] = useState("");

  useEffect(() => {
    if (!Object.keys(data).length) {
      setMapping("");
    }
  }, [handleClose]);

  useEffect(() => {
    if (Object.keys(data).length) {
      setMapping(data.mapping);
    }
  }, [data]);

  const changeValue = (e) => {
    const val = e.target.value;
    setMapping(val);
    if (!(val.startsWith("$.") && val.length >= 3)) {
      e.target.classList.add("is-invalid");
    } else {
      e.target.classList.remove("is-invalid");
    }
  };

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">
            {editMode ? t("edit") : t("add")} {t("data_object_attribute")}
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form
          ref={dataSourceRef}
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirm(e.target);
          }}
        >
          <Modal.Body>
            <label>{t("mapping_path")}</label>
            <input
              type="text"
              className="form-control"
              name="mapping"
              onChange={changeValue}
              value={mapping}
              required
            />
            <label>{t("dummy_value")}</label>
            <input
              type="text"
              className="form-control"
              name="dummy_value"
              defaultValue={data?.dummy_value || ""}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="" onClick={handleClose} className="outline my-0">
              {t("close")}
            </Button>
            <Button variant="" type="submit" className="primary my-0">
              {editMode ? (
                <>
                  <SaveIcon /> {t("save")}{" "}
                </>
              ) : (
                t("add")
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

CreateDataObjectAttribute.propTypes = {
  open: PropTypes.bool,
  editMode: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  data: PropTypes.object,
  variantClose: PropTypes.string,
  variantSave: PropTypes.string,
};

export default CreateDataObjectAttribute;
