// components/models/UploadOverlay.js
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./UploadOverlay.css";

const UploadOverlay = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div className="upload-overlay">
      <div className="upload-overlay-content">
        {status.isUploading && (
          <>
            <div className="upload-icon mb-3">
              <svg
                className="animate-upload"
                width="50"
                height="50"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z"
                />
              </svg>
            </div>
            <div className="progress-wrapper">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${status.progress}%` }}
                  aria-valuenow={status.progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {status.progress}%
                </div>
              </div>
              <div className="text-center mt-3">
                {t("uploading_model_file")}
              </div>
            </div>
          </>
        )}

        {status.error && (
          <div className="upload-error">
            <div className="alert alert-danger">
              <div className="d-flex align-items-center mb-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="mr-2"
                >
                  <path
                    fill="currentColor"
                    d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                </svg>
                <span>{status.error}</span>
              </div>
              <button
                className="btn btn-outline-danger w-100"
                onClick={status.retry}
              >
                {t("retry")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UploadOverlay.propTypes = {
  status: PropTypes.shape({
    isUploading: PropTypes.bool,
    progress: PropTypes.number,
    error: PropTypes.string,
    retry: PropTypes.func,
  }).isRequired,
};

export default UploadOverlay;
