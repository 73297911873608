import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Header = ({
  header,
  setHeaders,
  handleShowHeaderDeleteModal,
  isLast,
}) => {
  const { t } = useTranslation();

  const [content, setContent] = useState(header.content);

  const handleChange = (e, id) => {
    if (isLast) {
      setHeaders((prevState) => {
        const prevStateHeader = prevState.find(
          (param) => param.external_data_header_id === id
        );
        prevStateHeader.isDefault = false;

        return [...prevState, getNewHeader()];
      });
    }

    setContent(e.target.value);
  };

  return (
    <tr className={header.isDefault ? "bg-gray" : ""}>
      <td>
        <input
          className="form-control form-control-sm form-control-plaintext"
          type="text"
          placeholder={t("header")}
          name={
            !header.isDefault
              ? `headers[${header.external_data_header_id}][content]`
              : ""
          }
          onChange={(e) => handleChange(e, header.external_data_header_id)}
          defaultValue={content}
        />
      </td>
      <td className="text-center">
        {!header.isDefault ? (
          <DeleteIcon
            className="cursor-pointer"
            title={t("delete_header")}
            style={{
              width: "15px",
              height: "16px",
            }}
            onClick={() =>
              handleShowHeaderDeleteModal(header.external_data_header_id)
            }
          />
        ) : null}
      </td>
    </tr>
  );
};

const newHeader = {
  content: "",
  isDefault: true,
};

export function getNewHeader() {
  const newHeaderClone = structuredClone(newHeader);
  newHeaderClone.external_data_header_id = `new_${parseInt(
    Date.now() * Math.random()
  )}`;

  return newHeaderClone;
}

Header.propTypes = {
  header: PropTypes.object,
  handleShowHeaderDeleteModal: PropTypes.func,
  setHeaders: PropTypes.func,
  isLast: PropTypes.bool,
};

export default Header;
