import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createVectorRequest,
  deleteVectorRequest,
  getVectorsRequest,
} from "redux/vectors/action";
import usePrevious from "utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { isJson, sortByTitle, TableNoItems } from "utility/utility";
import SubHeader from "components/SubHeader";
import CreateDataObject from "components/modals/dataObjects/CreateDataObject";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { MainContext } from "context/contexts";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { CreateCopyOfDataObject } from "./utils";
import TemplateModal from "../modals/Template";
import { useTranslation } from "react-i18next";

const DataObjects = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    newVectorId,
    vectors,
    isGetVectorsSuccess,
    isGetVectorsError,
    isDeletedVectorSuccess,
    isDeletedVectorError,
    isCreatedVectorSuccess,
    isCreatedVectorError,
    isCreatedVectorRequest,
    isGetVectorError,
    isGetVectorAttributesError,
  } = useSelector((state) => state.vectors);

  const prevIsGetVectorsSuccess = usePrevious(isGetVectorsSuccess);
  const prevIsGetVectorsError = usePrevious(isGetVectorsError);
  const prevIsDeletedVectorSuccess = usePrevious(isDeletedVectorSuccess);
  const prevIsDeletedVectorError = usePrevious(isDeletedVectorError);
  const prevIsCreatedVectorSuccess = usePrevious(isCreatedVectorSuccess);
  const prevIsCreatedVectorError = usePrevious(isCreatedVectorError);
  const prevIsGetVectorError = usePrevious(isGetVectorError);
  const prevIsGetVectorAttributesError = usePrevious(
    isGetVectorAttributesError
  );

  const [vectorsClone, setVectorsClone] = useState([]);
  const [selectedVector, setSelectedVector] = useState({});
  const [isVectorDeleteModalOpen, setIsVectorDeleteModalOpen] = useState(false);
  const [isAddVectorModalOpen, setIsAddVectorModalOpen] = useState(false);
  const [isCreateNewFromListOpen, setIsCreateNewFromListOpen] = useState(false);
  const [selectedItemFromList, setSelectedItemFromList] = useState({});
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  useEffect(() => {
    document.title = `${t("nav_bar_data_objects")} - Decisimo`;
    setIsLoading(true);
    dispatch(getVectorsRequest());
  }, []);

  useEffect(() => {
    if (isGetVectorsSuccess && prevIsGetVectorsSuccess === false) {
      setIsLoading(false);
      const vectorsClone = structuredClone(vectors);
      vectorsClone?.sort(sortByTitle);
      setVectorsClone(vectorsClone);
    }
  }, [isGetVectorsSuccess]);

  useEffect(() => {
    if (
      isCreatedVectorSuccess &&
      prevIsCreatedVectorSuccess === false &&
      !isCreateNewFromListOpen
    ) {
      setIsLoading(false);
      toast.success(t("data_object_added"), ToastOptions);
      handleAddDataObjectClose();
      navigate(`/data-objects/${newVectorId}`);
    }
  }, [isCreatedVectorSuccess]);

  useEffect(() => {
    if (
      (isCreatedVectorError && prevIsCreatedVectorError === false) ||
      (isDeletedVectorError && prevIsDeletedVectorError === false) ||
      (isGetVectorsError && prevIsGetVectorsError === false) ||
      (isGetVectorError && prevIsGetVectorError === false) ||
      (isGetVectorAttributesError && prevIsGetVectorAttributesError === false)
    ) {
      setIsLoading(false);
      if (isGetVectorError) {
        setIsCreateNewFromListOpen(false);
        setSelectedItemFromList({});
      } else if (isCreatedVectorError) {
        handleAddDataObjectClose();
      }
    }
  }, [
    isGetVectorAttributesError,
    isCreatedVectorError,
    isDeletedVectorError,
    isGetVectorsError,
    isGetVectorError,
  ]);

  useEffect(() => {
    if (isDeletedVectorSuccess && prevIsDeletedVectorSuccess === false) {
      setIsLoading(false);
      setVectorsClone(
        vectorsClone.filter(
          (vector) => vector.vector_id !== selectedVector.vector_id
        )
      );
      toast.warning(t("data_object_deleted"), ToastOptions);
      dispatch(getVectorsRequest());
    }
  }, [isDeletedVectorSuccess]);

  const handleShowDeleteModal = (vector) => {
    setSelectedVector(vector);
    setIsVectorDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsVectorDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedVector({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteVectorRequest(selectedVector.vector_id));
    setIsVectorDeleteModalOpen(false);
  };

  const handleAddDataObjectOpen = () => {
    setIsAddVectorModalOpen(true);
  };

  const handleAddDataObjectClose = () => {
    setIsAddVectorModalOpen(false);
  };

  const handleAddDataObjectConfirm = (e, selectedFile) => {
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));
    const title = e.target.title.value;

    if (title.length === 0) {
      return toast.error(t("fill_title"), ToastOptions);
    }

    setIsLoading(true);
    if (selectedFile) {
      if (selectedFile.type.includes("json")) {
        const fileReader = new FileReader();
        fileReader.onload = (evt) => {
          if (isJson(evt.target.result)) {
            data.set(
              "json_vector",
              JSON.stringify(JSON.parse(evt.target.result))
            );
            dispatch(createVectorRequest(data));
          } else {
            setIsLoading(false);
            return toast.error(t("select_valid_json"), ToastOptions);
          }
        };
        fileReader.readAsText(selectedFile);
      } else {
        setIsLoading(false);
        return toast.error(t("select_valid_json"), ToastOptions);
      }
    } else {
      data.set("json_vector", null);
      dispatch(createVectorRequest(data));
    }
  };

  const handleOpenTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
  };

  return (
    <>
      <SubHeader
        title={t("nav_bar_data_objects")}
        actions={
          <div className="d-flex mb-3 mt-2 text-right btn-group">
            <button
              className="btn primary-header border-radius-left-4"
              title={t("add_data_object")}
              onClick={handleAddDataObjectOpen}
            >
              <PlusIcon /> <span className="ml-2">{t("data_object")}</span>
            </button>
            <div className="btn-group">
              <button
                title={t("create_data_object_template")}
                className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="sr-only">{t("toggle_dropdown")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                <span>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleOpenTemplatesModal}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("create_from_template")}</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        }
      />
      <div className="mb-5">
        <table className="table table-hover table-fixed border-top-0 data-object-table">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-9 py-3 ">
                {t("nav_bar_data_objects")}
              </th>
              <th className="function-col th-flow text-right" />
            </tr>
          </thead>
          <tbody className="">
            {vectorsClone?.length > 0
              ? vectorsClone.map((vector) => {
                  if (vector.vector_id < 1) return null;
                  return (
                    <tr key={vector.vector_id} className="td-flow ">
                      <td className="">
                        <Link to={`/data-objects/${vector.vector_id}`}>
                          {vector.title}
                        </Link>
                      </td>
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <button
                          title={t("delete_or_copy_data_object")}
                          type="button"
                          className="btn primary-text border-0 p-0 pb-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <MoreVertical
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </button>

                        <div
                          className="dropdown-menu table-dropdown"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              const { title, vector_id } = vector;
                              setIsCreateNewFromListOpen(true);
                              setSelectedItemFromList({
                                title,
                                vector_id,
                              });
                            }}
                          >
                            <CopyIcon /> {t("make_a_copy")}
                          </button>
                          <div className="dropdown-divider" />
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleShowDeleteModal(vector)}
                          >
                            <TrashIcon /> {t("delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetVectorsSuccess &&
                vectors?.length === 0 && <TableNoItems colspan={2} />}
          </tbody>
        </table>
      </div>

      {isTemplatesModalOpen && (
        <TemplateModal
          open={isTemplatesModalOpen}
          handleClose={() => setIsTemplatesModalOpen(false)}
          type={"data_object"}
          title={t("data_object")}
        />
      )}
      <CreateDataObject
        isLoading={isCreatedVectorRequest}
        handleClose={handleAddDataObjectClose}
        handleConfirm={handleAddDataObjectConfirm}
        open={isAddVectorModalOpen}
      />
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_data_object")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedVector.title}</b>{" "}
            {t("data_object_small")}?
          </span>
        }
        open={isVectorDeleteModalOpen}
      />
      <CreateCopyOfDataObject
        isCreateNewFromListOpen={isCreateNewFromListOpen}
        setIsCreateNewFromListOpen={setIsCreateNewFromListOpen}
        selectedItemFromList={selectedItemFromList}
        setSelectedItemFromList={setSelectedItemFromList}
      />
    </>
  );
};

export default DataObjects;
