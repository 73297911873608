import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createRoleRightsRequest,
  getRoleRightsRequest,
} from "redux/roleRights/action";
import usePrevious from "utility/hooks/usePrevious";
import { sortByTitle } from "utility/utility";
import { onHandleUpdateRole } from "utility/roles";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const ManageRoleRights = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(handleSubmit);
  const { t } = useTranslation();

  const {
    roleRights,
    isGetRoleRightsSuccess,
    isCreatedRoleRightsSuccess,
    isGetRoleRightsError,
    isCreatedRoleRightsError,
    newRoleRightsId,
  } = useSelector((state) => state.roleRights);

  const prevRemoveUserRoleSuccess = usePrevious(isGetRoleRightsSuccess);
  const prevRemoveUserRoleError = usePrevious(isGetRoleRightsError);
  const prevIsCreatedRoleRightsSuccess = usePrevious(
    isCreatedRoleRightsSuccess
  );
  const prevIsCreatedRoleRightsError = usePrevious(isCreatedRoleRightsError);

  const [roleRightsData, setRoleRightsData] = useState([]);

  useEffect(() => {
    document.title = t("role_decisimo");
    setIsLoading(true);
    dispatch(getRoleRightsRequest());
  }, []);

  useEffect(() => {
    if (isGetRoleRightsSuccess && prevRemoveUserRoleSuccess === false) {
      setIsLoading(false);
      const roleRightsClone = structuredClone(roleRights);
      roleRightsClone.sort(sortByTitle);
      setRoleRightsData(roleRightsClone);
    }
  }, [isGetRoleRightsSuccess]);

  useEffect(() => {
    if (
      (isGetRoleRightsError && prevRemoveUserRoleError === false) ||
      (isCreatedRoleRightsError && prevIsCreatedRoleRightsError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetRoleRightsError, isCreatedRoleRightsError]);

  useEffect(() => {
    if (
      isCreatedRoleRightsSuccess &&
      prevIsCreatedRoleRightsSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("role_added"), ToastOptions);
      navigate(`/user-roles/${newRoleRightsId}`);
    }
  }, [isCreatedRoleRightsSuccess]);

  function handleSubmit(e) {
    const data = onHandleUpdateRole(e, roleRightsData);
    setIsLoading(true);
    dispatch(createRoleRightsRequest(data));
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e.target);
        }}
      >
        <SubHeader
          title={t("user_role_rights")}
          actions={
            <>
              <Link to="/user-roles">
                <button className="btn outline-header">
                  <BackIcon />{" "}
                  <span className="ml-2">{t("back_to_roles")}</span>
                </button>
              </Link>

              <button className="btn primary-header mx-2" type="submit">
                <SaveIcon />
              </button>
            </>
          }
        />
        <div className="row mb-5">
          <div className="col">
            <label>{t("table_title")}</label>
            <input type="text" className="form-control" name="title" required />
          </div>
        </div>
        <div className="mb-5 table-responsive">
          <table className="table table-pretty vertical-align-middle">
            <thead className="bg-transparent border-0">
              <tr>
                <th>{t("access_right_area")}</th>
                <th>{t("read")}</th>
                <th>{t("write")}</th>
                <th>{t("delete")}</th>
              </tr>
            </thead>
            <tbody>
              {roleRightsData?.length > 0 &&
                roleRightsData.map((roleRights) => {
                  return (
                    <tr key={roleRights.access_right_id}>
                      <td>
                        {roleRights.title}
                        <input
                          type="hidden"
                          value="1"
                          name={`right[${roleRights.access_right_id}][_____irrelevant]`}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name={`right[${roleRights.access_right_id}][access_read]`}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name={`right[${roleRights.access_right_id}][access_write]`}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name={`right[${roleRights.access_right_id}][access_execute]`}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default ManageRoleRights;
