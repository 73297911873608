import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { createDecisionTableRequest } from "redux/decisionTables/action";
import { MainContext } from "context/contexts";
import DecisionTableContent from "components/decisionTables/DecisionTableContent";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { getVectors } from "utility/utility";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { useTranslation } from "react-i18next";

const AddDecisionTable = () => {
  const { vectorsData, autoSuggestData, setIsLoading, setIsEdited } =
    useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dataSourceRef] = useCtrlSHandler(saveDecisionTable);

  const { isCreatedDecisionTableSuccess, newDecisionTableId } = useSelector(
    (state) => state.decisionTables
  );

  const prevIsCreatedDecisionTableSuccess = usePrevious(
    isCreatedDecisionTableSuccess
  );

  const [vectorsDataClone, setVectorsDataClone] = useState([]);

  useEffect(() => {
    document.title = `${t("decision_table")} - Decisimo`;

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  useEffect(() => {
    if (
      isCreatedDecisionTableSuccess &&
      prevIsCreatedDecisionTableSuccess === false
    ) {
      setIsLoading(false);
      toast.success(`${t("decision_table_created")}.`, ToastOptions);
      navigate(`/decision-tables/${newDecisionTableId}/cols`);
    }
  }, [isCreatedDecisionTableSuccess]);

  function saveDecisionTable(target) {
    setIsEdited(false);
    if (target.querySelector(".is-invalid")) {
      return toast.error(t("data_attribute_path_is_invalid"), ToastOptions);
    }
    const data = new URLSearchParams(new FormData(target));
    const title = target.title.value;
    const name = target.name.value;

    if (title.length === 0 || name.length === 0) {
      return toast.error(
        t("please_fill_out_the_title_or_identifier"),
        ToastOptions
      );
    }

    setIsLoading(true);
    dispatch(createDecisionTableRequest({ data }));
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          saveDecisionTable(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <DecisionTableContent vectorsDataClone={vectorsDataClone} />
      </form>
    </>
  );
};

AddDecisionTable.propTypes = {
  ruleSetData: PropTypes.object,
  handleShowRevisionsModal: PropTypes.func,
  handleShowDeleteModal: PropTypes.func,
  handleShowTestRuleSetModal: PropTypes.func,
  revisionMode: PropTypes.bool,
  createMode: PropTypes.bool,
};

export default AddDecisionTable;
