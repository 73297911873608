import { createAction } from "redux-actions";

export const getBatchesRequest = createAction("GET_BATCHES_REQUEST");
export const getBatchesSuccess = createAction("GET_BATCHES_SUCCESS");
export const getBatchesFailure = createAction("GET_BATCHES_FAILURE");

export const getBatchRequest = createAction("GET_BATCH_REQUEST");
export const getBatchSuccess = createAction("GET_BATCH_SUCCESS");
export const getBatchFailure = createAction("GET_BATCH_FAILURE");

export const deleteBatchRequest = createAction("DELETE_BATCH_REQUEST");
export const deleteBatchSuccess = createAction("DELETE_BATCH_SUCCESS");
export const deleteBatchFailure = createAction("DELETE_BATCH_FAILURE");

export const createBatchRequest = createAction("CREATE_BATCH_REQUEST");
export const createBatchSuccess = createAction("CREATE_BATCH_SUCCESS");
export const createBatchFailure = createAction("CREATE_BATCH_FAILURE");

export const forceScheduleBatchTaskRequest = createAction(
  "FORCE_SCHEDULE_BATCH_TASK_REQUEST"
);
export const forceScheduleBatchTaskSuccess = createAction(
  "FORCE_SCHEDULE_BATCH_TASK_SUCCESS"
);
export const forceScheduleBatchTaskFailure = createAction(
  "FORCE_SCHEDULE_BATCH_TASK_FAILURE"
);

export const updateBatchRequest = createAction("UPDATE_BATCH_REQUEST");
export const updateBatchSuccess = createAction("UPDATE_BATCH_SUCCESS");
export const updateBatchFailure = createAction("UPDATE_BATCH_FAILURE");

export const getBatchTypesRequest = createAction("GET_BATCH_TYPES_REQUEST");
export const getBatchTypesSuccess = createAction("GET_BATCH_TYPES_SUCCESS");
export const getBatchTypesFailure = createAction("GET_BATCH_TYPES_FAILURE");

export const getBatchInstancesRequest = createAction(
  "GET_BATCH_INSTANCES_REQUEST"
);
export const getBatchInstancesSuccess = createAction(
  "GET_BATCH_INSTANCES_SUCCESS"
);
export const getBatchInstancesFailure = createAction(
  "GET_BATCH_INSTANCES_FAILURE"
);

export const getBatchTasksRequest = createAction("GET_BATCH_TASKS_REQUEST");
export const getBatchTasksSuccess = createAction("GET_BATCH_TASKS_SUCCESS");
export const getBatchTasksFailure = createAction("GET_BATCH_TASKS_FAILURE");

export const deleteTaskRequest = createAction("DELETE_TASK_REQUEST");
export const deleteTaskSuccess = createAction("DELETE_TASK_SUCCESS");
export const deleteTaskFailure = createAction("DELETE_TASK_FAILURE");
