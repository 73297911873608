import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import ReactMarkdown from "react-markdown";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import SimpleMde from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import showdown from "showdown";
import "fontawesome-4.7/css/font-awesome.min.css";
import "fontawesome-4.7/fonts/fontawesome-webfont.woff2";
import { ReactComponent as EditIcon } from "assets/icons/edit-full.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import usePrevious from "utility/hooks/usePrevious";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ReleaseDetails = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleConfirm,
    title = t("release_details"),
    data,
  } = props;

  const [editMode, setEditMode] = useState(true);
  const [notes, setNotes] = useState("");
  useCtrlSHandler(handleConfirm, notes);

  const { isUpdatedReleaseNotesSuccess } = useSelector(
    (state) => state.releases
  );

  const prevIsUpdatedReleaseNotesSuccess = usePrevious(
    isUpdatedReleaseNotesSuccess
  );

  useEffect(() => {
    if (
      isUpdatedReleaseNotesSuccess &&
      prevIsUpdatedReleaseNotesSuccess === false
    ) {
      setEditMode(true);
    }
  }, [isUpdatedReleaseNotesSuccess]);

  useEffect(() => {
    if (data?.notes) {
      setNotes(data.notes);
    }
  }, [data]);

  const showNotes = () => {
    const converter = new showdown.Converter();
    return converter.makeHtml(notes);
  };

  return (
    <>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirm(notes);
          }}
        >
          <Modal.Body>
            <label>{`${t("nav_bar_decision_flow")}: `} </label>{" "}
            {data?.workflow_id ? (
              <a
                href={`/workflows/${data?.workflow_id}`}
                target="_blank"
                title={data?.workflow_title}
                rel="noreferrer"
              >
                {data?.workflow_title}
              </a>
            ) : (
              <i> {t("deleted")}</i>
            )}
            <br />
            <label>{`${t("release_time")}:`} </label>{" "}
            <span>{data?.dtime_inserted}</span> <br />
            <label>{`${t("user_released")}:`} </label>{" "}
            <span>{data?.full_name}</span>
            <br />
            <div className="row">
              <div className="col">
                <h4 className="mt-3 mb-2">{t("release_notes")}</h4>
              </div>
            </div>
            {!editMode ? (
              <SimpleMde
                value={notes}
                onChange={setNotes}
                options={{
                  autofocus: true,
                  spellChecker: false,
                  autoDownloadFontAwesome: false,
                  previewRender(text) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown>{text}</ReactMarkdown>
                    );
                  },
                }}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: showNotes() }} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="" className="outline" onClick={handleClose}>
              {t("close")}
            </Button>
            <div className="text-right">
              {editMode ? (
                <button
                  className="btn primary"
                  title="edit"
                  type="submit"
                  onClick={() => setEditMode(false)}
                >
                  <EditIcon />
                </button>
              ) : (
                <button
                  className="btn primary"
                  title="save"
                  type="button"
                  onClick={() => setEditMode(true)}
                >
                  <SaveIcon />
                </button>
              )}
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

ReleaseDetails.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.object,
};

export default ReleaseDetails;
