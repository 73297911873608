import { createAction } from "redux-actions";

export const getDecisionTablesRequest = createAction(
  "GET_DECISION_TABLES_REQUEST"
);
export const getDecisionTablesSuccess = createAction(
  "GET_DECISION_TABLES_SUCCESS"
);
export const getDecisionTablesFailure = createAction(
  "GET_DECISION_TABLES_FAILURE"
);

export const createDecisionTableRequest = createAction(
  "CREATE_DECISION_TABLE_REQUEST"
);
export const createDecisionTableSuccess = createAction(
  "CREATE_DECISION_TABLE_SUCCESS"
);
export const createDecisionTableFailure = createAction(
  "CREATE_DECISION_TABLE_FAILURE"
);

export const getConditionsRequest = createAction("GET_CONDITIONS_REQUEST");
export const getConditionsSuccess = createAction("GET_CONDITIONS_SUCCESS");
export const getConditionsFailure = createAction("GET_CONDITIONS_FAILURE");

export const getDecisionTableRequest = createAction(
  "GET_DECISION_TABLE_REQUEST"
);
export const getDecisionTableSuccess = createAction(
  "GET_DECISION_TABLE_SUCCESS"
);
export const getDecisionTableFailure = createAction(
  "GET_DECISION_TABLE_FAILURE"
);

export const updateDecisionTableRequest = createAction(
  "UPDATE_DECISION_TABLE_REQUEST"
);
export const updateDecisionTableSuccess = createAction(
  "UPDATE_DECISION_TABLE_SUCCESS"
);
export const updateDecisionTableFailure = createAction(
  "UPDATE_DECISION_TABLE_FAILURE"
);

export const getDecisionTableRowsRequest = createAction(
  "GET_DECISION_TABLE_ROWS_REQUEST"
);
export const getDecisionTableRowsSuccess = createAction(
  "GET_DECISION_TABLE_ROWS_SUCCESS"
);
export const getDecisionTableRowsFailure = createAction(
  "GET_DECISION_TABLE_ROWS_FAILURE"
);

export const deleteDecisionTableRowRequest = createAction(
  "DELETE_DECISION_TABLE_ROW_REQUEST"
);
export const deleteDecisionTableRowSuccess = createAction(
  "DELETE_DECISION_TABLE_ROW_SUCCESS"
);
export const deleteDecisionTableRowFailure = createAction(
  "DELETE_DECISION_TABLE_ROW_FAILURE"
);

export const getDecisionTableRevisionsRequest = createAction(
  "GET_DECISION_TABLE_REVISIONS_REQUEST"
);
export const getDecisionTableRevisionsSuccess = createAction(
  "GET_DECISION_TABLE_REVISIONS_SUCCESS"
);
export const getDecisionTableRevisionsFailure = createAction(
  "GET_DECISION_TABLE_REVISIONS_FAILURE"
);

export const getDecisionTableRevisionRequest = createAction(
  "GET_DECISION_TABLE_REVISION_REQUEST"
);
export const getDecisionTableRevisionSuccess = createAction(
  "GET_DECISION_TABLE_REVISION_SUCCESS"
);
export const getDecisionTableRevisionFailure = createAction(
  "GET_DECISION_TABLE_REVISION_FAILURE"
);

export const getDecisionTableRevision2Request = createAction(
  "GET_DECISION_TABLE_REVISION2_REQUEST"
);
export const getDecisionTableRevision2Success = createAction(
  "GET_DECISION_TABLE_REVISION2_SUCCESS"
);
export const getDecisionTableRevision2Failure = createAction(
  "GET_DECISION_TABLE_REVISION2_FAILURE"
);

export const importTableDataRequest = createAction("IMPORT_TABLE_DATA_REQUEST");
export const importTableDataSuccess = createAction("IMPORT_TABLE_DATA_SUCCESS");
export const importTableDataFailure = createAction("IMPORT_TABLE_DATA_FAILURE");

export const exportTableDataRequest = createAction("EXPORT_TABLE_DATA_REQUEST");
export const exportTableDataSuccess = createAction("EXPORT_TABLE_DATA_SUCCESS");
export const exportTableDataFailure = createAction("EXPORT_TABLE_DATA_FAILURE");

export const getRunTestResultStatusTableRequest = createAction(
  "GET_RUN_TEST_RESULT_STATUS_TABLE_REQUEST"
);
export const getRunTestResultStatusTableSuccess = createAction(
  "GET_RUN_TEST_RESULT_STATUS_TABLE_SUCCESS"
);
export const getRunTestResultStatusTableFailure = createAction(
  "GET_RUN_TEST_RESULT_STATUS_TABLE_FAILURE"
);

export const getRunTestObjectResultRequest = createAction(
  "GET_RUN_TEST_OBJECT_RESULT_REQUEST"
);
export const getRunTestObjectResultSuccess = createAction(
  "GET_RUN_TEST_OBJECT_RESULT_SUCCESS"
);
export const getRunTestObjectResultFailure = createAction(
  "GET_RUN_TEST_OBJECT_RESULT_FAILURE"
);

export const getRunTestSimulationResultRequest = createAction(
  "GET_RUN_TEST_SIMULATION_RESULT_REQUEST"
);
export const getRunTestSimulationResultSuccess = createAction(
  "GET_RUN_TEST_SIMULATION_RESULT_SUCCESS"
);
export const getRunTestSimulationResultFailure = createAction(
  "GET_RUN_TEST_SIMULATION_RESULT_FAILURE"
);

export const updateDecisionTableDefinitionRequest = createAction(
  "UPDATE_DECISION_TABLE_DEFINITION_REQUEST"
);
export const updateDecisionTableDefinitionSuccess = createAction(
  "UPDATE_DECISION_TABLE_DEFINITION_SUCCESS"
);
export const updateDecisionTableDefinitionFailure = createAction(
  "UPDATE_DECISION_TABLE_DEFINITION_FAILURE"
);

export const deleteDecisionTableRequest = createAction(
  "DELETE_DECISION_TABLE_REQUEST"
);
export const deleteDecisionTableSuccess = createAction(
  "DELETE_DECISION_TABLE_SUCCESS"
);
export const deleteDecisionTableFailure = createAction(
  "DELETE_DECISION_TABLE_FAILURE"
);

export const deleteTableColumnRequest = createAction(
  "DELETE_TABLE_COLUMN_REQUEST"
);
export const deleteTableColumnSuccess = createAction(
  "DELETE_TABLE_COLUMN_SUCCESS"
);
export const deleteTableColumnFailure = createAction(
  "DELETE_TABLE_COLUMN_FAILURE"
);

//get overlap validation
export const getOverlapValidationRequest = createAction(
  "GET_OVERLAP_VALIDATION_REQUEST"
);
export const getOverlapValidationSuccess = createAction(
  "GET_OVERLAP_VALIDATION_SUCCESS"
);
export const getOverlapValidationFailure = createAction(
  "GET_OVERLAP_VALIDATION_FAILURE"
);
