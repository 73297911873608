import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createVectorAttributeFailure,
  createVectorAttributeRequest,
  createVectorAttributeSuccess,
  createVectorFailure,
  createVectorRequest,
  createVectorSuccess,
  deleteVectorAttributeFailure,
  deleteVectorAttributeRequest,
  deleteVectorAttributeSuccess,
  deleteVectorFailure,
  deleteVectorRequest,
  deleteVectorSuccess,
  getVectorAttributesFailure,
  getVectorAttributesRequest,
  getVectorAttributesSuccess,
  getVectorFailure,
  getVectorRequest,
  getVectorsFailure,
  getVectorsRequest,
  getVectorsExtraRequest,
  getVectorsExtraFailure,
  getVectorsExtraSuccess,
  getVectorsSuccess,
  getVectorSuccess,
  updateVectorAttributeFailure,
  updateVectorAttributeRequest,
  updateVectorAttributeSuccess,
  updateVectorFailure,
  updateVectorRequest,
  updateVectorSuccess,
} from "redux/vectors/action";

function* getVectors({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/vectors?page=${payload}` : "/vectors"
    );
    if (response.status === 200) {
      yield put(getVectorsSuccess(response.data));
    }
  } catch (e) {
    yield put(getVectorsFailure("e.message"));
  }
}
// vectors with extra data for the dropdown
function* getVectorsExtra({ payload }) {
  try {
    const response = yield call(axios.get, "/auto-suggest-vectors");
    if (response.status === 200) {
      yield put(getVectorsExtraSuccess(response.data));
    }
  } catch (e) {
    yield put(getVectorsExtraFailure("e.message"));
  }
}

function* getVector({ payload }) {
  try {
    const url = `/vectors/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getVectorSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getVectorFailure("e.message"));
  }
}

function* getVectorAttributes({ payload }) {
  try {
    const url = `/vectors/${payload}/attributes`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getVectorAttributesSuccess(response.data));
    }
  } catch (e) {
    yield put(getVectorAttributesFailure("e.message"));
  }
}

function* createVector({ payload }) {
  try {
    const response = yield call(axios.post, "vectors", payload);
    if (response.status === 201) {
      yield put(createVectorSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createVectorFailure("e.message"));
  }
}

function* createVectorAttribute({ payload }) {
  try {
    const url = `/vectors/${payload.id}/attributes`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 201) {
      yield put(createVectorAttributeSuccess());
    }
  } catch (e) {
    yield put(createVectorAttributeFailure("e.message"));
  }
}

function* updateVector({ payload }) {
  try {
    const url = `/vectors/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 204) {
      yield put(updateVectorSuccess());
    }
  } catch (e) {
    yield put(updateVectorFailure("e.message"));
  }
}

function* updateVectorAttribute({ payload }) {
  try {
    const url = `/vectors/${payload.id}/attributes/${payload.attrId}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateVectorAttributeSuccess());
    }
  } catch (e) {
    yield put(updateVectorAttributeFailure("e.message"));
  }
}

function* deleteVectorAttribute({ payload }) {
  try {
    const url = `/vectors/${payload.id}/attributes/${payload.attrId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteVectorAttributeSuccess());
    }
  } catch (e) {
    yield put(deleteVectorAttributeFailure("e.message"));
  }
}

function* deleteVector({ payload }) {
  try {
    const url = `/vectors/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteVectorSuccess());
    }
  } catch (e) {
    yield put(deleteVectorFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getVectorsRequest, getVectors);
  yield takeLatest(getVectorsExtraRequest, getVectorsExtra);
  yield takeLatest(getVectorRequest, getVector);
  yield takeLatest(getVectorAttributesRequest, getVectorAttributes);
  yield takeLatest(createVectorRequest, createVector);
  yield takeLatest(createVectorAttributeRequest, createVectorAttribute);
  yield takeLatest(updateVectorRequest, updateVector);
  yield takeLatest(updateVectorAttributeRequest, updateVectorAttribute);
  yield takeLatest(deleteVectorAttributeRequest, deleteVectorAttribute);
  yield takeLatest(deleteVectorRequest, deleteVector);
}
