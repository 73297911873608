import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";

const StepOptionEdit = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleConfirm,
    title = t("step_option"),
    data,
  } = props;

  const [optionTitle, setOptionTitle] = useState();
  const [optionName, setOptionName] = useState();

  useEffect(() => {
    if (data) {
      setOptionTitle(data.title);
      setOptionName(data.name);
    }
  }, [data]);

  return (
    <>
      <Modal size="md" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group col">
              <label>{t("table_title")}</label>
              <input
                type="text"
                defaultValue={data?.title}
                onChange={(e) => setOptionTitle(e.target.value)}
                className="form-control"
                required
              />
            </div>
            <div className="form-group col">
              <label>{t("identifier")}</label>
              <input
                type="text"
                defaultValue={data?.name}
                onChange={(e) => setOptionName(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant=""
            onClick={handleClose}
            type="button"
            className="outline ml-auto"
          >
            {t("close")}
          </Button>
          <Button
            variant=""
            type="submit"
            className="primary my-0"
            onClick={() =>
              handleConfirm(optionTitle, optionName, data.wf_step_option_id)
            }
          >
            <SaveIcon /> {t("save_option")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

StepOptionEdit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
  handleConfirm: PropTypes.func,
  data: PropTypes.object,
};

export default StepOptionEdit;
