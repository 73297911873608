import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequest } from "redux/auth/action";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card, CardsBottomSide } from "components/auth/Card";
import { emailRegex } from "utility/utility";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { isForgotPasswordSuccess } = useSelector((state) => state.auth);

  const prevIsForgotPasswordSuccess = usePrevious(isForgotPasswordSuccess);

  const [isWaiting, setIsWaiting] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("new_password") + " - Decisimo";
  }, []);

  useEffect(() => {
    if (isForgotPasswordSuccess && prevIsForgotPasswordSuccess === false) {
      setIsWaiting(false);
      toast.success(t("if_we_have_found_your_email"), {
        ...ToastOptions,
        autoClose: 5000,
      });
    }
  }, [isForgotPasswordSuccess]);

  const onHandleForgot = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const email = e.target.email.value;

    if (email.length === 0) {
      toast.error(t("please_fill_out_this_field"), ToastOptions);
      return false;
    } else if (email.match(emailRegex)) {
      setIsWaiting(true);
      dispatch(forgotPasswordRequest(data));
    } else {
      toast.error(t("invalid_email"), ToastOptions);
    }
  };

  return (
    <Card>
      <form className="form-pretty" onSubmit={onHandleForgot}>
        <h1 className="form-pretty__title my-4">{t("reset_your_password")}</h1>
        <label className="sr-only mt-2">{t("email")}</label>
        <input
          type="email"
          name="email"
          className="form-control mb-2"
          placeholder={t("email")}
          required
          autoFocus
        />
        <button className="form-pretty__btn" type="submit">
          {isWaiting ? (
            <div
              className="spinner-border"
              style={{
                width: "1rem",
                height: "1rem",
              }}
              role="status"
            >
              <span className="sr-only">{t("loading")}</span>
            </div>
          ) : (
            t("reset_password")
          )}
        </button>
        <hr />
      </form>
      <CardsBottomSide />
    </Card>
  );
};

export default ForgotPassword;
