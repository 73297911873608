import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createRuleSetRequest,
  deleteRuleSetRequest,
  getRuleSetRequest,
  getRuleSetsRequest,
} from "redux/rulesets/action";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SubHeader from "components/SubHeader";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import {
  Pagination,
  ruleDataConvertJson,
  sortByTitle,
  TableNoItems,
} from "utility/utility";
import CreateNewItem from "components/modals/CreateNewItem";
import TemplateModal from "components/modals/Template";
import { clearTemplates, getTemplatesRequest } from "redux/templates/action";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const RuleSets = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    ruleSet,
    isGetRuleSetSuccess,
    isGetRuleSetError,
    isCreatedRuleSetSuccess,
    isCreatedRuleSetError,
    isGetRuleSetsSuccess,
    isGetRuleSetsError,
    ruleSets,
    isRemovedRuleSetSuccess,
    isRemovedRuleSetError,
    newRuleSetId,
  } = useSelector((state) => state.ruleSets);

  const { templates } = useSelector((state) => state.templates);

  const prevIsGetRuleSetSuccess = usePrevious(isGetRuleSetSuccess);
  const prevIsGetRuleSetError = usePrevious(isGetRuleSetError);
  const prevIsGetRuleSetsSuccess = usePrevious(isGetRuleSetsSuccess);
  const prevIsGetRuleSetsError = usePrevious(isGetRuleSetsError);
  const prevIsRemovedRuleSetSuccess = usePrevious(isRemovedRuleSetSuccess);
  const prevIsRemovedRuleSetError = usePrevious(isRemovedRuleSetError);
  const prevIsCreatedRuleSetSuccess = usePrevious(isCreatedRuleSetSuccess);
  const prevIsCreatedRuleSetError = usePrevious(isCreatedRuleSetError);

  const [selectedRule, setSelectedRule] = useState({});
  const [isCreateNewFromListOpen, setIsCreateNewFromListOpen] = useState(false);
  const [isRuleSetDeleteModalOpen, setIsRuleSetDeleteModalOpen] =
    useState(false);
  const [ruleSetsCopy, setRuleSetsCopy] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItemFromList, setSelectedItemFromList] = useState({});
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  useEffect(() => {
    document.title = t("rules_set_decisimo");

    return () => {
      dispatch(clearTemplates());
    };
  }, []);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getRuleSetsRequest(currentPage.toString()));
    }
  }, [currentPage]);

  useEffect(() => {
    if (isGetRuleSetsSuccess && prevIsGetRuleSetsSuccess === false) {
      setIsCreateNewFromListOpen(false);
      setSelectedItemFromList({});
      setIsLoading(false);
      const ruleSetsClone = structuredClone(ruleSets);
      ruleSetsClone?.sort(sortByTitle);
      setRuleSetsCopy(ruleSetsClone);
    }
  }, [isGetRuleSetsSuccess]);

  useEffect(() => {
    if (isRemovedRuleSetSuccess && prevIsRemovedRuleSetSuccess === false) {
      setIsLoading(false);
      setRuleSetsCopy(
        ruleSetsCopy.filter(
          (rule) => rule.rule_set_id !== selectedRule.rule_set_id
        )
      );
      setSelectedRule({});
      toast.warning(t("ruleset_deleted"), ToastOptions);
    }
  }, [isRemovedRuleSetSuccess]);

  useEffect(() => {
    if (isCreatedRuleSetSuccess && prevIsCreatedRuleSetSuccess === false) {
      if (isTemplatesModalOpen) {
        setIsTemplatesModalOpen(false);
        navigate(`/rule-sets/${newRuleSetId}`);
      } else {
        dispatch(getRuleSetsRequest());
      }
      toast.success(t("ruleset_created"), ToastOptions);
    }
  }, [isCreatedRuleSetSuccess]);

  useEffect(() => {
    if (isGetRuleSetSuccess && prevIsGetRuleSetSuccess === false) {
      const data = new URLSearchParams(new FormData());

      const ruleJsonData = ruleDataConvertJson(data, ruleSet, true, true);

      dispatch(createRuleSetRequest(ruleJsonData));
    }
  }, [isGetRuleSetSuccess]);

  useEffect(() => {
    if (
      (isGetRuleSetsError && prevIsGetRuleSetsError === false) ||
      (isRemovedRuleSetError && prevIsRemovedRuleSetError === false)
    ) {
      setIsLoading(false);
    } else if (
      (isGetRuleSetError && prevIsGetRuleSetError === false) ||
      (isCreatedRuleSetError && prevIsCreatedRuleSetError === false)
    ) {
      setIsCreateNewFromListOpen(false);
    }
  }, [
    isCreatedRuleSetError,
    isGetRuleSetError,
    isGetRuleSetsError,
    isRemovedRuleSetError,
  ]);

  const handleShowDeleteModal = (selectedRuleInfo) => {
    setSelectedRule(selectedRuleInfo);
    setIsRuleSetDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsRuleSetDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedRule({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteRuleSetRequest(selectedRule.rule_set_id));
    setIsRuleSetDeleteModalOpen(false);
  };

  const handleCreateNewFromListConfirm = (id) => {
    dispatch(getRuleSetRequest({ id }));
  };

  const handleOpenTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
    if (templates.length === 0) {
      dispatch(getTemplatesRequest({ entity_type: "rule_set" }));
    }
  };

  return (
    <>
      <SubHeader
        title={t("nav_bar_rule_set")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/rule-set"
              className="btn primary-header border-radius-left-4"
              title={t("add_rule_set")}
            >
              <PlusIcon />
              <span className="ml-2">{t("rule_set")}</span>
            </Link>
            <div className="btn-group">
              <button
                title={t("create_rule_set_template")}
                className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
                data-tip={true}
                data-for="create-rule-set-template"
              >
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                <span>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleOpenTemplatesModal}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("create_from_template")}</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        }
      />

      <div>
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-4 py-3 ">
                {t("table_title")}
              </th>
              <th scope="col" className="th-flow col-lg-4 py-3 ">
                {t("identifier")}
              </th>
              {!isMobile && (
                <th scope="col" className="th-flow col-lg-4 py-3">
                  {t("last_change")}
                </th>
              )}
              <th scope="col" className="th-flow col-lg-2 py-3 " />
            </tr>
          </thead>
          <tbody className="">
            {ruleSetsCopy?.length > 0
              ? ruleSetsCopy.map((ruleSet) => {
                  return (
                    <tr key={ruleSet.rule_set_id} className="td-flow">
                      <td className="">
                        <Link
                          title={ruleSet.name}
                          to={`/rule-sets/${ruleSet.rule_set_id}`}
                        >
                          {ruleSet.title}
                        </Link>
                      </td>
                      <td className="">{ruleSet.name}</td>
                      {!isMobile && (
                        <td className="">
                          {ruleSet.dtime_modified}
                          <HelpCircle
                            title={`${t("modified_by")} ${ruleSet.full_name}`}
                            className="ml-2 cursor-pointer"
                            data-tip={true}
                            data-for={`modified-by-${ruleSet.rule_set_id}`}
                          />
                          <ReactTooltip
                            type="dark"
                            place="right"
                            effect="solid"
                            id={`modified-by-${ruleSet.rule_set_id}`}
                          >
                            {t("modified_by")} {ruleSet.full_name}
                          </ReactTooltip>
                        </td>
                      )}
                      <td className="d-flex align-items-center justify-content-end">
                        <div>
                          <button
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title={t("delete_or_copy_rule_set")}
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>
                          <div
                            className="dropdown-menu table-dropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                const { title, rule_set_id } = ruleSet;
                                setIsCreateNewFromListOpen(true);
                                setSelectedItemFromList({
                                  title,
                                  rule_set_id,
                                });
                              }}
                            >
                              <CopyIcon /> {t("make_a_copy")}
                            </button>
                            {isMobile && (
                              <Link
                                to={`/rule-sets/${ruleSet.rule_set_id}`}
                                className="dropdown-item"
                                title={t("edit_user")}
                              >
                                <EditIcon /> {t("edit")}
                              </Link>
                            )}
                            <div className="dropdown-divider" />
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleShowDeleteModal(ruleSet)}
                            >
                              <TrashIcon /> {t("delete")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetRuleSetsSuccess &&
                ruleSets?.length === 0 && <TableNoItems />}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        itemsLength={ruleSetsCopy?.length}
        setCurrentPage={setCurrentPage}
      />

      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_rule_set")}
        message={
          <span>
            {t("do_you_want_delete")} <b>{selectedRule.title}</b>{" "}
            {t("rule_set_small_caps")}?
          </span>
        }
        open={isRuleSetDeleteModalOpen}
      />

      {isTemplatesModalOpen && (
        <TemplateModal
          open={isTemplatesModalOpen}
          handleClose={() => setIsTemplatesModalOpen(false)}
          type={"rule_set"}
          title={t("rule_set")}
        />
      )}

      <CreateNewItem
        handleClose={() => setIsCreateNewFromListOpen(false)}
        handleConfirm={handleCreateNewFromListConfirm}
        open={isCreateNewFromListOpen}
        listMode={true}
        item={selectedItemFromList}
      />
    </>
  );
};

export default RuleSets;
