import React, { useContext } from "react";
import PropTypes from "prop-types";
import DraggableItem from "./DraggableItem";
import { DraggableFormulaContext } from "context/contexts";

const Item = ({ isDragging, style, nodeRef, item, ...props }) => {
  const { draggingItems } = useContext(DraggableFormulaContext);

  const inlineStyles = {
    opacity: isDragging ? "0.5" : "1",
    transformOrigin: "50% 50%",
    borderRadius: "10px",
    cursor: isDragging ? "grabbing" : "grab",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...style,
  };

  return (
    <div ref={nodeRef} style={inlineStyles} {...props}>
      {isDragging ? (
        <>
          {draggingItems.map((item) => (
            <DraggableItem
              key={item.fce_item_id}
              item={item}
              isDragging={true}
              {...props}
            />
          ))}
        </>
      ) : !draggingItems
          .map((item) => item.fce_item_id)
          .includes(item.fce_item_id) ? (
        <DraggableItem item={item} isDragging={isDragging} {...props} />
      ) : (
        ""
      )}
    </div>
  );
};

Item.propTypes = {
  nodeRef: PropTypes.any,
  isDragging: PropTypes.bool,
  style: PropTypes.object,
  item: PropTypes.object,
};

export default Item;
