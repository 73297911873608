import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddIcon } from "assets/icons/plus-icon.svg";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SliceItem from "./SliceItem"; // Ensure this path is correct
import PropTypes from "prop-types";

const AnalysisRecipeForm = ({
  formData,
  setFormData,
  onSubmit,
  id,
  vectorsData,
}) => {
  const { setIsLoading, setIsEdited, autoSuggestData } =
    useContext(MainContext);
  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSliceIndex, setSelectedSliceIndex] = useState(null);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsEdited(true);
  };

  // Add a new slice
  const handleAddSlice = () => {
    const newSlice = {
      release_recipe_slice_id: `new_${Date.now()}`, // Temporary ID
      name: "",
      dimension: "",
      metric: "",
      type: "",
      aggregation: "sum",
      sequence: formData.slices.length + 1,
    };
    setFormData((prevData) => ({
      ...prevData,
      slices: [...prevData.slices, newSlice],
    }));
    setIsEdited(true);
  };

  // Open delete confirmation modal
  const handleDeleteSlice = (index) => {
    setSelectedSliceIndex(index);
    setIsDeleteModalOpen(true);
  };

  // Confirm deletion of a slice
  const confirmDeleteSlice = () => {
    if (selectedSliceIndex !== null) {
      const updatedSlices = formData.slices.filter(
        (_, i) => i !== selectedSliceIndex
      );
      // Update sequence numbers
      const updatedSlicesWithSequence = updatedSlices.map((slice, idx) => ({
        ...slice,
        sequence: idx + 1,
      }));
      setFormData((prevData) => ({
        ...prevData,
        slices: updatedSlicesWithSequence,
      }));
      setIsDeleteModalOpen(false);
      setSelectedSliceIndex(null);
      setIsEdited(true);
    }
  };

  // Cancel deletion
  const cancelDeleteSlice = () => {
    setIsDeleteModalOpen(false);
    setSelectedSliceIndex(null);
  };

  // Handle changes within a slice
  const handleSliceChange = (index, field, value) => {
    const updatedSlices = [...formData.slices];
    updatedSlices[index] = { ...updatedSlices[index], [field]: value };
    setFormData({ ...formData, slices: updatedSlices });
    setIsEdited(true);
  };

  // Handle form submission (if using internal submit button)
  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation can be added here

    const payload = {
      ...formData,
      slices: formData.slices.map((slice) => ({
        release_recipe_slice_id: slice.release_recipe_slice_id.startsWith(
          "new_"
        )
          ? undefined
          : slice.release_recipe_slice_id,
        name: slice.name,
        dimension: slice.dimension,
        metric: slice.metric,
        type: slice.type,
        aggregation: slice.aggregation,
        sequence: slice.sequence,
      })),
    };

    onSubmit(payload);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row mb-4">
          <div className="col-md col-12">
            <label htmlFor="title">{t("title_capitalized")}</label>
            <input
              type="text"
              id="title"
              name="title"
              className="form-control border-radius-4"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md col-12">
            <label htmlFor="name">{t("name")}</label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control border-radius-4"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <h5>{t("measures")}</h5>
        <table
          className="table table-pretty vertical-align-middle mb-10"
          style={{ overflow: "visible" }}
        >
          {/* Add colgroup to define column widths */}
          <colgroup>
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "30%" }} />
            <col span="1" style={{ width: "30%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "5%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>{t("metric")}</th>
              <th>{t("dimension")}</th>
              <th>{t("aggregation")}</th>
              <th>{t("delete")}</th>
            </tr>
          </thead>
          <tbody>
            {formData.slices.map((slice, index) => (
              <SliceItem
                key={slice.release_recipe_slice_id}
                slice={slice}
                index={index}
                onChange={handleSliceChange}
                onDelete={() => handleDeleteSlice(index)}
                vectorsData={vectorsData}
              />
            ))}
            <tr>
              <td colSpan="6" className="p-0">
                <div className="d-flex justify-content-center add-row-bg py-3 border-bottom-radius-left-right-5 border">
                  <button
                    className="btn outline"
                    onClick={handleAddSlice}
                    title={t("add_slice")}
                    type="button"
                  >
                    <AddIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("add_measure")}</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <DeleteConfirm
        handleClose={cancelDeleteSlice}
        handleConfirm={confirmDeleteSlice}
        title={t("delete_slice")}
        message={
          <span>
            {t("do_you_want_to_delete")}{" "}
            <b>{formData.slices[selectedSliceIndex]?.name || ""}</b>?
          </span>
        }
        open={isDeleteModalOpen}
      />
    </>
  );
};

AnalysisRecipeForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
  vectorsData: PropTypes.array, // Ensure to pass this prop
};

export default AnalysisRecipeForm;
