import { createRuleSetRequest } from "../../redux/rulesets/action";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const createNewRuleSet = (ruleSet, dispatch) => {
  const { t } = useTranslation();

  const [isTranslationLoaded, setTranslationLoaded] = useState(false);

  const cloneOfRuleSetData = structuredClone(ruleSet);
  const data = new URLSearchParams(new FormData());

  data.set("title", `${t("copy_of")} ${cloneOfRuleSetData.title}`);
  data.set("name", `copy-${cloneOfRuleSetData.name}`);
  data.set("verbose", cloneOfRuleSetData.verbose);

  if (cloneOfRuleSetData?.rules[0]?.rule_id !== 0) {
    cloneOfRuleSetData.rules?.forEach((rule) => {
      const randomNumber1 = `new_${Math.round(Math.random() * 10000)}`;

      if (parseInt(rule.active)) {
        data.set(`rule[${randomNumber1}][active]`, "1");
      }
      data.set(`rule[${randomNumber1}][title]`, rule.title);
      data.set(`rule[${randomNumber1}][name]`, rule.name);
      data.set(`rule[${randomNumber1}][type]`, rule.type);
      data.set(`rule[${randomNumber1}][order_index]`, rule.order_index);

      if (rule.condition?.length > 0) {
        rule.condition.forEach((condition) => {
          const randomNumber2 = `new_${Math.round(Math.random() * 10000)}`;
          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][attribute1_path]`,
            condition.attribute1_path
          );
          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][attribute1_vector]`,
            condition.attribute1_vector
          );
          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][attribute1_value]`,
            condition.attribute1_value
          );

          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][operator]`,
            condition.condition_type_name
          );

          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][attribute2_vector]`,
            condition.attribute2_vector
          );
          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][attribute2_value]`,
            condition.attribute2_value
          );
          data.set(
            `rule[${randomNumber1}][condition][${randomNumber2}][attribute2_path]`,
            condition.attribute2_path
          );
        });
      }

      if (rule.action?.length > 0) {
        rule.action.forEach((action) => {
          const randomNumber3 = `new_${Math.round(Math.random() * 1000)}`;
          data.set(
            `rule[${randomNumber1}][action][${randomNumber3}][attribute_path]`,
            action.attribute_path
          );
          data.set(
            `rule[${randomNumber1}][action][${randomNumber3}][action_type]`,
            action.action_type
          );
          data.set(
            `rule[${randomNumber1}][action][${randomNumber3}][action_vector]`,
            action.action_vector
          );
          data.set(
            `rule[${randomNumber1}][action][${randomNumber3}][action_value]`,
            action.action_value
          );
          data.set(
            `rule[${randomNumber1}][action][${randomNumber3}][action_path]`,
            action.action_path
          );
        });
      }
    });
  }

  setTimeout(() => {
    dispatch(createRuleSetRequest({ data }));
  }, 1000);
};

export const types = [
  {
    value: "1",
    label: "Attribute",
  },
  {
    value: "0",
    label: "Value",
  },
  {
    value: "2",
    label: "JSON",
  },
];

export const ruleSetTypes = [
  {
    value: "all",
    label: "all",
  },
  {
    value: "any",
    label: "any",
  },
];

export const buildTree = (items, parentId = null) => {
  const nestedItems = [];

  for (const item of items) {
    if (item.parent_condition_id === parentId) {
      const children = buildTree(items, item.rule_condition_id);
      if (children.length > 0) {
        item.children = children;
      }
      nestedItems.push(item);
    }
  }

  return nestedItems;
};
