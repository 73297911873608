import { handleActions } from "redux-actions";
import {
  getCountriesFailure,
  getCountriesRequest,
  getCountriesSuccess,
  getSubscriptionFailure,
  getSubscriptionRequest,
  getSubscriptionSuccess,
  updateSubscriptionFailure,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
} from "redux/subscription/action";

const initialState = {
  subscriptions: {},
  countries: [],
  isGetSubscriptionSuccess: true,
  isGetSubscriptionError: false,
  isGetCountriesSuccess: true,
  isGetCountriesError: false,
  isUpdateSubscriptionsSuccess: false,
  isUpdateSubscriptionsError: false,
};

const reducer = handleActions(
  {
    [getSubscriptionRequest]: (state) => ({
      ...state,
      isGetSubscriptionSuccess: false,
      isGetSubscriptionError: false,
    }),
    [getSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      subscriptions: payload ?? {},
      isGetSubscriptionSuccess: true,
      isGetSubscriptionError: false,
    }),
    [getSubscriptionFailure]: (state) => ({
      ...state,
      isGetSubscriptionSuccess: false,
      isGetSubscriptionError: true,
    }),
    // get countries
    [getCountriesRequest]: (state) => ({
      ...state,
      isGetCountriesSuccess: false,
      isGetCountriesError: false,
    }),
    [getCountriesSuccess]: (state, { payload }) => ({
      ...state,
      countries: payload,
      isGetCountriesSuccess: true,
      isGetCountriesError: false,
    }),
    [getCountriesFailure]: (state) => ({
      ...state,
      isGetCountriesSuccess: false,
      isGetCountriesError: true,
    }),
    // update subscription
    [updateSubscriptionRequest]: (state) => ({
      ...state,
      isUpdateSubscriptionsSuccess: false,
      isUpdateSubscriptionsError: false,
    }),
    [updateSubscriptionSuccess]: (state) => ({
      ...state,
      isUpdateSubscriptionsSuccess: true,
      isUpdateSubscriptionsError: false,
    }),
    [updateSubscriptionFailure]: (state) => ({
      ...state,
      isUpdateSubscriptionsSuccess: false,
      isUpdateSubscriptionsError: true,
    }),
  },
  initialState
);

export default reducer;
