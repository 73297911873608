import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import SubHeader from "components/SubHeader";
import { generateCloudInstanceName } from "utility/utility";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as PlusIcon } from "assets/icons/custom_plus.svg";
import { ReactComponent as OptionsIcon } from "assets/icons/options.svg";
import { ReactComponent as RuleSetsIcon } from "assets/icons/rule_sets.svg";
import { useTranslation } from "react-i18next";

const RuleSetComponent = ({
  ruleSetData,
  setRuleSetData,
  handleShowRuleSetOptionsModal,
  handleShowRuleSetsModal,
  handleShowRevisionsModal,
  handleShowDeleteModal,
  handleShowTestRuleSetModal,
  handleShowCreateNewFromRevisionModal,
  revisionMode = false,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowRuleSetOptionsModal}
          type="button"
        >
          <OptionsIcon className="feather feather-sliders" /> {t("options")}
        </button>
      ),
      show: !id,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowRevisionsModal}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: revisionMode,
    },
    {
      id: 3,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowCreateNewFromRevisionModal}
          type="button"
        >
          <PlusIcon /> {t("create_new_rule_set")}
        </button>
      ),
      show: !revisionMode,
      divider: false,
    },
    {
      id: 4,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowRuleSetsModal}
          type="button"
        >
          <CopyIcon /> {t("copy_rules")}
        </button>
      ),
      show: revisionMode,
      divider: !revisionMode,
    },
    {
      id: 5,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleShowDeleteModal}
        >
          <DeleteIcon /> {t("delete")}
        </button>
      ),
      show: revisionMode,
      divider: false,
    },
  ];

  const changeVerbose = (verbose) => {
    const clone = structuredClone(ruleSetData);
    clone.verbose = verbose ? "1" : "0";

    setRuleSetData(clone);
  };

  return (
    <>
      <SubHeader
        alt={t("nav_bar_rule_set")}
        icon={id && <RuleSetsIcon />}
        title={
          revisionMode ? (
            <>
              <em>{t("[revision]")}</em>
              {ruleSetData?.content?.title}
            </>
          ) : ruleSetData && id ? (
            ruleSetData.title
          ) : (
            t("new_rule_set")
          )
        }
        actions={
          <>
            {id && !revisionMode && (
              <>
                <button
                  title={t("test_rule_set")}
                  className="btn outline mr-1"
                  type="button"
                  onClick={handleShowTestRuleSetModal}
                >
                  <WindIcon />
                </button>
              </>
            )}
            {!revisionMode && (
              <>
                <button
                  title={t("save_rule_set")}
                  className="btn primary mr-1"
                  type="submit"
                  data-tip={true}
                  data-for="save-rule-set"
                >
                  <SaveIcon />
                </button>
              </>
            )}
            {id && (
              <>
                {id && revisionMode && (
                  <Link to={`/rule-sets/${id}`}>
                    <button className="btn outline mr-1 w-170">
                      <BackIcon />
                      <span className="ml-2">{t("back_rule_set")}</span>
                    </button>
                  </Link>
                )}
                <div>
                  <button
                    role="button"
                    className="btn primary"
                    type="button"
                    title={t("save_rule_settings")}
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <SettingsIcon />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                    {settings.length > 0 &&
                      settings.map((setting) => {
                        if (!setting.show) {
                          return (
                            <span key={setting.id}>
                              {setting.content}
                              {setting.divider && (
                                <div className="dropdown-divider" />
                              )}
                            </span>
                          );
                        }
                      })}
                  </div>
                </div>
              </>
            )}
          </>
        }
      />
      {!id && (
        <div className="row pb-5">
          <div className="col-12 col-lg">
            <label>{t("table_title")}</label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={
                !revisionMode && ruleSetData
                  ? ruleSetData.title
                  : ruleSetData && ruleSetData.content
                  ? ruleSetData.content.title
                  : ""
              }
              disabled={revisionMode ? "disabled" : ""}
              required
            />
          </div>
          <div className="col-12 col-lg">
            <label>
              {t("identifier")}
              <span
                className="badge badge-secondary ml-1"
                data-tip={true}
                data-for="rule-set-use-lowercase"
              >
                ?
              </span>
              <ReactTooltip
                type="dark"
                place="right"
                effect="solid"
                id="rule-set-use-lowercase"
              >
                {t("lowercase_numbers")}
              </ReactTooltip>
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              defaultValue={
                !revisionMode && id
                  ? ruleSetData.name
                  : ruleSetData?.content
                  ? ruleSetData?.content?.name
                  : !id
                  ? generateCloudInstanceName()
                  : ""
              }
              disabled={revisionMode ? "disabled" : ""}
              required
            />
          </div>
          <div className="col-12 col-lg-auto p-4 p-lg-0 text-center">
            <label>
              Record hits
              <span
                className="badge badge-secondary ml-1"
                data-tip={true}
                data-for="save-rule-record"
              >
                ?
              </span>
              <ReactTooltip
                type="dark"
                place="left"
                effect="solid"
                id="save-rule-record"
              >
                {t("whether_record")}
              </ReactTooltip>
            </label>
            <br />
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <label
                className={`btn outline ${
                  (ruleSetData?.verbose === "1" ||
                    ruleSetData?.content?.verbose === "1") &&
                  "active"
                }`}
              >
                <input
                  type="radio"
                  name="verbose"
                  onChange={(e) => changeVerbose(e.target.checked)}
                  defaultChecked={
                    ruleSetData?.verbose === "1" ||
                    ruleSetData?.content?.verbose === "1"
                  }
                  disabled={revisionMode ? "disabled" : ""}
                />
                {t("yes")}
              </label>
              <label
                className={`btn outline ${
                  (ruleSetData?.verbose === "0" ||
                    ruleSetData?.content?.verbose === "0") &&
                  "active"
                }`}
              >
                <input
                  type="radio"
                  name="verbose"
                  onChange={(e) => changeVerbose(!e.target.checked)}
                  defaultChecked={
                    ruleSetData?.verbose === "0" ||
                    ruleSetData?.content?.verbose === "0"
                  }
                  disabled={revisionMode ? "disabled" : ""}
                />
                {t("no")}
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RuleSetComponent.propTypes = {
  ruleSetData: PropTypes.object,
  setRuleSetData: PropTypes.func,
  handleShowRuleSetOptionsModal: PropTypes.func,
  handleShowRuleSetsModal: PropTypes.func,
  handleShowRevisionsModal: PropTypes.func,
  handleShowDeleteModal: PropTypes.func,
  handleShowTestRuleSetModal: PropTypes.func,
  handleShowCreateNewFromRevisionModal: PropTypes.func,
  revisionMode: PropTypes.bool,
};

export default RuleSetComponent;
