import * as React from "react";
import {
  deleteWorkflowStepRequest,
  updateWorkflowStepRequest,
} from "redux/workflows/action";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DOM from "react-dom-factories";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import "assets/css/ReactFlow/custom-node-tree.scss";
import "assets/css/ReactFlow/custom-node-tree-dt.scss";
import { DecisionTreeContext } from "context/DecisionTreeContext";
import { ReactComponent as EditSvg } from "assets/icons/edit_step_flow.svg";
import { ReactComponent as DeleteSvg } from "assets/icons/delete_step_flow.svg";
import TreeNodeModal from "components/modals/decisionTree/TreeNodeModal";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { set } from "lodash";

class DTCustomNodeWidget extends React.PureComponent {
  static contextType = DecisionTreeContext;

  constructor(props) {
    super(props);

    this.state = {
      size: 150,
      node: null,
      isFlowStepModalOpen: false,
      isFlowStepDeleteModalOpen: false,
      id: this.props.node.data.decision_tree_node_id,
      data: this.props.node.data,
      workflowId: this.props.node.data.workflowId,
    };
  }

  render() {
    const {
      setIsEdited,
      setIsLoading,
      setIsCanvasDragging,
      decisionTreeClone,
      setDecisionTreeClone,
      setIsFirstLoad,
      handleNodeDelete,
      deleteConditionThatLeadsToNode,
      revisionMode,
    } = this.context;

    const handleClose = () => {
      this.setState({
        isFlowStepModalOpen: false,
      });
      setIsCanvasDragging(true);
    };

    const handleOpenStepModal = () => {
      this.setState({
        isFlowStepModalOpen: true,
      });
      setIsCanvasDragging(false);
    };

    const keyDownFunc = (event) => {
      if (event.key === "Delete") {
        handleOpenStepDeleteModal();
      }
    };

    const handleOpenStepDeleteModal = () => {
      this.setState({
        isFlowStepDeleteModalOpen: true,
      });
    };

    const handleCloseDelete = () => {
      this.setState({
        isFlowStepDeleteModalOpen: false,
      });
    };

    const handleConfirmDelete = (e) => {
      e.preventDefault();

      setIsEdited(true);

      const id = this.props.node.data.decision_tree_node_id;
      handleNodeDelete(id);

      handleClose();
      handleCloseDelete();
    };

    const operatorSymbols = {
      greater_than: ">",
      greater_than_or_equal_to: ">=",
      less_than: "<",
      less_than_or_equal_to: "<=",
      starts_with: "^",
      ends_with: "$",
      equal_to: "=",
      not_equal_to: "≠",
      closed_interval: "[",
      open_interval: "(",
      left_closed_right_open: "[",
      left_open_right_closed: "(",
      is_null: "∅",
    };

    const interval = [
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ];

    const printNodeLogic = (condition_type, value_a, value_b) => {
      if (!value_a) {
        value_a = "";
      }
      if (!value_b) {
        value_b = "";
      }
      if (condition_type === "is_null") {
        return `${operatorSymbols[condition_type]}`;
      } else if (interval.includes(condition_type)) {
        let right_side =
          condition_type === "open_interval"
            ? ")"
            : condition_type === "left_closed_right_open"
            ? ")"
            : "]";

        return `${operatorSymbols[condition_type]} ${value_a}, ${value_b} ${right_side}`;
      } else {
        return ` x ${operatorSymbols[condition_type]} ${value_a}`;
      }
    };

    const renderNode = () => {
      // Compute the conditional text
      let conditionText = "";

      //get node info from the decisionTreeClone so that we have the latest data
      const nodeData = decisionTreeClone.nodes.find(
        (node) =>
          node.decision_tree_node_id ===
          this.props.node.data.decision_tree_node_id
      );

      const parentNodeData = decisionTreeClone.nodes.find(
        (node) =>
          node.decision_tree_node_id ===
          this.props.node.data.parent_decision_tree_node_id
      );

      if (nodeData?.parent_decision_tree_node_id) {
        //find in conditions of parent node the condition that leads to this node
        const condition = parentNodeData.conditions.find(
          (condition) =>
            condition.leads_to_decision_tree_node_id ===
            this.props.node.data.decision_tree_node_id
        );
        conditionText = printNodeLogic(
          condition?.condition_type,
          condition?.value_a,
          condition?.value_b
        );
      }

      return DOM.div(
        {
          className:
            "align-items-center flowchart-operator-connector-label h-max-content w-max",
        },
        <>
          <div className={`circle ${this.props.node.icon}`} />
          <div className="ml-2">{this.props.node.name}</div>
          <div style={{ fontWeight: "normal", margin: 0, textAlign: "center" }}>
            {nodeData?.parent_decision_tree_node_id ? conditionText : ""}
          </div>

          {this.props.node.data?.actions &&
            this.props.node.data?.actions.length > 0 && (
              <>
                <hr />
                <div style={{ fontWeight: "normal" }} className="pb-2">
                  {this.props.node.data?.actions.map((action, index) => (
                    <div key={index} title={action.attribute_path}>
                      {action.action_value}
                    </div>
                  ))}
                </div>
              </>
            )}
        </>,
        React.createElement("div", {
          "data-name": "right",
          "data-nodeid": this.props.node.getID(),
          className: `port w-auto ${
            window.location.href.includes("revisions")
              ? "pointer-events-none"
              : ""
          }`,
        }),
        React.createElement("div", {
          "data-name": "left",
          "data-nodeid": this.props.node.getID(),
          className: `port w-auto ${
            window.location.href.includes("revisions")
              ? "pointer-events-none"
              : ""
          }`,
        })
      );
    };

    return (
      <>
        {!revisionMode && (
          <div className="step-flow-tooltip">
            <div className="step-flow-tooltip__wrapper">
              <div
                title="Edit node"
                className={`step-flow-tooltip__edit ${
                  !this.props.node?.data?.parent_decision_tree_node_id
                    ? "border-radius-4"
                    : ""
                }`}
                onClick={handleOpenStepModal}
              >
                <EditSvg />
                <p>Edit</p>
              </div>
              {this.props.node?.data?.parent_decision_tree_node_id && (
                <>
                  <div
                    title="Delete node"
                    className="step-flow-tooltip__delete"
                    onClick={handleOpenStepDeleteModal}
                  >
                    <DeleteSvg />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {revisionMode && (
          <>
            <div className="step-flow-tooltip">
              <div className="step-flow-tooltip__wrapper">
                <div
                  title="View node"
                  className={`step-flow-tooltip__edit ${
                    !this.props.node?.data?.parent_decision_tree_node_id
                      ? "border-radius-4"
                      : ""
                  }`}
                  onClick={handleOpenStepModal}
                >
                  <p>View</p>
                </div>
              </div>
            </div>
          </>
        )}
        {DOM.div(
          {
            tabIndex: "0",
            className: `flowchart-operator`,
            onDoubleClick: handleOpenStepModal,
            onKeyDown: keyDownFunc,
            "data-testid": `node-${this.props.node.index}`,
          },
          renderNode()
        )}
        <TreeNodeModal
          handleClose={handleClose}
          handleDelete={handleOpenStepDeleteModal}
          open={this.state.isFlowStepModalOpen}
          data={this.state.data}
        />
        <DeleteConfirm
          handleClose={handleCloseDelete}
          handleConfirm={handleConfirmDelete}
          open={this.state.isFlowStepDeleteModalOpen}
        />
      </>
    );
  }
}

DTCustomNodeWidget.propTypes = {
  size: PropTypes.number,
  node: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.string,
  actions: PropTypes.any,
  id: PropTypes.string,
  match: PropTypes.any,
  params: PropTypes.any,
  updateWorkflowStepRequest: PropTypes.any,
  deleteWorkflowStepRequest: PropTypes.any,
};

export var DTCustomNodeWidgetFactory = React.createFactory(
  connect(null, { updateWorkflowStepRequest, deleteWorkflowStepRequest })(
    DTCustomNodeWidget
  )
);
