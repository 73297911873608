import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createEndPointFailure,
  createEndPointLimitedFlowFailure,
  createEndPointLimitedFlowRequest,
  createEndPointLimitedFlowSuccess,
  createEndPointRequest,
  createEndPointSuccess,
  createEndPointUserNotificationFailure,
  createEndPointUserNotificationRequest,
  createEndPointUserNotificationSuccess,
  deleteEndPointFailure,
  deleteEndPointLimitedFlowFailure,
  deleteEndPointLimitedFlowRequest,
  deleteEndPointLimitedFlowSuccess,
  deleteEndPointRequest,
  deleteEndPointSuccess,
  deleteEndPointUserNotificationFailure,
  deleteEndPointUserNotificationRequest,
  deleteEndPointUserNotificationSuccess,
  getEndPointFailure,
  getEndPointInstancesFailure,
  getEndPointInstancesRequest,
  getEndPointInstancesSuccess,
  getEndPointRequest,
  getEndPointsFailure,
  getEndPointsRequest,
  getEndPointsSuccess,
  getEndPointSuccess,
  updateEndPointFailure,
  updateEndPointRequest,
  updateEndPointSuccess,
  updateEndPointSuccessKeyChanged,
} from "redux/endpoints/action";

function* getEndPoints({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/api-endpoints?page=${payload}` : "/api-endpoints"
    );
    if (response.status === 200) {
      yield put(getEndPointsSuccess(response.data));
    }
  } catch (e) {
    yield put(getEndPointsFailure("e.message"));
  }
}

function* getEndPoint({ payload }) {
  try {
    const url = `api-endpoints/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getEndPointSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getEndPointFailure("e.message"));
  }
}

function* getEndPointInstances() {
  try {
    const response = yield call(axios.get, "endpoint-instances");
    if (response.status === 200) {
      yield put(getEndPointInstancesSuccess(response.data));
    }
  } catch (e) {
    yield put(getEndPointInstancesFailure("e.message"));
  }
}

function* createEndPoint({ payload }) {
  try {
    const response = yield call(axios.post, "api-endpoints", payload);
    if (response.status === 201) {
      yield put(createEndPointSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createEndPointFailure("e.message"));
  }
}

function* updateEndPoint({ payload }) {
  try {
    const url = `api-endpoints/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateEndPointSuccess());
    } else if (response.status === 204) {
      yield put(updateEndPointSuccessKeyChanged());
    }
  } catch (e) {
    yield put(updateEndPointFailure("e.message"));
  }
}

function* deleteEndPoint({ payload }) {
  try {
    const url = `api-endpoints/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteEndPointSuccess());
    }
  } catch (e) {
    yield put(deleteEndPointFailure("e.message"));
  }
}

function* createEndPointLimitedFlow({ payload }) {
  try {
    const url = `api-endpoints/${payload.id}/workflow-limits`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 201) {
      yield put(createEndPointLimitedFlowSuccess());
    }
  } catch (e) {
    yield put(createEndPointLimitedFlowFailure("e.message"));
  }
}

function* deleteEndPointLimitedFlow({ payload }) {
  try {
    const url = `api-endpoints/${payload.id}/workflow-limits/${payload.limitId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteEndPointLimitedFlowSuccess());
    }
  } catch (e) {
    yield put(deleteEndPointLimitedFlowFailure("e.message"));
  }
}

function* createEndPointUserNotification({ payload }) {
  try {
    const url = `api-endpoints/${payload.id}/notification-users`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 201) {
      yield put(createEndPointUserNotificationSuccess());
    }
  } catch (e) {
    yield put(createEndPointUserNotificationFailure("e.message"));
  }
}

function* deleteEndPointUserNotification({ payload }) {
  try {
    const url = `api-endpoints/${payload.id}/notification-users/${payload.userId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteEndPointUserNotificationSuccess());
    }
  } catch (e) {
    yield put(deleteEndPointUserNotificationFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getEndPointsRequest, getEndPoints);
  yield takeLatest(getEndPointRequest, getEndPoint);
  yield takeLatest(getEndPointInstancesRequest, getEndPointInstances);
  yield takeEvery(createEndPointRequest, createEndPoint);
  yield takeLatest(updateEndPointRequest, updateEndPoint);
  yield takeLatest(deleteEndPointRequest, deleteEndPoint);
  yield takeLatest(createEndPointLimitedFlowRequest, createEndPointLimitedFlow);
  yield takeLatest(deleteEndPointLimitedFlowRequest, deleteEndPointLimitedFlow);
  yield takeLatest(
    createEndPointUserNotificationRequest,
    createEndPointUserNotification
  );
  yield takeLatest(
    deleteEndPointUserNotificationRequest,
    deleteEndPointUserNotification
  );
}
