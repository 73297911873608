import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplateRequest,
  getTemplatesRequest,
} from "redux/templates/action";
import usePrevious from "utility/hooks/usePrevious";
import {
  generateEndpointApiName,
  generateUniqueFourDigitNumber,
  JsonToFormData,
  ruleDataConvertJson,
} from "utility/utility";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { createItemCopy } from "components/functions/utils";
import { ReactComponent as UseCaseIcon } from "assets/icons/templateIcons/use_case.svg";
import { ReactComponent as DecisionDesignIcon } from "assets/icons/templateIcons/decision_designer.svg";
import { ReactComponent as RuleSetIcon } from "assets/icons/templateIcons/rule_set.svg";
import { ReactComponent as DecisionTableIcon } from "assets/icons/templateIcons/decision_tables.svg";
import { ReactComponent as DataSourceIcon } from "assets/icons/templateIcons/data_source_small.svg";
import { ReactComponent as DataObjectIcon } from "assets/icons/templateIcons/data_object_small.svg";
import { ReactComponent as FunctionIcon } from "assets/icons/templateIcons/function_small.svg";
import { ReactComponent as PlusIcon } from "assets/icons/templateIcons/plus_icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/templateIcons/close_icon.svg";
import { ReactComponent as CloseSmallIcon } from "assets/icons/templateIcons/close_icon_small.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import templateImg from "assets/images/template.png";
import {
  createItemCopy as createItem,
  updateDecisionTable,
} from "components/decisionTables/utils";
import { MainContext } from "context/contexts";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { createFunctionRequest } from "redux/functions/action";
import { createDataSourceRequest } from "redux/dataSources/action";
import { createRuleSetRequest } from "redux/rulesets/action";
import {
  createDecisionTableRequest,
  getDecisionTableRequest,
} from "redux/decisionTables/action";
import { createVectorRequest } from "redux/vectors/action";
import { useCaseWorkflowRequest } from "redux/workflows/action";
import { createEndPointRequest } from "redux/endpoints/action";
import { createWorkflowCopy } from "../workflow/utils";
import DecisionDesigner from "./DecisionDesigner";
import { useTranslation } from "react-i18next";

const TemplateModal = (props) => {
  const { open, handleClose, type = "use_case", title = "" } = props;
  const { setIsLoading } = useContext(MainContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    allTemplates,
    templates,
    template,
    isGetTemplateSuccess,
    isGetTemplatesSuccess,
    isGetTemplateError,
    isGetTemplatesError,
    useCaseTemplate,
    isCreateTemplateDecisionSuccess,
  } = useSelector((state) => state.templates);

  const { isCreatedRuleSetSuccess, newRuleSetId } = useSelector(
    (state) => state.ruleSets
  );

  const { newFunctionId, isCreatedFunctionSuccess } = useSelector(
    (state) => state.functions
  );

  const { isCreatedDataSourceSuccess, newDataSourceId } = useSelector(
    (state) => state.dataSources
  );

  const { user } = useSelector((state) => state.auth);

  const {
    decisionTable,
    isGetDecisionTableSuccess,
    isCreatedDecisionTableSuccess,
    newDecisionTableId,
    isUpdatedDecisionTableSuccess,
  } = useSelector((state) => state.decisionTables);

  const { isCreatedVectorSuccess, newVectorId } = useSelector(
    (state) => state.vectors
  );

  const { isCreatedWorkflowSuccess, eTag, useCaseWorkflow } = useSelector(
    (state) => state.workflows
  );

  const { isCreatedEndPointSuccess } = useSelector((state) => state.endPoints);

  const [createTemplateLoading, setCreateTemplateLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [isUseCase, setIsUseCase] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [tab, setTab] = useState(type);
  const [templateTitle, setTemplateTitle] = useState(title);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [templatesNames, setTemplatesNames] = useState([
    {
      icon: <UseCaseIcon />,
      label: t("use_case"),
      value: "use_case",
    },
    {
      icon: <RuleSetIcon />,
      label: t("rule_set"),
      value: "rule_set",
    },
    {
      icon: <DecisionTableIcon />,
      label: t("decision_table"),
      value: "decision_table",
    },
    {
      icon: <DataSourceIcon />,
      label: t("data_source"),
      value: "ext_data",
    },
    {
      icon: <DataObjectIcon />,
      label: t("data_object"),
      value: "data_object",
    },
    {
      icon: <FunctionIcon />,
      label: t("function"),
      value: "function",
    },
  ]);

  const prevIsCreatedEndPointSuccess = usePrevious(isCreatedEndPointSuccess);
  const prevIsCreatedRuleSetSuccess = usePrevious(isCreatedRuleSetSuccess);
  const prevIsCreatedWorkflowSuccess = usePrevious(isCreatedWorkflowSuccess);
  const prevIsGetTemplateSuccess = usePrevious(isGetTemplateSuccess);
  const prevIsGetTemplatesSuccess = usePrevious(isGetTemplatesSuccess);
  const prevIsCreatedFunctionSuccess = usePrevious(isCreatedFunctionSuccess);
  const prevIsCreatedDataSourceSuccess = usePrevious(
    isCreatedDataSourceSuccess
  );
  const prevIsUpdatedDecisionTableSuccess = usePrevious(
    isUpdatedDecisionTableSuccess
  );
  const prevIsGetTemplateError = usePrevious(isGetTemplateError);
  const prevIsGetTemplatesError = usePrevious(isGetTemplatesError);
  const prevIsCreatedDecisionTableSuccess = usePrevious(
    isCreatedDecisionTableSuccess
  );
  const prevIsGetDecisionTableSuccess = usePrevious(isGetDecisionTableSuccess);
  const prevIsCreatedVectorSuccess = usePrevious(isCreatedVectorSuccess);
  const prevIsCreateTemplateDecisionSuccess = usePrevious(
    isCreateTemplateDecisionSuccess
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(getTemplatesRequest({ entity_type: tab }));
  }, [tab]);

  useEffect(() => {
    if (isCreatedWorkflowSuccess && prevIsCreatedWorkflowSuccess === false) {
      navigate(`/workflows/${eTag}`);
    }
  }, [isCreatedWorkflowSuccess]);

  useEffect(() => {
    setSelectedTemplateId(null);
    setCreateTemplateLoading(false);
    if (isCreatedFunctionSuccess && prevIsCreatedFunctionSuccess === false) {
      if (!isUseCase) {
        navigate(`/functions/${newFunctionId}`);
      } else {
        toast.success(t("function_created"), ToastOptions);
      }
    } else if (
      isCreatedRuleSetSuccess &&
      prevIsCreatedRuleSetSuccess === false
    ) {
      if (!isUseCase) {
        navigate(`/rule-sets/${newRuleSetId}`);
      } else {
        toast.success(t("ruleset_created"), ToastOptions);
      }
    } else if (
      isCreatedDataSourceSuccess &&
      prevIsCreatedDataSourceSuccess === false
    ) {
      if (!isUseCase) {
        navigate(`/external-data-source/${newDataSourceId}`);
      } else {
        toast.success(t("data_source_created"), ToastOptions);
      }
    } else if (
      isCreatedDecisionTableSuccess &&
      prevIsCreatedDecisionTableSuccess === false
    ) {
      dispatch(getDecisionTableRequest(newDecisionTableId));
    } else if (
      isGetDecisionTableSuccess &&
      prevIsGetDecisionTableSuccess === false
    ) {
      const templateClone = { ...template };
      updateDecisionTable(
        decisionTable,
        JSON.parse(templateClone.content).rows,
        newDecisionTableId,
        dispatch
      );
    } else if (
      isUpdatedDecisionTableSuccess &&
      prevIsUpdatedDecisionTableSuccess === false
    ) {
      if (!isUseCase) {
        navigate(`/decision-tables/${newDecisionTableId}`);
      } else {
        toast.success(t("decision_table_created"), ToastOptions);
      }
    } else if (isCreatedVectorSuccess && prevIsCreatedVectorSuccess === false) {
      if (!isUseCase) {
        navigate(`/data-objects/${newVectorId}`);
      } else {
        toast.success(t("data_object_added"), ToastOptions);
      }
    } else if (
      isCreatedEndPointSuccess &&
      prevIsCreatedEndPointSuccess === false
    ) {
      if (isUseCase) {
        toast.success(t("endpoint_created"), ToastOptions);
      }
    }
  }, [
    isCreatedEndPointSuccess,
    isUpdatedDecisionTableSuccess,
    isCreatedDataSourceSuccess,
    isCreatedRuleSetSuccess,
    isCreatedFunctionSuccess,
    isGetDecisionTableSuccess,
    isCreatedDecisionTableSuccess,
    isCreatedVectorSuccess,
    newDecisionTableId,
  ]);

  useEffect(() => {
    if (
      (isGetTemplatesSuccess && prevIsGetTemplatesSuccess === false) ||
      (isGetTemplateSuccess && prevIsGetTemplateSuccess === false) ||
      (isGetTemplateError && prevIsGetTemplateError === false) ||
      (isGetTemplatesError && prevIsGetTemplatesError === false)
    ) {
      setIsLoading(false);
      setCreateTemplateLoading(false);
    }
  }, [
    isGetTemplatesSuccess,
    isGetTemplatesError,
    isGetTemplateSuccess,
    isGetTemplateError,
  ]);

  useEffect(() => {
    if (templates?.length > 0) {
      setFiltered(templates);
    } else {
      setFiltered([]);
    }
  }, [templates]);

  useEffect(() => {
    if (useCaseTemplate.length === useCaseWorkflow.entitiesLenght) {
      useCaseTemplate.forEach((data, index) => {
        const formData = new URLSearchParams(new FormData());
        switch (data.type) {
          case "data_object": {
            formData.set("title", data.title);
            formData.set("json_vector", data.content);
            dispatch(createVectorRequest(formData));
            break;
          }
          case "rule_set": {
            let templateClone = structuredClone(data.content);
            templateClone = JSON.parse(templateClone);
            templateClone.name += `-${generateUniqueFourDigitNumber()}`;
            templateClone["rule"] = templateClone["rules"];
            delete templateClone["rules"];
            if (templateClone?.rule?.length > 0) {
              templateClone?.rule?.forEach((rule) => {
                rule.condition?.forEach((cond) => {
                  cond["operator"] = cond["condition_type_name"];
                  delete cond["condition_type_name"];
                });
              });
            }
            const ruleJsonData = ruleDataConvertJson(
              formData,
              templateClone,
              true
            );

            dispatch(
              createRuleSetRequest({
                ruleJsonData,
                rule_set_id: data.rule_set_id,
              })
            );
            break;
          }
          case "decision_table": {
            localStorage.setItem("decision_table", data.content);
            let cloned = structuredClone(data.content);
            const decisionTableDefinition = JSON.parse(cloned);
            formData.set("title", decisionTableDefinition.title);
            formData.set(
              "name",
              decisionTableDefinition.name +
                `-${generateUniqueFourDigitNumber()}`
            );

            if (
              decisionTableDefinition?.cols?.length > 0 &&
              decisionTableDefinition?.cols[0].decision_table_column_id !== null
            ) {
              decisionTableDefinition?.cols?.forEach((col) => {
                const randomNumber = `new_${Math.round(Math.random() * 10000)}`;
                formData.set(`column[${randomNumber}][title]`, col.title);
                formData.set(
                  `column[${randomNumber}][attribute_path]`,
                  col.attribute_path
                );
                formData.set(`column[${randomNumber}][role]`, col.role);
              });
            }

            dispatch(
              createDecisionTableRequest({
                data: formData,
                decision_table_id: data.decision_table_id,
                useCase: true,
              })
            );
            break;
          }
          case "external_data": {
            const clone = JSON.parse(structuredClone(data.content));
            clone.name += `-${generateUniqueFourDigitNumber()}`;
            JsonToFormData(formData, clone);

            dispatch(
              createDataSourceRequest({
                data: formData,
                external_data_id: data.external_data_id,
              })
            );
            break;
          }
          default:
            break;
        }

        if (useCaseTemplate.length - 1 === index) {
          createWorkflowCopy(useCaseWorkflow, dispatch, true);
        }
      });
    }
  }, [useCaseTemplate]);

  const handleGetTemplate = (id) => {
    if (selectedTemplateId !== id) {
      setCreateTemplateLoading(true);
      setSelectedTemplateId(id);
      dispatch(
        getTemplateRequest({
          service_template_id: id,
        })
      );
    }
  };

  const handleTemplateConfirm = () => {
    if (Object.keys(template).length > 0) {
      setIsLoading(true);
      setModalLoading(true);
      setCreateTemplateLoading(true);
      const formData = new URLSearchParams(new FormData());
      if (tab === "rule_set") {
        let cloneOfTemplate = structuredClone(template?.content);
        cloneOfTemplate = JSON.parse(cloneOfTemplate);
        cloneOfTemplate["rule"] = cloneOfTemplate["rules"];
        delete cloneOfTemplate["rules"];
        if (cloneOfTemplate?.rule?.length > 0) {
          cloneOfTemplate?.rule?.forEach((rule) => {
            rule.condition?.forEach((cond) => {
              cond["operator"] = cond["condition_type_name"];
              delete cond["condition_type_name"];
            });
          });
        }
        JsonToFormData(formData, cloneOfTemplate);
      } else if (tab === "use_case") {
        setIsUseCase(true);
        let useCase = structuredClone(template?.content);
        useCase = JSON.parse(useCase);
        useCase.workflow.name += `-${generateUniqueFourDigitNumber()}`;
        useCase.workflow.entitiesLenght =
          useCase.data_objects.length +
          useCase.decision_tables.length +
          useCase.rule_sets.length;

        if (useCase.external_data) {
          useCase.workflow.entitiesLenght += useCase.external_data.length;
        }

        dispatch(useCaseWorkflowRequest(useCase.workflow));

        useCase.data_objects.forEach((data) => {
          const template = allTemplates.find(
            (temp) => temp.name === data.template_name
          );

          dispatch(
            getTemplateRequest({
              service_template_id: template?.service_template_id,
              type: "data_object",
              data_object_id: template?.data_object_id,
            })
          );
        });

        useCase.decision_tables.forEach((data) => {
          const template = allTemplates.find(
            (temp) => temp.name === data.template_name
          );

          dispatch(
            getTemplateRequest({
              service_template_id: template.service_template_id,
              type: "decision_table",
              decision_table_id: data.decision_table_id,
            })
          );
        });

        if (useCase.external_data) {
          useCase.external_data.forEach((data) => {
            const template = allTemplates.find(
              (temp) => temp.name === data.template_name
            );
            dispatch(
              getTemplateRequest({
                service_template_id: template.service_template_id,
                type: "external_data",
                external_data_id: data.external_data_id,
              })
            );
          });
        }

        useCase.rule_sets.forEach((data) => {
          const template = allTemplates.find(
            (temp) => temp.name === data.template_name
          );
          dispatch(
            getTemplateRequest({
              service_template_id: template.service_template_id,
              type: "rule_set",
              rule_set_id: data.rule_set_id,
            })
          );
        });

        useCase.endpoints.forEach((data) => {
          const randomApiKey =
            Math.random().toString(36).substring(2) +
            Math.random().toString(36).substring(2) +
            Math.random().toString(36).substring(2);

          const formData = new URLSearchParams(new FormData());

          formData.set("name", data.name + "-" + generateEndpointApiName());
          formData.set("api_key", randomApiKey);
          formData.set("endpoint_instance_id", "1");

          dispatch(createEndPointRequest(formData));
        });

        return false;
      } else if (tab === "data_object") {
        formData.set("title", template.title);
        formData.set("json_vector", template.content);
      } else {
        let clone = structuredClone(template?.content);

        if (tab === "function") {
          createItemCopy(JSON.parse(clone), dispatch, true);
        } else if (tab === "decision_table") {
          createItem(JSON.parse(clone), dispatch, true);
        } else {
          const parsedData = JSON.parse(clone);
          if (tab === "ext_data") {
            parsedData.name += `-${generateUniqueFourDigitNumber()}`;
          }
          JsonToFormData(formData, parsedData);
        }
      }

      if (!["function", "decision_table"].includes(tab)) {
        handleConfirm(formData, tab, JSON.parse(template.content));
      }
    } else {
      toast.error("Something went wrong...", ToastOptions);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const templatesData = structuredClone(templates);
    const filtered = templatesData.filter((f) => {
      const title = f.title;
      if (
        title.toUpperCase().indexOf(e.target.filter.value.toUpperCase()) > -1
      ) {
        return f;
      }
    });
    setFiltered(filtered);
  };

  useEffect(() => {
    const aiDecisionDesignerModule = user?.license?.active_modules.find(
      (module) => module?.module_name === "ai_decision_designer"
    );
    if (
      aiDecisionDesignerModule?.module_name === "ai_decision_designer" &&
      user
    ) {
      const tmp = [...templatesNames];
      tmp.unshift({
        icon: <DecisionDesignIcon />,
        label: t("decision_designer"),
        value: "decision_designer",
      });
      setTemplatesNames(tmp);
    }
  }, [user]);

  const handleChangeType = (type) => {
    setTemplateTitle(
      templatesNames.find((template) => template.value === type).label
    );

    if (type !== tab) {
      if (type === "use_case") {
        dispatch(
          getTemplatesRequest({ isGetAllTemplates: true, entity_type: "" })
        );
      } else {
        dispatch(getTemplatesRequest({ entity_type: type }));
      }
    }

    setTab(type);
    setSelectedTemplateId(null);
    setCreateTemplateLoading(false);
  };

  const handleConfirm = (data, tab, ruleSet) => {
    switch (tab) {
      case "function":
        dispatch(createFunctionRequest({ data }));
        break;
      case "ext_data":
        dispatch(createDataSourceRequest({ data }));
        break;
      case "rule_set":
        const ruleJsonData = ruleDataConvertJson(data, ruleSet, true);
        dispatch(createRuleSetRequest({ ruleJsonData }));
        break;
      case "decision_table":
        dispatch(createDecisionTableRequest({ data }));
        break;
      case "data_object":
        dispatch(createVectorRequest(data));
        break;
      default:
        break;
    }
  };

  const getTemplateImage = (svg) => {
    const imageDataUrl = `data:image/svg+xml;base64,${btoa(svg)}`;

    return <img src={imageDataUrl} width="100px" height="125px" />;
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        size="xl"
        className="template-modal"
      >
        {modalLoading && (
          <div className="overlay">
            <ClipLoader color={"#000"} loading={modalLoading} size={150} />
            <div>{t("loading")}</div>
          </div>
        )}
        <Modal.Body className="p-0">
          <div className="d-flex justify-content-between py-3 px-4">
            <h3 className="primary-text mb-0">{t("your_project")}</h3>
            <button
              type="button bg-gray mr-5"
              className="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="d-flex border-top modal-container">
            <div className="bg-gray template-sidebar tab-border-right">
              <div className="nav-tabs mt-3" id="templates-tab" role="tablist">
                {templatesNames?.map((temp, i) => {
                  return (
                    <div key={temp.value} className="nav-item">
                      <button
                        id={temp.value}
                        data-toggle="tab"
                        role="tab"
                        aria-controls={temp.value}
                        onClick={() => handleChangeType(temp.value)}
                        aria-selected="true"
                        className={`bg-gray ${
                          tab === temp.value ? "active" : ""
                        } w-100 btn-nav font-size-14 bg-transparent border-bottom-nav btn text-gray text-left menu-item-btn`}
                      >
                        {temp.icon}
                        <span className="ml-2">{temp.label}</span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={`${
                isShowSuccess
                  ? "d-flex justify-content-center align-items-center"
                  : ""
              } template-content`}
            >
              <div className="p-3">
                {isCreateTemplateDecisionSuccess &&
                prevIsCreateTemplateDecisionSuccess === false ? null : (
                  <>
                    {tab !== "decision_designer" ? (
                      <div className="py-4 align-items-center d-flex justify-content-between">
                        <h3 className="pl-3">
                          {!templateTitle ? title : templateTitle}
                        </h3>
                        <form
                          className="col-md-4 input-group mt-2 pl-0 pl-md-3 pr-0"
                          onSubmit={handleFilter}
                        >
                          <input
                            type="text"
                            name="filter"
                            className="form-control border-radius-left-4"
                            placeholder={t("filter")}
                          />
                          <div className="input-group-append">
                            <button className="border-0 p-0 border-radius-right-4">
                              <span className="input-group-text h-100">
                                <SearchIcon />
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </>
                )}
                <div className="tab-content" id="templates-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id={tab}
                    role="tabpanel"
                    aria-labelledby={tab}
                    style={{
                      height: tab === "decision_designer" ? "unset" : "493px",
                      overflowX: "hidden",
                    }}
                  >
                    {tab === "decision_designer" ? (
                      <DecisionDesigner
                        isShowSuccess={isShowSuccess}
                        setIsShowSuccess={setIsShowSuccess}
                      />
                    ) : (
                      <div className="row mx-0">
                        {filtered.map((template) => {
                          return (
                            <div
                              onClick={() =>
                                handleGetTemplate(template.service_template_id)
                              }
                              key={template.service_template_id}
                              className={`col-6 align-items-stretch mt-3 clickable template-item ${
                                selectedTemplateId ===
                                template.service_template_id
                                  ? "template"
                                  : ""
                              }`}
                            >
                              <div className="card-horizontal align-items-center">
                                <div className="img-square-wrapper">
                                  {template.icon ? (
                                    getTemplateImage(template.icon)
                                  ) : (
                                    <img src={templateImg} />
                                  )}
                                </div>
                                <div className="card-body">
                                  <p className="card-title font-size-18 fw-600 text-body text-break">
                                    {template.title}
                                  </p>
                                  <p className="card-text">
                                    {template.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={handleClose}>
            <CloseSmallIcon /> <span className="ml-2">{t("close")}</span>
          </Button>
          {tab !== "decision_designer" ? (
            <Button
              variant=""
              className={`${
                templates?.length === 0 ||
                !selectedTemplateId ||
                createTemplateLoading
                  ? "template-btn-bg"
                  : "template-btn"
              }`}
              type="submit"
              onClick={handleTemplateConfirm}
              disabled={
                (templates?.length === 0 ||
                  !selectedTemplateId ||
                  createTemplateLoading) &&
                "disabled"
              }
            >
              <PlusIcon />{" "}
              <span className="ml-2">{t("create_from_template")}</span>
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

TemplateModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
};

export default TemplateModal;
