import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRuleRevisionRequest } from "redux/rulesets/action";
import RuleSetContent from "components/rulesets/RuleSetContent";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { useTranslation } from "react-i18next";

const RuleRevision = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { ruleRevision, isGetRuleRevisionSuccess, isGetRuleRevisionError } =
    useSelector((state) => state.ruleSets);

  const prevIsGetRuleRevisionSuccess = usePrevious(isGetRuleRevisionSuccess);
  const prevIsGetRuleRevisionError = usePrevious(isGetRuleRevisionError);

  useEffect(() => {
    document.title = t("rule_set_revision");
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getRuleRevisionRequest([id, revisionId]));
  }, [revisionId]);

  useEffect(() => {
    if (
      (isGetRuleRevisionSuccess && prevIsGetRuleRevisionSuccess === false) ||
      (isGetRuleRevisionError && prevIsGetRuleRevisionError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetRuleRevisionSuccess, isGetRuleRevisionError]);

  return (
    <>
      <RuleSetContent ruleSetData={ruleRevision} revisionMode={true} />
    </>
  );
};

export default RuleRevision;
