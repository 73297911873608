import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getUserAccountRequest,
  updateUserAccountRequest,
} from "redux/user/action";
import { getLanguagesRequest } from "redux/auth/action";
import { getUserRolesRequest } from "redux/roleRights/action";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import SubHeader from "components/SubHeader";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";
import { parse } from "json5";
import { use } from "react-dom-factories";

const Account = () => {
  const { id } = useParams();
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const [dataSourceRef] = useCtrlSHandler(saveUserAccount);
  const { t } = useTranslation();

  const { languages } = useSelector((state) => state.auth);

  const { userAccount, isGetUserAccountSuccess, isChangeAccountSuccess } =
    useSelector((state) => state.users);

  const { userRoles, isGetUserRolesSuccess } = useSelector((state) => {
    return state.roleRights;
  });

  //two factor require
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);

  const prevIsChangeAccountSuccess = usePrevious(isChangeAccountSuccess);
  const prevIsGetUserAccountSuccess = usePrevious(isGetUserAccountSuccess);
  const prevIsGetUserRolesSuccess = usePrevious(isGetUserRolesSuccess);

  const [userAccountData, setUserAccountData] = useState({});

  useEffect(() => {
    document.title = t("user_decisimo");
    setIsLoading(true);
    dispatch(getUserAccountRequest(id));
    dispatch(getUserRolesRequest());
    dispatch(getLanguagesRequest());
  }, []);

  useEffect(() => {
    if (isGetUserAccountSuccess && prevIsGetUserAccountSuccess === false) {
      setIsLoading(false);
      setUserAccountData(userAccount);

      const twoFactorRequired = parse(userAccount.two_factor_required);

      setTwoFactorRequired(!!twoFactorRequired);
    }
  }, [isGetUserAccountSuccess]);

  useEffect(() => {
    if (isGetUserRolesSuccess && prevIsGetUserRolesSuccess === false) {
      setIsLoading(false);
    }
  }, [isGetUserRolesSuccess]);

  useEffect(() => {
    if (isChangeAccountSuccess && prevIsChangeAccountSuccess === false) {
      setIsLoading(false);
      toast.success(t("user_account_changed"), ToastOptions);
    }
  }, [isChangeAccountSuccess]);

  const getRoleCheckedValue = (role) => {
    return (
      userAccountData &&
      userAccountData.access_roles?.includes(parseInt(role.access_role_id))
    );
  };

  const handleChangeLanguage = (newLanguageId) => {
    // Update the userAccountData state with the new language_id
    setUserAccountData((prevState) => ({
      ...prevState,
      language_id: newLanguageId,
    }));
  };

  function saveUserAccount(target) {
    const data = new URLSearchParams(new FormData(target));
    let accessRoleId;

    if (target.access_role_id.length) {
      accessRoleId = [...target.access_role_id];
    } else {
      accessRoleId = [target.access_role_id];
    }
    data.delete("access_role_id");

    const roles = [];
    accessRoleId.forEach((roleId) => {
      if (roleId.checked) {
        roles.push(roleId.value);
        data.append("access_role_id[]", roleId.value);
      }
    });

    if (roles.length > 0) {
      setIsLoading(true);
      dispatch(updateUserAccountRequest({ data, id }));
    } else {
      toast.error(t("check_role"), ToastOptions);
    }
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          saveUserAccount(e.target);
        }}
      >
        <SubHeader
          title={t("user_account")}
          actions={
            <>
              <Link to="/user-accounts">
                <button className="btn outline-header">
                  <BackIcon />{" "}
                  <span className="ml-2">{t("back_to_users")}</span>
                </button>
              </Link>

              <button className="btn primary-header mx-2" type="submit">
                <SaveIcon />
              </button>
            </>
          }
        />
        <div className="row mb-5">
          <div className="col-md col-12 mb-md-0 mb-3">
            <label>{t("full_name")}</label>
            <input
              type="text"
              className="form-control"
              name="full_name"
              defaultValue={userAccountData?.full_name}
              required
            />
          </div>
          <div className="col-md col-12">
            <label>{t("email")}</label>
            <input
              type="email"
              className="form-control"
              name="email"
              defaultValue={userAccountData?.email}
              required
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-3">
            <label>{t("language")}</label>
            <select
              name="language_id"
              value={userAccountData?.language_id}
              className="form-control mb-2"
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              {languages?.length > 0 &&
                languages.map((lang) => (
                  <option key={lang.language_id} value={lang.language_id}>
                    {lang.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md col-12">
            <label>Security</label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                id="two_factor_required"
                name="two_factor_required"
                className="custom-control-input"
                checked={twoFactorRequired}
                onChange={(e) => setTwoFactorRequired(e.target.checked)}
              />
              <label
                htmlFor="two_factor_required"
                className="custom-control-label ml-2"
              >
                {t("two_factor_required_for_this_user")}
              </label>
            </div>
          </div>
        </div>
        <h3>{t("roles")}</h3>
        <div className="table-responsive">
          <table className="table table-pretty vertical-align-middle">
            <thead className="bg-transparent border-0">
              <tr>
                <th>{t("access_role")}</th>
                <th>{t("is_member")}</th>
              </tr>
            </thead>
            <tbody id="user-roles">
              {userRoles?.length > 0 &&
                userRoles.map((role) => {
                  return (
                    <tr key={role.access_role_id}>
                      <td>{role.title}</td>
                      <td>
                        <input
                          type="checkbox"
                          value={role.access_role_id}
                          name="access_role_id"
                          defaultChecked={getRoleCheckedValue(role)}
                          key={role.access_role_id}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default Account;
