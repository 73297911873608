import React, { memo, useRef } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PropTypes from "prop-types";
import { ReactComponent as GripVertical } from "assets/icons/grip-vertical.svg";
import { ReactComponent as DeleteSvg } from "assets/icons/delete_gray.svg";
import { ContextMenuTrigger } from "react-contextmenu";
import Column from "./Column";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SortableItem = ({
  contextMenuIds,
  setContextMenuIds,
  index,
  row,
  id,
  revisionMode,
  handleShow,
  handleDataFromChild,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id });
  const { revisionId } = useParams();
  const inputRef = useRef([]);
  const { t } = useTranslation();

  const style = {
    transform: CSS.Transform.toString(transform),
    touchAction: "none",
    transition,
  };

  return (
    <tr
      ref={setNodeRef}
      {...attributes}
      style={style}
      className={`table-row ${
        !contextMenuIds?.dt_row_column_id &&
        contextMenuIds?.decision_table_row_id === id
          ? "border border-2 border-danger"
          : ""
      }`}
    >
      <td
        ref={setActivatorNodeRef}
        className="text-start row-index cursor-move position-relative"
      >
        {!revisionId && (
          <GripVertical {...listeners} className="cursor-move grip-svg" />
        )}
        <ContextMenuTrigger holdToDisplay={-1} id={id}>
          {index + 1}.
        </ContextMenuTrigger>
      </td>
      {row.cols?.map((col, index1) => {
        return (
          <Column
            handleDataFromChild={handleDataFromChild}
            inputRef={inputRef}
            index={index1}
            rowIndex={index}
            key={col.dt_row_column_id}
            col={col}
            colsClone={row.cols}
            prevCol={row.cols[index - 1] ? row.cols[index - 1] : {}}
            decision_table_row_id={row.decision_table_row_id}
            revisionMode={revisionMode}
            contextMenuIds={contextMenuIds}
            setContextMenuIds={setContextMenuIds}
            revisionId={revisionId}
          />
        );
      })}
      {!revisionMode && (
        <td
          title={t("delete_decision")}
          className="bg-light text-center"
          onClick={() => handleShow(row.decision_table_row_id)}
        >
          <DeleteSvg className="cursor-pointer" />
        </td>
      )}
    </tr>
  );
};

SortableItem.propTypes = {
  index: PropTypes.number,
  id: PropTypes.any,
  row: PropTypes.object,
  revisionMode: PropTypes.bool,
  contextMenuIds: PropTypes.object,
  setContextMenuIds: PropTypes.func,
  handleShow: PropTypes.func,
  inputRef: PropTypes.object,
  handleDataFromChild: PropTypes.func,
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.index === nextProps.index &&
    nextProps.contextMenuIds.decision_table_row_id !== nextProps.id &&
    prevProps.contextMenuIds.decision_table_row_id !== nextProps.id &&
    JSON.stringify(prevProps.row.cols.map((col) => col.operator)) ===
      JSON.stringify(nextProps.row.cols.map((col) => col.operator)) &&
    JSON.stringify(prevProps.row.cols.map((col) => col.value_a)) ===
      JSON.stringify(nextProps.row.cols.map((col) => col.value_a)) &&
    JSON.stringify(prevProps.row.cols.map((col) => col.value_b)) ===
      JSON.stringify(nextProps.row.cols.map((col) => col.value_b)) &&
    JSON.stringify(prevProps.row.cols.map((col) => col.dt_row_column_id)) ===
      JSON.stringify(nextProps.row.cols.map((col) => col.dt_row_column_id))
  );
};

export default memo(SortableItem, comparisonFn);
