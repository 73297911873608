import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagesRequest, signUpRequest } from "redux/auth/action";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card, CardsBottomSide } from "components/auth/Card";
import "components/auth/register.scss";
import { emailRegex } from "utility/utility";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const maxPasswordLength = 64;
const minPasswordLength = 8;

const Register = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isSignUpRequest,
    isSignUpSuccess,
    isSignUpFailure,
    statueCode,
    languages,
  } = useSelector((state) => state.auth);
  const prevIsSignUpSuccess = usePrevious(isSignUpSuccess);
  const prevIsSignUpFailure = usePrevious(isSignUpFailure);
  const [secret, setSecret] = useState(1);

  useEffect(() => {
    document.title = "Sign up - Decisimo";
  }, []);

  useEffect(() => {
    if (isSignUpSuccess && prevIsSignUpSuccess === false) {
      toast.success(t("joining_us"), ToastOptions);
    }
  }, [isSignUpSuccess, prevIsSignUpSuccess]);

  useEffect(() => {
    if (
      isSignUpFailure &&
      prevIsSignUpFailure === false &&
      statueCode !== 405
    ) {
      toast.error(t("unable_sign_up"), ToastOptions);
    }
  }, [isSignUpFailure]);

  useEffect(() => {
    dispatch(getLanguagesRequest());
  }, []);

  const onHandleRegister = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const fullName = e.target.full_name.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const agreeTerms = e.target.agree_terms.checked;
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const selectedLanguageCode = languages.find(
      (item) => item.language_id === e.target.language_id.value
    );
    localStorage.setItem("user_language", selectedLanguageCode?.code);

    data.append("time_zone", time_zone);

    if (fullName.length < 3) {
      return toast.error(t("fill_full_name"), ToastOptions);
    }

    if (email.length > 0 && email.match(emailRegex)) {
      if (
        password.length > minPasswordLength &&
        password.length < maxPasswordLength &&
        password
      ) {
        if (!agreeTerms) {
          return toast.error(t("agree_terms"), ToastOptions);
        } else {
          dispatch(signUpRequest(data));
        }
      } else {
        return toast.error(t("password_length"), ToastOptions);
      }
    } else {
      return toast.error(t("invalid_email_address"), ToastOptions);
    }

    if (password.length === 0 || email.length === 0) {
      return toast.error(t("fill_out"), ToastOptions);
    } else if (password.length <= 8 || password.length >= 100) {
      return toast.error(t("password_length"), ToastOptions);
    }
  };

  const togglePassword = () => {
    if (secret === 1) {
      setSecret(0);
      return;
    }
    setSecret(1);
  };

  const handleChangeLanguage = (e) => {
    const selectedLanguage = languages.find((item) => item.language_id === e);
    i18n.changeLanguage(selectedLanguage.code);
  };

  return (
    <>
      <Card>
        <form className="form-pretty" onSubmit={onHandleRegister}>
          <h1 className="form-pretty__title mt-4 mb-4">{t("set_up")}</h1>
          <div>
            <label className="sr-only">{t("full_name")}</label>
            <input
              type="text"
              name="full_name"
              className="form-control mb-2"
              placeholder={t("full_name")}
              required
            />
            <label htmlFor="inputEmail" className="sr-only mt-2">
              {t("work_email")}
            </label>
            <input
              type="email"
              name="email"
              className="form-control mb-2"
              placeholder={t("work_email")}
              required
              autoFocus
            />
            <div className="d-flex">
              <label className="sr-only">{t("password")}</label>
              <input
                type={secret ? "password" : "text"}
                name="password"
                className="form-control mb-2 border-radius-none-right-top-bottom"
                placeholder={t("password")}
                required
              />
              <button
                className="btn outline password-eye border-radius-none-left-top-bottom"
                type="button"
                onClick={togglePassword}
              >
                {secret ? <EyeIcon /> : <EyeOffIcon />}
              </button>
            </div>
            <select
              name="language_id"
              className="form-control mb-2"
              onChange={(e) => handleChangeLanguage(e.target.value)}
              defaultValue="1"
            >
              {languages?.length > 0 &&
                languages.map((lang) => (
                  <option
                    key={lang.language_id}
                    value={lang.language_id}
                    selected={lang.language_id === "1"}
                  >
                    {lang.title}
                  </option>
                ))}
            </select>
            <div className="d-flex align-start">
              <input
                type="checkbox"
                name="agree_terms"
                value="1"
                id="terms"
                required
              />
              <label className="signup-checkbox mb-0 ml-2" htmlFor="terms">
                {t("agree_with")}
                <a
                  href="https://www.decisimo.com/terms_portal.html"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-1"
                  style={{ textDecoration: "underline" }}
                >
                  {t("terms_of_use")}
                </a>
              </label>
            </div>
            <button
              className="form-pretty__btn w-170"
              type="submit"
              disabled={isSignUpRequest}
            >
              {isSignUpRequest ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">{t("loading")}</span>
                </div>
              ) : (
                t("sign_up")
              )}
            </button>
            <hr />
          </div>
          <CardsBottomSide />
        </form>
      </Card>
    </>
  );
};

export default Register;
