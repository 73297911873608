import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getCurrentYear } from "utility/utility";
import { ReactComponent as Logo } from "assets/images/decisimo-logo.svg";
import { useTranslation } from "react-i18next";

export const Card = ({ children }) => {
  return (
    <div className="auth-main">
      <div
        className={`card ${
          window.location.pathname.includes("/register")
            ? "card-register"
            : "card-login"
        } text-center`}
      >
        <div className="card-img-top card-logo-top">
          <Logo width="280" />
        </div>
        {children}
      </div>
    </div>
  );
};

export const CardsBottomSide = ({ isForgotten = false }) => {
  const { t } = useTranslation();

  return (
    <>
      {!window.location.pathname.includes("/register") && (
        <div>
          <p className="mb-3">
            <Link
              to={isForgotten ? "/new-password" : "/login"}
              className="text-decoration-none"
            >
              {isForgotten ? t("forgot_password") : t("try_login")}
            </Link>
          </p>
        </div>
      )}
      <p className="mt-2 text-muted">
        <small>© {getCurrentYear()}</small>
      </p>
    </>
  );
};

Card.propTypes = {
  children: PropTypes.any,
};

CardsBottomSide.propTypes = {
  isForgotten: PropTypes.bool,
};
