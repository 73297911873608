import * as SRD from "react-js-diagrams/lib/main";
import { DTCustomPortModel } from "components/decisionTrees/drawflow/CustomNode/DTCustomPortModel";

export class DTCustomNodeModel extends SRD.NodeModel {
  constructor(node, index) {
    super("custom");
    this.x = node.x;
    this.y = node.y;
    this.index = index;
    this.name = node.name;
    this.data = node.data;
    this.icon = "";
    this.id = node.decision_tree_node_id;
    this.decision_tree_node_id = node.decision_tree_node_id;

    if (node.wf_step_type_name === "start") {
      this.icon = "start";
      this.addPort(
        new DTCustomPortModel({
          isInput: false,
          id: `right-${node.decision_tree_node_id}`,
          name: "right",
        })
      );
    } else if (node.wf_step_type_name === "fork") {
      this.icon = "fork";
      this.addPort(
        new DTCustomPortModel({
          isInput: true,
          id: `left-${node.decision_tree_node_id}`,
          name: "left",
          disable: true,
        })
      );
      this.addPort(
        new DTCustomPortModel({
          isInput: false,
          id: `right-${node.decision_tree_node_id}`,
          name: "right",
        })
      );
      this.addPort(
        new DTCustomPortModel({
          isInput: false,
          id: `right-down-${node.decision_tree_node_id}`,
          name: "right-down",
        })
      );
    } else {
      this.addPort(
        new DTCustomPortModel({
          isInput: true,
          id: `left-${node.decision_tree_node_id}`,
          name: "left",
          disable: true,
        })
      );
      this.addPort(
        new DTCustomPortModel({
          isInput: false,
          id: `right-${node.decision_tree_node_id}`,
          name: "right",
        })
      );
    }
  }
}
