import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthUserRequest,
  getLanguagesRequest,
  updateSettingsRequest,
} from "redux/auth/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import i18n from "i18next";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const [dataSourceRef] = useCtrlSHandler(onHandleChangePassword);
  const { t } = useTranslation();

  const selectedLanguage = localStorage.getItem("user_language");

  const { user, isChangeSettingsSuccess, isChangeSettingsFailure, languages } =
    useSelector((state) => state.auth);

  const prevIsChangeSettingsSuccess = usePrevious(isChangeSettingsSuccess);
  const prevIsChangeSettingsFailure = usePrevious(isChangeSettingsFailure);

  useEffect(() => {
    document.title = t("settings_decisimo");
    dispatch(getLanguagesRequest());
  }, []);

  useEffect(() => {
    if (isChangeSettingsSuccess && prevIsChangeSettingsSuccess === false) {
      setIsLoading(false);
      toast.success(t("user_settings_changed"), ToastOptions);
      dispatch(getAuthUserRequest());
    }
  }, [isChangeSettingsSuccess]);

  useEffect(() => {
    if (isChangeSettingsFailure && prevIsChangeSettingsFailure === false) {
      toast.error(t("user_settings_not_changed"), ToastOptions);
    }
  }, [isChangeSettingsFailure]);

  function onHandleChangePassword(target) {
    const name = target.name.value;
    const email = target.email.value;
    const language = target.language_id.value;
    const data = new URLSearchParams();
    const findLanguage = languages.find((item) => item.code === language);

    data.append("full_name", name);
    data.append("email", email);
    data.append("language_id", findLanguage?.language_id);
    setIsLoading(true);
    dispatch(updateSettingsRequest(data));
  }

  const handleChangeLanguage = (e) => {
    localStorage.setItem("user_language", e);
    i18n.changeLanguage(e);
  };
  return (
    <>
      <SubHeader title={t("header_change_settings")} />
      <div className="row">
        <form
          ref={dataSourceRef}
          className="col-lg-4 col-md-6 col-10"
          name="changePasswordForm"
          onSubmit={(e) => {
            e.preventDefault();
            onHandleChangePassword(e.target);
          }}
        >
          <div className="mb-2">
            <label>{t("name")}</label>
            <input
              type="text"
              name="name"
              defaultValue={user?.full_name}
              className="form-control"
              required
            />
            <label>{t("email")}</label>
            <input
              type="email"
              name="email"
              className="form-control mb-2"
              defaultValue={user?.email}
              required
            />
          </div>
          <label>{t("language")}</label>
          <select
            name="language_id"
            value={selectedLanguage}
            className="form-control mb-2"
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            {languages?.length > 0 &&
              languages.map((lang) => (
                <option key={lang.language_id} value={lang.code}>
                  {lang.title}
                </option>
              ))}
          </select>
          <button className="btn primary">
            <SaveIcon /> <span className="ml-2">{t("update_settings")}</span>
          </button>
        </form>
      </div>
    </>
  );
};

export default Settings;
