import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as DecisionFlowsIcon } from "assets/icons/decision_flows.svg";
import { ReactComponent as RuleSetsIcon } from "assets/icons/rule_sets.svg";
import { ReactComponent as DecisionTablesIcon } from "assets/icons/decision_tables.svg";
import { ReactComponent as ModelsIcon } from "assets/icons/models.svg";
import { ReactComponent as FunctionsIcon } from "assets/icons/functions.svg";
import { ReactComponent as ExternalDataIcon } from "assets/icons/external_data.svg";
import { ReactComponent as ReleasesIcon } from "assets/icons/releases.svg";
import { ReactComponent as DeploymentsIcon } from "assets/icons/deployments.svg";
import { ReactComponent as EndPointsIcon } from "assets/icons/endpoints.svg";
import { ReactComponent as VectorsIcon } from "assets/icons/vectors.svg";
import { ReactComponent as DocumentationIcon } from "assets/icons/documentation.svg";
import { ReactComponent as BatchesIcon } from "assets/icons/batch-process-two.svg";
import { ReactComponent as ReportIcon } from "assets/icons/comment-help-solid.svg";
import { ReactComponent as MagicIcon } from "assets/icons/magic.svg";
import { ReactComponent as ScorecardIcon } from "assets/icons/scorecard.svg";
import { ReactComponent as DecisionTreeIcon } from "assets/icons/decision_tree.svg";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import ContactUs from "components/modals/ContactUs";
import { useDispatch, useSelector } from "react-redux";
import { createContactUsRequest } from "redux/contactUs/action";
import { useTranslation } from "react-i18next";

const Navbar = ({ isSideBarOpen, setIsSideBarOpen }) => {
  const { isMobile } = useContext(MainContext);
  const navbarRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const [isUserBatch, setIsUserBatch] = useState(false);
  const [isUserAIDD, setIsUserAIDD] = useState(false);
  const prevLocationPathName = usePrevious(window.location.pathname);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const batchUser = user?.license?.active_modules.find(
      (module) => module.module_name === "batch" && module.included === 1
    );
    if (
      user &&
      batchUser?.module_name === "batch" &&
      batchUser?.included === 1
    ) {
      setIsUserBatch(true);
    }
  }, [user]);

  useEffect(() => {
    const aiddUser = user?.license?.active_modules.find(
      (module) =>
        module.module_name === "ai_decision_designer" && module.included === 1
    );
    if (
      user &&
      aiddUser?.module_name === "ai_decision_designer" &&
      aiddUser?.included === 1
    ) {
      setIsUserAIDD(true);
    } else {
      setIsUserAIDD(false);
    }
  }, [user]);

  useEffect(() => {
    if (prevLocationPathName) {
      if (window.location.pathname !== prevLocationPathName) {
        navbarRef.current.classList.remove("show");
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    localStorage.setItem("sidebar_open", isSideBarOpen);
  }, [isSideBarOpen]);

  const onHandleNavbarState = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  const onHandleContactUs = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (
      e.target.title.value.length > 0 &&
      e.target.description.value.length > 0
    ) {
      dispatch(createContactUsRequest(data));
    }
  };

  return (
    <>
      <nav
        className={`${
          isSideBarOpen || isMobile ? "pr-0 col-lg-2 col-md-3" : ""
        } ${
          isMobile ? "collapse" : "d-none d-md-block"
        } bg-light sidebar sidebar-menu-wrapper`}
        id="navbarToggleExternalContent"
        ref={navbarRef}
      >
        <div className="tab-content">
          <div className="sidebar-sticky">
            <ul className="nav flex-column">
              <li className="nav-item menu-link-element">
                <Link
                  data-tip={true}
                  data-for="dashboard"
                  className="nav-link"
                  to="/dashboard"
                  title={t("nav_bar_dashboard")}
                  aria-describedby="trash-desc"
                >
                  <DashboardIcon />
                  <span
                    data-tip={true}
                    data-for="dashboard"
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_dashboard")}
                  </span>
                  <ReactTooltip
                    id="dashboard"
                    disable={isSideBarOpen}
                    type="dark"
                    place="right"
                    effect="solid"
                  >
                    {t("nav_bar_dashboard")}
                  </ReactTooltip>
                </Link>
              </li>
              <li className="nav-item pt-3">
                <span
                  className={`${
                    !isSideBarOpen && !isMobile ? "d-none" : ""
                  } text-muted nav-link`}
                >
                  {t("nav_bar_build")}
                </span>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/workflows"
                  title={t("nav_bar_decision_flows")}
                  data-tip={true}
                  data-for="decision-flows"
                >
                  <DecisionFlowsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_decision_flows")}
                  </span>
                </Link>
                <ReactTooltip
                  id="decision-flows"
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                >
                  {t("nav_bar_decision_flows")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/rule-sets"
                  title={t("nav_bar_rule_set")}
                  data-tip={true}
                  data-for="rule-sets"
                >
                  <RuleSetsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_rule_set")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="rule-sets"
                >
                  {t("nav_bar_rule_set")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/decision-tables"
                  title={t("nav_bar_decision_tables")}
                  data-tip={true}
                  data-for="decision-tables"
                >
                  <DecisionTablesIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_decision_tables")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="decision-tables"
                >
                  {t("nav_bar_decision_tables")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/scorecards"
                  title={t("nav_bar_decision_tables")}
                  data-tip={true}
                  data-for="scorecard"
                >
                  <ScorecardIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_scorecards")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="scorecard"
                >
                  {t("nav_bar_scorecards")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/decision-trees"
                  title={t("nav_bar_decision_trees")}
                  data-tip={true}
                  data-for="decision-trees"
                >
                  <DecisionTreeIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_decision_trees")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="decision-trees"
                >
                  {t("nav_bar_decision_trees")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/models"
                  title={t("nav_bar_models")}
                  data-tip={true}
                  data-for="models"
                >
                  <ModelsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_models")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="models"
                >
                  {t("nav_bar_models")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/functions"
                  title={t("nav_bar_functions")}
                  data-tip={true}
                  data-for="functions"
                >
                  <FunctionsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_functions")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="functions"
                >
                  {t("nav_bar_functions")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/external-data"
                  title={t("nav_bar_data_sources")}
                  data-tip={true}
                  data-for="external-data"
                >
                  <ExternalDataIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_data_sources")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="external-data"
                >
                  {t("nav_bar_data_sources")}
                </ReactTooltip>
              </li>
              {/*isUserAIDD ? (
                <li className="nav-item menu-link-element">
                  <Link
                    className="nav-link"
                    to="/ai-decision-designs"
                    title={t("nav_bar_build_with_aidd")}
                    data-tip={true}
                    data-for="decision-designer"
                  >
                    <MagicIcon />
                    <span
                      className={`${
                        !isSideBarOpen && !isMobile ? "d-none" : ""
                      } menu-link-text pl-2`}
                    >
                      {t("nav_bar_build_with_ai")}
                    </span>
                  </Link>
                  <ReactTooltip
                    id="decision-designer"
                    disable={isSideBarOpen}
                    type="dark"
                    place="right"
                    effect="solid"
                  >
                    {t("nav_bar_build_with_aidd")}
                  </ReactTooltip>
                </li>
              ) : null */}
              <li className="nav-item pt-3">
                <span
                  className={`${
                    !isSideBarOpen && !isMobile ? "d-none" : ""
                  } text-muted nav-link`}
                >
                  {t("nav_bar_public")}
                </span>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/releases"
                  title={t("nav_bar_releases")}
                  data-tip={true}
                  data-for="releases"
                >
                  <ReleasesIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_releases")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="releases"
                >
                  {t("nav_bar_releases")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/deployments"
                  title={t("nav_bar_deployments")}
                  data-tip={true}
                  data-for="deployments"
                >
                  <DeploymentsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_deployments")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="deployments"
                >
                  {t("nav_bar_deployments")}
                </ReactTooltip>
              </li>
              {isUserBatch ? (
                <li className="nav-item menu-link-element">
                  <Link
                    className="nav-link"
                    to="/batches"
                    title={t("nav_bar_batches")}
                    data-tip={true}
                    data-for="batches"
                  >
                    <BatchesIcon />
                    <span
                      className={`${
                        !isSideBarOpen && !isMobile ? "d-none" : ""
                      } menu-link-text pl-2`}
                    >
                      {t("nav_bar_batches")}
                    </span>
                  </Link>
                  <ReactTooltip
                    disable={isSideBarOpen}
                    type="dark"
                    place="right"
                    effect="solid"
                    id="batches"
                  >
                    {t("nav_bar_batches")}
                  </ReactTooltip>
                </li>
              ) : null}
              <li className="nav-item pt-3">
                <span
                  className={`${
                    !isSideBarOpen && !isMobile ? "d-none" : ""
                  } text-muted nav-link`}
                >
                  {t("nav_bar_set_up")}
                </span>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/api-endpoints"
                  title={t("nav_bar_endpoint")}
                  data-tip={true}
                  data-for="api-endpoints"
                >
                  <EndPointsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_endpoint")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="api-endpoints"
                >
                  {t("nav_bar_endpoint")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <Link
                  className="nav-link"
                  to="/data-objects"
                  title={t("nav_bar_data_objects")}
                  data-tip={true}
                  data-for="vectors"
                >
                  <VectorsIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_data_objects")}
                  </span>
                </Link>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="vectors"
                >
                  {t("nav_bar_data_objects")}
                </ReactTooltip>
              </li>
              <li className="nav-item pt-3">
                <span
                  className={`${
                    !isSideBarOpen && !isMobile ? "d-none" : ""
                  } text-muted nav-link`}
                >
                  {t("nav_bar_support")}
                </span>
              </li>
              <li className="nav-item menu-link-element">
                <a
                  className="nav-link"
                  href="//decisimo.atlassian.net/wiki/spaces/DOC/overview"
                  title={t("nav_bar_documentation")}
                  target="_blank"
                  rel="noreferrer"
                  data-tip={true}
                  data-for="documentation"
                >
                  <DocumentationIcon />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_documentation")}
                  </span>
                </a>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="documentation"
                >
                  {t("nav_bar_documentation")}
                </ReactTooltip>
              </li>
              <li className="nav-item menu-link-element">
                <button
                  title={t("nav_bar_contact_us")}
                  onClick={() => setIsContactUsModalOpen(true)}
                  className="bg-transparent border-0 nav-link text-left w-100"
                  data-tip={true}
                  data-for="contact-us"
                >
                  <ReportIcon title={t("nav_bar_contact_us")} />
                  <span
                    className={`${
                      !isSideBarOpen && !isMobile ? "d-none" : ""
                    } menu-link-text pl-2`}
                  >
                    {t("nav_bar_contact_us")}
                  </span>
                </button>
                <ReactTooltip
                  disable={isSideBarOpen}
                  type="dark"
                  place="right"
                  effect="solid"
                  id="contact-us"
                >
                  {t("nav_bar_contact_us")}
                </ReactTooltip>
              </li>
            </ul>
            {!isMobile ? (
              <div className="position-relative fixed-bottom">
                <button
                  className="float-right btn"
                  onClick={onHandleNavbarState}
                  type="button"
                  data-tip={true}
                  data-for="sidebar"
                >
                  {isSideBarOpen ? "«" : "»"}
                </button>
                <ReactTooltip
                  id="sidebar"
                  type="dark"
                  place="right"
                  effect="solid"
                >
                  {isSideBarOpen
                    ? `${t("nav_bar_minimize")}`
                    : `${t("nav_bar_show_full_menu")}`}
                </ReactTooltip>
              </div>
            ) : null}
          </div>
        </div>
      </nav>
      <ContactUs
        open={isContactUsModalOpen}
        handleClose={() => {
          setIsContactUsModalOpen(false);
        }}
        handleConfirm={onHandleContactUs}
      />
    </>
  );
};

Navbar.propTypes = {
  setIsSideBarOpen: PropTypes.func,
  isSideBarOpen: PropTypes.bool,
};

export default Navbar;
