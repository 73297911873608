import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sortByOrderIndex, sortByTitle } from "utility/utility";
import usePrevious from "utility/hooks/usePrevious";
import { getRuleSetRequest } from "redux/rulesets/action";
import Rule from "../../rulesets/rule";
import { useTranslation } from "react-i18next";

const RuleSetsModal = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, handleConfirm, title, conditionTypesData } = props;
  const { t } = useTranslation();

  const {
    isGetRuleSetsSuccess,
    ruleSets,
    isGetRuleSetForCopySuccess,
    ruleSetForCopy,
  } = useSelector((state) => state.ruleSets);

  const prevIsGetRuleSetsSuccess = usePrevious(isGetRuleSetsSuccess);
  const prevIsGetRuleSetForCopySuccess = usePrevious(
    isGetRuleSetForCopySuccess
  );

  const [ruleSetsClone, setRuleSetsClone] = useState([]);
  const [ruleSetClone, setRuleSetData] = useState({});
  const [selectedRules, setSelectedRules] = useState([]);
  const [isRenderedRules, setIsRenderedRules] = useState(false);

  useEffect(() => {
    setIsRenderedRules(true);
    clearStates();
  }, []);

  useEffect(() => {
    if (isGetRuleSetsSuccess && prevIsGetRuleSetsSuccess === false) {
      const ruleSetsClone = structuredClone(ruleSets);
      ruleSetsClone?.sort(sortByTitle);
      setRuleSetsClone(ruleSetsClone);
      dispatch(
        getRuleSetRequest({
          id: ruleSetsClone[0]?.rule_set_id,
          importMode: true,
          copyMode: true,
        })
      );
    }
  }, [isGetRuleSetsSuccess]);

  useEffect(() => {
    if (
      isGetRuleSetForCopySuccess &&
      prevIsGetRuleSetForCopySuccess === false
    ) {
      setIsRenderedRules(false);
      const ruleSetClone = structuredClone(ruleSetForCopy);
      ruleSetClone?.rules?.sort(sortByOrderIndex);
      setRuleSetData(ruleSetClone);
    }
  }, [isGetRuleSetForCopySuccess]);

  const handleChangeRuleSet = (e) => {
    setRuleSetData({});
    setIsRenderedRules(true);
    dispatch(
      getRuleSetRequest({
        id: e.target.defaultValue,
        importMode: true,
        copyMode: true,
      })
    );
  };

  const renderRule = useCallback(
    (rule, index) => {
      return (
        <Rule
          key={rule.rule_id}
          id={rule.rule_id}
          rule={rule}
          setRuleSet={setRuleSetData}
          index={index}
          conditionTypesData={conditionTypesData}
          importMode={true}
          revisionMode={true}
        />
      );
    },
    [conditionTypesData, ruleSetClone]
  );

  const handleSelectRule = (e, rule) => {
    let selectedRulesClone = structuredClone(selectedRules);
    if (e.target.checked) {
      selectedRulesClone.push(rule);
    } else {
      selectedRulesClone = selectedRulesClone.filter(
        (selectedRule) => selectedRule.rule_id !== rule.rule_id
      );
    }

    setSelectedRules(selectedRulesClone);
  };

  const checkForRule = (rule) => {
    return selectedRules
      .map((selectedRule) => {
        return selectedRule.rule_id;
      })
      .includes(rule);
  };

  const clearStates = () => {
    setSelectedRules([]);
    setRuleSetData({});
  };

  return (
    <>
      <Modal
        size="xl"
        show={open}
        onHide={() => {
          clearStates();
          handleClose();
        }}
      >
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body
          className="d-flex flex-column flex-md-row modal-body"
          style={{ height: "600px" }}
        >
          <div
            className="border-right btn-group btn-group-toggle col-lg-2 col-md-3 d-block h-sm-25 mb-5 mb-md-0 overflow-auto"
            data-toggle="buttons"
            onChange={handleChangeRuleSet}
            key={ruleSetsClone}
          >
            {ruleSetsClone?.map((ruleSet, index) => {
              return (
                <label
                  key={ruleSet.rule_set_id}
                  className={`border-0 btn btn-outline-primary clickable col-12 text-left ${
                    index === 0 ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    className="field response-json"
                    name="batch_type_id"
                    defaultChecked={index === 0}
                    defaultValue={ruleSet.rule_set_id}
                    key={ruleSet.rule_set_id}
                  />{" "}
                  {ruleSet.title}
                </label>
              );
            })}
          </div>
          {isRenderedRules ? (
            <div className="align-items-center col d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">{t("loading")}</span>
              </div>
            </div>
          ) : (
            <div className="col overflow-auto">
              {ruleSetClone?.rules?.length > 0 &&
              ruleSetClone?.rules[0].rule_id !== 0
                ? ruleSetClone?.rules.map((rule, i) => {
                    return (
                      <div
                        className={`cursor-auto mb-2 border border-2 d-flex${
                          checkForRule(rule.rule_id) ? " selectedRules" : ""
                        }`}
                        key={rule.rule_id}
                      >
                        <label
                          className="col-1 pt-4 text-center clickable"
                          htmlFor={rule.rule_id}
                        >
                          <input
                            type="checkbox"
                            defaultChecked={checkForRule(rule.rule_id)}
                            className="field response-json"
                            id={rule.rule_id}
                            onChange={(e) => handleSelectRule(e, rule)}
                            key={rule.rule_id}
                          />
                        </label>
                        <div
                          key={rule.rule_id}
                          className="ui-sortable accordion w-100"
                          id={`rules-${rule.rule_id}`}
                        >
                          <div className="col p-0">{renderRule(rule, i)}</div>
                        </div>
                      </div>
                    );
                  })
                : isGetRuleSetForCopySuccess &&
                  prevIsGetRuleSetForCopySuccess === true
                ? t("no_rules")
                : ""}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-testid="btn-close"
            variant=""
            className="outline"
            onClick={() => {
              clearStates();
              handleClose();
            }}
          >
            {t("close")}
          </Button>
          {selectedRules?.length > 0 ? (
            <Button
              data-testid="btn-import"
              variant=""
              className="primary"
              type="submit"
              onClick={() => {
                handleConfirm(selectedRules);
                clearStates();
              }}
            >
              {selectedRules.length === 1
                ? "Import (1) rule"
                : selectedRules.length > 1
                ? `Import (${selectedRules.length}) rules`
                : "Import"}
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

RuleSetsModal.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  conditionTypesData: PropTypes.array,
  title: PropTypes.string,
};

export default RuleSetsModal;
