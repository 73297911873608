import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { FormulaContext, RevisionContext } from "context/contexts";
import { useTranslation } from "react-i18next";

const Operator = ({ item, path }) => {
  const { t } = useTranslation();
  const revisionMode = useContext(RevisionContext);
  const { contextMenuItem, setContextMenuItem } = useContext(FormulaContext);

  if (revisionMode) {
    return <div className="input-group-text mb-1">{item.operator}</div>;
  }

  const clearContextMenuItem = () => {
    if (Object.keys(contextMenuItem).length > 0) {
      setContextMenuItem({});
    }

    return false;
  };

  return (
    <div>
      <div
        className="input-group-text border-radius-0 border-radius-right-0"
        data-tip={true}
        data-for="right-click-to-change"
        onContextMenuCapture={() =>
          setContextMenuItem({
            item,
            path,
          })
        }
        onClick={clearContextMenuItem}
      >
        {item.operator}
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][parent_fce_item_id]`}
          defaultValue={
            item.parent_fce_item_id ? item.parent_fce_item_id : "null"
          }
        />
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][item_type]`}
          defaultValue={item.item_type}
        />
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][operand]`}
          defaultValue={item.operator}
        />
        <input
          type="hidden"
          name={`fce_itm[${item.fce_item_id}][fce_item_id]`}
          defaultValue={item.fce_item_id}
        />
      </div>
      <ReactTooltip
        type="dark"
        place="top"
        effect="solid"
        id="right-click-to-change"
      >
        {t("right_click")}
      </ReactTooltip>
    </div>
  );
};

Operator.propTypes = {
  item: PropTypes.object,
  path: PropTypes.array,
};

export default Operator;
