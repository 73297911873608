import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getCountriesRequest,
  getSubscriptionRequest,
  updateSubscriptionRequest,
} from "redux/subscription/action";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";
import "./SubscriptionSettings.css";

const SubscriptionSettings = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const [dataSourceRef] = useCtrlSHandler(onHandleUpdateSubscription);
  const { t } = useTranslation();

  const {
    isGetSubscriptionSuccess,
    isGetSubscriptionError,
    isGetCountriesError,
    subscriptions,
    countries,
    isUpdateSubscriptionsSuccess,
    isUpdateSubscriptionsError,
  } = useSelector((state) => {
    return state.subscriptions;
  });

  const prevIsGetCountriesError = usePrevious(isGetCountriesError);
  const prevIsGetSubscriptionError = usePrevious(isGetSubscriptionError);
  const prevIsGetSubscriptionSuccess = usePrevious(isGetSubscriptionSuccess);
  const prevIsUpdateSubscriptionsSuccess = usePrevious(
    isUpdateSubscriptionsSuccess
  );
  const prevIsUpdateSubscriptionsError = usePrevious(
    isUpdateSubscriptionsError
  );

  const [subscriptionsData, setSubscriptionsData] = useState({});

  useEffect(() => {
    document.title = t("subscriptions_settings_decisimo");
    setIsLoading(true);
    dispatch(getSubscriptionRequest());
    dispatch(getCountriesRequest());
  }, []);

  useEffect(() => {
    if (isGetSubscriptionSuccess && prevIsGetSubscriptionSuccess === false) {
      setIsLoading(false);
      setSubscriptionsData(subscriptions);
    }
  }, [isGetSubscriptionSuccess]);

  useEffect(() => {
    if (
      (isGetSubscriptionError && prevIsGetSubscriptionError === false) ||
      (isGetCountriesError && prevIsGetCountriesError === false) ||
      (isUpdateSubscriptionsError && prevIsUpdateSubscriptionsError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetSubscriptionError, isGetCountriesError, isUpdateSubscriptionsError]);

  useEffect(() => {
    if (
      isUpdateSubscriptionsSuccess &&
      prevIsUpdateSubscriptionsSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("subscription_settings_changed"), ToastOptions);
    }
  }, [isUpdateSubscriptionsSuccess]);

  function onHandleUpdateSubscription(target) {
    localStorage.setItem("tenant", target.title.value);
    const data = new URLSearchParams(new FormData(target));
    setIsLoading(true);
    dispatch(updateSubscriptionRequest(data));
  }

  const handleChange = (e) => {
    const countryId = e.target.value;
    const copySubscriptionsData = structuredClone(subscriptionsData);
    copySubscriptionsData["tenant"].country_id = countryId;
    setSubscriptionsData(copySubscriptionsData);
  };

  return (
    <>
      <SubHeader title={t("settings")} />
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          onHandleUpdateSubscription(e.target);
        }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 mb-2">
            <label className="mt-2">{t("company_name")}</label>
            <input
              type="text"
              name="title"
              defaultValue={subscriptionsData?.tenant?.title}
              className="form-control"
            />

            <label className="mt-2">{t("company_id_company_number")}</label>
            <input
              type="text"
              name="company_code"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.company_code}
            />
            <hr />
            <h5>{t("primary_contact")}</h5>
            <label className="mt-2">{t("name")}:</label>
            <input
              type="text"
              name="primary_contact_name"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.primary_contact_name}
            />
            <label className="mt-2">{t("email")}:</label>
            <input
              type="text"
              name="primary_contact_email"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.primary_contact_email}
            />
            <label className="mt-2">{t("phone")}:</label>
            <input
              type="text"
              name="primary_contact_phone"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.primary_contact_phone}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-2">
            <h5>{t("address")}</h5>
            <label className="mt-2">{t("address_building_street")}</label>
            <textarea
              rows="3"
              name="address"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.address}
            />
            <label className="mt-2">{t("town")}</label>
            <input
              type="text"
              name="town"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.town}
            />
            <label className="mt-2">{t("county")}</label>
            <input
              type="text"
              name="county"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.county}
            />
            <label className="mt-2">{t("post_code")}</label>
            <input
              type="text"
              name="post_code"
              className="form-control"
              defaultValue={subscriptionsData?.tenant?.post_code}
            />
            <label className="mt-2">{t("country")}</label>
            <select
              name="country_id"
              value={subscriptionsData?.tenant?.country_id}
              className="form-control"
              onChange={(e) => handleChange(e)}
            >
              {countries?.length > 0 &&
                countries.map((country) => {
                  return (
                    <option key={country.country_id} value={country.country_id}>
                      {country.title}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <button className="btn primary" type="submit">
              <SaveIcon /> <span className="ml-2">{t("update_settings")}</span>
            </button>
          </div>
        </div>
      </form>
      <h2 className="h3">{t("subscriptions")}</h2>
      {!subscriptionsData?.subscriptions ||
      !subscriptionsData?.subscriptions.active_modules.length ? (
        <div>{t("no_active_subscriptions")}</div>
      ) : (
        <div className="subscriptions-container mb-4">
          <div className="subscription-info">
            <p>
              <strong>{t("active_subscription")}</strong>{" "}
              {subscriptionsData?.subscriptions.title}
            </p>
            <p>
              <strong>{t("license_end_date")}</strong>{" "}
              {!subscriptionsData?.subscriptions.end_date
                ? t("open_ended")
                : subscriptionsData?.subscriptions.end_date}
            </p>
          </div>
          <div className="modules-container">
            <table className="modules-table">
              <thead>
                <tr>
                  <th>{t("module")}</th>
                  <th>{t("included_capitalized")}</th>
                  <th>{t("number_allowed")}</th>
                  <th>{t("overage_allowed")}</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionsData?.subscriptions.active_modules.map(
                  (module, index) => (
                    <tr key={index}>
                      <td>{module.module_name}</td>
                      <td>{module.included ? t("yes") : t("no")}</td>
                      <td>{module.number_allowed}</td>
                      <td>{module.overage_allowed ? t("yes") : t("no")}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionSettings;
