import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import AutoSuggestInput from "../autosuggest";

const SliceItem = ({ slice, index, onChange, onDelete, vectorsData }) => {
  const { t } = useTranslation();

  // State to manage whether the dimension is being edited
  const [isEditingDimension, setIsEditingDimension] = useState(
    slice.dimension ? true : false
  );

  // Handle input changes for name, metric, and aggregation
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(index, name, value);
  };

  // Handle dimension input change
  const handleDimensionChange = (value) => {
    onChange(index, "dimension", value);
    setIsEditingDimension(true); // Keep editing mode if needed
  };

  // Handle click on "Global" text to switch to input mode
  const handleGlobalClick = () => {
    setIsEditingDimension(true);
  };

  return (
    <tr>
      {/* Name */}
      <td className="p-0 m-0">
        <input
          type="text"
          name="name"
          className="form-control m-0 border-0 rounded-0"
          placeholder={t("name")}
          value={slice.name}
          onChange={handleInputChange}
          required
        />
      </td>

      {/* Metric */}
      <td className="p-0 m-0">
        <div>
          <AutoSuggestInput
            vectorsData={vectorsData}
            defaultValue={slice.metric || ""}
            placeholder={t("metric")}
            inputName={`metric_${slice.release_recipe_slice_id}`}
            tableMode={true}
            onInputChange={(value) => onChange(index, "metric", value)}
          />
        </div>
      </td>

      {/* Dimension */}
      <td className="p-0 m-0">
        <div>
          {isEditingDimension ? (
            <AutoSuggestInput
              vectorsData={vectorsData}
              defaultValue={slice.dimension || ""}
              placeholder={t("dimension")}
              className="pl-2"
              inputName={`dimension_${slice.release_recipe_slice_id}`}
              tableMode={true}
              onInputChange={handleDimensionChange}
              // Optionally, handle blur or enter key to exit edit mode
              onBlur={() => {
                if (!slice.dimension) {
                  setIsEditingDimension(false);
                }
              }}
            />
          ) : (
            <span
              className="text-muted cursor-pointer d-flex justify-content-center"
              onClick={handleGlobalClick}
              title={t("click_to_set_dimension")}
              style={{ userSelect: "none" }}
            >
              {t("global")}
            </span>
          )}
        </div>
      </td>
      {/* Aggregation */}
      <td className="p-0 m-0">
        <select
          name="aggregation"
          className="form-control m-0 border-0 rounded-0"
          value={slice.aggregation}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>
            {t("select_aggregation")}
          </option>
          <option value="sum">{t("sum")}</option>
          <option value="average">{t("average")}</option>
          <option value="count">{t("count")}</option>
          <option value="min">{t("min")}</option>
          <option value="max">{t("max")}</option>
          <option value="quartile">{t("quartile")}</option>
          <option value="decile">{t("decile")}</option>
        </select>
      </td>

      {/* Delete Button */}
      <td className="py-0">
        <button
          type="button"
          className="border-radius-4 border-0 btn btn-outline-light option-delete"
          onClick={onDelete}
          title={t("delete_slice")}
        >
          <TrashIcon
            style={{
              color: "#6C757D",
            }}
          />
        </button>
      </td>
    </tr>
  );
};

SliceItem.propTypes = {
  slice: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  vectorsData: PropTypes.array,
};

export default SliceItem;
