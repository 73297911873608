import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDecisionTableRequest,
  getDecisionTableRevisionRequest,
  getDecisionTableRevisionsRequest,
} from "redux/decisionTables/action";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import SubHeader from "components/SubHeader";
import Revisions from "components/modals/ruleSet/Revisions";
import DecisionTableRows from "components/decisionTables/DecisionTableRows";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as PlusIcon } from "assets/icons/custom_plus.svg";
import CreateNewItem from "components/modals/CreateNewItem";
import { createItemCopy, updateDecisionTable } from "./utils";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as DecisionTablesIcon } from "../../assets/icons/decision_tables.svg";
import { useTranslation } from "react-i18next";

const DecisionTableRevision = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    decisionTableRevisions,
    isGetDecisionTableRevisionsSuccess,
    revision,
    isGetRevisionSuccess,
    isGetRevisionError,
    isCreatedDecisionTableSuccess,
    isCreatedDecisionTableError,
    isUpdatedDecisionTableSuccess,
    isUpdatedDecisionTableError,
    newDecisionTableId,
    decisionTable,
    isGetDecisionTableSuccess,
    isGetDecisionTableError,
  } = useSelector((state) => state.decisionTables);

  const prevIsGetDecisionTableSuccess = usePrevious(isGetDecisionTableSuccess);
  const prevIsGetDecisionTableError = usePrevious(isGetDecisionTableError);
  const prevIsGetRevisionSuccess = usePrevious(isGetRevisionSuccess);
  const prevIsGetRevisionError = usePrevious(isGetRevisionError);
  const prevIsCreatedDecisionTableSuccess = usePrevious(
    isCreatedDecisionTableSuccess
  );
  const prevIsCreatedDecisionTableError = usePrevious(
    isCreatedDecisionTableError
  );
  const prevIsUpdatedDecisionTableSuccess = usePrevious(
    isUpdatedDecisionTableSuccess
  );
  const prevIsUpdatedDecisionTableError = usePrevious(
    isUpdatedDecisionTableError
  );
  const prevIsGetDecisionTableRevisionsSuccess = usePrevious(
    isGetDecisionTableRevisionsSuccess
  );

  const [decisionTableColsData, setDecisionTableColsData] = useState([]);
  const [decisionTableRowsData, setDecisionTableRowsData] = useState([]);
  const [
    isDecisionTableRevisionsModalOpen,
    setIsDecisionTableRevisionsModalOpen,
  ] = useState(false);
  const [finalRowsData, setFinalRowsData] = useState([]);
  const [isCreateNewRevisionOpen, setIsCreateNewRevisionOpen] = useState(false);
  const [isRenderedTable, setIsRenderedTable] = useState(true);

  useEffect(() => {
    document.title = `${t("decision_table_revision")} - Decisimo`;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDecisionTableRevisionRequest({ id, revisionId }));
  }, [revisionId]);

  useEffect(() => {
    if (isGetRevisionSuccess && prevIsGetRevisionSuccess === false) {
      setIsRenderedTable(false);
      setIsLoading(false);
      const cols = revision.content?.definition?.cols?.map((col) => ({
        title: col.title,
        decision_table_column_id: col.decision_table_column_id,
        type: "list",
        role: col.role,
        simTypeList: [],
      }));
      setDecisionTableColsData(cols);
      setDecisionTableRowsData(revision.content?.rows);
    }
  }, [isGetRevisionSuccess]);

  useEffect(() => {
    if (
      (isGetRevisionError && prevIsGetRevisionError === false) ||
      (isGetDecisionTableRevisionsSuccess &&
        prevIsGetDecisionTableRevisionsSuccess === false)
    ) {
      setIsRenderedTable(false);
      setIsLoading(false);
    }
  }, [isGetDecisionTableRevisionsSuccess, isGetRevisionError]);

  useEffect(() => {
    if (
      isCreatedDecisionTableSuccess &&
      prevIsCreatedDecisionTableSuccess === false
    ) {
      dispatch(getDecisionTableRequest(newDecisionTableId));
    }
  }, [isCreatedDecisionTableSuccess]);

  useEffect(() => {
    if (
      isUpdatedDecisionTableSuccess &&
      prevIsUpdatedDecisionTableSuccess === false
    ) {
      navigate(`/decision-tables/${newDecisionTableId}`);
    }
  }, [isUpdatedDecisionTableSuccess]);

  useEffect(() => {
    if (isGetDecisionTableSuccess && prevIsGetDecisionTableSuccess === false) {
      updateDecisionTable(
        decisionTable,
        revision?.content?.rows,
        newDecisionTableId,
        dispatch
      );
    }
  }, [isGetDecisionTableSuccess]);

  useEffect(() => {
    if (
      (isCreatedDecisionTableError &&
        prevIsCreatedDecisionTableError === false) ||
      (isGetDecisionTableError && prevIsGetDecisionTableError === false) ||
      (isUpdatedDecisionTableError && prevIsUpdatedDecisionTableError === false)
    ) {
      setIsCreateNewRevisionOpen(false);
    }
  }, [
    isCreatedDecisionTableError,
    isGetDecisionTableError,
    isUpdatedDecisionTableError,
  ]);

  // show modal revisions
  const handleShowDecisionTableRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getDecisionTableRevisionsRequest(id));
    setIsDecisionTableRevisionsModalOpen(true);
  };

  // close modal revisions
  const handleCloseRevisions = () => {
    setIsDecisionTableRevisionsModalOpen(false);
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowDecisionTableRevisionsModal}
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: true,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsCreateNewRevisionOpen(true)}
          type="button"
        >
          <PlusIcon /> {t("create_a_new_decision_table")}
        </button>
      ),
      divider: false,
    },
  ];

  return (
    <>
      <SubHeader
        title={
          <>
            <em>{t("[revision]")} </em> {t("decision_table")}
          </>
        }
        icon={<DecisionTablesIcon />}
        actions={
          <>
            <Link to={`/decision-tables/${id}`} className="mr-2">
              <button className="btn outline" title={t("back_to_table")}>
                <BackIcon />
              </button>
            </Link>
            <div className="btn-group">
              <button
                type="button"
                className="btn primary"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingsIcon />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                {settings.length > 0 &&
                  settings.map((setting) => {
                    return (
                      <span key={setting.id}>
                        {setting.content}
                        {setting.divider && (
                          <div className="dropdown-divider" />
                        )}
                      </span>
                    );
                  })}
              </div>
            </div>
          </>
        }
      />

      {isRenderedTable ? (
        <div className="align-items-center col d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t("loading")}</span>
          </div>
        </div>
      ) : (
        <DecisionTableRows
          finalRowsData={finalRowsData}
          setFinalRowsData={setFinalRowsData}
          decisionTableCols={decisionTableColsData}
          decisionTableRowsData={decisionTableRowsData}
          tableId={parseInt(id)}
          revisionMode={true}
        />
      )}

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={decisionTableRevisions}
        linkTo={"decision-tables"}
        open={isDecisionTableRevisionsModalOpen}
        elemId={parseInt(id)}
      />

      <CreateNewItem
        handleClose={() => setIsCreateNewRevisionOpen(false)}
        handleConfirm={() =>
          createItemCopy(revision?.content?.definition, dispatch)
        }
        title="decision table"
        open={isCreateNewRevisionOpen}
      />
    </>
  );
};

export default DecisionTableRevision;
