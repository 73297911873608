import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDecisionTableRevisionRequest,
  getDecisionTableRevision2Request,
  getDecisionTableRevisionsRequest,
} from "redux/decisionTables/action";
import { MainContext } from "context/contexts";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DecisionTablesIcon } from "assets/icons/decision_tables.svg";
import Revisions from "components/modals/decisionTables/Revisions";
import usePrevious from "utility/hooks/usePrevious";
import SubHeader from "components/SubHeader";
import { useTranslation } from "react-i18next";
import RevisionHeader from "./RevisionHeader";
import { compareDecisionTables } from "components/decisionTables/utils"; // existing utility

const DecisionTableRevisionComparison = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId, revisionId2 } = useParams();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleShowDecisionTableRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getDecisionTableRevisionsRequest(id));
    setIsDecisionTableRevisionsModalOpen(true);
  };

  const handleCloseRevisions = () => {
    setIsDecisionTableRevisionsModalOpen(false);
  };
  // Then add these state variables inside your component
  const [
    isDecisionTableRevisionsModalOpen,
    setIsDecisionTableRevisionsModalOpen,
  ] = useState(false);

  const {
    revision,
    revision2,
    isGetRevisionSuccess,
    isGetRevision2Success,
    isGetRevisionError,
    isGetRevision2Error,
    decisionTableRevisions,
    isGetDecisionTableRevisionsSuccess,
  } = useSelector((state) => state.decisionTables);

  const prevIsGetDecisionTableRevisionsSuccess = usePrevious(
    isGetDecisionTableRevisionsSuccess
  );
  const [mergedColumns, setMergedColumns] = useState([]);
  const [mergedRows, setMergedRows] = useState([]);

  useEffect(() => {
    document.title = t("compare_decision_table_revisions");
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDecisionTableRevisionRequest({ id, revisionId }));
    dispatch(getDecisionTableRevision2Request({ id, revisionId2 }));
  }, [dispatch, id, revisionId, revisionId2, setIsLoading]);

  useEffect(() => {
    if (
      isGetDecisionTableRevisionsSuccess &&
      prevIsGetDecisionTableRevisionsSuccess === false
    ) {
      setIsLoading(false);
    }
  }, [isGetDecisionTableRevisionsSuccess]);

  useEffect(() => {
    if (
      (isGetRevisionError || isGetRevision2Error) &&
      !(isGetRevisionSuccess && isGetRevision2Success)
    ) {
      setIsLoading(false);
      // Handle errors
    }
    if (isGetRevisionSuccess && isGetRevision2Success) {
      setIsLoading(false);

      // Perform comparison
      const changes = compareDecisionTables(revision, revision2);

      // Build a merged column list from both revisions
      const cols1 = revision.content.definition.cols;
      const cols2 = revision2.content.definition.cols;
      const colMap = new Map();
      cols1.forEach((c) =>
        colMap.set(c.decision_table_column_id, { from: c, to: null })
      );
      cols2.forEach((c) => {
        if (colMap.has(c.decision_table_column_id)) {
          const val = colMap.get(c.decision_table_column_id);
          val.to = c;
          colMap.set(c.decision_table_column_id, val);
        } else {
          colMap.set(c.decision_table_column_id, { from: null, to: c });
        }
      });

      // Create a stable column order. For simplicity, use order from the first revision
      // If missing columns from rev2, they still appear (and vice versa).
      // Sort columns by column_index
      const allColumns = Array.from(colMap.keys())
        .map((key) => {
          const { from, to } = colMap.get(key);
          return {
            column_id: key,
            title: (to && to.title) || (from && from.title) || "Unknown",
            column_index:
              (to && to.column_index) || (from && from.column_index) || 0,
            role: (to && to.role) || (from && from.role),
            from,
            to,
          };
        })
        .sort((a, b) => a.column_index - b.column_index);

      setMergedColumns(allColumns);

      // Build row map for both revisions
      const rowMap1 = {};
      revision.content.rows.forEach((r) => {
        rowMap1[r.decision_table_row_id] = r;
      });

      const rowMap2 = {};
      revision2.content.rows.forEach((r) => {
        rowMap2[r.decision_table_row_id] = r;
      });

      const allRowIds = new Set([
        ...Object.keys(rowMap1),
        ...Object.keys(rowMap2),
      ]);
      // Sort rows by row_index and include row_index in the final rows
      const finalRows = Array.from(allRowIds)
        .map((rowId) => {
          const row1 = rowMap1[rowId] || null;
          const row2 = rowMap2[rowId] || null;

          let status = "unchanged";
          if (row1 && !row2) status = "deleted";
          else if (!row1 && row2) status = "added";
          else if (row1 && row2) {
            const modifiedEntry = changes.rowChanges.find(
              (c) => c.type === "modified" && c.rowId === rowId
            );
            if (modifiedEntry) status = "modified";
          }

          return {
            rowId,
            row1,
            row2,
            status,
            row_index:
              (row2 && row2.row_index) || (row1 && row1.row_index) || "0",
          };
        })
        .sort((a, b) => parseInt(a.row_index) - parseInt(b.row_index));

      setMergedRows(finalRows);
    }
  }, [
    isGetRevisionSuccess,
    isGetRevision2Success,
    isGetRevisionError,
    isGetRevision2Error,
    revision,
    revision2,
    setIsLoading,
  ]);

  if (!isGetRevisionSuccess || !isGetRevision2Success) {
    return (
      <div className="align-items-center col d-flex justify-content-center">
        <span>{t("loading")}...</span>
      </div>
    );
  }

  if (!mergedColumns.length || !mergedRows.length) {
    return null; // No data to show yet
  }

  function renderCellContent(cell1, cell2, rowStatus, columnRole) {
    // If row is added, only cell2 exists. Show cell2 in green.
    if (rowStatus === "added") {
      return (
        <span style={{ color: "green" }}>
          {formatCellValue(cell2, columnRole)}
        </span>
      );
    }
    // If row is deleted, only cell1 exists. Show struck out in red.
    if (rowStatus === "deleted") {
      return (
        <span style={{ color: "red", textDecoration: "line-through" }}>
          {formatCellValue(cell1, columnRole)}
        </span>
      );
    }

    // If unchanged row, both cells exist and are identical
    if (rowStatus === "unchanged" && cellsEqual(cell1, cell2)) {
      return formatCellValue(cell1, columnRole);
    }

    // If modified row, show old vs new
    if (rowStatus === "modified") {
      if (!cell1 && cell2) {
        // Cell added in this column
        return (
          <span style={{ color: "green" }}>
            {formatCellValue(cell2, columnRole)}
          </span>
        );
      }
      if (cell1 && !cell2) {
        // Cell removed in this column
        return (
          <span style={{ color: "red", textDecoration: "line-through" }}>
            {formatCellValue(cell1, columnRole)}
          </span>
        );
      }
      if (cell1 && cell2 && !cellsEqual(cell1, cell2)) {
        // Modified cell
        return (
          <>
            <span
              style={{
                textDecoration: "line-through",
                color: "red",
                marginRight: "5px",
              }}
            >
              {formatCellValue(cell1, columnRole)}
            </span>
            <span style={{ color: "green" }}>
              {formatCellValue(cell2, columnRole)}
            </span>
          </>
        );
      }
      // If they are equal (no change in this cell), just show value
      return formatCellValue(cell1, columnRole);
    }

    // Default fallback
    return (
      formatCellValue(cell1, columnRole) ||
      formatCellValue(cell2, columnRole) ||
      "-"
    );
  }

  function formatCellValue(cell, columnRole) {
    if (!cell) return "";

    if (columnRole === "result") {
      return cell.value_a !== undefined && cell.value_a !== null
        ? cell.value_a
        : "";
    }

    // Special handling for ANY operator
    if (cell.operator === "any_value") {
      return "ANY";
    }

    // Handle interval notations
    const isInterval = [
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(cell.operator);

    if (isInterval) {
      const closingBracket = {
        closed_interval: "]",
        open_interval: ")",
        left_closed_right_open: ")",
        left_open_right_closed: "]",
      }[cell.operator];

      return `${getOperatorSymbol(cell.operator)}${cell.value_a || ""}, ${
        cell.value_b || ""
      }${closingBracket}`;
    }

    // For all other operators
    const operatorSymbol = getOperatorSymbol(cell.operator);
    const value =
      cell.value_a !== undefined && cell.value_a !== null ? cell.value_a : "";
    return `${operatorSymbol} ${value}`;
  }

  function getOperatorSymbol(operator) {
    switch (operator) {
      case "equal_to":
        return "=";
      case "not_equal_to":
        return "≠";
      case "any_value":
        return "ANY";
      case "greater_than":
        return ">";
      case "greater_than_or_equal_to":
        return "≥";
      case "less_than":
        return "<";
      case "less_than_or_equal_to":
        return "≤";
      case "starts_with":
        return "^";
      case "ends_with":
        return "$";
      case "closed_interval":
        return "["; // Opening bracket only, closing handled in formatCellValue
      case "open_interval":
        return "("; // Opening parenthesis only
      case "left_closed_right_open":
        return "["; // Opening bracket only
      case "left_open_right_closed":
        return "("; // Opening parenthesis only
      default:
        return operator;
    }
  }

  function cellsEqual(c1, c2) {
    if (!c1 || !c2) return false;
    return (
      c1.operator === c2.operator &&
      c1.value_a === c2.value_a &&
      c1.value_b === c2.value_b
    );
  }

  function getRowStyle(type) {
    switch (type) {
      case "added":
        return { backgroundColor: "#d4edda" }; // Light green for added rows
      case "deleted":
        return { backgroundColor: "#f8d7da" }; // Light red for deleted rows
      case "modified":
        return { backgroundColor: "#fff3cd" }; // Light yellow for modified rows
      default:
        return {}; // unchanged
    }
  }

  function getCellStyle(rowStatus, cell1, cell2) {
    if (rowStatus === "modified" && !cellsEqual(cell1, cell2)) {
      return { backgroundColor: "#fff3cd" }; // highlight changed cells in yellow
    }
    return {};
  }

  // Add the settings array
  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowDecisionTableRevisionsModal}
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: false,
    },
  ];

  return (
    <>
      <SubHeader
        title={t("compare_decision_table_revisions")}
        icon={<DecisionTablesIcon />}
        actions={
          <>
            <Link to={`/decision-tables/${id}`} className="mr-2">
              <button className="btn outline" title={t("back_to_table")}>
                <BackIcon />
              </button>
            </Link>
            <div className="btn-group">
              <button
                type="button"
                className="btn primary"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingsIcon />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                {settings.map((setting) => (
                  <span key={setting.id}>
                    {setting.content}
                    {setting.divider && <div className="dropdown-divider" />}
                  </span>
                ))}
              </div>
            </div>
          </>
        }
      />
      <RevisionHeader revision1={revision} revision2={revision2} />
      <div className="comparison-table-container p-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: "50px" }}>Row Index</th>
              {mergedColumns.map((col) => (
                <th key={col.column_id}>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mergedRows.map((rowObj, rowIndex) => {
              const { rowId, row1, row2, status } = rowObj;

              // Highlight entire row if added/deleted
              const rowStyle = getRowStyle(status);

              return (
                <tr key={rowIndex} style={rowStyle}>
                  <td>{parseInt(rowObj.row_index) + 1}</td>
                  {mergedColumns.map((col, colIndex) => {
                    const { from: colDef1, to: colDef2, role } = col;

                    // Find corresponding cells
                    const cell1 = row1
                      ? row1.cols.find(
                          (c) =>
                            c.decision_table_column_id ===
                            (colDef1
                              ? colDef1.decision_table_column_id
                              : colDef2 && colDef2.decision_table_column_id)
                        )
                      : null;
                    const cell2 = row2
                      ? row2.cols.find(
                          (c) =>
                            c.decision_table_column_id ===
                            (colDef1
                              ? colDef1.decision_table_column_id
                              : colDef2 && colDef2.decision_table_column_id)
                        )
                      : null;

                    return (
                      <td
                        key={colIndex}
                        style={getCellStyle(status, cell1, cell2)}
                      >
                        {renderCellContent(cell1, cell2, status, role)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={decisionTableRevisions}
        linkTo="decision-tables"
        open={isDecisionTableRevisionsModalOpen}
        elemId={parseInt(id)}
        leftRevisionId={parseInt(revisionId)}
        rightRevisionId={parseInt(revisionId2)}
      />
    </>
  );
};

export default DecisionTableRevisionComparison;
