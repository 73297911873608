import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  getDashboardsFailure,
  getDashboardsRequest,
  getDashboardsSuccess,
} from "redux/dashboard/action";

function* getDashboard() {
  try {
    const response = yield call(axios.get, "/dashboards");
    if (response.status === 200) {
      yield put(getDashboardsSuccess(response.data));
    }
  } catch (e) {
    yield put(getDashboardsFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getDashboardsRequest, getDashboard);
}
