import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import RuleSetContent from "components/rulesets/RuleSetContent";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import { ruleDataConvertJson } from "../../utility/utility";
import { createRuleSetRequest } from "../../redux/rulesets/action";
import { useTranslation } from "react-i18next";

const ruleSetDataDefault = {
  name: "",
  rules: [],
  title: "",
  verbose: "1",
  rule_set_id: `new_${Math.round(Math.random() * 10000)}`,
};

const CreateRuleSet = () => {
  const { t } = useTranslation();

  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(createRuleSet);

  const { newRuleSetId, isCreatedRuleSetSuccess } = useSelector(
    (state) => state.ruleSets
  );

  const prevIsCreatedRuleSetSuccess = usePrevious(isCreatedRuleSetSuccess);
  const [ruleSetData, setRuleSetData] = useState(ruleSetDataDefault);

  useEffect(() => {
    document.title = t("rule_set_decisimo");
  }, []);

  useEffect(() => {
    if (isCreatedRuleSetSuccess && prevIsCreatedRuleSetSuccess === false) {
      setIsLoading(false);
      toast.success(t("ruleset_created"), ToastOptions);
      navigate(`/rule-sets/${newRuleSetId}`);
    }
  }, [isCreatedRuleSetSuccess]);

  function createRuleSet(target) {
    setIsEdited(false);
    if (target.querySelector(".is-invalid.react-autosuggest__input")) {
      return toast.error(t("data_attribute_path_is_invalid"), ToastOptions);
    } else if (target.querySelector(".is-invalid")) {
      return toast.error(t("invalid_json"), ToastOptions);
    }
    const data = new URLSearchParams(new FormData(target));
    const name = target.name.value;
    const title = target.title.value;

    if (name.length === 0 || title.length === 0) {
      return toast.error("Please fill out the Title/ID.", ToastOptions);
    }

    ruleSetData.rules.forEach((rule) => {
      if (
        rule.rule_id !== 0 &&
        data.get(`rule[${rule.rule_id}][active]`) === "on"
      ) {
        data.set(`rule[${rule.rule_id}][active]`, "1");
      }
    });

    const ruleJsonData = ruleDataConvertJson(data, ruleSetData);

    setIsLoading(true);

    dispatch(createRuleSetRequest(ruleJsonData));
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          createRuleSet(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <RuleSetContent
          ruleSetData={ruleSetData}
          setRuleSetData={setRuleSetData}
        />
      </form>
    </>
  );
};

export default CreateRuleSet;
