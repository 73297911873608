import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TaskCancel = ({ open, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("task_cancel")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <span>{t("task_cancel_question")}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={handleClose}>
            {t("no")}
          </Button>
          <Button
            variant=""
            className="outline text-danger"
            onClick={handleConfirm}
          >
            {t("yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TaskCancel.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default TaskCancel;
