import { createAction } from "redux-actions";

export const getDataSourcesRequest = createAction("GET_DATA_SOURCES_REQUEST");
export const getDataSourcesSuccess = createAction("GET_DATA_SOURCES_SUCCESS");
export const getDataSourcesFailure = createAction("GET_DATA_SOURCES_FAILURE");

export const deleteDataSourceRequest = createAction(
  "DELETE_DATA_SOURCE_REQUEST"
);
export const deleteDataSourceSuccess = createAction(
  "DELETE_DATA_SOURCE_SUCCESS"
);
export const deleteDataSourceFailure = createAction(
  "DELETE_DATA_SOURCE_FAILURE"
);

export const createDataSourceRequest = createAction(
  "CREATE_DATA_SOURCE_REQUEST"
);
export const createDataSourceSuccess = createAction(
  "CREATE_DATA_SOURCE_SUCCESS"
);
export const createDataSourceFailure = createAction(
  "CREATE_DATA_SOURCE_FAILURE"
);

export const getDataSourceRequest = createAction("GET_DATA_SOURCE_REQUEST");
export const getDataSourceSuccess = createAction("GET_DATA_SOURCE_SUCCESS");
export const getDataSourceFailure = createAction("GET_DATA_SOURCE_FAILURE");

export const getRunTestResultRequest = createAction(
  "GET_RUN_TEST_RESULT_REQUEST"
);
export const getRunTestResultSuccess = createAction(
  "GET_RUN_TEST_RESULT_SUCCESS"
);
export const getRunTestResultFailure = createAction(
  "GET_RUN_TEST_RESULT_FAILURE"
);

export const getExternalDataTestingRequest = createAction(
  "GET_EXTERNAL_DATA_TESTING_REQUEST"
);
export const getExternalDataTestingSuccess = createAction(
  "GET_EXTERNAL_DATA_TESTING_SUCCESS"
);
export const getExternalDataTestingFailure = createAction(
  "GET_EXTERNAL_DATA_TESTING_FAILURE"
);

export const deleteDataSourceParamRequest = createAction(
  "DELETE_DATA_SOURCE_PARAM_REQUEST"
);
export const deleteDataSourceParamSuccess = createAction(
  "DELETE_DATA_SOURCE_PARAM_SUCCESS"
);
export const deleteDataSourceParamFailure = createAction(
  "DELETE_DATA_SOURCE_PARAM_FAILURE"
);

export const deleteDataSourceHeaderRequest = createAction(
  "DELETE_DATA_SOURCE_HEADER_REQUEST"
);
export const deleteDataSourceHeaderSuccess = createAction(
  "DELETE_DATA_SOURCE_HEADER_SUCCESS"
);
export const deleteDataSourceHeaderFailure = createAction(
  "DELETE_DATA_SOURCE_HEADER_FAILURE"
);

export const updateDataSourceRequest = createAction(
  "UPDATE_DATA_SOURCE_REQUEST"
);
export const updateDataSourceSuccess = createAction(
  "UPDATE_DATA_SOURCE_SUCCESS"
);
export const updateDataSourceFailure = createAction(
  "UPDATE_DATA_SOURCE_FAILURE"
);

export const getOAuth2TokensRequest = createAction("GET_OAUTH2_TOKENS_REQUEST");
export const getOAuth2TokensSuccess = createAction("GET_OAUTH2_TOKENS_SUCCESS");
export const getOAuth2TokensFailure = createAction("GET_OAUTH2_TOKENS_FAILURE");

export const getOAuth2TokenRequest = createAction("GET_OAUTH2_TOKEN_REQUEST");
export const getOAuth2TokenSuccess = createAction("GET_OAUTH2_TOKEN_SUCCESS");
export const getOAuth2TokenFailure = createAction("GET_OAUTH2_TOKEN_FAILURE");

export const updateOAuth2TokenRequest = createAction(
  "UPDATE_OAUTH2_TOKEN_REQUEST"
);
export const updateOAuth2TokenSuccess = createAction(
  "UPDATE_OAUTH2_TOKEN_SUCCESS"
);
export const updateOAuth2TokenFailure = createAction(
  "UPDATE_OAUTH2_TOKEN_FAILURE"
);

export const createOAuth2TokenRequest = createAction(
  "CREATE_OAUTH2_TOKEN_REQUEST"
);
export const createOAuth2TokenSuccess = createAction(
  "CREATE_OAUTH2_TOKEN_SUCCESS"
);
export const createOAuth2TokenFailure = createAction(
  "CREATE_OAUTH2_TOKEN_FAILURE"
);

export const deleteOAuth2TokenRequest = createAction(
  "DELETE_OAUTH2_TOKEN_REQUEST"
);
export const deleteOAuth2TokenSuccess = createAction(
  "DELETE_OAUTH2_TOKEN_SUCCESS"
);
export const deleteOAuth2TokenFailure = createAction(
  "DELETE_OAUTH2_TOKEN_FAILURE"
);
//RESPONSE

export const createExternalDataResponseRequest = createAction(
  "CREATE_EXTERNAL_DATA_RESPONSE_REQUEST"
);
export const createExternalDataResponseSuccess = createAction(
  "CREATE_EXTERNAL_DATA_RESPONSE_SUCCESS"
);
export const createExternalDataResponseFailure = createAction(
  "CREATE_EXTERNAL_DATA_RESPONSE_FAILURE"
);

export const getExternalDataResponseRequest = createAction(
  "GET_EXTERNAL_DATA_RESPONSE_REQUEST"
);
export const getExternalDataResponseSuccess = createAction(
  "GET_EXTERNAL_DATA_RESPONSE_SUCCESS"
);
export const getExternalDataResponseFailure = createAction(
  "GET_EXTERNAL_DATA_RESPONSE_FAILURE"
);

export const getExternalDataResponsesRequest = createAction(
  "GET_EXTERNAL_DATA_RESPONSE_REQUEST"
);
export const getExternalDataResponsesSuccess = createAction(
  "GET_EXTERNAL_DATA_RESPONSE_SUCCESS"
);
export const getExternalDataResponsesFailure = createAction(
  "GET_EXTERNAL_DATA_RESPONSE_FAILURE"
);

export const updateExternalDataResponseRequest = createAction(
  "UPDATE_EXTERNAL_DATA_RESPONSE_REQUEST"
);
export const updateExternalDataResponseSuccess = createAction(
  "UPDATE_EXTERNAL_DATA_RESPONSE_SUCCESS"
);
export const updateExternalDataResponseFailure = createAction(
  "UPDATE_EXTERNAL_DATA_RESPONSE_FAILURE"
);

export const deleteExternalDataResponseRequest = createAction(
  "DELETE_EXTERNAL_DATA_RESPONSE_REQUEST"
);
export const deleteExternalDataResponseSuccess = createAction(
  "DELETE_EXTERNAL_DATA_RESPONSE_SUCCESS"
);
export const deleteExternalDataResponseFailure = createAction(
  "DELETE_EXTERNAL_DATA_RESPONSE_FAILURE"
);
