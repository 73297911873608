import React from "react";
import * as RJD from "react-js-diagrams/lib/main";

import DTCustomLinkWidget from "components/decisionTrees/drawflow/CustomNode/DTCustomLinkWidget";

export class DTCustomLinkFactory extends RJD.LinkWidgetFactory {
  constructor(props) {
    super("CustomLink");
    this.state = {
      setLinkId: props,
    };
  }

  generateReactWidget(diagramEngine, link) {
    return (
      <DTCustomLinkWidget
        link={link}
        diagramEngine={diagramEngine}
        setLinkId={this.state.setLinkId}
      />
    );
  }
}
