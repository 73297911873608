import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AutoSuggestInput from "components/autosuggest";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as GripVertical } from "assets/icons/grip-vertical.svg";
import { useTranslation } from "react-i18next";

const Variable = ({
  index,
  variable,
  vectorsData,
  handleShowVariableDeleteModal,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: variable.model_variable_id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { t } = useTranslation();

  return (
    <tr ref={setNodeRef} {...attributes} style={style} className="table-row">
      <td ref={setActivatorNodeRef} {...listeners}>
        <GripVertical className="cursor-move" />
      </td>

      <td>
        <input
          type="text"
          name={`model_variable[${variable.model_variable_id}][name]`}
          className="form-control form-control-sm form-control-plaintext"
          defaultValue={variable.name}
          maxLength={130}
          placeholder={t("enter_variable_name")}
        />
        <input
          type="hidden"
          name={`model_variable[${variable.model_variable_id}][model_variable_id]`}
          value={variable.model_variable_id}
        />
      </td>

      <td>
        <select
          name={`model_variable[${variable.model_variable_id}][data_type]`}
          className="form-control form-control-sm form-control-plaintext"
          defaultValue={variable.data_type}
        >
          <option value="STRING">string</option>
          <option value="INTEGER">integer</option>
          <option value="NUMBER">number</option>
          <option value="DATE">date</option>
        </select>
      </td>

      <td>
        <AutoSuggestInput
          isModel={true}
          vectorsData={vectorsData}
          defaultValue={variable.mapping || ""}
          inputName={`model_variable[${variable.model_variable_id}][mapping]`}
          tableMode={true}
        />
      </td>

      <td className="text-center">
        <button
          className="btn"
          type="button"
          onClick={() =>
            handleShowVariableDeleteModal(variable.model_variable_id)
          }
        >
          <TrashIcon />
        </button>
      </td>
    </tr>
  );
};

Variable.propTypes = {
  index: PropTypes.number,
  variable: PropTypes.object,
  vectorsData: PropTypes.array,
  handleShowVariableDeleteModal: PropTypes.func,
};

export default Variable;
