import React, { memo } from "react";
import PropTypes from "prop-types";
import ColumnInput from "components/decisionTables/ColumnInput";

const TableColumn = ({
  col,
  decision_table_row_id,
  revisionMode = false,
  contextMenuIds,
  setContextMenuIds,
  index,
  inputRef,
  rowIndex,
  handleDataFromChild,
  colsClone,
}) => {
  const firstRuleSetIndex = colsClone.findIndex(
    (item) => item.role === "result"
  );

  const resultKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      handleDataFromChild(rowIndex + 1, index);
    } else if (event.key === "ArrowUp") {
      handleDataFromChild(rowIndex - 1, index);
    } else if (event.key === "ArrowLeft" && event.target.selectionStart === 0) {
      const activeElement = document.activeElement;
      let correctLeft = inputRef.current[`value_a_${index - 1}`];
      const name = activeElement.name;
      if (name.includes("value_a")) {
        if (inputRef.current[`value_b_${index - 1}`] && correctLeft) {
          correctLeft = inputRef.current[`value_b_${index - 1}`];
        } else if (correctLeft) {
          correctLeft = inputRef.current[`value_a_${index - 1}`];
        }
      }
      if (correctLeft) {
        correctLeft.focus();
        setTimeout(() => {
          correctLeft.setSelectionRange(0, 0);
        }, 0);
      }
    } else if (
      event.key === "ArrowRight" &&
      event.target.selectionStart === event.target.value.length
    ) {
      const activeElement = document.activeElement;
      let correctLeft = inputRef.current[`value_a_${index + 1}`];
      const name = activeElement.name;
      if (name.includes("value_a")) {
        if (inputRef.current[`value_b_${index - 1}`] && correctLeft) {
          correctLeft = inputRef.current[`value_b_${index + 1}`];
        } else if (correctLeft) {
          correctLeft = inputRef.current[`value_a_${index + 1}`];
        }
      }
      if (correctLeft) {
        correctLeft.focus();
        setTimeout(() => {
          correctLeft.setSelectionRange(0, 0);
        }, 0);
      }
    }
  };

  return (
    <td
      className={firstRuleSetIndex === index ? "result-th" : ""}
      style={{ minWidth: "220px", width: `calc(100% / ${colsClone.length})` }}
    >
      {col.role === "result" ? (
        <div className="result-field">
          <input
            onKeyDown={(event) => resultKeyDown(event)}
            ref={(el) => {
              inputRef.current[`value_a_${index}`] = el;
            }}
            type="text"
            className="form-control border-0 m-0 rounded-0"
            defaultValue={col.value_a}
            name={`field[${col.dt_row_column_id}][value_a]`}
            disabled={revisionMode ? "disabled" : ""}
            key={decision_table_row_id || col.dt_row_column_id}
          />
        </div>
      ) : (
        <>
          {revisionMode ? (
            <ColumnInput
              col={col}
              revisionMode={revisionMode}
              inputRef={inputRef}
            />
          ) : (
            <ColumnInput
              handleDataFromChild={handleDataFromChild}
              index={index}
              rowIndex={rowIndex}
              inputRef={inputRef}
              col={col}
              decisionTableRowId={decision_table_row_id}
              contextMenuIds={contextMenuIds}
              setContextMenuIds={setContextMenuIds}
            />
          )}
        </>
      )}
      <input
        type="hidden"
        name={`field[${col.dt_row_column_id}][decision_table_column_id]`}
        defaultValue={col.decision_table_column_id}
      />
      <input
        type="hidden"
        name={`field[${col.dt_row_column_id}][dt_row_column_id]`}
        defaultValue={col.dt_row_column_id}
      />
      <input
        type="hidden"
        name={`field[${col.dt_row_column_id}][decision_table_row_id]`}
        defaultValue={decision_table_row_id}
      />
      <input
        type="hidden"
        name={`field[${col.dt_row_column_id}][operator]`}
        defaultValue={col.operator}
      />
    </td>
  );
};

TableColumn.propTypes = {
  col: PropTypes.object,
  decision_table_row_id: PropTypes.string,
  revisionMode: PropTypes.bool,
  contextMenuIds: PropTypes.object,
  setContextMenuIds: PropTypes.func,
  index: PropTypes.number,
  inputRef: PropTypes.object,
  rowIndex: PropTypes.number,
  handleDataFromChild: PropTypes.func,
  colsClone: PropTypes.array,
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.col) === JSON.stringify(nextProps.col) &&
    prevProps.decision_table_row_id === nextProps.decision_table_row_id &&
    prevProps.revisionMode === nextProps.revisionMode
  );
};

export default memo(TableColumn, comparisonFn);
