import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const AIDDSidebar = ({ sidebarWidth, setSidebarWidth }) => {
  const [messages, setMessages] = useState([]); // Store chat messages
  const [inputValue, setInputValue] = useState(""); // Input from the user

  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = Math.min(
      Math.max(200, window.innerWidth - e.clientX),
      500
    );
    setSidebarWidth(newWidth);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const sendMessage = () => {
    if (inputValue.trim()) {
      const newMessage = { text: inputValue, sender: "user" };
      setMessages([...messages, newMessage]);
      setInputValue(""); // Clear input after sending
    }
  };

  // Simulate receiving a message from the bot
  useEffect(() => {
    if (messages.length && messages[messages.length - 1].sender === "user") {
      setTimeout(() => {
        const newMessage = {
          text: "Hello! This is a response from the bot.",
          sender: "bot",
        };
        setMessages((messages) => [...messages, newMessage]);
      }, 1000);
    }
  }, [messages]);

  return (
    <>
      <div
        className="resize-handle"
        onMouseDown={handleMouseDown}
        style={{
          position: "fixed",
          right: `${
            window.innerWidth - (window.innerWidth - sidebarWidth) - 2
          }px`,
          top: 0,
          width: "5px",
          height: "100vh",
          cursor: "ew-resize",
          backgroundColor: "#ccc",
          zIndex: 1001,
        }}
      />
      <div
        className="right-sidebar bg-light sidebar-menu-wrapper aidd"
        style={{ width: `${sidebarWidth}px` }}
      >
        <ul className="message-list">
          {messages.map((msg, index) => (
            <li key={index} className={`message-item ${msg.sender}`}>
              {msg.text}
            </li>
          ))}
        </ul>
        <div className="chat-input">
          <textarea
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type your message..."
            rows="3"
            style={{ width: "100%", resize: "none" }}
          ></textarea>
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </>
  );
};

AIDDSidebar.propTypes = {
  sidebarWidth: PropTypes.number,
  setSidebarWidth: PropTypes.func,
};

export default AIDDSidebar;
