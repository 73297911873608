import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as Close } from "assets/icons/close_modal.svg";
import StepMainContent from "components/workflow/steps/MainContent";
import StepContent from "components/workflow/StepContent";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { stepTitles } from "../../workflow/utils";
import { useTranslation } from "react-i18next";

const StepWorkflow = (props) => {
  const { open, handleClose, handleConfirm, handleDelete, data } = props;

  const { workflow } = useSelector((state) => state.workflows);
  const { revisionId } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    if (open && !revisionId) {
      const workflowClone = structuredClone(workflow);
      const step = workflowClone?.workflow_step?.find(
        (step) => step.workflow_step_id === data.id
      );
      if (step?.options?.length === step?.links?.length) {
        data.options = step.options;
      }
    }
  }, [data, workflow, open, revisionId]);

  const stepOptions = {
    wf_step_option_id: `new_${Math.round(Math.random() * 10000)}`,
    attributes: [
      {
        attribute_vector: 1,
        name: "attribute_path_input",
        path: "$.",
        value: "",
        wf_step_opt_condition_type_id: null,
        wf_step_option_attribute_id: `new_${Math.round(Math.random() * 10000)}`,
      },
      {
        attribute_vector: 1,
        name: "attribute_path_output",
        path: "$.",
        value: "",
        wf_step_opt_condition_type_id: null,
        wf_step_option_attribute_id: `new_${Math.round(Math.random() * 10000)}`,
      },
      {
        attribute_vector: 0,
        name: "execution_type",
        path: "",
        value: "object",
        wf_step_opt_condition_type_id: null,
        wf_step_option_attribute_id: `new_${Math.round(Math.random() * 10000)}`,
      },
    ],
  };

  return (
    <>
      <Modal
        dialogClassName={`workflow-modal ${
          ["external_data_call", "fork"].includes(data.type) ? "w-1300" : ""
        }`}
        show={open}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <h5 className="modal-workflow__title d-flex align-items-center modal-workflow__title_color">
            {stepTitles(t)[data.type]}
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <Close />
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <Modal.Body className={revisionId ? "mb-3" : ""}>
            <input type="hidden" name="workflow_step_id" value={data.id} />
            <StepMainContent
              title={data.title}
              name={data.name}
              type={data.type}
            />
            {data.type === "rule_set" ? (
              <StepContent
                stepOptions={data.options?.length && data.options[0]}
                options={data.ruleSets}
                optionTypeId="rule_set_id"
                linkTo="rule-sets"
              />
            ) : data.type === "fce" ? (
              <StepContent
                stepOptions={data.options?.length && data.options[0]}
                label1={t("function")}
                options={data.functions}
                optionTypeId="fce_id"
                linkTo="functions"
              />
            ) : data.type === "model" ? (
              <StepContent
                stepOptions={data.options?.length && data.options[0]}
                label1={t("model")}
                options={data.models}
                optionTypeId="model_id"
                linkTo="models"
              />
            ) : data.type === "decision_table" ? (
              <StepContent
                stepOptions={data.options?.length && data.options[0]}
                label1={t("decision_table")}
                options={data.decisionTables}
                optionTypeId="decision_table_id"
                linkTo="decision-tables"
              />
            ) : data.type === "scorecard" ? (
              <StepContent
                stepOptions={data.options?.length && data.options[0]}
                label1={t("scorecard")}
                options={data.scorecards}
                optionTypeId="scorecard_id"
                linkTo="scorecards"
              />
            ) : data.type === "decision_tree" ? (
              <StepContent
                stepOptions={data.options?.length && data.options[0]}
                label1={t("decision_tree")}
                options={data.decisionTrees}
                optionTypeId="decision_tree_id"
                linkTo="decision-trees"
              />
            ) : data.type === "subflow" ? (
              <StepContent
                stepOptions={
                  data.options?.length > 0 ? data.options[0] : stepOptions
                }
                label1={t("decision_flow_step")}
                options={data.subFlows}
                optionTypeId="sub_workflow_id"
                linkTo="workflows"
              />
            ) : data.type === "external_data_call" ? (
              <StepContent
                stepOptions={data.options}
                options={data.externalData}
                label1=""
                optionTypeId="external_data_id"
                linkTo="external-data"
              />
            ) : (
              data.type === "fork" && (
                <StepContent
                  stepOptions={data.options}
                  conditionTypes={data.conditionTypes}
                  label1=""
                  optionTypeId="fork"
                />
              )
            )}
          </Modal.Body>
          {!revisionId ? (
            <Modal.Footer className="justify-content-between">
              {data.type !== "start" && (
                <div
                  title={t("delete_this_step")}
                  className="cursor-pointer text-danger"
                  onClick={handleDelete}
                >
                  {t("delete")}
                </div>
              )}
              {data.title === "Start" && (
                <div className="workflows-modal-btn ml-1" onClick={handleClose}>
                  <Close />
                  {t("close")}
                </div>
              )}
              <div className={`d-flex `}>
                {data.title !== "Start" && (
                  <div
                    className="workflows-modal-btn mr-1"
                    onClick={handleClose}
                  >
                    <Close />
                    {t("close")}
                  </div>
                )}
                <button
                  type="submit"
                  className="workflows-modal-btn text-white save-step"
                >
                  <SaveIcon />
                  {t("save_step")}
                </button>
              </div>
            </Modal.Footer>
          ) : null}
        </form>
      </Modal>
    </>
  );
};

StepWorkflow.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
  handleConfirm: PropTypes.func,
  data: PropTypes.object,
};

export default StepWorkflow;
