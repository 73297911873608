import React from "react";
import { Link } from "react-router-dom";

function ErrorFallback() {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <h3 className="text-secondary">Oh no! Something went wrong...</h3>
      <Link to="/dashboard" className="btn btn-primary">
        Go Home
      </Link>
    </div>
  );
}

export default ErrorFallback;
