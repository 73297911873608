import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import {
  deleteBatchRequest,
  getBatchRequest,
  getBatchTasksRequest,
  updateBatchRequest,
  forceScheduleBatchTaskRequest,
} from "redux/batches/action";
import BatchContent from "./BatchContent";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as BatchesIcon } from "assets/icons/batch-process-two.svg";
import usePrevious from "utility/hooks/usePrevious";
import { Pagination } from "utility/utility";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { useTranslation } from "react-i18next";

const UpdateBatch = () => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataSourceRef] = useCtrlSHandler(handleUpdateBatch);
  const { t } = useTranslation();

  const {
    batch,
    isGetBatchSuccess,
    isGetBatchError,
    isUpdatedBatchSuccess,
    isUpdatedBatchError,
    isGetBatchTasksSuccess,
    isGetBatchTasksError,
    tasks,
    isDeletedBatchError,
    isDeletedBatchSuccess,
  } = useSelector((state) => state.batches);

  const { workflowReleases } = useSelector((state) => state.releases);

  const [batchClone, setBatchClone] = useState({});
  const [tasksClone, setTasksClone] = useState([]);
  const [isBatchDeleteModalOpen, setIsBatchDeleteModalOpen] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [releaseData, setReleaseData] = useState(true);

  const prevIsGetBatchSuccess = usePrevious(isGetBatchSuccess);
  const prevIsGetBatchError = usePrevious(isGetBatchError);
  const prevIsUpdatedBatchSuccess = usePrevious(isUpdatedBatchSuccess);
  const prevIsUpdatedBatchError = usePrevious(isUpdatedBatchError);
  const prevIsGetBatchTasksSuccess = usePrevious(isGetBatchTasksSuccess);
  const prevIsGetBatchTasksError = usePrevious(isGetBatchTasksError);
  const prevIsDeletedBatchSuccess = usePrevious(isDeletedBatchSuccess);
  const prevIsDeletedBatchError = usePrevious(isDeletedBatchError);

  useEffect(() => {
    document.title = `${t("batch")} - Decisimo`;
    setIsLoading(true);
    dispatch(getBatchRequest(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getBatchTasksRequest({
        id,
        page: currentPage,
      })
    );
  }, [currentPage]);

  useEffect(() => {
    if (isGetBatchSuccess && prevIsGetBatchSuccess === false) {
      setBatchClone(batch);
      setIsLoading(false);
    } else if (isGetBatchTasksSuccess && prevIsGetBatchTasksSuccess === false) {
      setTasksClone(tasks);
    }
  }, [isGetBatchSuccess, isGetBatchTasksSuccess]);

  useEffect(() => {
    if (isUpdatedBatchSuccess && prevIsUpdatedBatchSuccess === false) {
      setIsLoading(false);
      toast.info(t("saved"), ToastOptions);
    }
  }, [isUpdatedBatchSuccess]);

  useEffect(() => {
    if (
      (isUpdatedBatchError && prevIsUpdatedBatchError === false) ||
      (isGetBatchError && prevIsGetBatchError === false) ||
      (isGetBatchTasksError && prevIsGetBatchTasksError === false) ||
      (isDeletedBatchError && prevIsDeletedBatchError === false)
    ) {
      if (isDeletedBatchError) {
        setIsBatchDeleteModalOpen(false);
      }
      setIsLoading(false);
    }
  }, [
    isDeletedBatchError,
    isUpdatedBatchError,
    isGetBatchError,
    isGetBatchTasksError,
  ]);

  useEffect(() => {
    if (workflowReleases.length) {
      setReleaseData(true);
    }
  }, [workflowReleases]);

  function handleUpdateBatch(target) {
    setIsEdited(false);
    const data = new URLSearchParams(new FormData(target));
    setIsLoading(true);
    if (workflowReleases.length) {
      dispatch(updateBatchRequest({ id, data }));
    } else {
      toast.error(t("release_no_empty"), ToastOptions);
      setIsLoading(false);
      setReleaseData(false);
    }
  }

  useEffect(() => {
    if (isDeletedBatchSuccess && prevIsDeletedBatchSuccess === false) {
      setIsLoading(false);
      navigate("/batches");
      toast.warning(t("batch_deleted"), ToastOptions);
      setIsBatchDeleteModalOpen(false);
    }
  }, [isDeletedBatchSuccess]);

  const handleConfirm = () => {
    setIsLoading(true);
    setIsEdited(false);
    dispatch(deleteBatchRequest(id));
  };

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdateBatch(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          alt={t("batch_definition")}
          title={t("batch_definition")}
          icon={<BatchesIcon />}
          actions={
            <>
              <Link to={`/batches`} className="mr-2">
                <button className="btn outline" title={t("back_to_table")}>
                  <BackIcon />
                </button>
              </Link>
              <button
                title={t("save_batch")}
                className="btn primary mr-2"
                type="submit"
                key={workflowReleases?.length === 0}
              >
                <SaveIcon />
              </button>
              <button
                title={t("delete_batch")}
                type="button"
                className="btn option-delete outline"
                onClick={() => setIsBatchDeleteModalOpen(true)}
              >
                <DeleteIcon />
              </button>
            </>
          }
        />
        <BatchContent
          batch={batchClone}
          batchTasks={tasksClone}
          setBatchTasks={setTasksClone}
          releaseData={releaseData}
        />
      </form>
      <Pagination
        currentPage={currentPage}
        itemsLength={tasks?.length}
        setCurrentPage={setCurrentPage}
        isScrollTo={false}
      />
      <DeleteConfirm
        handleClose={() => setIsBatchDeleteModalOpen(false)}
        handleConfirm={handleConfirm}
        title={t("delete_batch")}
        message={t("batch_delete_message")}
        open={isBatchDeleteModalOpen}
      />
    </>
  );
};

export default UpdateBatch;
