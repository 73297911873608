import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  deleteDeploymentFailure,
  deleteDeploymentRequest,
  deleteDeploymentSuccess,
  getDeploymentByIdFailure,
  getDeploymentByIdRequest,
  getDeploymentByIdSuccess,
  getDeploymentsFailure,
  getDeploymentsRequest,
  getDeploymentsSuccess,
} from "redux/deployments/action";

function* getDeployments({ payload }) {
  try {
    let url = "/deployments";
    if (payload) {
      url = `deployments?page=${payload}`;
    }
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDeploymentsSuccess(response.data));
    }
  } catch (e) {
    yield put(getDeploymentsFailure("e.message"));
  }
}

function* getDeploymentById({ payload }) {
  try {
    let url = "/deployments";
    if (payload) {
      url = `deployments/${payload}`;
    }
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDeploymentByIdSuccess(response.data));
    }
  } catch (e) {
    yield put(getDeploymentByIdFailure("e.message"));
  }
}

function* deleteDeployment({ payload }) {
  try {
    const url = `deployments/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteDeploymentSuccess());
    }
  } catch (e) {
    yield put(deleteDeploymentFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getDeploymentsRequest, getDeployments);
  yield takeLatest(getDeploymentByIdRequest, getDeploymentById);
  yield takeLatest(deleteDeploymentRequest, deleteDeployment);
}
