import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  // Approval Processes
  getApprovalProcessesRequest,
  getApprovalProcessesSuccess,
  getApprovalProcessesFailure,
  getApprovalProcessRequest,
  getApprovalProcessSuccess,
  getApprovalProcessFailure,
  deleteApprovalProcessRequest,
  deleteApprovalProcessSuccess,
  deleteApprovalProcessFailure,
  startApprovalProcessRequest,
  startApprovalProcessSuccess,
  startApprovalProcessFailure,
  startApprovalProcessConflict,
  signApprovalProcessStepSuccess,
  signApprovalProcessStepFailure,
  signApprovalProcessStepRequest,
  // Approval Sequences
  getApprovalSequencesRequest,
  getApprovalSequencesSuccess,
  getApprovalSequencesFailure,
  getApprovalSequenceRequest,
  getApprovalSequenceSuccess,
  getApprovalSequenceFailure,
  deleteApprovalSequenceRequest,
  deleteApprovalSequenceSuccess,
  deleteApprovalSequenceFailure,
  createApprovalSequenceRequest,
  createApprovalSequenceSuccess,
  createApprovalSequenceFailure,
  //update
  updateApprovalSequenceRequest,
  updateApprovalSequenceSuccess,
  updateApprovalSequenceFailure,
  //get approval step
  getApprovalStepRequest,
  getApprovalStepSuccess,
  getApprovalStepFailure,
} from "redux/approvalProcesses/action";

// Approval Processes Sagas
function* getApprovalProcesses() {
  try {
    const response = yield call(axios.get, "/approval-processes");
    if (response.status === 200) {
      yield put(getApprovalProcessesSuccess(response.data));
    }
  } catch (error) {
    yield put(getApprovalProcessesFailure(error.message));
  }
}

function* getApprovalProcess({ payload }) {
  try {
    const response = yield call(axios.get, `/approval-processes/${payload}`);
    if (response.status === 200) {
      yield put(getApprovalProcessSuccess(response.data));
    }
  } catch (error) {
    yield put(getApprovalProcessFailure(error.message));
  }
}

function* deleteApprovalProcess({ payload }) {
  try {
    const response = yield call(axios.delete, `/approval-processes/${payload}`);
    if (response.status === 204) {
      yield put(deleteApprovalProcessSuccess());
    }
  } catch (error) {
    yield put(deleteApprovalProcessFailure(error.message));
  }
}

function* startApprovalProcess({ payload }) {
  try {
    const response = yield call(axios.post, `/approval-processes`, payload);
    if (response.status === 201) {
      yield put(startApprovalProcessSuccess(response.data));
    }
  } catch (error) {
    if (error.response.status === 409) {
      yield put(startApprovalProcessConflict());
    } else {
      yield put(startApprovalProcessFailure(error.message));
    }
  }
}

function* signApprovalProcessStep({ payload }) {
  try {
    const response = yield call(
      axios.put,
      `/approval-process-steps/${payload.id}`,
      payload
    );
    if (response.status === 200) {
      yield put(signApprovalProcessStepSuccess(response.data));
    }
  } catch (error) {
    yield put(signApprovalProcessStepFailure(error.message));
  }
}

// Approval Sequences Sagas
function* getApprovalSequences() {
  try {
    const response = yield call(axios.get, "/approval-sequences");
    if (response.status === 200) {
      yield put(getApprovalSequencesSuccess(response.data));
    }
  } catch (error) {
    yield put(getApprovalSequencesFailure(error.message));
  }
}

function* getApprovalSequence({ payload }) {
  try {
    const response = yield call(axios.get, `/approval-sequences/${payload}`);
    if (response.status === 200) {
      yield put(getApprovalSequenceSuccess(response.data));
    }
  } catch (error) {
    yield put(getApprovalSequenceFailure(error.message));
  }
}

function* deleteApprovalSequence({ payload }) {
  try {
    const response = yield call(axios.delete, `/approval-sequences/${payload}`);
    if (response.status === 204) {
      yield put(deleteApprovalSequenceSuccess());
    }
  } catch (error) {
    yield put(deleteApprovalSequenceFailure(error.message));
  }
}
//create sequence
function* createApprovalSequence({ payload }) {
  try {
    const response = yield call(axios.post, `/approval-sequences`, payload);
    if (response.status === 201) {
      yield put(createApprovalSequenceSuccess(response.headers.etag));
    }
  } catch (error) {
    yield put(createApprovalSequenceFailure(error.message));
  }
}

//update sequence
function* updateApprovalSequence({ payload }) {
  try {
    const response = yield call(
      axios.put,
      `/approval-sequences/${payload.id}`,
      payload.data
    );
    if (response.status === 200) {
      yield put(updateApprovalSequenceSuccess(response.data));
    }
  } catch (error) {
    yield put(updateApprovalSequenceFailure(error.message));
  }
}

//get approval step
function* getApprovalStep({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `/approval-process-steps/${payload}`
    );
    if (response.status === 200) {
      yield put(getApprovalStepSuccess(response.data));
    }
  } catch (error) {
    yield put(getApprovalStepFailure(error.message));
  }
}

// Root saga
export default function* rootSaga() {
  yield takeLatest(getApprovalProcessesRequest, getApprovalProcesses);
  yield takeLatest(getApprovalProcessRequest, getApprovalProcess);
  yield takeLatest(deleteApprovalProcessRequest, deleteApprovalProcess);
  yield takeLatest(getApprovalSequencesRequest, getApprovalSequences);
  yield takeLatest(getApprovalSequenceRequest, getApprovalSequence);
  yield takeLatest(deleteApprovalSequenceRequest, deleteApprovalSequence);
  yield takeLatest(createApprovalSequenceRequest, createApprovalSequence);
  yield takeLatest(updateApprovalSequenceRequest, updateApprovalSequence);
  yield takeLatest(startApprovalProcessRequest, startApprovalProcess);
  yield takeLatest(getApprovalStepRequest, getApprovalStep);
  yield takeLatest(signApprovalProcessStepRequest, signApprovalProcessStep);
}
