import { handleActions } from "redux-actions";
import {
  createContactUsFailure,
  createContactUsRequest,
  createContactUsSuccess,
} from "./action";

const initialState = {
  serviceDeskLink: null,
  isCreatedContactUsRequest: false,
  isCreatedContactUsSuccess: false,
  isCreatedContactUsFailure: false,
};

const reducer = handleActions(
  {
    // create Contact us
    [createContactUsRequest]: (state) => ({
      ...state,
      serviceDeskLink: null,
      isCreatedContactUsRequest: true,
      isCreatedContactUsSuccess: false,
      isCreatedContactUsFailure: false,
    }),
    [createContactUsSuccess]: (state, { payload }) => ({
      ...state,
      serviceDeskLink: payload,
      isCreatedContactUsRequest: false,
      isCreatedContactUsSuccess: true,
      isCreatedContactUsFailure: false,
    }),
    [createContactUsFailure]: (state) => ({
      ...state,
      serviceDeskLink: null,
      isCreatedContactUsRequest: false,
      isCreatedContactUsSuccess: false,
      isCreatedContactUsFailure: true,
    }),
  },
  initialState
);

export default reducer;
