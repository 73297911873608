import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PageNotFound = ({ isLogged }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t("page_not_found")} - Decisimo`;
  }, []);

  return (
    <section className="d-flex justify-content-center align-items-center error-section">
      <div className="error-container">
        <span>4</span>
        <span>
          <span className="screen-reader-text">0</span>
        </span>
        <span>4</span>
        <h3 className="h2 text-center">{t("well")},</h3>

        <h5 className="text-center">
          <p>{t("it_looks_like")}</p>
          <p>{t("page_is_nowhere")}</p>
          {!isLogged && (
            <Link to="/login" className="btn btn-primary mt-3">
              {t("go_to_login")}
            </Link>
          )}
        </h5>
      </div>
    </section>
  );
};

PageNotFound.propTypes = {
  isLogged: PropTypes.bool,
};

export default PageNotFound;
