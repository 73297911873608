import { handleActions } from "redux-actions";
import {
  createBatchFailure,
  createBatchRequest,
  createBatchSuccess,
  deleteBatchFailure,
  deleteBatchRequest,
  deleteBatchSuccess,
  deleteTaskFailure,
  deleteTaskRequest,
  deleteTaskSuccess,
  getBatchesFailure,
  getBatchesRequest,
  getBatchesSuccess,
  getBatchFailure,
  getBatchInstancesFailure,
  getBatchInstancesRequest,
  getBatchInstancesSuccess,
  getBatchRequest,
  getBatchSuccess,
  getBatchTasksFailure,
  getBatchTasksRequest,
  getBatchTasksSuccess,
  getBatchTypesFailure,
  getBatchTypesRequest,
  getBatchTypesSuccess,
  updateBatchFailure,
  updateBatchRequest,
  updateBatchSuccess,
  forceScheduleBatchTaskFailure,
  forceScheduleBatchTaskRequest,
  forceScheduleBatchTaskSuccess,
} from "./action";

const initialState = {
  batches: [],
  batch: {},
  batchTypes: [],
  batchInstances: [],
  tasks: [],
  newBatchId: "",
  isGetBatchesSuccess: false,
  isGetBatchesError: false,
  isGetBatchSuccess: false,
  isGetBatchError: false,
  isDeletedBatchSuccess: false,
  isDeletedBatchError: false,
  isCreatedBatchSuccess: false,
  isCreatedBatchError: false,
  isGetBatchTypesSuccess: false,
  isGetBatchTypesError: false,
  isGetBatchInstancesSuccess: false,
  isGetBatchInstancesError: false,
  isGetBatchTasksSuccess: false,
  isGetBatchTasksError: false,
  isUpdatedBatchSuccess: false,
  isUpdatedBatchError: false,
  isTaskCanceledSuccess: false,
  isTaskCanceledError: false,
  isForceScheduleBatchTaskSuccess: false,
  isForceScheduleBatchTaskError: false,
};

const reducer = handleActions(
  {
    // batches
    [getBatchesRequest]: (state) => ({
      ...state,
      isGetBatchesSuccess: false,
      isGetBatchesError: false,
    }),
    [getBatchesSuccess]: (state, { payload }) => ({
      ...state,
      batches: payload || [],
      isGetBatchesSuccess: true,
      isGetBatchesError: false,
    }),
    [getBatchesFailure]: (state) => ({
      ...state,
      isGetBatchesSuccess: false,
      isGetBatchesError: true,
    }),
    // get batch
    [getBatchRequest]: (state) => ({
      ...state,
      isGetBatchSuccess: false,
      isGetBatchError: false,
    }),
    [getBatchSuccess]: (state, { payload }) => ({
      ...state,
      batch: payload || {},
      isGetBatchSuccess: true,
      isGetBatchError: false,
    }),
    [getBatchFailure]: (state) => ({
      ...state,
      isGetBatchSuccess: false,
      isGetBatchError: true,
    }),
    // delete batch
    [deleteBatchRequest]: (state) => ({
      ...state,
      isDeletedBatchSuccess: false,
      isDeletedBatchError: false,
    }),
    [deleteBatchSuccess]: (state) => ({
      ...state,
      isDeletedBatchSuccess: true,
      isDeletedBatchError: false,
    }),
    [deleteBatchFailure]: (state) => ({
      ...state,
      isDeletedBatchSuccess: false,
      isDeletedBatchError: true,
    }),
    // create delete batch
    [createBatchRequest]: (state) => ({
      ...state,
      isCreatedBatchSuccess: false,
      isCreatedBatchError: false,
    }),
    [createBatchSuccess]: (state, { payload }) => ({
      ...state,
      newBatchId: payload || "",
      isCreatedBatchSuccess: true,
      isCreatedBatchError: false,
    }),
    [createBatchFailure]: (state) => ({
      ...state,
      isCreatedBatchSuccess: false,
      isCreatedBatchError: true,
    }),
    // batch types
    [getBatchTypesRequest]: (state) => ({
      ...state,
      isGetBatchTypesSuccess: false,
      isGetBatchTypesError: false,
    }),
    [getBatchTypesSuccess]: (state, { payload }) => ({
      ...state,
      batchTypes: payload || [],
      isGetBatchTypesSuccess: true,
      isGetBatchTypesError: false,
    }),
    [getBatchTypesFailure]: (state) => ({
      ...state,
      isGetBatchTypesSuccess: false,
      isGetBatchTypesError: true,
    }),
    // batch instances
    [getBatchInstancesRequest]: (state) => ({
      ...state,
      isGetBatchInstancesSuccess: false,
      isGetBatchInstancesError: false,
    }),
    [getBatchInstancesSuccess]: (state, { payload }) => ({
      ...state,
      batchInstances: payload || [],
      isGetBatchInstancesSuccess: true,
      isGetBatchInstancesError: false,
    }),
    [getBatchInstancesFailure]: (state) => ({
      ...state,
      isGetBatchInstancesSuccess: false,
      isGetBatchInstancesError: true,
    }),
    // batch tasks
    [getBatchTasksRequest]: (state) => ({
      ...state,
      isGetBatchTasksSuccess: false,
      isGetBatchTasksError: false,
    }),
    [getBatchTasksSuccess]: (state, { payload }) => ({
      ...state,
      tasks: payload || [],
      isGetBatchTasksSuccess: true,
      isGetBatchTasksError: false,
    }),
    [getBatchTasksFailure]: (state) => ({
      ...state,
      isGetBatchTasksSuccess: false,
      isGetBatchTasksError: true,
    }),
    // update batch
    [updateBatchRequest]: (state) => ({
      ...state,
      isUpdatedBatchSuccess: false,
      isUpdatedBatchError: false,
    }),
    [updateBatchSuccess]: (state) => ({
      ...state,
      isUpdatedBatchSuccess: true,
      isUpdatedBatchError: false,
    }),
    [updateBatchFailure]: (state) => ({
      ...state,
      isUpdatedBatchSuccess: false,
      isUpdatedBatchError: true,
    }),
    // cancel task
    [deleteTaskRequest]: (state) => ({
      ...state,
      isTaskCanceledSuccess: false,
      isTaskCanceledError: false,
    }),
    [deleteTaskSuccess]: (state) => ({
      ...state,
      isTaskCanceledSuccess: true,
      isTaskCanceledError: false,
    }),
    [deleteTaskFailure]: (state) => ({
      ...state,
      isTaskCanceledSuccess: false,
      isTaskCanceledError: true,
    }),
    // force schedule task
    [forceScheduleBatchTaskRequest]: (state) => ({
      ...state,
      isForceScheduleBatchTaskSuccess: false,
      isForceScheduleBatchTaskError: false,
    }),
    [forceScheduleBatchTaskSuccess]: (state) => ({
      ...state,
      isForceScheduleBatchTaskSuccess: true,
      isForceScheduleBatchTaskError: false,
    }),
    [forceScheduleBatchTaskFailure]: (state) => ({
      ...state,
      isForceScheduleBatchTaskSuccess: false,
      isForceScheduleBatchTaskError: true,
    }),
  },
  initialState
);

export default reducer;
