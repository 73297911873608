import { createAction } from "redux-actions";

export const getSubscriptionRequest = createAction("GET_SUBSCRIPTIONS");
export const getSubscriptionSuccess = createAction("GET_SUBSCRIPTIONS_SUCCESS");
export const getSubscriptionFailure = createAction("GET_SUBSCRIPTIONS_FAILURE");

export const updateSubscriptionRequest = createAction("UPDATE_SUBSCRIPTIONS");
export const updateSubscriptionSuccess = createAction(
  "UPDATE_SUBSCRIPTIONS_SUCCESS"
);
export const updateSubscriptionFailure = createAction(
  "UPDATE_SUBSCRIPTIONS_FAILURE"
);

export const getCountriesRequest = createAction("GET_COUNTRIES");
export const getCountriesSuccess = createAction("GET_COUNTRIES_SUCCESS");
export const getCountriesFailure = createAction("GET_COUNTRIES_FAILURE");
