import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  clearTemplates,
  createTemplateDecisionFailure,
  createTemplateDecisionRequest,
  createTemplateDecisionSuccess,
  getAllTemplatesSuccess,
  getTemplateDecisionFailure,
  getTemplateDecisionRequest,
  getTemplateDecisionsFailure,
  getTemplateDecisionsRequest,
  getTemplateDecisionsSuccess,
  getTemplateDecisionSuccess,
  getTemplateFailure,
  getTemplateRequest,
  getTemplatesFailure,
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplateSuccess,
} from "redux/templates/action";
import {
  getLanguagesFailure,
  getLanguagesRequest,
  getLanguagesSuccess,
} from "../auth/action";

const useCaseTemplates = (state) => state.templates.useCaseTemplate;

function* getTemplates({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `templates?filter=${payload.entity_type}`
    );
    if (response.status === 200) {
      if (payload.isGetAllTemplates) {
        yield put(getAllTemplatesSuccess(response.data));
      } else {
        yield put(getTemplatesSuccess(response.data));
      }
    }
  } catch (e) {
    yield put(getTemplatesFailure("e.message"));
  }
}

function* getTemplate({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `templates/${payload.service_template_id}`
    );

    const templates = yield select(useCaseTemplates);

    if (response.status === 200) {
      if (payload.type) {
        const newTemplate = { ...response.data, type: payload.type };
        if (payload.decision_table_id) {
          newTemplate.decision_table_id = payload.decision_table_id;
        } else if (payload.rule_set_id) {
          newTemplate.rule_set_id = payload.rule_set_id;
        } else if (payload.external_data_id) {
          newTemplate.external_data_id = payload.external_data_id;
        } else if (payload.data_object_id) {
          newTemplate.data_object_id = payload.data_object_id;
        }
        yield put(
          getTemplateSuccess({
            data: response.data,
            useCaseTemplate: [...templates, newTemplate],
          })
        );
      } else {
        yield put(
          getTemplateSuccess({
            data: response.data,
            useCaseTemplate: [],
          })
        );
      }
    }
  } catch (e) {
    yield put(getTemplateFailure("e.message"));
  }
}

function* clearTemplatesFunc() {
  try {
    yield put(getTemplatesSuccess([]));
  } catch (e) {
    yield put(getTemplatesFailure("e.message"));
  }
}

function* createTemplateDecision({ payload }) {
  try {
    const response = yield call(axios.post, "/ai-decision-designs", payload);
    if (response.status === 202) {
      yield put(createTemplateDecisionSuccess(response.data));
    }
  } catch (e) {
    yield put(
      createTemplateDecisionFailure({
        e: e.message,
        status: e.response.status,
      })
    );
  }
}

function* getTemplatesDecisions() {
  try {
    const response = yield call(axios.get, `/ai-decision-designs`);
    if (response.status === 200) {
      yield put(getTemplateDecisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getTemplateDecisionsFailure("e.message"));
  }
}

function* getTemplateDecision({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `/ai-decision-designs/${payload.id}`
    );
    if (response.status === 200) {
      yield put(getTemplateDecisionSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getTemplateDecisionFailure("e.message"));
  }
}

function* getLanguages() {
  try {
    const response = yield call(axios.get, "/languages");
    if (response.status === 200) {
      yield put(getLanguagesSuccess(response.data));
    }
  } catch (e) {
    yield put(getLanguagesFailure("e.message"));
  }
}

export default function* saga() {
  yield takeEvery(getTemplatesRequest, getTemplates);
  yield takeEvery(getTemplateRequest, getTemplate);
  yield takeLatest(clearTemplates, clearTemplatesFunc);
  yield takeLatest(createTemplateDecisionRequest, createTemplateDecision);
  yield takeLatest(getTemplateDecisionsRequest, getTemplatesDecisions);
  yield takeLatest(getTemplateDecisionRequest, getTemplateDecision);
  yield takeLatest(getLanguagesRequest, getLanguages);
}
