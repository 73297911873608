import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Item from "./Item";
import PropTypes from "prop-types";

const SortableItem = (props) => {
  const { item, id } = props;
  const {
    isDragging = false,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
  };

  return (
    <Item
      nodeRef={setNodeRef}
      item={item}
      style={style}
      isDragging={isDragging}
      {...props}
      {...listeners}
      {...attributes}
    />
  );
};

SortableItem.propTypes = {
  id: PropTypes.any,
  item: PropTypes.object,
};

export default SortableItem;
