import React, { memo, useEffect, useRef } from "react";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import SortableItem from "./SortableItem";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const Table = ({
  handleDragEnd,
  finalRowsData,
  colsClone,
  revisionMode,
  contextMenuIds,
  setContextMenuIds,
  handleShow,
}) => {
  const rows = useRef(finalRowsData);
  const { t } = useTranslation();

  useEffect(() => {
    rows.current = finalRowsData;
  }, [finalRowsData]);

  const handleDataFromChild = (rowIndex, colIndex) => {
    const currentRef = rows.current;
    const element = currentRef[rowIndex];
    const activeElementName = document.activeElement.name;

    if (element) {
      let inputLastParam = "value_a";
      if (
        activeElementName.includes("value_b") &&
        (currentRef[rowIndex].cols[colIndex].operator.includes("open") ||
          currentRef[rowIndex].cols[colIndex].operator.includes("close"))
      ) {
        inputLastParam = "value_b";
      }
      const elName = `field[${currentRef[rowIndex].cols[colIndex].dt_row_column_id}][${inputLastParam}]`;
      const foundedElement = document.querySelector(`input[name='${elName}']`);
      if (foundedElement) {
        foundedElement.focus();
      }
    }
  };

  const TableHeader = memo(() => {
    return (
      <thead className="thead-border">
        <tr>
          <th scope="col" className="text-center">
            <div className="text-gray">{t("index")}</div>
          </th>
          {colsClone?.length > 0 &&
            colsClone.map((col, index) => {
              return (
                <th
                  data-for={`column-identifier-${col.decision_table_column_id}`}
                  data-tip={col.attribute_path}
                  scope="col"
                  key={col.decision_table_column_id}
                  className={`${
                    col.role === "result" &&
                    colsClone[index - 1]?.role !== "result"
                      ? "result-th"
                      : null
                  } 
                    ${
                      col.role === "result"
                        ? "border border-left text-info result"
                        : "text-gray"
                    }
                      `}
                >
                  {col.title}
                  <ReactTooltip
                    id={`column-identifier-${col.decision_table_column_id}`}
                    type="dark"
                    place="top"
                    effect="solid"
                  />
                </th>
              );
            })}
          <th style={{ minWidth: "50px" }} className="delete-column" />
        </tr>
      </thead>
    );
  }, [colsClone]);

  TableHeader.displayName = "TableHeader";

  return (
    <div style={{ overflowX: "auto" }}>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={
            finalRowsData
              ? finalRowsData.map((row) => row.decision_table_row_id)
              : []
          }
          strategy={verticalListSortingStrategy}
        >
          <table
            className="border table-active table table-bordered table-condensed table-layout-fixed decision-table"
            style={{ minWidth: "100%", tableLayout: "auto" }}
          >
            <TableHeader />
            <tbody>
              {finalRowsData &&
                finalRowsData.length > 0 &&
                finalRowsData.map((row, index) => {
                  return (
                    <SortableItem
                      handleDataFromChild={handleDataFromChild}
                      handleShow={handleShow}
                      key={row.decision_table_row_id}
                      index={index}
                      id={row.decision_table_row_id}
                      row={row}
                      revisionMode={revisionMode}
                      contextMenuIds={contextMenuIds}
                      setContextMenuIds={setContextMenuIds}
                    />
                  );
                })}
            </tbody>
          </table>
        </SortableContext>
      </DndContext>
    </div>
  );
};

Table.propTypes = {
  handleDragEnd: PropTypes.any,
  finalRowsData: PropTypes.any,
  colsClone: PropTypes.any,
  revisionMode: PropTypes.any,
  contextMenuIds: PropTypes.any,
  setContextMenuIds: PropTypes.any,
  handleShow: PropTypes.func,
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.finalRowsData.map((row, index) => index)) ===
      JSON.stringify(nextProps.finalRowsData.map((row, index) => index)) &&
    JSON.stringify(prevProps.colsClone) ===
      JSON.stringify(nextProps.colsClone) &&
    JSON.stringify(prevProps.finalRowsData.map((data) => data.cols)) ===
      JSON.stringify(nextProps.finalRowsData.map((data) => data.cols)) &&
    nextProps.contextMenuIds.dt_row_column_id
  );
};

export default memo(Table, comparisonFn);
