import React, { useContext, useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardsRequest } from "redux/dashboard/action";
import usePrevious from "utility/hooks/usePrevious";
import {
  chartEmpty,
  chartOption,
  dashboardCards,
  loadingOption,
} from "utility/utility";
import SubHeader from "components/SubHeader";
import DashBoardCard from "components/dashboard/DashboardCard";
import "components/dashboard/index.scss";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import dummyChartBar from "assets/images/dummy-graph-bar.svg";
import dummyChartBigBar from "assets/images/dummy-graph-big-bar.svg";
import dummyChartStack from "assets/images/dummy-graph-stack.svg";

const Dashboard = () => {
  const { setIsLoading } = useContext(MainContext);
  const chartLast24 = useRef(null);
  const chartRequests = useRef(null);
  const chartExtDataStatus = useRef(null);
  const chartExtDataRequests = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { isGetDashboardsSuccess, isGetDashboardsError, dashboards } =
    useSelector((state) => {
      return state.dashboards;
    });

  const prevIsGetDashboardsSuccess = usePrevious(isGetDashboardsSuccess);
  const prevIsGetDashboardsError = usePrevious(isGetDashboardsError);

  const [overview, setOverview] = useState({});

  useEffect(() => {
    document.title = `${t("dashboard")} - Decisimo`;
    setIsLoading(true);
    dispatch(getDashboardsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isGetDashboardsSuccess && prevIsGetDashboardsSuccess === false) {
      setIsLoading(false);
      setOverview(dashboards?.overview);
      const dashboardClone = structuredClone(dashboards);
      drawLast24(dashboardClone?.request24hrs);

      if (dashboardClone.ext_data_dist?.date_request?.length > 0) {
        drawExtDataStatus(dashboardClone.ext_data_dist);
        drawExtDataRequests(dashboardClone.ext_data_dist);
        drawRequests({
          date_request: dashboardClone.ext_data_dist.date_request,
          cnt_request: dashboardClone.ext_data_dist.cnt_request,
        });
      } else {
        setHasChartData((prevState) => ({
          ...prevState,
          extDataStatus: false,
        }));
        setHasChartData((prevState) => ({
          ...prevState,
          extDataRequests: false,
        }));
        setHasChartData((prevState) => ({
          ...prevState,
          requests: false,
        }));
      }
    }
  }, [isGetDashboardsSuccess]);

  useEffect(() => {
    if (isGetDashboardsSuccess) {
      setOverview(dashboards?.overview);
      const dashboardClone = structuredClone(dashboards);
      drawLast24(dashboardClone?.request24hrs);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (isGetDashboardsError && prevIsGetDashboardsError === false) {
      setIsLoading(false);
      chartLast24Ref().hideLoading();
      chartExtDataStatusRef().hideLoading();
      chartExtDataRequestsRef().hideLoading();
      chartRequestsRef().hideLoading();
    }
  }, [isGetDashboardsError]);

  const chartLast24Ref = () =>
    chartLast24.current && echarts.init(chartLast24.current.ele);

  const chartRequestsRef = () =>
    chartRequests.current && echarts.init(chartRequests.current.ele);

  const chartExtDataStatusRef = () =>
    chartExtDataStatus.current && echarts.init(chartExtDataStatus.current.ele);

  const chartExtDataRequestsRef = () =>
    chartExtDataRequests.current &&
    echarts.init(chartExtDataRequests.current.ele);

  const [hasChartData, setHasChartData] = useState({
    last24: true,
    requests: true,
    extDataStatus: true,
    extDataRequests: true,
  });

  function drawLast24(last24Data = {}) {
    if (last24Data.endpoints && last24Data.endpoints.length > 0) {
      setHasChartData((prevState) => ({ ...prevState, last24: true }));

      let last24h = chartOption;
      last24h.title.text = t("endpoint_requests_response");
      last24h.title.textStyle = {
        color: "#003057",
        fontWeight: 600,
        fontSize: "18px",
      };
      // last24h.title.textStyle.fontWeight = "bold";
      last24h.tooltip = {
        trigger: "axis",
      };
      last24h.yAxis = [
        {
          axisLine: {
            show: true,
          },
          type: "value",
          name: t("dashboard_chart_request_cnt"),
          nameTextStyle: {
            color: " #000000",
          },
          scale: true,
          min: 0,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
            length: 12,
          },
          axisLabel: {
            margin: 16,
            formatter: function (param) {
              return param === 0 ? "" : param;
            },
          },
          splitLine: {
            show: false,
          },
        },
        {
          axisLine: {
            show: true,
          },
          type: "value",
          scale: true,
          name: "Avg speed [ms]",
          nameTextStyle: {
            color: " #000000",
          },
          min: 0,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
            length: 12,
          },
          axisLabel: {
            margin: 16,
            formatter: function (param) {
              return param === 0 ? "" : param;
            },
          },
          splitLine: {
            show: false,
          },
        },
      ];

      let vSeries = [];
      let vMax = 10;
      let vRequestMax = 10;

      last24Data.endpoints?.forEach((vEndpoint) => {
        const currentMax = Math.max(...last24Data.avg_duration[vEndpoint].data);
        const requestMax = Math.max(...last24Data.cnt_request[vEndpoint].data);
        vMax = vMax > currentMax ? vMax : currentMax;
        vRequestMax = vRequestMax + requestMax;
        vSeries.push({
          name: vEndpoint + `: ${t("response_speed")} [${t("ms")}]`,
          nameTextStyle: {
            color: " #000000",
          },
          yAxisIndex: 1,
          type: "line",
          lineStyle: { width: 1 },
          data: last24Data.avg_duration[vEndpoint].data,
        });

        vSeries.push({
          name: vEndpoint + `: ${t("requests")} [#]`,
          nameTextStyle: {
            color: " #000000",
          },
          type: "bar",
          stack: "volume",
          emphasis: {
            focus: "series",
          },
          yAxis: 0,
          data: last24Data.cnt_request[vEndpoint].data,
        });
      });

      last24h.legend.data = last24Data.endpoints;
      last24h.xAxis.data = last24Data.legend;
      last24h.xAxis.axisTick = {
        length: 12,
      };
      (last24h.xAxis.axisLabel = {
        margin: 16,
      }),
        (last24h.series = vSeries);
      last24h.yAxis[0].max = Math.round(vRequestMax * 1.1);
      last24h.yAxis[1].max = Math.round(vMax * 1.1);

      if (!last24Data.endpoints) {
        last24h = chartEmpty;
      }
      chartLast24Ref().setOption(last24h);
    } else {
      setHasChartData((prevState) => ({ ...prevState, last24: false }));
    }
  }

  function drawExtDataStatus(extDataRequests = {}) {
    if (
      extDataRequests?.rate_4x.length > 0 ||
      extDataRequests?.rate_5x.length > 0 ||
      extDataRequests?.rate_2x.length > 0
    ) {
      setHasChartData((prevState) => ({ ...prevState, extDataStatus: true }));
      const extDataStatus = chartOption;
      extDataStatus.title.text = t("dashboard_ext_data_request_status");
      extDataStatus.tooltip = {
        trigger: "axis",
      };
      extDataStatus.yAxis = [
        {
          axisLine: {
            show: true,
          },
          type: "value",
          name: t("ratio"),
          nameTextStyle: {
            color: " #000000",
          },
          scale: true,
          min: 0,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
            length: 12,
          },
          axisLabel: {
            margin: 16,
            formatter: function (param) {
              return param === 0 ? "" : param;
            },
          },
          splitLine: {
            show: false,
          },
        },
      ];

      const vSeries = [];

      vSeries.push({
        name: `${t("response")} 4xx [%]`,
        type: "line",
        stack: "volume",
        data: extDataRequests.rate_4x,
        areaStyle: {
          color: "#C23531",
          shadowColor: "#C23531",
        },
        color: "#C23531",
        symbol: "none",
        lineStyle: {
          color: "#C23531",
        },
      });
      vSeries.push({
        name: `${t("response")} 5xx [%]`,
        type: "line",
        stack: "volume",
        data: extDataRequests.rate_5x,
        areaStyle: {
          color: "#91c7ae",
          shadowColor: "#91c7ae",
        },
        color: "#91c7ae",
        symbol: "none",
        lineStyle: {
          color: "#91c7ae",
        },
      });
      vSeries.push({
        name: `${t("response")} 2xx [%]`,
        type: "line",
        stack: "volume",
        data: extDataRequests.rate_2x,
        areaStyle: {
          color: "#A5EDB7",
          shadowColor: "#A5EDB7",
        },
        color: "#A5EDB7",
        symbol: "none",
        lineStyle: {
          color: "#A5EDB7",
        },
      });
      extDataStatus.xAxis.boundaryGap = false;
      extDataStatus.xAxis.data = extDataRequests.date_request;
      extDataStatus.series = vSeries;

      chartExtDataStatusRef().setOption(extDataStatus);
    } else {
      setHasChartData((prevState) => ({ ...prevState, extDataStatus: false }));
    }
  }

  function drawExtDataRequests(extData = {}) {
    if (extData.cnt_ext_data_request) {
      const extDataRequests = chartOption;
      extDataRequests.title.text = t("dashboard_ext_data_request_count");
      extDataRequests.tooltip = {
        trigger: "axis",
      };
      extDataRequests.yAxis = [
        {
          axisLine: {
            show: true,
          },
          type: "value",
          name: t("dashboard_chart_request_cnt"),
          scale: true,
          nameTextStyle: {
            color: " #000000",
          },
          min: 0,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
            length: 12,
          },
          axisLabel: {
            margin: 16,
            formatter: function (param) {
              return param === 0 ? "" : param;
            },
          },
          splitLine: {
            show: false,
          },
        },
      ];
      const vSeries = [];

      vSeries.push({
        name: t("dashboard_chart_request_cnt"),
        type: "bar",
        barWidth: 27,
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          color: "#014A80",
        },
        data: extData.cnt_ext_data_request,
      });

      extDataRequests.yAxis[0].max = Math.max(...extData.cnt_ext_data_request);

      extDataRequests.xAxis.boundaryGap = true;
      extDataRequests.xAxis.data = extData.date_request;
      extDataRequests.xAxis.type = "category";
      extDataRequests.series = vSeries;

      chartExtDataRequestsRef().setOption(extDataRequests);
    } else {
      setHasChartData((prevState) => ({
        ...prevState,
        extDataRequests: false,
      }));
    }
  }

  function drawRequests(requestsInfo = {}) {
    if (requestsInfo.cnt_request?.length !== 0) {
      let requests = chartOption;
      requests.title.text = t("dashboard_request_count");
      requests.tooltip = {
        trigger: "axis",
      };
      requests.yAxis = [
        {
          type: "value",
          name: t("dashboard_chart_request_cnt"),
          nameTextStyle: {
            color: " #000000",
          },
          scale: true,
          min: 0,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
            length: 12,
          },
          axisLabel: {
            margin: 16,
            formatter: function (param) {
              return param === 0 ? "" : param;
            },
          },
          splitLine: {
            show: false,
          },
        },
      ];
      const vSeries = [];

      vSeries.push({
        name: t("dashboard_chart_detail_requests"),
        nameTextStyle: {
          color: " #000000",
        },
        type: "bar",
        barWidth: 27,
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          color: "#014A80",
        },
        data: requestsInfo.cnt_request,
      });

      requests.yAxis[0].max = Math.max(...requestsInfo.cnt_request) + 100;

      requests.xAxis.boundaryGap = true;
      requests.xAxis.data = requestsInfo.date_request;
      requests.xAxis.type = "category";
      requests.series = vSeries;

      if (requestsInfo.cnt_request?.length === 0) {
        requests = chartEmpty;
      }
      chartRequestsRef().setOption(requests);
    } else {
      setHasChartData((prevState) => ({ ...prevState, requests: false }));
    }
  }

  return (
    <>
      <SubHeader title={t("nav_bar_dashboard")} />

      <div className="dashboard-row">
        {dashboardCards(overview).map((card) => (
          <DashBoardCard
            key={card.id}
            size={card.size}
            color={card.color}
            title={card.title}
            titleKey={card.titleTranslate}
            description={card.description ? card.description : "-"}
            subTitle={t(card.key)}
          />
        ))}
      </div>

      <div className="row">
        <div className="col-md-6 col-12">
          {hasChartData.last24 ? (
            <ReactECharts
              option={{}}
              showLoading={!isGetDashboardsSuccess}
              loadingOption={loadingOption}
              ref={(chart) => {
                chartLast24.current = chart;
              }}
            />
          ) : (
            <img src={dummyChartBar} alt="No Data" />
          )}
        </div>
        <div className="col-md-6 col-12">
          {hasChartData.requests ? (
            <ReactECharts
              option={{}}
              showLoading={!isGetDashboardsSuccess}
              loadingOption={loadingOption}
              ref={(chart) => {
                chartRequests.current = chart;
              }}
            />
          ) : (
            <img src={dummyChartBigBar} alt="No Data" />
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6 col-12">
          {hasChartData.extDataStatus ? (
            <ReactECharts
              option={{}}
              showLoading={!isGetDashboardsSuccess}
              loadingOption={loadingOption}
              ref={(chart) => {
                chartExtDataStatus.current = chart;
              }}
            />
          ) : (
            <img src={dummyChartStack} alt="No Data" />
          )}
        </div>
        <div className="col-md-6 col-12">
          {hasChartData.extDataRequests ? (
            <ReactECharts
              option={{}}
              showLoading={!isGetDashboardsSuccess}
              loadingOption={loadingOption}
              ref={(chart) => {
                chartExtDataRequests.current = chart;
              }}
            />
          ) : (
            <img src={dummyChartBigBar} alt="No Data" />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
