export const onHandleUpdateRole = (e, roleRightsData) => {
  const data = new URLSearchParams();
  data.append("title", e.title.value);
  //if sso element exists
  if (e.is_default_sso)
    data.append("is_default_sso", e.is_default_sso.checked ? 1 : 0);
  roleRightsData.forEach((roleRight) => {
    data.append(`right[${roleRight.access_right_id}][_____irrelevant]`, 1);

    if (e[`right[${roleRight.access_right_id}][access_read]`].checked) {
      data.append(`right[${roleRight.access_right_id}][access_read]`, 1);
    }

    if (e[`right[${roleRight.access_right_id}][access_write]`].checked) {
      data.append(`right[${roleRight.access_right_id}][access_write]`, 1);
    }

    if (e[`right[${roleRight.access_right_id}][access_execute]`].checked) {
      data.append(`right[${roleRight.access_right_id}][access_execute]`, 1);
    }
  });
  return data;
};
