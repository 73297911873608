import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import { createBatchRequest } from "redux/batches/action";
import BatchContent from "./BatchContent";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { useTranslation } from "react-i18next";

const CreateBatch = () => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(handleCreateBatch);
  const { t } = useTranslation();

  const { newBatchId, isCreatedBatchSuccess, isCreatedBatchError } =
    useSelector((state) => state.batches);

  const { workflowReleases } = useSelector((state) => state.releases);

  const prevIsCreatedBatchSuccess = usePrevious(isCreatedBatchSuccess);
  const prevIsCreatedBatchError = usePrevious(isCreatedBatchError);

  useEffect(() => {
    document.title = `${t("batch")} - Decisimo`;
  }, []);

  useEffect(() => {
    if (isCreatedBatchSuccess && prevIsCreatedBatchSuccess === false) {
      setIsLoading(false);
      toast.success(t("batch_created"), ToastOptions);
      navigate(`/batches/${newBatchId}`);
    }
  }, [isCreatedBatchSuccess]);

  useEffect(() => {
    if (isCreatedBatchError && prevIsCreatedBatchError === false) {
      setIsLoading(false);
    }
  }, [isCreatedBatchError]);

  useEffect(() => {
    if (workflowReleases.length) {
      document
        .getElementById("workflow_release_id")
        .classList.remove("is-invalid");
    }
  }, [workflowReleases]);

  function handleCreateBatch(target) {
    setIsEdited(false);
    const data = new FormData(target);
    setIsLoading(true);
    if (workflowReleases.length) {
      dispatch(createBatchRequest(data));
    } else {
      toast.error(t("release_no_empty"), ToastOptions);
      document
        .getElementById("workflow_release_id")
        .classList.add("is-invalid");
      setIsLoading(false);
    }
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleCreateBatch(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          title={t("batch_definition")}
          actions={
            <>
              <Link to={`/batches`} className="mr-2">
                <button className="btn outline" title={t("back_to_table")}>
                  <BackIcon />
                </button>
              </Link>
              <button
                className="btn primary mr-2"
                type="submit"
                title={t("save")}
              >
                <SaveIcon />
              </button>
            </>
          }
        />
        <BatchContent />
      </form>
    </>
  );
};

export default CreateBatch;
