import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import * as RJD from "react-js-diagrams";
import PropTypes from "prop-types";
import {
  deleteLinkRequest,
  updateWorkflowRequest,
} from "redux/workflows/action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { WorkflowContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { toPng } from "html-to-image";

const DiagramDraw = forwardRef(({ nodes, links, engine, workflow }, ref) => {
  const model = new RJD.DiagramModel();
  const { id, revisionId } = useParams();
  const { isCanvasDragging, setIsUpdatedStep } = useContext(WorkflowContext);
  const dispatch = useDispatch();
  const canvas = useRef(null);
  const [zoom, setZoom] = useState(100);
  const [offset, setOffset] = useState({});
  const [sourceNodeId, setSourceNodeId] = useState(null);
  const [targetNodeId, setTargetNodeId] = useState(null);
  const [isRendered, setIsRendered] = useState(false);
  const [localWorkflow, setLocalWorkflow] = useState({});
  const prevZoom = usePrevious(zoom);
  model.setZoomLevel(zoom);

  useImperativeHandle(
    ref,
    () => ({
      zoomFit() {
        handleZoomToFit();
      },
      zoom() {
        handleZoom();
      },
      zoomOut() {
        handleZoomOut();
      },
      saveAsImage() {
        handleSaveAsImage();
      },
    }),
    []
  );

  useEffect(() => {
    if (workflow && Object.keys(workflow)?.length > 0) {
      setLocalWorkflow(workflow);
    }
  }, [workflow]);

  useEffect(() => {
    if (zoom === prevZoom && !isRendered) {
      handleZoomToFit();
    }
  }, [links, nodes]);

  useEffect(() => {
    if (Object.keys(offset).length > 0) {
      model.setOffset(offset.offsetX, offset.offsetY);
    }

    canvas.current.refs.canvas.addEventListener(
      "wheel",
      function (e) {
        if (e.ctrlKey) {
          const diagramModel = engine.diagramModel;
          e.preventDefault();
          e.stopPropagation();
          if (
            diagramModel.getZoomLevel() - e.deltaY / 300 > 10 &&
            diagramModel.getZoomLevel() - e.deltaY < 300
          ) {
            diagramModel.setZoomLevel(
              diagramModel.getZoomLevel() - e.deltaY / 300
            );
            setZoom(diagramModel.getZoomLevel() - e.deltaY / 300);
            engine.enableRepaintEntities([]);
            engine.forceUpdate();
          }
        }
      },
      {
        passive: false,
      }
    );

    nodes.forEach((node) => {
      model.addNode(node);
    });

    links.forEach((link) => {
      model.addLink(link);
    });
    engine.setDiagramModel(model);
  }, [engine, links, nodes, model, workflow]);

  const updateWorkflow = (workflow) => {
    const workflowData = structuredClone(workflow);

    workflowData.workflow_step = workflowData.workflow_step.map((step) => {
      step.options = [];
      return step;
    });

    const data = JSON.stringify({
      steps: workflowData.workflow_step,
      title: workflowData.title,
      name: workflowData.name,
      description: workflowData.description,
      block_deletion: workflowData.block_deletion,
    });
    const id = workflow.workflow_id;
    setLocalWorkflow(workflow);
    dispatch(updateWorkflowRequest({ id, data }));
  };

  const handleZoomToFit = () => {
    const xFactor = engine.canvas.clientWidth / engine.canvas.scrollWidth;
    const yFactor = engine.canvas.clientHeight / engine.canvas.scrollHeight;
    const zoomFactor = xFactor < yFactor ? xFactor : yFactor;
    setZoom(zoom * zoomFactor);
    model.setZoomLevel(zoom * zoomFactor);
    model.setOffset(0, 0);
    engine.enableRepaintEntities([]);
    engine.forceUpdate();
  };

  const handleZoom = () => {
    const zoomSize = engine.diagramModel.getZoomLevel() + 10;
    engine.diagramModel.setZoomLevel(zoomSize);
    engine.enableRepaintEntities([]);
    setZoom(zoomSize);
    setTimeout(() => {
      engine.forceUpdate();
    });
  };

  const handleZoomOut = () => {
    if (engine.diagramModel.getZoomLevel() > 20) {
      const zoomSize = engine.diagramModel.getZoomLevel() - 10;
      engine.diagramModel.setZoomLevel(zoomSize);
      engine.enableRepaintEntities([]);
      setZoom(zoomSize);
      setTimeout(() => {
        engine.forceUpdate();
      });
    }
  };

  const saveAsImage = () => {
    return new Promise((resolve, reject) => {
      if (canvas.current && canvas.current.refs.canvas) {
        const diagramCanvas = canvas.current.refs.canvas;

        // Temporarily set the background to transparent
        const originalBackground = diagramCanvas.style.background;
        const originalBorderRight = diagramCanvas.style.borderRight;
        const originalBorderTop = diagramCanvas.style.borderTop;
        const originalBorder = diagramCanvas.style.border;
        diagramCanvas.style.background = "none";
        diagramCanvas.style.borderRight = "none";
        diagramCanvas.style.borderTop = "none";
        diagramCanvas.style.border = "none";

        // Create an off-screen canvas to draw the diagram
        const offScreenCanvas = document.createElement("canvas");
        const offScreenContext = offScreenCanvas.getContext("2d");
        const boundingBox = calculateBoundingBox(diagramCanvas);
        const scaleFactor = 0.5; // Change this factor to scale the image (0.5 means 50% size)

        offScreenCanvas.width = boundingBox.width * scaleFactor;
        offScreenCanvas.height = boundingBox.height * scaleFactor;

        // Ensure the off-screen canvas background is transparent
        offScreenContext.fillStyle = "rgba(0, 0, 0, 0)";
        offScreenContext.fillRect(
          0,
          0,
          offScreenCanvas.width,
          offScreenCanvas.height
        );

        // Generate full PNG of the canvas without affecting the visible canvas
        toPng(diagramCanvas)
          .then((dataUrl) => {
            const image = new Image();
            image.src = dataUrl;

            image.onload = () => {
              // Draw the cropped and scaled part of the image onto the off-screen canvas
              offScreenContext.drawImage(
                image,
                boundingBox.x,
                boundingBox.y,
                boundingBox.width,
                boundingBox.height,
                0,
                0,
                boundingBox.width * scaleFactor,
                boundingBox.height * scaleFactor
              );

              // Convert the off-screen canvas to PNG
              offScreenCanvas.toBlob((blob) => {
                // Restore the original background
                diagramCanvas.style.background = originalBackground;
                diagramCanvas.style.borderRight = originalBorderRight;
                diagramCanvas.style.borderTop = originalBorderTop;
                diagramCanvas.style.border = originalBorder;

                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error("Blob creation failed."));
                }
              }, "image/png");
            };

            image.onerror = (error) => {
              // Restore the original background
              diagramCanvas.style.background = originalBackground;
              diagramCanvas.style.borderRight = originalBorderRight;
              diagramCanvas.style.borderTop = originalBorderTop;
              diagramCanvas.style.border = originalBorder;
              reject(error);
            };
          })
          .catch((error) => {
            // Restore the original background
            diagramCanvas.style.background = originalBackground;
            diagramCanvas.style.borderRight = originalBorderRight;
            diagramCanvas.style.borderTop = originalBorderTop;
            diagramCanvas.style.border = originalBorder;
            reject(error);
          });
      } else {
        reject(new Error("Canvas ref is not set."));
      }
    });
  };

  const calculateBoundingBox = (diagramCanvas) => {
    const nodes = diagramCanvas.querySelectorAll(".node, .port");
    const svgElement = diagramCanvas.querySelector("svg");
    const links = svgElement ? svgElement.querySelectorAll("path") : [];

    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;

    nodes.forEach((node) => {
      const bbox = node.getBoundingClientRect();
      minX = Math.min(minX, bbox.left);
      minY = Math.min(minY, bbox.top);
      maxX = Math.max(maxX, bbox.right);
      maxY = Math.max(maxY, bbox.bottom);
    });

    links.forEach((link) => {
      const bbox = link.getBBox();
      minX = Math.min(minX, bbox.x);
      minY = Math.min(minY, bbox.y);
      maxX = Math.max(maxX, bbox.x + bbox.width);
      maxY = Math.max(maxY, bbox.y + bbox.height);
    });

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  };

  const handleSaveAsImage = async () => {
    try {
      const blob = await saveAsImage();
      // Create a link element to download the image
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "diagram.png";
      document.body.appendChild(link); // Append link to body
      link.click(); // Trigger click event on the link
      document.body.removeChild(link); // Remove link from body
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  const onChange = (event, item) => {
    if (!revisionId) {
      if (!isRendered) {
        setIsRendered(true);
      }
      if (item.type === "node-moved" && item.model) {
        setZoom(model.zoom);
        setOffset({ offsetX: event.offsetX, offsetY: event.offsetY });
        const workflowClone = structuredClone(
          typeof localWorkflow === "string"
            ? JSON.parse(localWorkflow)
            : localWorkflow
        );
        const steps = workflowClone.workflow_step;
        const node = steps?.find(
          (node) => node.workflow_step_id === item.model.data.id
        );
        if (
          node &&
          node.position_left !== item.model.x &&
          node.position_top !== item.model.y
        ) {
          node.position_left = item.model.x;
          node.position_top = item.model.y;

          setIsUpdatedStep(true);
          updateWorkflow(workflowClone);
        }
      } else if (item.type === "link-selected") {
        if (sourceNodeId && targetNodeId) {
          selectedLink(sourceNodeId, targetNodeId, false);
        }
        setSourceNodeId(item.model.sourcePort.parentNode.id);
        setTargetNodeId(item.model.targetPort.parentNode.id);
        selectedLink(
          item.model.sourcePort.parentNode.id,
          item.model.targetPort.parentNode.id,
          true,
          item.model.sourcePort.name
        );
      } else if (item.type === "link-connected") {
        const isSamePort =
          item.linkModel.sourcePort.name.includes(
            item.linkModel.targetPort.name
          ) ||
          item.linkModel.targetPort.name.includes(
            item.linkModel.sourcePort.name
          );
        const isSameNode =
          item.linkModel.sourcePort.parentNode.data.id ===
          item.linkModel.targetPort.parentNode.data.id;
        const isDisabled =
          item.linkModel.sourcePort.disabled ||
          item.linkModel.sourcePort.isInput;

        const sourceNode = item.linkModel.sourcePort.parentNode;
        const sourceNodeType = sourceNode.data.type;

        const isInvalidConnection =
          !item.linkModel.targetPort || isSamePort || isSameNode || isDisabled;

        if (isInvalidConnection) {
          item.linkModel.points = [];
        } else {
          const workflowClone = structuredClone(
            typeof localWorkflow === "string"
              ? JSON.parse(localWorkflow)
              : localWorkflow
          );
          const step = workflowClone?.workflow_step?.find(
            (flow) =>
              flow.workflow_step_id ===
              item.linkModel.sourcePort.parentNode.data.id
          );
          if (!step.links) {
            step.links = [];
          }
          const sourcePortName = item.linkModel.sourcePort.name;

          // Allow multiple outputs only if the node is a fork
          if (sourceNodeType === "fork") {
            // For fork nodes, allow multiple outputs
            step.links.push({
              workflow_step_id_from:
                item.linkModel.sourcePort.parentNode.data.id,
              workflow_step_id_to: item.linkModel.targetPort.parentNode.data.id,
              workflow_step_link_id: `new_${Math.round(Math.random() * 10000)}`,
              // You may use order_index if necessary
            });
          } else {
            // For non-fork nodes, check if an existing link exists
            const existingLink = step.links.find(
              (link) =>
                link.workflow_step_id_from ===
                item.linkModel.sourcePort.parentNode.data.id
            );

            if (existingLink) {
              // Remove the existing link if any
              const linkId = existingLink.workflow_step_link_id;
              dispatch(deleteLinkRequest({ id, linkId }));
              step.links = step.links.filter(
                (link) => link.workflow_step_link_id !== linkId
              );
            }

            // Add the new link
            step.links.push({
              workflow_step_id_from:
                item.linkModel.sourcePort.parentNode.data.id,
              workflow_step_id_to: item.linkModel.targetPort.parentNode.data.id,
              workflow_step_link_id: `new_${Math.round(Math.random() * 10000)}`,
              // order_index if needed
            });
          }

          setIsUpdatedStep(false);

          updateWorkflow(workflowClone);
        }
        return false;
      } else if (item.type === "link-created") {
        if (item.model && item.model.link) {
          item.model.link.points = [];
        } else {
          const modelLinksKeys = Object.keys(engine.diagramModel.links).filter(
            (key) => {
              return engine.diagramModel.links[key].targetPort !== null;
            }
          );
          engine.diagramModel.links = Object.keys(engine.diagramModel.links)
            .filter((key) => modelLinksKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = engine.diagramModel.links[key];
              return obj;
            }, {});
        }
      } else if (item.type === "canvas-drag") {
        setOffset({ offsetX: event.offsetX, offsetY: event.offsetY });
        setZoom(model.zoom);
      } else {
        if (sourceNodeId && targetNodeId) {
          selectedLink(sourceNodeId, targetNodeId);
        }
      }
      engine.diagramModel.setOffset(event?.offsetX, event?.offsetY);
      return false;
    }
  };

  const selectedLink = (
    sourceNodeId,
    targetNodeId,
    linkSelected = false,
    sourcePortName = ""
  ) => {
    const portRight = document.querySelector(
      `.port[data-nodeid="${sourceNodeId}"][data-name="right"]`
    );
    const portRightDown = document.querySelector(
      `.port[data-nodeid="${sourceNodeId}"][data-name="right-down"]`
    );
    const portLeft = document.querySelector(
      `.port[data-nodeid="${targetNodeId}"][data-name="left"]`
    );

    if (linkSelected !== null) {
      if (sourcePortName === "right-down") {
        portRightDown?.classList.add("port-border-left");
      }
      portLeft?.classList.add("port-border-left");
      portRight?.classList.add("port-border-left");
    }

    if (!linkSelected) {
      portRight?.classList.remove("selected");
      portRightDown?.classList.remove("selected");
      portLeft?.classList.remove("selected");
    } else {
      portLeft?.classList.add("selected");
      if (sourcePortName === "right-down") {
        portRightDown?.classList.add("selected");
      } else {
        portRight?.classList.add("selected");
      }
    }
  };

  return (
    <RJD.DiagramWidget
      ref={canvas}
      diagramEngine={engine}
      onChange={(e, item) => onChange(e, item)}
      actions={{
        zoom: false,
        canvasDrag: !revisionId && isCanvasDragging,
        copy: false,
        selectAll: false,
        deleteItems: false,
        multiselect: false,
        multiselectDrag: false,
        moveItems: !revisionId,
      }}
    />
  );
});

DiagramDraw.displayName = "DiagramDraw";

DiagramDraw.propTypes = {
  nodes: PropTypes.array,
  links: PropTypes.array,
  engine: PropTypes.any,
  workflow: PropTypes.object,
};

export default DiagramDraw;
