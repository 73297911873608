import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { RevisionContext } from "context/contexts";
import HiddenInputs from "components/form/HiddenInputs";
import CustomSelect from "../../../CustomSelect";

const Variable = ({ item, attributes }) => {
  const revisionMode = useContext(RevisionContext);
  const [attributesName, setAttributesName] = useState([]);

  const getSelectValue = () => {
    return attributesName.find((data) => data.value === item.fce_attribute_id);
  };

  useEffect(() => {
    const filteredAttributes = attributes.filter((item) => item.name !== "");
    const newAtt = filteredAttributes.map((item) => ({
      label: item.name,
      value: item.fce_attribute_id,
    }));

    setAttributesName(newAtt);
  }, [attributes]);

  return (
    <div className="input-group-prepend">
      {!revisionMode && (
        <HiddenInputs
          data={[
            {
              name: `fce_itm[${item.fce_item_id}][parent_fce_item_id]`,
              value: item.parent_fce_item_id ? item.parent_fce_item_id : "null",
            },
            {
              name: `fce_itm[${item.fce_item_id}][item_type]`,
              value: "a",
            },
            {
              name: `fce_itm[${item.fce_item_id}][fce_item_id]`,
              value: item.fce_item_id,
            },
          ]}
        />
      )}
      {attributesName.length ? (
        <CustomSelect
          name={`fce_itm[${item.fce_item_id}][fce_attribute_id]`}
          menuPosition="fixed"
          defaultValue={getSelectValue}
          key={attributesName.length}
          options={attributesName}
          isSearchable={false}
          isFormula={true}
          isDisabled={revisionMode}
          components={{
            IndicatorSeparator: () => null,
          }}
          selectStyle={{
            dropdownIndicator: (styles) => ({
              ...styles,
              marginRight: "0",
            }),
          }}
        />
      ) : null}
    </div>
  );
};

Variable.propTypes = {
  item: PropTypes.object,
  attributes: PropTypes.array,
};

export default Variable;
