import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUserAccountRequest } from "redux/user/action";
import { getUserRolesRequest } from "redux/roleRights/action";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";

const CreateUserAccount = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(onSubmitHandler);
  const { t } = useTranslation();

  const { userRoles, isGetUserRolesSuccess, isGetUserRolesError } = useSelector(
    (state) => {
      return state.roleRights;
    }
  );

  const {
    isCreatedAccountSuccess,
    newUserAccountId,
    isCreatedAccountConflict,
  } = useSelector((state) => {
    return state.users;
  });

  const prevIsGetUserRolesSuccess = usePrevious(isGetUserRolesSuccess);
  const prevIsGetUserRolesError = usePrevious(isGetUserRolesError);
  const prevIsCreatedAccountSuccess = usePrevious(isCreatedAccountSuccess);
  const prevIsCreatedAccountConflict = usePrevious(isCreatedAccountConflict);

  useEffect(() => {
    document.title = t("user_decisimo");
    setIsLoading(true);
    dispatch(getUserRolesRequest());
  }, []);

  useEffect(() => {
    if (
      (isGetUserRolesSuccess && prevIsGetUserRolesSuccess === false) ||
      (isGetUserRolesError && prevIsGetUserRolesError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetUserRolesSuccess, isGetUserRolesError]);

  useEffect(() => {
    if (isCreatedAccountSuccess && prevIsCreatedAccountSuccess === false) {
      setIsLoading(false);
      toast.success(t("user_account_added"), ToastOptions);
      navigate(`/user-accounts/${newUserAccountId}`);
    }
  }, [isCreatedAccountSuccess]);

  //conflict
  useEffect(() => {
    if (isCreatedAccountConflict && prevIsCreatedAccountConflict === false) {
      setIsLoading(false);
      toast.error(t("user_with_email_already_exists"), ToastOptions);
    }
  }, [isCreatedAccountConflict]);

  function onSubmitHandler(target) {
    const data = new URLSearchParams();
    data.append("full_name", target.full_name.value);
    data.append("email", target.email.value);
    data.append("send_email", target.send_email.checked ? 1 : 0);
    data.append(
      "two_factor_required",
      target.two_factor_required.checked ? 1 : 0
    );
    let accessRoleId = target.access_role_id;
    if (userRoles?.length === 1) {
      accessRoleId = [target.access_role_id];
    }

    const roles = [];
    accessRoleId.forEach((roleId) => {
      if (roleId.checked) {
        roles.push(roleId.value);
        data.append("access_role_id[]", roleId.value);
      }
    });

    if (roles.length) {
      setIsLoading(true);
      dispatch(createUserAccountRequest(data));
    } else {
      toast.error(t("check_role"), ToastOptions);
    }
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        id="createUserAccountForm"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitHandler(e.target);
        }}
      >
        <SubHeader
          title={t("user_account")}
          actions={
            <>
              <Link to="/user-accounts">
                <button className="btn outline-header">
                  <BackIcon />{" "}
                  <span className="ml-2">{t("back_to_users")}</span>
                </button>
              </Link>

              <button className="btn primary-header mx-2" type="submit">
                <SaveIcon />
              </button>
            </>
          }
        />
        <div className="row mb-4">
          <div className="col-md col-12 mb-md-0 mb-3">
            <label>{t("full_name")}</label>
            <input
              type="text"
              className="form-control"
              name="full_name"
              required
            />
          </div>
          <div className="col-md col-12">
            <label>{t("email")}</label>
            <input
              type="email"
              className="form-control"
              name="email"
              required
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md col-12">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                id="send_email"
                name="send_email"
                className="custom-control-input"
                defaultChecked
              />
              <label htmlFor="send_email" className="custom-control-label ml-2">
                {t("send_an_info_email_to_the_user_about_created_account")}
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md col-12">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                id="two_factor_required"
                name="two_factor_required"
                className="custom-control-input"
                defaultChecked
              />
              <label
                htmlFor="two_factor_required"
                className="custom-control-label ml-2"
              >
                {t("two_factor_required_for_this_user")}
              </label>
            </div>
          </div>
        </div>

        <h3>{t("roles")}</h3>
        <div className="table-responsive">
          <table className="table table-pretty vertical-align-middle">
            <thead className="bg-transparent border-0">
              <tr>
                <th>{t("access_role")}</th>
                <th>{t("is_member")}</th>
              </tr>
            </thead>
            <tbody className="table-light" id="user-roles">
              {userRoles?.length > 0 &&
                userRoles.map((role) => {
                  return (
                    <tr key={role.access_role_id}>
                      <td>{role.title}</td>
                      <td>
                        <input
                          type="checkbox"
                          value={role.access_role_id}
                          name="access_role_id"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="mb-5 mt-5">
          <button className="btn primary" type="submit">
            <SaveIcon /> <span className="ml-2">{t("save")}</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateUserAccount;
