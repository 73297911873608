import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RevisionHeader = memo(({ revision1, revision2 }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: "1rem",
        marginBottom: "1rem",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4>{t("original_version")}</h4>
          <p>
            {t("title_capitalized")}: {revision1.content.definition.title}
          </p>
          <p>
            {t("created_by")}: {revision1.full_name}
            <br />
            {t("date_created")}: {formatDate(revision1.dtime_inserted)}
          </p>
          <p>
            {t("revision")}:
            <Link
              to={`/decision-tables/${revision1?.content.definition.decision_table_id}/revisions/${revision1.revision_id}`}
            >
              {" "}
              {revision1.revision_index}
            </Link>
          </p>
        </div>
        <div style={{ textAlign: "right" }}>
          <h4>{t("modified_version")}</h4>

          <p>
            {t("title_capitalized")}: {revision2.content.definition.title}
          </p>
          <p>
            {t("created_by")}: {revision2.full_name} <br />
            {t("date_created")}: {formatDate(revision2.dtime_inserted)}
          </p>
          <p>
            {t("revision")}:
            <Link
              to={`/decision-tables/${revision2?.content.definition.decision_table_id}/revisions/${revision2.revision_id}`}
            >
              {" "}
              {revision2.revision_index}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
});

RevisionHeader.propTypes = {
  revision1: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    dtime_inserted: PropTypes.string.isRequired,
    revision_id: PropTypes.any,
    revision_index: PropTypes.number,
    entity_id: PropTypes.number,
    content: PropTypes.object,
  }).isRequired,
  revision2: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    dtime_inserted: PropTypes.string.isRequired,
    revision_id: PropTypes.any,
    revision_index: PropTypes.number,
    entity_id: PropTypes.number,
    content: PropTypes.object,
  }).isRequired,
};

RevisionHeader.displayName = "RevisionHeader";

export default RevisionHeader;
