import { handleActions } from "redux-actions";
import {
  getApprovalProcessesRequest,
  getApprovalProcessesSuccess,
  getApprovalProcessesFailure,
  getApprovalProcessRequest,
  getApprovalProcessSuccess,
  getApprovalProcessFailure,
  deleteApprovalProcessRequest,
  deleteApprovalProcessSuccess,
  deleteApprovalProcessFailure,
  getApprovalSequencesRequest,
  getApprovalSequencesSuccess,
  getApprovalSequencesFailure,
  getApprovalSequenceRequest,
  getApprovalSequenceSuccess,
  getApprovalSequenceFailure,
  deleteApprovalSequenceRequest,
  deleteApprovalSequenceSuccess,
  deleteApprovalSequenceFailure,
  createApprovalSequenceRequest,
  createApprovalSequenceSuccess,
  createApprovalSequenceFailure,
  updateApprovalSequenceRequest,
  updateApprovalSequenceSuccess,
  updateApprovalSequenceFailure,
  startApprovalProcessRequest,
  startApprovalProcessSuccess,
  startApprovalProcessFailure,
  startApprovalProcessConflict,
  signApprovalProcessStepRequest,
  signApprovalProcessStepSuccess,
  signApprovalProcessStepFailure,
  getApprovalStepFailure,
  getApprovalStepRequest,
  getApprovalStepSuccess,
} from "./action";

const initialState = {
  approvalProcesses: [],
  approvalProcess: {},
  approvalSequences: [],
  approvalSequence: {},
  newApprovalSequenceId: "",
  newApprovalProcessId: "",
  isGetApprovalProcessesSuccess: false,
  isGetApprovalProcessesError: false,
  isGetApprovalProcessSuccess: false,
  isGetApprovalProcessError: false,
  isDeleteApprovalProcessSuccess: false,
  isDeleteApprovalProcessError: false,
  isGetApprovalSequencesSuccess: false,
  isGetApprovalSequencesError: false,
  isGetApprovalSequenceSuccess: false,
  isGetApprovalSequenceError: false,
  isDeleteApprovalSequenceSuccess: false,
  isDeleteApprovalSequenceError: false,
  isCreateApprovalSequenceSuccess: false,
  isCreateApprovalSequenceError: false,
  isUpdatedApprovalSequenceSuccess: false,
  isUpdatedApprovalSequenceError: false,
  isGetApprovalStepSuccess: false,
  isGetApprovalStepError: false,
  approvalStep: {},
  isStartApprovalProcessSuccess: false,
  isStartApprovalProcessError: false,
  isStartApprovalProcessConflict: false,
  isSignApprovalProcessStepSuccess: false,
  isSignApprovalProcessStepError: false,
};

const reducer = handleActions(
  {
    // Approval Processes
    [getApprovalProcessesRequest]: (state) => ({
      ...state,
      isGetApprovalProcessesSuccess: false,
      isGetApprovalProcessesError: false,
    }),
    [getApprovalProcessesSuccess]: (state, { payload }) => ({
      ...state,
      approvalProcesses: payload ?? [],
      isGetApprovalProcessesSuccess: true,
      isGetApprovalProcessesError: false,
    }),
    [getApprovalProcessesFailure]: (state) => ({
      ...state,
      isGetApprovalProcessesSuccess: false,
      isGetApprovalProcessesError: true,
    }),
    [getApprovalProcessRequest]: (state) => ({
      ...state,
      isGetApprovalProcessSuccess: false,
      isGetApprovalProcessError: false,
    }),
    [getApprovalProcessSuccess]: (state, { payload }) => ({
      ...state,
      approvalProcess: payload,
      isGetApprovalProcessSuccess: true,
      isGetApprovalProcessError: false,
    }),
    [getApprovalProcessFailure]: (state) => ({
      ...state,
      isGetApprovalProcessSuccess: false,
      isGetApprovalProcessError: true,
    }),
    [deleteApprovalProcessRequest]: (state) => ({
      ...state,
      isDeleteApprovalProcessSuccess: false,
      isDeleteApprovalProcessError: false,
    }),
    [deleteApprovalProcessSuccess]: (state, { payload }) => ({
      ...state,
      approvalProcesses: state.approvalProcesses.filter(
        (process) => process.id !== payload
      ),
      isDeleteApprovalProcessSuccess: true,
      isDeleteApprovalProcessError: false,
    }),
    [deleteApprovalProcessFailure]: (state) => ({
      ...state,
      isDeleteApprovalProcessSuccess: false,
      isDeleteApprovalProcessError: true,
    }),
    [startApprovalProcessRequest]: (state) => ({
      ...state,
      isStartApprovalProcessSuccess: false,
      isStartApprovalProcessError: false,
    }),
    [startApprovalProcessSuccess]: (state, { payload }) => ({
      ...state,
      newApprovalProcessId: payload,
      isStartApprovalProcessSuccess: true,
      isStartApprovalProcessError: false,
    }),
    [startApprovalProcessFailure]: (state) => ({
      ...state,
      isStartApprovalProcessSuccess: false,
      isStartApprovalProcessError: true,
    }),
    [startApprovalProcessConflict]: (state) => ({
      ...state,
      isStartApprovalProcessSuccess: false,
      isStartApprovalProcessConflict: true,
    }),
    [signApprovalProcessStepRequest]: (state) => ({
      ...state,
      isSignApprovalProcessStepSuccess: false,
      isSignApprovalProcessStepError: false,
    }),
    [signApprovalProcessStepSuccess]: (state) => ({
      ...state,
      isSignApprovalProcessStepSuccess: true,
      isSignApprovalProcessStepError: false,
    }),
    [signApprovalProcessStepFailure]: (state) => ({
      ...state,
      isSignApprovalProcessStepSuccess: false,
      isSignApprovalProcessStepError: true,
    }),
    // Approval Sequences
    [getApprovalSequencesRequest]: (state) => ({
      ...state,
      isGetApprovalSequencesSuccess: false,
      isGetApprovalSequencesError: false,
    }),
    [getApprovalSequencesSuccess]: (state, { payload }) => ({
      ...state,
      approvalSequences: payload ?? [],
      isGetApprovalSequencesSuccess: true,
      isGetApprovalSequencesError: false,
    }),
    [getApprovalSequencesFailure]: (state) => ({
      ...state,
      isGetApprovalSequencesSuccess: false,
      isGetApprovalSequencesError: true,
    }),
    [getApprovalSequenceRequest]: (state) => ({
      ...state,
      isGetApprovalSequenceSuccess: false,
      isGetApprovalSequenceError: false,
    }),
    [getApprovalSequenceSuccess]: (state, { payload }) => ({
      ...state,
      approvalSequence: payload,
      isGetApprovalSequenceSuccess: true,
      isGetApprovalSequenceError: false,
    }),
    [getApprovalSequenceFailure]: (state) => ({
      ...state,
      isGetApprovalSequenceSuccess: false,
      isGetApprovalSequenceError: true,
    }),
    [deleteApprovalSequenceRequest]: (state) => ({
      ...state,
      isDeleteApprovalSequenceSuccess: false,
      isDeleteApprovalSequenceError: false,
    }),
    [deleteApprovalSequenceSuccess]: (state, { payload }) => ({
      ...state,
      approvalSequences: state.approvalSequences.filter(
        (sequence) => sequence.id !== payload
      ),
      isDeleteApprovalSequenceSuccess: true,
      isDeleteApprovalSequenceError: false,
    }),
    [deleteApprovalSequenceFailure]: (state) => ({
      ...state,
      isDeleteApprovalSequenceSuccess: false,
      isDeleteApprovalSequenceError: true,
    }),
    [createApprovalSequenceRequest]: (state) => ({
      ...state,
      isCreateApprovalSequenceSuccess: false,
      isCreateApprovalSequenceError: false,
    }),
    [createApprovalSequenceSuccess]: (state, { payload }) => ({
      ...state,
      newApprovalSequenceId: payload,
      isCreateApprovalSequenceSuccess: true,
      isCreateApprovalSequenceError: false,
    }),
    [createApprovalSequenceFailure]: (state) => ({
      ...state,
      isCreateApprovalSequenceSuccess: false,
      isCreateApprovalSequenceError: true,
    }),
    [updateApprovalSequenceRequest]: (state) => ({
      ...state,
      isUpdatedApprovalSequenceSuccess: false,
      isUpdatedApprovalSequenceError: false,
    }),
    [updateApprovalSequenceSuccess]: (state, { payload }) => ({
      ...state,
      approvalSequence: payload,
      isUpdatedApprovalSequenceSuccess: true,
      isUpdatedApprovalSequenceError: false,
    }),
    [updateApprovalSequenceFailure]: (state) => ({
      ...state,
      isUpdatedApprovalSequenceSuccess: false,
      isUpdatedApprovalSequenceError: true,
    }),
    [getApprovalStepRequest]: (state) => ({
      ...state,
      isGetApprovalStepSuccess: false,
      isGetApprovalStepError: false,
    }),
    [getApprovalStepSuccess]: (state, { payload }) => ({
      ...state,
      approvalStep: payload,
      isGetApprovalStepSuccess: true,
      isGetApprovalStepError: false,
    }),
    [getApprovalStepFailure]: (state) => ({
      ...state,
      isGetApprovalStepSuccess: false,
      isGetApprovalStepError: true,
    }),
  },
  initialState
);

export default reducer;
