import { handleActions } from "redux-actions";
import {
  createRoleRightsFailure,
  createRoleRightsRequest,
  createRoleRightsSuccess,
  deleteUserRoleFailure,
  deleteUserRoleRequest,
  deleteUserRoleSuccess,
  getRoleFailure,
  getRoleRequest,
  getRoleRightsFailure,
  getRoleRightsRequest,
  getRoleRightsSuccess,
  getRoleSuccess,
  getUserRolesFailure,
  getUserRolesRequest,
  getUserRolesSuccess,
  updateRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
} from "redux/roleRights/action";

const initialState = {
  roleRights: [],
  role: [],
  userRoles: [],
  isGetUserRolesSuccess: false,
  isGetUserRolesError: false,
  isGetRoleRightsSuccess: false,
  isGetRoleRightsError: false,
  isCreatedRoleRightsSuccess: false,
  isCreatedRoleRightsError: false,
  isGetRoleSuccess: false,
  isGetRoleError: false,
  isUpdateRoleSuccess: true,
  isUpdateRoleError: false,
  removeUserRoleSuccess: true,
  removeUserRoleFailure: false,
  newRoleRightsId: "",
};

const reducer = handleActions(
  {
    [getRoleRightsRequest]: (state) => ({
      ...state,
      roleRights: [],
      isGetRoleRightsSuccess: false,
      isGetRoleRightsError: false,
    }),
    [getRoleRightsSuccess]: (state, { payload }) => ({
      ...state,
      roleRights: payload || [],
      isGetRoleRightsSuccess: true,
      isGetRoleRightsError: false,
    }),
    [getRoleRightsFailure]: (state) => ({
      ...state,
      isGetRoleRightsSuccess: false,
      isGetRoleRightsError: true,
    }),
    // create subscription rights
    [createRoleRightsRequest]: (state) => ({
      ...state,
      isCreatedRoleRightsSuccess: false,
      isCreatedRoleRightsError: false,
    }),
    [createRoleRightsSuccess]: (state, { payload }) => ({
      ...state,
      roleRights: payload.data,
      newRoleRightsId: payload.eTag || "",
      isCreatedRoleRightsSuccess: true,
      isCreatedRoleRightsError: false,
    }),
    [createRoleRightsFailure]: (state) => ({
      ...state,
      isCreatedRoleRightsSuccess: false,
      isCreatedRoleRightsError: true,
    }),
    // create subscription rights
    [getRoleRequest]: (state) => ({
      ...state,
      isGetRoleSuccess: false,
      isGetRoleError: false,
    }),
    [getRoleSuccess]: (state, { payload }) => ({
      ...state,
      role: payload,
      isGetRoleSuccess: true,
      isGetRoleError: false,
    }),
    [getRoleFailure]: (state) => ({
      ...state,
      isGetRoleSuccess: false,
      isGetRoleError: true,
    }),
    // update subscription rights
    [updateRoleRequest]: (state) => ({
      ...state,
      isUpdateRoleSuccess: false,
      isUpdateRoleError: false,
    }),
    [updateRoleSuccess]: (state, { payload }) => ({
      ...state,
      role: payload,
      isUpdateRoleSuccess: true,
      isUpdateRoleError: false,
    }),
    [updateRoleFailure]: (state) => ({
      ...state,
      isUpdateRoleSuccess: false,
      isUpdateRoleError: true,
    }),
    // get user account
    [getUserRolesRequest]: (state) => ({
      ...state,
      userRoles: [],
      isGetUserRolesSuccess: false,
      isGetUserRolesError: false,
    }),
    [getUserRolesSuccess]: (state, { payload }) => ({
      ...state,
      userRoles: payload,
      isGetUserRolesSuccess: true,
      isGetUserRolesError: false,
    }),
    [getUserRolesFailure]: (state) => ({
      ...state,
      userRoles: [],
      isGetUserRolesSuccess: false,
      isGetUserRolesError: true,
    }),
    // remove user subscription
    [deleteUserRoleRequest]: (state) => ({
      ...state,
      removeUserRoleSuccess: false,
      removeUserRoleFailure: false,
    }),
    [deleteUserRoleSuccess]: (state) => ({
      ...state,
      removeUserRoleSuccess: true,
      removeUserRoleFailure: false,
    }),
    [deleteUserRoleFailure]: (state) => ({
      ...state,
      removeUserRoleSuccess: false,
      removeUserRoleFailure: true,
    }),
  },
  initialState
);

export default reducer;
