import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { generateCloudInstanceName } from "utility/utility";
import { useParams } from "react-router-dom";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";

const FunctionOptions = ({
  open,
  handleClose,
  functionData = {},
  updateFunctionOptions,
  revisionMode = false,
}) => {
  const { id } = useParams();
  const ref = useRef();
  const { t } = useTranslation();

  const handleXAttributePathChange = (e) => {
    if (
      e.target.value?.charAt(0) === "$" &&
      e.target.value?.charAt(1) === "." &&
      e.target.value?.length > 2
    ) {
      e.target.classList.remove("is-invalid");
    } else {
      e.target.classList.add("is-invalid");
    }
  };

  const handleConfirm = (form) => {
    if (form.checkValidity()) {
      const formData = new FormData(form);
      updateFunctionOptions(
        formData.get("name"),
        formData.get("title"),
        formData.get("x_attribute_path")
      );
      handleClose();
    } else {
      form.reportValidity();
    }
  };

  return (
    <Modal size="lg" show={open} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title">{t("edit_function")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <form ref={ref}>
        <Modal.Body className="row">
          <div className="col-12 col-lg-12 mb-2">
            <label>{t("table_title")}</label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={functionData?.title || ""}
              disabled={revisionMode}
              required
            />
          </div>
          <div className="col-12 col-lg-12 mb-2">
            <label>
              {t("identifier")}
              <span
                className="badge badge-secondary ml-1"
                data-tip={true}
                data-for="name-hint"
              >
                ?
              </span>
              <ReactTooltip
                type="dark"
                place="right"
                effect="solid"
                id="name-hint"
              >
                {t("lowercase_numbers")}
              </ReactTooltip>
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              defaultValue={
                functionData?.name || (!id ? generateCloudInstanceName() : "")
              }
              disabled={revisionMode}
              required
            />
          </div>
          <div className="col-12 col-lg-12 mb-2">
            <label>{t("result_attribute_path")}</label>
            <input
              type="text"
              className="form-control"
              name="x_attribute_path"
              defaultValue={functionData?.x_attribute_path || ""}
              onChange={handleXAttributePathChange}
              disabled={revisionMode}
              required
            />
          </div>
        </Modal.Body>
        {!revisionMode && (
          <Modal.Footer>
            <Button variant="" className="outline" onClick={handleClose}>
              {t("close")}
            </Button>
            <Button
              variant=""
              className="primary d-flex align-items-center"
              onClick={() => handleConfirm(ref?.current)}
            >
              <SaveIcon /> <span className="ml-2">{t("save")}</span>
            </Button>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
};

FunctionOptions.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  functionData: PropTypes.object,
  updateFunctionOptions: PropTypes.func,
  revisionMode: PropTypes.bool,
};

export default FunctionOptions;
