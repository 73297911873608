import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAnalysisRecipeRequest,
  getAnalysisRecipesRequest,
} from "redux/analysisRecipes/actions";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import { Pagination, sortByTimeDesc, TableNoItems } from "utility/utility";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const AnalysisRecipes = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetAnalysisRecipesSuccess,
    analysisRecipes,
    isDeletedAnalysisRecipeSuccess,
    isGetAnalysisRecipesError,
    isDeletedAnalysisRecipeError,
  } = useSelector((state) => state.analysisRecipes);

  const prevIsDeletedAnalysisRecipeSuccess = usePrevious(
    isDeletedAnalysisRecipeSuccess
  );
  const prevIsGetAnalysisRecipesSuccess = usePrevious(
    isGetAnalysisRecipesSuccess
  );
  const prevIsGetAnalysisRecipesError = usePrevious(isGetAnalysisRecipesError);
  const prevIsDeletedAnalysisRecipeError = usePrevious(
    isDeletedAnalysisRecipeError
  );

  const [recipesClone, setRecipesClone] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isRecipeDeleteModalOpen, setIsRecipeDeleteModalOpen] = useState(false);

  // Fetch recipes when component mounts or page changes
  useEffect(() => {
    document.title = `${t("nav_bar_analysis_recipes")} - Decisimo`;
  }, [t]);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getAnalysisRecipesRequest(currentPage));
    }
  }, [currentPage, dispatch, setIsLoading]);

  // Handle successful fetch of analysis recipes
  useEffect(() => {
    if (
      isGetAnalysisRecipesSuccess &&
      prevIsGetAnalysisRecipesSuccess === false
    ) {
      setIsLoading(false);
      const sortedRecipes = structuredClone(analysisRecipes);
      sortedRecipes?.sort(sortByTimeDesc);
      setRecipesClone(sortedRecipes);
    }
  }, [
    isGetAnalysisRecipesSuccess,
    analysisRecipes,
    prevIsGetAnalysisRecipesSuccess,
    setIsLoading,
  ]);

  // Handle errors during fetch or deletion
  useEffect(() => {
    if (
      (isGetAnalysisRecipesError && prevIsGetAnalysisRecipesError === false) ||
      (isDeletedAnalysisRecipeError &&
        prevIsDeletedAnalysisRecipeError === false)
    ) {
      setIsLoading(false);
      toast.error(t("error_loading_recipes"), ToastOptions);
    }
  }, [
    isGetAnalysisRecipesError,
    isDeletedAnalysisRecipeError,
    prevIsGetAnalysisRecipesError,
    prevIsDeletedAnalysisRecipeError,
    t,
  ]);

  // Handle successful deletion of a recipe
  useEffect(() => {
    if (
      isDeletedAnalysisRecipeSuccess &&
      prevIsDeletedAnalysisRecipeSuccess === false
    ) {
      setIsLoading(false);
      setRecipesClone((prevRecipes) =>
        prevRecipes.filter(
          (recipe) =>
            recipe.release_analysis_recipe_id !==
            selectedRecipe.release_analysis_recipe_id
        )
      );
      handleClose();
      toast.warning(t("recipe_deleted"), ToastOptions);
    }
  }, [
    isDeletedAnalysisRecipeSuccess,
    prevIsDeletedAnalysisRecipeSuccess,
    selectedRecipe.release_analysis_recipe_id,
    t,
    setIsLoading,
  ]);

  const handleShowDeleteModal = (recipe) => {
    setSelectedRecipe(recipe);
    setIsRecipeDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsRecipeDeleteModalOpen(false);
    setSelectedRecipe({});
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(
      deleteAnalysisRecipeRequest(selectedRecipe.release_analysis_recipe_id)
    );
    setIsRecipeDeleteModalOpen(false);
  };

  const handleChangePage = (isPrev = false) => {
    setCurrentPage(isPrev ? currentPage - 1 : currentPage + 1);
  };

  return (
    <>
      <SubHeader title={t("analysis_recipes")} actions="" />
      <div className="mb-3">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="col-lg-6 py-3">
                {t("title_capitalized")}
              </th>
              <th scope="col" className="col-lg-2 py-3">
                {t("last_change")}
              </th>
              <th scope="col" className="col-lg-2 py-3" />
            </tr>
          </thead>
          <tbody className="py-4">
            {recipesClone?.length > 0 ? (
              recipesClone.map((recipe) => (
                <tr key={recipe.release_analysis_recipe_id} className="td-flow">
                  <td>
                    <Link
                      to={`/analysis-recipes/${recipe.release_analysis_recipe_id}`}
                    >
                      {recipe.title}
                    </Link>
                  </td>

                  {!isMobile && (
                    <td>
                      {recipe.dtime_modified}
                      <HelpCircle
                        data-tip
                        data-for={`modified-${recipe.release_analysis_recipe_id}`}
                        className="ml-2 cursor-pointer"
                      />
                      <ReactTooltip
                        id={`modified-${recipe.release_analysis_recipe_id}`}
                        type="dark"
                        place="right"
                        effect="solid"
                      >
                        {t("modified_by")} {recipe.full_name}
                      </ReactTooltip>
                    </td>
                  )}

                  <td className="d-flex align-items-center justify-content-end border-0">
                    <button
                      title={t("delete_recipe")}
                      type="button"
                      className="btn primary-text border-0 p-0 pb-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <MoreVertical style={{ cursor: "pointer" }} />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleShowDeleteModal(recipe)}
                      >
                        <TrashIcon /> {t("delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : isGetAnalysisRecipesSuccess && analysisRecipes?.length === 0 ? (
              <TableNoItems colspan={5} />
            ) : null}
          </tbody>
        </table>
      </div>
      <div className="pagination justify-content-end mb-5">
        <Pagination
          currentPage={currentPage}
          itemsLength={analysisRecipes?.length || 0}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_analysis_recipe")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedRecipe.title}</b>?
          </span>
        }
        open={isRecipeDeleteModalOpen}
      />
    </>
  );
};

export default AnalysisRecipes;
