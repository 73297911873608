import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { MainContext } from "context/contexts";
import { getTimeZonesRequest } from "redux/timezones/action";
import {
  deleteTaskRequest,
  getBatchInstancesRequest,
  getBatchTypesRequest,
  forceScheduleBatchTaskRequest,
  getBatchTasksRequest,
} from "redux/batches/action";
import { getWorkflowsRequest } from "redux/workflows/action";
import { getWorkflowReleasesRequest } from "redux/releases/action";
import usePrevious from "utility/hooks/usePrevious";
import { getBatchTaskStatus, sortByTitle } from "utility/utility";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/x-circle.svg";
import ReactTooltip from "react-tooltip";
import StorageSourceItem from "./StorageSourceItem";
import TaskCancel from "components/modals/batches/TaskCancel";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BatchAnalysisAttributes from "./BatchAnalysisAttributes";
import { isArray } from "lodash";

const BatchContent = ({
  batchTasks = [],
  setBatchTasks = () => {},
  releaseData = true,
}) => {
  const { setIsLoading, setIsEdited, vectorsData } = useContext(MainContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    batch,
    batchTypes,
    batchInstances,
    isGetBatchTypesSuccess,
    isGetBatchTypesError,
    isGetBatchInstancesSuccess,
    isGetBatchInstancesError,
    isTaskCanceledSuccess,
    isTaskCanceledError,
    isForceScheduleBatchTaskSuccess,
    isForceScheduleBatchTaskError,
  } = useSelector((state) => state.batches);

  const { workflows, isGetWorkflowsSuccess, isGetWorkflowsError } = useSelector(
    (state) => state.workflows
  );

  const { timezones, isGetTimeZonesSuccess, isGetTimeZonesError } = useSelector(
    (state) => state.timezones
  );

  const {
    workflowReleases,
    isGetWorkflowReleasesSuccess,
    isGetWorkflowReleasesError,
  } = useSelector((state) => state.releases);

  const [vectorsDataClone, setVectorsDataClone] = useState(vectorsData);

  const [batchClone, setBatchClone] = useState({});
  const [batchTypesClone, setBatchTypesClone] = useState([]);
  const [batchInstancesClone, setBatchInstancesClone] = useState([]);
  const [workflowsClone, setWorkflowsClone] = useState([]);
  const [timezonesClone, setTimezonesClone] = useState([]);
  const [workflowReleasesClone, setWorkflowReleasesClone] = useState([]);
  const [selectedBatchTypeId, setSelectedBatchTypeId] = useState(null);
  const [workflowReleaseId, setWorkflowReleaseId] = useState("");
  const [timezone, setTimezone] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isBatchTasksModalOpen, setIsBatchTasksModalOpen] = useState(false);
  const [selectBatchType, setSelectBatchType] = useState(null);

  const prevIsGetBatchTypesSuccess = usePrevious(isGetBatchTypesSuccess);
  const prevIsGetBatchInstancesSuccess = usePrevious(
    isGetBatchInstancesSuccess
  );
  const prevIsGetWorkflowsSuccess = usePrevious(isGetWorkflowsSuccess);
  const prevIsGetTimeZonesSuccess = usePrevious(isGetTimeZonesSuccess);
  const prevIsGetWorkflowReleasesSuccess = usePrevious(
    isGetWorkflowReleasesSuccess
  );

  const prevIsTaskCanceledSuccess = usePrevious(isTaskCanceledSuccess);
  const prevIsTaskCanceledError = usePrevious(isTaskCanceledError);
  const prevIsForceScheduleBatchTaskSuccess = usePrevious(
    isForceScheduleBatchTaskSuccess
  );

  // errors
  const prevIsGetBatchTypesError = usePrevious(isGetBatchTypesError);
  const prevIsGetBatchInstancesError = usePrevious(isGetBatchInstancesError);
  const prevIsGetWorkflowsError = usePrevious(isGetWorkflowsError);
  const prevIsGetTimeZonesError = usePrevious(isGetTimeZonesError);
  const prevIsGetWorkflowReleasesError = usePrevious(
    isGetWorkflowReleasesError
  );

  useEffect(() => {
    dispatch(getTimeZonesRequest());
    dispatch(getBatchTypesRequest());
    dispatch(getWorkflowsRequest());
    dispatch(getBatchInstancesRequest());

    return () => {
      setIsEdited(false);
    };
  }, [dispatch]);

  useEffect(() => {
    setBatchClone(batch);
    if (Object.keys(batch).length > 0) {
      setWorkflowReleaseId(batch.workflow_release_id);
      setTimezone(batch.timezone);
    }
  }, [batch]);

  useEffect(() => {
    if (isTaskCanceledSuccess && prevIsTaskCanceledSuccess === false) {
      const tasksClone = structuredClone(batchTasks);
      //when task is canceled, set its status to 'c'
      setBatchTasks((tasksClone) =>
        tasksClone.map((task) =>
          task.batch_task_id === selectedTaskId
            ? { ...task, status: "c" }
            : task
        )
      );
      setIsCancelModalOpen(false);
      setSelectedTaskId(null);
      setIsLoading(false);
      toast.success(t("task_canceled"), ToastOptions);
    }
  }, [isTaskCanceledSuccess]);

  useEffect(() => {
    if (isGetBatchTypesSuccess && prevIsGetBatchTypesSuccess === false) {
      setBatchTypesClone(batchTypes);
    } else if (
      isGetBatchInstancesSuccess &&
      prevIsGetBatchInstancesSuccess === false
    ) {
      setBatchInstancesClone(batchInstances);
    }
  }, [isGetBatchTypesSuccess, isGetBatchInstancesSuccess]);

  useEffect(() => {
    if (isGetWorkflowsSuccess && prevIsGetWorkflowsSuccess === false) {
      const cloneWorkflows = structuredClone(workflows);
      cloneWorkflows.sort(sortByTitle);
      if (!id && cloneWorkflows.length > 0) {
        dispatch(getWorkflowReleasesRequest(cloneWorkflows[0].workflow_id));
      }
      setWorkflowsClone(cloneWorkflows);
    } else if (isGetTimeZonesSuccess && prevIsGetTimeZonesSuccess === false) {
      setTimezonesClone(timezones);
    } else if (
      isGetWorkflowReleasesSuccess &&
      prevIsGetWorkflowReleasesSuccess === false
    ) {
      setWorkflowReleasesClone(workflowReleases);
    }
  }, [
    isGetWorkflowsSuccess,
    isGetTimeZonesSuccess,
    isGetWorkflowReleasesSuccess,
  ]);

  //when forced schedule task is successful, get the updated batch tasks
  useEffect(() => {
    if (
      isForceScheduleBatchTaskSuccess &&
      prevIsForceScheduleBatchTaskSuccess === false
    ) {
      dispatch(getBatchTasksRequest({ id, page: 0 }));
    }
  }, [isForceScheduleBatchTaskSuccess]);

  useEffect(() => {
    if (Object.keys(batch).length > 0) {
      setSelectedBatchTypeId(batch.batch_type_id);
      dispatch(getWorkflowReleasesRequest(batch.workflow_id));
    } else {
      // get the first batch type id from the list
      if (!selectedBatchTypeId)
        setSelectedBatchTypeId(batchTypesClone[0]?.batch_type_id);
    }
  }, [batch]);

  useEffect(() => {
    if (
      (isGetBatchTypesError && prevIsGetBatchTypesError === false) ||
      (isGetBatchInstancesError && prevIsGetBatchInstancesError === false) ||
      (isGetWorkflowsError && prevIsGetWorkflowsError === false) ||
      (isGetTimeZonesError && prevIsGetTimeZonesError === false) ||
      (isGetWorkflowReleasesError &&
        prevIsGetWorkflowReleasesError === false) ||
      (isTaskCanceledError && prevIsTaskCanceledError === false)
    ) {
      setIsLoading(false);

      if (isTaskCanceledError) {
        setIsCancelModalOpen(false);
        setSelectedTaskId(null);
      }
    }
  }, [
    isGetBatchTypesError,
    isGetBatchInstancesError,
    isGetWorkflowsError,
    isGetTimeZonesError,
    isGetWorkflowReleasesError,
    isTaskCanceledError,
  ]);
  //if forced schedule task is not successful, show an error message
  useEffect(() => {
    if (
      isForceScheduleBatchTaskSuccess === false &&
      isForceScheduleBatchTaskError === true
    ) {
      setIsLoading(false);
      toast.error(t("force_schedule_task_error"), ToastOptions);
    }
  }, [isForceScheduleBatchTaskSuccess, isForceScheduleBatchTaskError]);

  //if forced schedule task is successful, get the updated batch tasks
  useEffect(() => {
    if (isForceScheduleBatchTaskSuccess === true) {
      setIsLoading(false);
      toast.success(t("force_schedule_task_success"), ToastOptions);
    }
  }, [isForceScheduleBatchTaskSuccess]);

  const forceScheduleTask = () => {
    setIsLoading(true);
    dispatch(forceScheduleBatchTaskRequest({ id }));
  };

  const handleOpenCancelModal = (taskId) => {
    setSelectedTaskId(taskId);
    setIsCancelModalOpen(true);
  };

  const handleCloseCancelModal = () => {
    setSelectedTaskId(null);
    setIsCancelModalOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsLoading(true);
    dispatch(deleteTaskRequest({ id, taskId: selectedTaskId }));
  };
  const [batchTypeSelected, setBatchTypeSelected] = useState(false);

  const openBatchTypes = (task) => {
    if (task.additional_info && !batchTypeSelected) {
      setSelectBatchType(task);
      setIsBatchTasksModalOpen(true);
      setBatchTypeSelected(true);
    }
  };

  const isValidUrl = (url) => {
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const handleWebhookUrlChange = (event) => {
    const url = event.target.value;
    if (!isValidUrl(url)) {
      // Show an error message or perform some other action
      toast.warning(t("check_webhook_url"), ToastOptions);
    }
  };

  //function to add attribute to the batch analysis attributes
  const onAddAttribute = (attribute) => {
    const updatedAttributes = [...batchClone.batch_analysis_attributes];
    updatedAttributes.push(attribute);
    setBatchClone({
      ...batchClone,
      batch_analysis_attributes: updatedAttributes,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-12">
          <label>{t("table_title")}</label>
          <input
            type="text"
            className="form-control field"
            name="title"
            defaultValue={batchClone?.title}
            key={batchClone?.title}
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4 col-12">
          <label>{t("decision_flow")}:</label>
          <select
            className="form-control field"
            name="workflow_id"
            defaultValue={batchClone?.workflow_id}
            onChange={(e) =>
              dispatch(getWorkflowReleasesRequest(e.target.value))
            }
            key={batchClone?.workflow_id}
          >
            {workflowsClone?.map((workflow) => {
              return (
                <option key={workflow.workflow_id} value={workflow.workflow_id}>
                  {workflow.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-4 col-12">
          <label>{t("release_table")}:</label>
          <select
            id="workflow_release_id"
            className={`${!releaseData ? "is-invalid" : ""} form-control field`}
            name="workflow_release_id"
            value={workflowReleaseId}
            onChange={(e) => setWorkflowReleaseId(e.target.value)}
          >
            {workflowReleasesClone?.length > 0 &&
              workflowReleasesClone.map((release) => {
                return (
                  <option
                    key={release.workflow_release_id}
                    value={release.workflow_release_id}
                  >
                    {release.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <label>{t("processing_region")}:</label>
          <select
            className="form-control field"
            name="instance_region_id"
            defaultValue={
              Object.keys(batch).length > 0 && batchClone?.instance_region_id
            }
            key={
              Object.keys(batch).length > 0 && batchClone?.instance_region_id
            }
          >
            {batchInstancesClone?.length > 0 &&
              batchInstancesClone.map((instance) => {
                return (
                  <option
                    key={instance.instance_region_id}
                    value={instance.instance_region_id}
                  >
                    {instance.title}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <label>{t("webhook_url")}:</label>
          <input
            type="text"
            className="form-control field"
            name="webhook_url"
            key={batch.webhook_url}
            defaultValue={batchClone?.webhook_url}
            onBlur={handleWebhookUrlChange}
            placeholder="https://"
          />
        </div>
      </div>
      <div className="card mt-2">
        <div className="row card-body pb-7">
          <div className="col-md col-12">
            <label>{t("storage_source")}</label>
            <br />
            <div
              className="btn-group btn-group-toggle"
              data-toggle="buttons"
              id="batch-type-buttons"
              defaultValue={
                Object.keys(batch).length > 0 && batch.batch_type_id
              }
              onChange={(e) => setSelectedBatchTypeId(e.target.defaultValue)}
              key={Object.keys(batch).length > 0 && batch.batch_type_id}
            >
              {batchTypesClone?.map((type, index) => {
                const isActive =
                  Object.keys(batch).length > 0
                    ? batch.batch_type_id === type.batch_type_id
                    : index === 0;
                return (
                  <label
                    key={type.batch_type_id}
                    className={`btn outline clickable ${
                      isActive ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      className="field response-json"
                      name="batch_type_id"
                      defaultChecked={isActive}
                      defaultValue={type.batch_type_id}
                      key={type.batch_type_id}
                    />{" "}
                    {type.title}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        {batchTypesClone
          .filter((type) => type.batch_type_id === selectedBatchTypeId)
          .map((item) => {
            return (
              <div
                key={item.batch_type_id}
                className="col-md col-6 mt-md-0 mt-3 batch-type-details w-50"
              >
                {item.title}
                {item.options?.map((option) => {
                  return (
                    <StorageSourceItem
                      key={option.batch_type_option_id}
                      option={option}
                      batch={batchClone}
                    />
                  );
                })}
              </div>
            );
          })}
      </div>
      {/** Batch Analysis Attributes
      }<BatchAnalysisAttributes
        batch={batchClone}
        onBatchUpdate={setBatchClone}
        vectorData={vectorsDataClone}
      />
      */}
      <div className="card mt-2">
        <div className="row card-body mt-2">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-md col-12">
                <label>{t("start_process_time")}</label>
                <input
                  type="time"
                  className="form-control field"
                  name="execution_time"
                  defaultValue={batch.execution_time}
                  key={batchClone?.execution_time}
                />
              </div>
              <div className="col-md col-12">
                <label>{t("timezone")}</label>
                <select
                  className="form-control field"
                  name="timezone"
                  value={timezone}
                  onChange={(e) => setTimezone(e.target.value)}
                >
                  {timezonesClone?.map((timezone) => {
                    return (
                      <option key={timezone} value={timezone}>
                        {timezone}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md col-12">
                <label>{t("interval_type")}</label>
                <select
                  className="form-control field"
                  defaultValue={batch.interval_type}
                  key={batchClone?.interval_type}
                  name="interval_type"
                >
                  <option value="d">{t("daily")}</option>
                  <option value="w">{t("weekly")}</option>
                  <option value="s">{t("once")}</option>
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md col-12">
                <label>{t("starting_date")}</label>
                <input
                  type="date"
                  className="form-control field"
                  name="execution_date"
                  defaultValue={
                    Object.keys(batch).length > 0 && batch.execution_date
                  }
                  key={Object.keys(batch).length > 0 && batch.execution_date}
                />
              </div>
              <div className="col-md col-12">
                <label>{t("number_of_executions")}</label>
                <input
                  type="number"
                  className="form-control field"
                  name="execution_limit"
                  defaultValue={
                    Object.keys(batch).length > 0 && batch.execution_limit
                  }
                  key={Object.keys(batch).length > 0 && batch.execution_limit}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-md text-right">
                <button
                  onClick={forceScheduleTask}
                  className="btn primary"
                  type="button"
                >
                  {t("schedule_now")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {batchTasks && isArray(batchTasks) && batchTasks.length > 0 && (
        <div className="card mt-2 mb-5">
          <div className="row card-body mt-2">
            <div className="table-responsive">
              <table className="table table-pretty vertical-align-middle">
                <colgroup>
                  <col
                    span="1"
                    style={{
                      width: "12%",
                    }}
                  />
                  <col
                    span="1"
                    style={{
                      width: "30%",
                    }}
                  />
                  <col
                    span="1"
                    style={{
                      width: "12%",
                    }}
                  />
                  <col
                    span="1"
                    style={{
                      width: "12%",
                    }}
                  />
                  <col
                    span="1"
                    style={{
                      width: "12%",
                    }}
                  />
                  <col
                    span="1"
                    style={{
                      width: "12%",
                    }}
                  />
                  <col
                    span="1"
                    style={{
                      width: "8%",
                    }}
                  />
                </colgroup>
                <thead className="bg-transparent border-0">
                  <tr>
                    <th>{t("scheduled_time")}</th>
                    <th>{t("decision_flow_release")}</th>
                    <th>{t("finished")}</th>
                    <th>{t("processed")}</th>
                    <th>{t("processing_time")}</th>
                    <th>{t("status")}</th>
                    <th className="function-col text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <Modal
                    size="lg"
                    show={isBatchTasksModalOpen}
                    onHide={() => setIsBatchTasksModalOpen(false)}
                  >
                    <Modal.Body
                      style={{
                        maxHeight: 300,
                        overflow: "auto",
                      }}
                    >
                      <h5>{t("additional_info")}</h5>

                      <table className="table table-hover table-fixed border-top-0">
                        <tbody>
                          {selectBatchType?.additional_info &&
                            Object.entries(
                              selectBatchType?.additional_info
                            ).map(([key, value]) => {
                              return (
                                <tr key={key}>
                                  <td className="font-weight-bold">{key}:</td>
                                  <td className="pt-3">
                                    {value.includes("http") ? (
                                      <a
                                        rel={"noreferrer"}
                                        href={value}
                                        target="_blank"
                                      >
                                        {value}
                                      </a>
                                    ) : (
                                      <span>{value}</span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Modal.Body>
                  </Modal>
                  {batchTasks?.map((task) => {
                    return (
                      <tr key={task.batch_task_id}>
                        <td>{task.dtime_run}</td>
                        <td>
                          <a
                            href={`/workflows/${task?.workflow_id}`}
                            title={task.workflow_title}
                          >
                            {task.workflow_title}
                          </a>
                          &nbsp;/&nbsp;
                          <a
                            href={`/releases/${task?.workflow_release_id}`}
                            title={task.workflow_release_name}
                          >
                            {task.workflow_release_name}
                          </a>
                        </td>
                        <td>{task.dtime_finished || "-"}</td>
                        <td>{task.cnt_processed || "-"}</td>
                        <td>
                          {task.processing_time === null ||
                          task.processing_time === undefined
                            ? "-"
                            : parseFloat(task.processing_time) > 1000
                            ? (task.processing_time / 1000).toFixed(2) + "s"
                            : task.processing_time + "ms"}
                        </td>
                        <td>{getBatchTaskStatus(task.status)}</td>
                        <td className="text-center">
                          <span
                            data-tip={true}
                            data-for="info"
                            className="cursor-pointer"
                            onClick={() => openBatchTypes(task)}
                          >
                            <InfoIcon />
                          </span>
                          <ReactTooltip
                            id="info"
                            type="dark"
                            place="bottom"
                            effect="solid"
                          >
                            {t("info")}
                          </ReactTooltip>
                          {task.status === "s" ? (
                            <>
                              <ReactTooltip
                                id="cancel"
                                type="dark"
                                place="bottom"
                                effect="solid"
                              >
                                {t("Cancel")}
                              </ReactTooltip>
                              <span
                                data-tip={true}
                                data-for="cancel"
                                className="ml-2"
                                onClick={() =>
                                  handleOpenCancelModal(task.batch_task_id)
                                }
                              >
                                <DeleteIcon />
                              </span>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="row mb-4"> </div>

      <TaskCancel
        open={isCancelModalOpen}
        handleClose={handleCloseCancelModal}
        handleConfirm={handleConfirmCancel}
      />
    </>
  );
};

BatchContent.propTypes = {
  batchTasks: PropTypes.array,
  setBatchTasks: PropTypes.func,
  releaseData: PropTypes.bool,
};

export default BatchContent;
