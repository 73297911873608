import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { generateCloudInstanceName } from "utility/utility";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";

const RuleSetOptions = ({
  open,
  handleClose,
  handleConfirm,
  revisionMode = false,
  ruleSetData = {},
  setRuleSetData,
}) => {
  const { id } = useParams();
  const ref = useRef();
  const { t } = useTranslation();

  const { ruleSet } = useSelector((state) => state.ruleSets);

  const changeVerbose = (verbose) => {
    const clone = structuredClone(ruleSetData);
    clone.verbose = verbose ? "1" : "0";

    setRuleSetData(clone);
  };

  return (
    <>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("edit_rule_set")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form ref={ref}>
          <Modal.Body className={`row ${revisionMode ? "mb-3" : ""}`}>
            <div className="col-12 col-lg-12 mb-2">
              <label>{t("table_title")}</label>
              <input
                type="text"
                className="form-control"
                name="title"
                defaultValue={
                  !revisionMode && Object.keys(ruleSetData).length > 0
                    ? ruleSetData.title
                    : ruleSetData && ruleSetData.content
                    ? ruleSetData.content.title
                    : ""
                }
                disabled={revisionMode ? "disabled" : ""}
                required
              />
            </div>
            <div className="col-12 col-lg-12 mb-2">
              <label>
                {t("identifier")}
                <span
                  className="badge badge-secondary ml-1"
                  data-tip={true}
                  data-for="rule-set-use-lowercase"
                >
                  ?
                </span>
                <ReactTooltip
                  type="dark"
                  place="right"
                  effect="solid"
                  id="rule-set-use-lowercase"
                >
                  {t("lowercase_numbers")}
                </ReactTooltip>
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                defaultValue={
                  !revisionMode && id
                    ? ruleSetData?.name
                    : ruleSetData?.content
                    ? ruleSetData?.content?.name
                    : !id
                    ? generateCloudInstanceName()
                    : ""
                }
                disabled={revisionMode ? "disabled" : ""}
                required
              />
            </div>
            <div className="col-12 col-lg-auto">
              <label>
                {t("record_hits")}
                <span
                  className="badge badge-secondary ml-1"
                  data-tip={true}
                  data-for="save-rule-record"
                >
                  ?
                </span>
                <ReactTooltip
                  type="dark"
                  place="left"
                  effect="solid"
                  id="save-rule-record"
                >
                  {t("whether_record")}
                </ReactTooltip>
              </label>
              <br />
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className={`btn outline ${
                    (ruleSetData?.verbose === "1" ||
                      ruleSetData?.content?.verbose === "1") &&
                    "active"
                  }`}
                >
                  <input
                    type="radio"
                    name="verbose"
                    onChange={(e) => changeVerbose(e.target.checked)}
                    defaultChecked={
                      ruleSet?.verbose === "1" ||
                      ruleSet?.content?.verbose === "1"
                    }
                    key={ruleSet?.verbose || ruleSet?.content?.verbose}
                    disabled={revisionMode ? "disabled" : ""}
                  />
                  {t("yes")}
                </label>
                <label
                  className={`btn outline ${
                    (ruleSetData?.verbose === "0" ||
                      ruleSetData?.content?.verbose === "0") &&
                    "active"
                  }`}
                >
                  <input
                    type="radio"
                    name="verbose"
                    onChange={(e) => changeVerbose(!e.target.checked)}
                    defaultChecked={
                      ruleSet?.verbose === "0" ||
                      ruleSet?.content?.verbose === "0"
                    }
                    key={ruleSet?.verbose || ruleSet?.content?.verbose}
                    disabled={revisionMode ? "disabled" : ""}
                  />
                  {t("no")}
                </label>
              </div>
            </div>
          </Modal.Body>
          {!revisionMode && (
            <Modal.Footer>
              <Button
                variant=""
                className="outline"
                type="button"
                onClick={handleClose}
              >
                {t("close")}
              </Button>
              <Button
                variant=""
                className="primary d-flex align-items-center"
                type="button"
                onClick={() => handleConfirm(ref?.current)}
              >
                <SaveIcon /> <span className="ml-2">{t("save")}</span>
              </Button>
            </Modal.Footer>
          )}
        </form>
      </Modal>
    </>
  );
};

RuleSetOptions.propTypes = {
  open: PropTypes.bool,
  revisionMode: PropTypes.bool,
  ruleSetData: PropTypes.object,
  setRuleSetData: PropTypes.func,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default RuleSetOptions;
