import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { getUserRolesRequest } from "redux/roleRights/action";
import PropTypes from "prop-types";
import { ReactComponent as AddIcon } from "assets/icons/plus.svg";
import ApprovalSequenceStep from "components/approvalProcess/ApprovalSequenceStep";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteConfirm from "components/modals/DeleteConfirm";

const ApprovalSequenceContent = ({ isNew, initialData, onChange }) => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const { userRoles, isGetUserRolesSuccess } = useSelector(
    (state) => state.roleRights
  );

  const [sequenceData, setSequenceData] = useState({
    title: "",
    description: "",
    steps: [],
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [exceedsRoles, setExceedsRoles] = useState(false);
  const [isWarningDismissed, setIsWarningDismissed] = useState(false);

  useEffect(() => {
    dispatch(getUserRolesRequest());
  }, [dispatch]);

  useEffect(() => {
    setSequenceData(initialData || { title: "", description: "", steps: [] });
  }, [initialData]);

  useEffect(() => {
    if (isGetUserRolesSuccess) {
      setIsLoading(false);
    }
  }, [isGetUserRolesSuccess, setIsLoading]);

  useEffect(() => {
    checkIfExceedsRoles();
  }, [sequenceData.steps, userRoles]);

  const checkIfExceedsRoles = () => {
    const steps_count = sequenceData.steps.length;
    const roles_count = userRoles.length;
    if (steps_count < 2 || roles_count < 2) {
      return false;
    }

    setExceedsRoles(steps_count > roles_count);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...sequenceData, [name]: value };
    setSequenceData(updatedData);
    onChange(updatedData);
    setIsEdited(true);
  };

  const handleAddStep = () => {
    const newStep = {
      approval_step_id: `new_${Date.now()}`,
      title: t("approval_step"),
      access_role_id: "",
      step_sequence: sequenceData.steps.length + 1,
    };
    const updatedData = {
      ...sequenceData,
      steps: [...sequenceData.steps, newStep],
    };
    setSequenceData(updatedData);
    onChange(updatedData);
    checkIfExceedsRoles();
  };

  //if it is new sequence, we will add new step to the list
  useEffect(() => {
    if (isNew) {
      handleAddStep();
    }
  }, [isNew]);

  const handleStepChange = (index, field, value) => {
    const updatedSteps = [...sequenceData.steps];
    updatedSteps[index] = { ...updatedSteps[index], [field]: value };
    const updatedData = { ...sequenceData, steps: updatedSteps };
    setSequenceData(updatedData);
    onChange(updatedData);
    setIsEdited(true);
    checkIfExceedsRoles();
  };

  const handleShowDeleteModal = (index) => {
    setSelectedStepIndex(index);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedStepIndex(null);
  };

  const handleConfirmDelete = () => {
    if (selectedStepIndex !== null) {
      const updatedSteps = sequenceData.steps.filter(
        (_, i) => i !== selectedStepIndex
      );
      const updatedData = { ...sequenceData, steps: updatedSteps };
      setSequenceData(updatedData);
      onChange(updatedData);
      setIsEdited(true);
      handleCloseDeleteModal();
      checkIfExceedsRoles();
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedSteps = Array.from(sequenceData.steps);
    const [movedStep] = updatedSteps.splice(result.source.index, 1);
    updatedSteps.splice(result.destination.index, 0, movedStep);

    const updatedStepsWithSequence = updatedSteps.map((step, index) => ({
      ...step,
      step_sequence: index + 1,
    }));

    const updatedData = { ...sequenceData, steps: updatedStepsWithSequence };
    setSequenceData(updatedData);
    onChange(updatedData);
    setIsEdited(true);
  };

  const handleDismissWarning = () => {
    setIsWarningDismissed(true);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-12">
          <label>{t("title_capitalized")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="title"
            value={sequenceData.title}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="mt-4">
        <h5>{t("steps_in_sequence")}</h5>
        {exceedsRoles && !isWarningDismissed && (
          <div className="alert alert-warning d-flex justify-content-between">
            <span>{t("you_have_more_steps_than_unique_roles")}</span>
            <button
              type="button"
              className="close"
              onClick={handleDismissWarning}
            >
              &times;
            </button>
          </div>
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="steps">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sequenceData.steps.map((step, index) => (
                  <Draggable
                    key={step.approval_step_id}
                    draggableId={`step_${step.approval_step_id}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ApprovalSequenceStep
                          step={step}
                          index={index}
                          onChange={handleStepChange}
                          deleteStep={() => handleShowDeleteModal(index)}
                          userRoles={userRoles}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={`add-rule-container container-border`}>
          <button
            className="btn outline mt-1"
            type="button"
            title={t("add_step")}
            onClick={handleAddStep}
          >
            <AddIcon
              style={{
                filter: "brightness(0.5)",
              }}
            />
            <span className="ml-2 mb-2">{t("add_step")}</span>
          </button>
        </div>
      </div>

      <DeleteConfirm
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
        title={t("delete_step")}
        message={
          <span>
            {t("do_you_want_delete")}{" "}
            <b>{sequenceData.steps[selectedStepIndex]?.title}</b>?
          </span>
        }
        open={isDeleteModalOpen}
      />
    </div>
  );
};

ApprovalSequenceContent.propTypes = {
  isNew: PropTypes.bool,
  initialData: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ApprovalSequenceContent;
