import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "context/contexts";
import DecisionTreeContent from "components/decisionTrees/DecisionTreeContent"; // Ensure the path is correct
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastOptions } from "../toastify";
import { useNavigate } from "react-router-dom";
import usePrevious from "utility/hooks/usePrevious";
import {
  DecisionTreeProvider,
  DecisionTreeContext,
} from "context/DecisionTreeContext";

const CreateDecisionTree = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { setIsLoading, setIsEdited, isEdited } = useContext(MainContext);
  const { t } = useTranslation();

  const {
    decisionTree,
    isUpdateSuccess,
    isUpdateTreeSuccess,
    isUpdateError,
    isCreateTreeSuccess,
    isCreateError,
    isFetchTreeError,
    isFetchTreeSuccess,
    newDecisionTreeId,
  } = useSelector((state) => state.decisionTrees);

  const prevIsCreateSuccess = usePrevious(isCreateTreeSuccess);
  const prevIsCreateError = usePrevious(isCreateError);

  useEffect(() => {
    if (isCreateTreeSuccess && prevIsCreateSuccess === false) {
      setIsLoading(false);
      setIsEdited(false);
      toast.success(
        `${t("decision_tree_created_successfully")}.`,
        ToastOptions
      );
      navigate(`/decision-trees/${newDecisionTreeId}`);
    }
  }, [isCreateTreeSuccess]);

  useEffect(() => {
    if (isCreateError && prevIsCreateError === false) {
      setIsLoading(false);
      setIsEdited(true);
      toast.error(t("create_error"), ToastOptions);
    }
  }, [isCreateError]);

  return (
    <>
      <DecisionTreeProvider>
        <DecisionTreeContent />
      </DecisionTreeProvider>
    </>
  );
};

export default CreateDecisionTree;
