import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { Button, Modal } from "react-bootstrap";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import ReactTooltip from "react-tooltip";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const VisualizeJson = ({ open, handleClose, handleConfirm, data }) => {
  const { t } = useTranslation();

  const [visualizeData, setVisualizeData] = useState("");
  useCtrlSHandler(handleConfirm, visualizeData);

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      setVisualizeData(data);
    }
  }, [data]);

  const handleCopyJson = () => {
    navigator.clipboard.writeText(visualizeData);
    toast.success(t("copied"), ToastOptions);
  };

  return (
    <>
      <Modal size="xl" show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">Visualized data object</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="copy-modal">
          <div className="text-right">
            <button
              className="btn btn-transparent"
              data-tip={true}
              data-for="copyJson"
              onClick={handleCopyJson}
            >
              <CopyIcon />
            </button>
            <ReactTooltip type="dark" place="top" effect="solid" id="copyJson">
              {t("copy_clipboard")}
            </ReactTooltip>
          </div>
          <CodeMirror
            value={data}
            extensions={[json(), EditorView.lineWrapping]}
            onChange={(e) => setVisualizeData(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="outline" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button
            variant=""
            className="primary"
            onClick={() => handleConfirm(visualizeData)}
          >
            <SaveIcon /> {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

VisualizeJson.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  data: PropTypes.string,
};

export default VisualizeJson;
