import React from "react";
import { ReactComponent as CheckIcon } from "assets/icons/approved.svg";
import { ReactComponent as CrossIcon } from "assets/icons/rejected.svg";
import { ReactComponent as MailIcon } from "assets/icons/envelope.svg";
import { ReactComponent as RestartIcon } from "assets/icons/restart.svg";
import "./ApprovalProcessProgress.scss";
import PropTypes from "prop-types";
import { t } from "i18next";

const ApprovalProcessProgress = ({
  approval_process,
  startApprovalProcess = () => {},
}) => {
  // Helper function to count approved steps
  const countApprovedSteps = (steps) => {
    return steps.filter((step) => step.status === "a").length;
  };

  // Helper function to check if any step is rejected
  const hasRejectedStep = (steps) => {
    return steps.some((step) => step.status === "r");
  };

  // Helper function to check if any unfinished process exists
  const hasActiveProcess = (processes, currentProcess) => {
    return processes.some(
      (process) =>
        process.approval_sequence_id === currentProcess.approval_sequence_id &&
        process.approval_process_id !== currentProcess.approval_process_id &&
        !hasRejectedStep(process.steps)
    );
  };

  const handleClickRestartSequence = (sequenceId) => {
    startApprovalProcess(sequenceId);
  };

  return (
    <div className="approval-process-progress">
      {approval_process.map((process) => {
        let rejectedFound = hasRejectedStep(process.steps);

        //order steps by step_sequence
        process.steps.sort((a, b) => a.step_sequence - b.step_sequence);

        return (
          <div key={process.approval_process_id} className="sequence-container">
            <h2>Approval Process progress</h2>
            <div className="sequence-header">
              <span>Sequence steps</span>
              <span>
                Completed {countApprovedSteps(process.steps)}/
                {process.steps.length}
              </span>
              {!hasActiveProcess(approval_process, process) &&
                hasRejectedStep(process.steps) && (
                  <button
                    className="restart-button"
                    onClick={() =>
                      handleClickRestartSequence(process.approval_sequence_id)
                    }
                  >
                    <RestartIcon />
                    {t("restart_approval_process")}
                  </button>
                )}
            </div>
            <div className="sequence-steps">
              {process.steps.map((step) => {
                if (rejectedFound && step.status === "w") {
                  return (
                    <div
                      key={step.approval_process_step_id}
                      className="sequence-step sequence-step--disabled"
                    >
                      <span>{step.title}</span>
                      <span>{t("canceled")}</span>
                      <span></span>
                    </div>
                  );
                }

                return (
                  <div
                    key={step.approval_process_step_id}
                    className="sequence-step"
                  >
                    <span>{step.title}</span>
                    <span>
                      {step.status === "a"
                        ? "Approved"
                        : step.status === "w"
                        ? "Waiting"
                        : "Rejected"}
                    </span>
                    <span>
                      {step.status === "a" && <CheckIcon />}
                      {step.status === "r" && <CrossIcon />}
                      {step.status === "w" && <MailIcon />}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ApprovalProcessProgress.propTypes = {
  approval_process: PropTypes.array.isRequired,
  startApprovalProcess: PropTypes.func,
};

export default ApprovalProcessProgress;
