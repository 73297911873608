import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createContactUsFailure,
  createContactUsRequest,
  createContactUsSuccess,
} from "./action";

function* createContactUs({ payload }) {
  try {
    const response = yield call(
      axios.post,
      "customer-service/1/tickets",
      payload
    );
    if (response.status === 201) {
      yield put(createContactUsSuccess(response.data));
    }
  } catch (e) {
    yield put(createContactUsFailure(e.message));
  }
}

export default function* saga() {
  yield takeLatest(createContactUsRequest, createContactUs);
}
