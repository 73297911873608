import { handleActions } from "redux-actions";
import {
  createDeployReleaseFailure,
  createDeployReleaseRequest,
  createDeployReleaseSuccess,
  deleteReleaseFailure,
  deleteReleaseRequest,
  deleteReleaseSuccess,
  getReleaseApiEndPointsFailure,
  getReleaseApiEndPointsRequest,
  getReleaseApiEndPointsSuccess,
  getReleaseFailure,
  getReleaseRequest,
  getReleasesFailure,
  getReleasesRequest,
  getReleasesSuccess,
  getReleaseSuccess,
  getTestReleaseFailure,
  getTestReleaseRequest,
  getTestReleaseSuccess,
  getWorkflowReleasesFailure,
  getWorkflowReleasesRequest,
  getWorkflowReleasesSuccess,
  updateReleaseNotesFailure,
  updateReleaseNotesRequest,
  updateReleaseNotesSuccess,
  downloadBlueprintRequest,
  downloadBlueprintSuccess,
  downloadBlueprintFailure,
  createValidationSetRequest,
  createValidationSetSuccess,
  createValidationSetFailure,
  notifyValidationSetUploadRequest,
  notifyValidationSetUploadSuccess,
  notifyValidationSetUploadFailure,
} from "redux/releases/action";

const initialState = {
  releases: [],
  release: {},
  workflowReleases: [],
  testRelease: {},
  releaseApiEndPoints: [],
  isGetReleasesSuccess: false,
  isGetReleasesError: false,
  isGetReleaseSuccess: false,
  isGetReleaseError: false,
  isGetWorkflowReleasesSuccess: false,
  isGetWorkflowReleasesError: false,
  isGetTestReleaseSuccess: false,
  isGetTestReleaseError: false,
  isDeletedReleaseSuccess: false,
  isDeletedReleaseError: false,
  isGetReleaseApiEndPointsSuccess: false,
  isGetReleaseApiEndPointsError: false,
  isCreatedDeployReleaseSuccess: false,
  isCreatedDeployReleaseRequest: false,
  isCreatedDeployReleaseError: false,
  isUpdatedReleaseNotesSuccess: false,
  isUpdatedReleaseNotesError: false,
  isDownloadingBlueprint: false,
  isDownloadBlueprintSuccess: false,
  isDownloadBlueprintError: false,
  downloadBlueprintError: null,
  errorStatus: null,
  validationSet: {},
  isCreateValidationSetSuccess: false,
  isCreateValidationSetError: false,
  isNotifyValidationSetUploadSuccess: false,
  isNotifyValidationSetUploadError: false,
  validationSetId: null,
};

const reducer = handleActions(
  {
    // get releases
    [getReleasesRequest]: (state) => ({
      ...state,
      isGetReleasesSuccess: false,
      isGetReleasesError: false,
    }),
    [getReleasesSuccess]: (state, { payload }) => ({
      ...state,
      releases: payload || [],
      isGetReleasesSuccess: true,
      isGetReleasesError: false,
    }),
    [getReleasesFailure]: (state) => ({
      ...state,
      isGetReleasesSuccess: false,
      isGetReleasesError: true,
    }),
    // get release
    [getReleaseRequest]: (state) => ({
      ...state,
      isGetReleaseSuccess: false,
      isGetReleaseError: false,
    }),
    [getReleaseSuccess]: (state, { payload }) => ({
      ...state,
      release: payload,
      isGetReleaseSuccess: true,
      isGetReleaseError: false,
    }),
    [getReleaseFailure]: (state) => ({
      ...state,
      isGetReleaseSuccess: false,
      isGetReleaseError: true,
    }),
    // get workflow release
    [getWorkflowReleasesRequest]: (state) => ({
      ...state,
      isGetWorkflowReleasesSuccess: false,
      isGetWorkflowReleasesError: false,
    }),
    [getWorkflowReleasesSuccess]: (state, { payload }) => ({
      ...state,
      workflowReleases: payload,
      isGetWorkflowReleasesSuccess: true,
      isGetWorkflowReleasesError: false,
    }),
    [getWorkflowReleasesFailure]: (state) => ({
      ...state,
      isGetWorkflowReleasesSuccess: false,
      isGetWorkflowReleasesError: true,
    }),
    // get test release
    [getTestReleaseRequest]: (state) => ({
      ...state,
      isGetTestReleaseSuccess: false,
      isGetTestReleaseError: false,
    }),
    [getTestReleaseSuccess]: (state, { payload }) => ({
      ...state,
      testRelease: payload,
      isGetTestReleaseSuccess: true,
      isGetTestReleaseError: false,
    }),
    [getTestReleaseFailure]: (state) => ({
      ...state,
      isGetTestReleaseSuccess: false,
      isGetTestReleaseError: true,
    }),
    // delete release
    [deleteReleaseRequest]: (state) => ({
      ...state,
      isDeletedReleaseSuccess: false,
      isDeletedReleaseError: false,
    }),
    [deleteReleaseSuccess]: (state) => ({
      ...state,
      isDeletedReleaseSuccess: true,
      isDeletedReleaseError: false,
    }),
    [deleteReleaseFailure]: (state) => ({
      ...state,
      isDeletedReleaseSuccess: false,
      isDeletedReleaseError: true,
    }),
    // get test release
    [getReleaseApiEndPointsRequest]: (state) => ({
      ...state,
      isGetReleaseApiEndPointsSuccess: false,
      isGetReleaseApiEndPointsError: false,
    }),
    [getReleaseApiEndPointsSuccess]: (state, { payload }) => ({
      ...state,
      releaseApiEndPoints: payload ?? [],
      isGetReleaseApiEndPointsSuccess: true,
      isGetReleaseApiEndPointsError: false,
    }),
    [getReleaseApiEndPointsFailure]: (state) => ({
      ...state,
      isGetReleaseApiEndPointsSuccess: false,
      isGetReleaseApiEndPointsError: true,
    }),
    // create deploy release
    [createDeployReleaseRequest]: (state) => ({
      ...state,
      isCreatedDeployReleaseRequest: true,
      isCreatedDeployReleaseSuccess: false,
      isCreatedDeployReleaseError: false,
    }),
    [createDeployReleaseSuccess]: (state) => ({
      ...state,
      isCreatedDeployReleaseRequest: false,
      isCreatedDeployReleaseSuccess: true,
      isCreatedDeployReleaseError: false,
    }),
    [createDeployReleaseFailure]: (state, { payload }) => ({
      ...state,
      isCreatedDeployReleaseRequest: false,
      isCreatedDeployReleaseSuccess: false,
      isCreatedDeployReleaseError: true,
      errorStatus: payload,
    }),
    // update release notes
    [updateReleaseNotesRequest]: (state) => ({
      ...state,
      isUpdatedReleaseNotesSuccess: false,
      isUpdatedReleaseNotesError: false,
    }),
    [updateReleaseNotesSuccess]: (state) => ({
      ...state,
      isUpdatedReleaseNotesSuccess: true,
      isUpdatedReleaseNotesError: false,
    }),
    [updateReleaseNotesFailure]: (state) => ({
      ...state,
      isUpdatedReleaseNotesSuccess: false,
      isUpdatedReleaseNotesError: true,
    }),
    // download blueprint
    [downloadBlueprintRequest]: (state) => ({
      ...state,
      isDownloadingBlueprint: true,
      isDownloadBlueprintSuccess: false,
      isDownloadBlueprintError: false,
      downloadBlueprintError: null,
    }),
    [downloadBlueprintSuccess]: (state) => ({
      ...state,
      isDownloadingBlueprint: false,
      isDownloadBlueprintSuccess: true,
      isDownloadBlueprintError: false,
    }),
    [downloadBlueprintFailure]: (state, { payload }) => ({
      ...state,
      isDownloadingBlueprint: false,
      isDownloadBlueprintSuccess: false,
      isDownloadBlueprintError: true,
      downloadBlueprintError: payload, // Assume payload is the error
    }),
    // create validation set
    [createValidationSetRequest]: (state) => ({
      ...state,
      isCreateValidationSetSuccess: false,
      isCreateValidationSetError: false,
    }),
    [createValidationSetSuccess]: (state, { payload }) => ({
      ...state,
      validationSet: payload.data,
      validationSetId: payload.eTag,
      isCreateValidationSetSuccess: true,
      isCreateValidationSetError: false,
    }),
    [createValidationSetFailure]: (state) => ({
      ...state,
      isCreateValidationSetSuccess: false,
      isCreateValidationSetError: true,
    }),
    // notify validation set upload
    [notifyValidationSetUploadRequest]: (state) => ({
      ...state,
      isNotifyValidationSetUploadSuccess: false,
      isNotifyValidationSetUploadError: false,
    }),
    [notifyValidationSetUploadSuccess]: (state) => ({
      ...state,
      isNotifyValidationSetUploadSuccess: true,
      isNotifyValidationSetUploadError: false,
    }),
    [notifyValidationSetUploadFailure]: (state) => ({
      ...state,
      isNotifyValidationSetUploadSuccess: false,
      isNotifyValidationSetUploadError: true,
    }),
  },
  initialState
);

export default reducer;
