import * as SRD from "react-js-diagrams/lib/main";
import { CustomNodeWidgetFactory } from "components/workflow/drawflow/CustomNode/CustomNodeWidget";

export class CustomWidgetFactory extends SRD.NodeWidgetFactory {
  constructor() {
    super("custom");
  }

  generateReactWidget(diagramEngine, node) {
    return CustomNodeWidgetFactory({ node });
  }
}
