import React, { useEffect } from "react";
import CreateNewItem from "../modals/CreateNewItem";
import PropTypes from "prop-types";
import {
  createVectorRequest,
  getVectorAttributesRequest,
  getVectorRequest,
  getVectorsRequest,
} from "redux/vectors/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastOptions } from "../toastify";
import usePrevious from "utility/hooks/usePrevious";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CreateCopyOfDataObject = ({
  isCreateNewFromListOpen = false,
  setIsCreateNewFromListOpen = () => {},
  setSelectedItemFromList,
  selectedItemFromList = {},
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    newVectorId,
    vectorAttributes,
    vector,
    isCreatedVectorSuccess,
    isGetVectorSuccess,
    isGetVectorError,
    isGetVectorAttributesSuccess,
    isGetVectorAttributesError,
  } = useSelector((state) => state.vectors);

  const prevIsCreatedVectorSuccess = usePrevious(isCreatedVectorSuccess);
  const prevIsGetVectorSuccess = usePrevious(isGetVectorSuccess);
  const prevIsGetVectorError = usePrevious(isGetVectorError);
  const prevIsGetVectorAttributesSuccess = usePrevious(
    isGetVectorAttributesSuccess
  );
  const prevIsGetVectorAttributesError = usePrevious(
    isGetVectorAttributesError
  );

  useEffect(() => {
    if (isCreatedVectorSuccess && prevIsCreatedVectorSuccess === false) {
      if (Object.keys(selectedItemFromList).length > 0 || editMode) {
        setIsCreateNewFromListOpen(false);
        if (!editMode) {
          setTimeout(() => {
            setSelectedItemFromList({});
          }, 300);
        }
        toast.success(t("data_object_added"), ToastOptions);
        if (editMode) {
          navigate(`/data-objects/${newVectorId}`);
        }
        dispatch(getVectorsRequest());
      }
    }
  }, [isCreatedVectorSuccess]);

  useEffect(() => {
    if (isGetVectorError && prevIsGetVectorError === false) {
      setIsCreateNewFromListOpen(false);
      if (!editMode) setSelectedItemFromList({});
    }
  }, [isGetVectorError]);

  useEffect(() => {
    if (isGetVectorSuccess && prevIsGetVectorSuccess === false && !editMode) {
      dispatch(getVectorAttributesRequest(selectedItemFromList?.vector_id));
    }
  }, [isGetVectorSuccess]);

  useEffect(() => {
    if (
      ((isGetVectorAttributesSuccess &&
        prevIsGetVectorAttributesSuccess === false) ||
        (isGetVectorAttributesError &&
          prevIsGetVectorAttributesError === false)) &&
      !editMode
    ) {
      createCopy();
    }
  }, [isGetVectorAttributesSuccess, isGetVectorAttributesError]);

  const createCopy = () => {
    const attrs = structuredClone(vectorAttributes);
    const data = new URLSearchParams(new FormData());
    data.set("title", `${t("copy_of")} ${vector.title}`);
    data.set("json_vector", JSON.stringify(attrs));

    dispatch(createVectorRequest(data));
  };

  const handleCreateNewFromListConfirm = () => {
    if (editMode) {
      createCopy();
    } else {
      dispatch(getVectorRequest(selectedItemFromList?.vector_id));
    }
  };

  const handleClose = () => {
    setIsCreateNewFromListOpen(false);
    if (setSelectedItemFromList) {
      setTimeout(() => {
        setSelectedItemFromList({});
      }, 500);
    }
  };

  return (
    <>
      <CreateNewItem
        handleClose={handleClose}
        handleConfirm={handleCreateNewFromListConfirm}
        open={isCreateNewFromListOpen}
        item={
          Object.keys(selectedItemFromList).length > 0
            ? selectedItemFromList
            : { title: vector.title }
        }
        listMode={true}
      />
    </>
  );
};

CreateCopyOfDataObject.propTypes = {
  isCreateNewFromListOpen: PropTypes.bool,
  setIsCreateNewFromListOpen: PropTypes.func,
  selectedItemFromList: PropTypes.object,
  setSelectedItemFromList: PropTypes.func,
  editMode: PropTypes.bool,
};
