import React, { useContext } from "react";
import PropTypes from "prop-types";
import SubFlowStepOptionAttribute from "components/workflow/SubFlowStepOptionAttribute";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-small.svg";
import { ReactComponent as RenameIcon } from "assets/icons/rename-small.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { ReactComponent as CopyIcon } from "assets/icons/union.svg";
import { useParams } from "react-router-dom";
import { MainContext } from "../../context/contexts";
import { ToastOptions } from "../toastify";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SubFlowStepOption = ({
  option,
  handleShowParamDeleteModal,
  handleShowOptionDeleteModal,
  handleAddParameter,
  handleShowOptionEditModal,
  isFork,
  setIsCopied,
  conditionTypes,
  setOpenedOption,
  openedOption,
}) => {
  const { t } = useTranslation();

  const { revisionId } = useParams();
  const { setIsEdited } = useContext(MainContext);

  // Check if the option is associated with a dama_service_id
  const isExternalDataWithDamaService = !!option.dama_service_id;

  const handleToggleOption = () => {
    if (openedOption) {
      const optionSelected = document.querySelector(
        `[data-id="option-${openedOption}"]`
      );
      optionSelected.classList.remove("option-opened");
      setOpenedOption(null);
    }

    if (openedOption !== option.wf_step_option_id) {
      const optionSelected = document.querySelector(
        `[data-id="option-${option.wf_step_option_id}"]`
      );
      if (optionSelected.classList.contains("option-opened")) {
        setOpenedOption(null);
        optionSelected.classList.remove("option-opened");
      } else {
        setOpenedOption(option.wf_step_option_id);
        optionSelected.classList.add("option-opened");
      }
    }
  };
  console;

  const handleCopyOption = () => {
    const cloneOfOption = structuredClone(option);
    cloneOfOption.wf_step_option_id = `new_${parseInt(
      Date.now() * Math.random()
    )}`;
    cloneOfOption.attributes?.map((attr) => {
      attr.wf_step_option_attribute_id = `new_${parseInt(
        Date.now() * Math.random()
      )}`;
    });
    localStorage.setItem("selectedOption", JSON.stringify(cloneOfOption));
    toast.success("Copied", ToastOptions);
    setIsCopied(true);
    setIsEdited(true);
  };

  return (
    <div className="container-option">
      {/* Hidden inputs */}
      <input
        type="hidden"
        name={`option[${option.wf_step_option_id}][wf_step_option_id]`}
        value={option.wf_step_option_id || ""}
      />
      <input
        type="hidden"
        name={`option[${option.wf_step_option_id}][title]`}
        value={option.title || ""}
      />
      <input
        type="hidden"
        name={`option[${option.wf_step_option_id}][name]`}
        value={option.name || ""}
      />
      <input
        type="hidden"
        name={`option[${option.wf_step_option_id}][external_data_id]`}
        value={option.external_data_id || ""}
      />
      {isFork && (
        <input
          type="hidden"
          name={`option[${option.wf_step_option_id}][workflow_step_link_id]`}
          value={option.workflow_step_link_id || ""}
        />
      )}

      {/* Option Header */}
      <div
        className="option-step"
        id="headingOne"
        data-id={`option-${option.wf_step_option_id}`}
      >
        <div className="float-right">
          <button
            title={t("edit_data_source")}
            className="border-radius-4 border-0 btn btn-outline-light mr-3"
            type="button"
            data-toggle="collapse"
            data-target={`#option-content-${option.wf_step_option_id}`}
            aria-expanded="false"
            aria-controls={`#option-content-${option.wf_step_option_id}`}
            onClick={handleToggleOption}
          >
            <EditIcon />
          </button>
          {!isFork && !revisionId && (
            <>
              <button
                title={t("rename_data_source")}
                className="border-radius-4 border-0 btn btn-outline-light mr-3"
                type="button"
                onClick={() => handleShowOptionEditModal(option)}
              >
                <RenameIcon />
              </button>
              <button
                title={t("copy_data_source_btn")}
                className="border-radius-4 border-0 btn btn-outline-light mr-3"
                type="button"
                onClick={handleCopyOption}
              >
                <CopyIcon />
              </button>
              <button
                title={t("delete_data_source")}
                className="border-radius-4 border-0 btn btn-outline-light mr-3 option-delete"
                type="button"
                onClick={() =>
                  handleShowOptionDeleteModal(option.wf_step_option_id)
                }
              >
                <DeleteIcon
                  style={{
                    color: "#6C757D",
                  }}
                />
              </button>
            </>
          )}
        </div>
        <div className="d-flex mb-0">
          <button
            className="font-size-14 text-gray btn collapsed"
            type="button"
            data-toggle="collapse"
            data-target={`#option-content-${option.wf_step_option_id}`}
            aria-expanded="false"
            aria-controls={`#option-content-${option.wf_step_option_id}`}
            onClick={handleToggleOption}
          >
            {option.title}
          </button>
        </div>
      </div>
      <div
        id={`option-content-${option.wf_step_option_id}`}
        className="collapse bg-gray mx-2"
        aria-labelledby="headingOne"
        data-parent="#option-details"
      >
        <div className="card-body p-3 pb-5">
          {option.attributes && option.attributes.length > 0 && (
            <div className="attributes">
              {option.attributes.map((attr) => (
                <SubFlowStepOptionAttribute
                  key={
                    attr.wf_step_option_attribute_id ||
                    `new_${Math.round(Math.random() * 10000)}`
                  }
                  attr={attr}
                  option={option}
                  handleDelete={handleShowParamDeleteModal}
                  isFork={isFork}
                  conditionTypes={conditionTypes ?? []}
                  isExternalDataWithDamaService={isExternalDataWithDamaService}
                />
              ))}
            </div>
          )}
          {!revisionId && !isExternalDataWithDamaService && (
            <div className="my-3 text-center">
              <button
                className="border-radius-4 add-option align-items-center btn d-flex justify-content-center mx-auto my-3 p-2 text-center"
                type="button"
                onClick={() => handleAddParameter(option.wf_step_option_id)}
              >
                <PlusIcon className="mr-2" /> {t("add")}{" "}
                {isFork ? t("fork_condition") : t("fork_parameter")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SubFlowStepOption.propTypes = {
  option: PropTypes.object,
  handleShowParamDeleteModal: PropTypes.func,
  handleShowOptionDeleteModal: PropTypes.func,
  handleAddParameter: PropTypes.func,
  handleShowOptionEditModal: PropTypes.func,
  isFork: PropTypes.bool,
  setIsCopied: PropTypes.func,
  conditionTypes: PropTypes.array,
  setOpenedOption: PropTypes.func,
  openedOption: PropTypes.any,
};

export default SubFlowStepOption;
