// src/redux/analysisRecipes/saga.js

import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "api/axios";
import {
  getAnalysisRecipesRequest,
  getAnalysisRecipesSuccess,
  getAnalysisRecipesFailure,
  getAnalysisRecipeRequest,
  getAnalysisRecipeSuccess,
  getAnalysisRecipeFailure,
  createAnalysisRecipeRequest,
  createAnalysisRecipeSuccess,
  createAnalysisRecipeFailure,
  updateAnalysisRecipeRequest,
  updateAnalysisRecipeSuccess,
  updateAnalysisRecipeFailure,
  deleteAnalysisRecipeRequest,
  deleteAnalysisRecipeSuccess,
  deleteAnalysisRecipeFailure,
} from "./actions";

// Worker Saga: Get all analysis recipes
function* fetchAnalysisRecipes({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/analysis-recipes?page=${payload}` : "/analysis-recipes"
    );
    if (response.status === 200) {
      yield put(getAnalysisRecipesSuccess(response.data));
    }
  } catch (error) {
    yield put(getAnalysisRecipesFailure(error.message));
  }
}

// Worker Saga: Get a single analysis recipe
function* fetchAnalysisRecipe({ payload }) {
  try {
    const url = `/analysis-recipes/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getAnalysisRecipeSuccess(response.data));
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // Optionally handle 404, e.g., redirect to not found page
      // window.location.href = "/page-not-found";
    }
    yield put(getAnalysisRecipeFailure(error.message));
  }
}

// Worker Saga: Create a new analysis recipe
function* createAnalysisRecipe({ payload }) {
  try {
    const response = yield call(axios.post, "/analysis-recipes", payload);
    if (response.status === 201) {
      yield put(createAnalysisRecipeSuccess(response.data));
    }
  } catch (error) {
    yield put(createAnalysisRecipeFailure(error.message));
  }
}

// Worker Saga: Update an existing analysis recipe
function* updateAnalysisRecipe({ payload }) {
  try {
    const { id, data } = payload;
    const url = `/analysis-recipes/${id}`;
    const response = yield call(axios.put, url, data);
    if (response.status === 200) {
      yield put(updateAnalysisRecipeSuccess(response.data));
    }
  } catch (error) {
    yield put(updateAnalysisRecipeFailure(error.message));
  }
}

// Worker Saga: Delete an analysis recipe
function* deleteAnalysisRecipeSaga({ payload }) {
  try {
    const url = `/analysis-recipes/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteAnalysisRecipeSuccess(payload));
    }
  } catch (error) {
    yield put(deleteAnalysisRecipeFailure(error.message));
  }
}

export default function* analysisRecipesSaga() {
  yield takeLatest(getAnalysisRecipesRequest, fetchAnalysisRecipes);
  yield takeLatest(getAnalysisRecipeRequest, fetchAnalysisRecipe);
  yield takeEvery(createAnalysisRecipeRequest, createAnalysisRecipe);
  yield takeLatest(updateAnalysisRecipeRequest, updateAnalysisRecipe);
  yield takeLatest(deleteAnalysisRecipeRequest, deleteAnalysisRecipeSaga);
}
