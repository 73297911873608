import { handleActions } from "redux-actions";
import {
  getDashboardsFailure,
  getDashboardsRequest,
  getDashboardsSuccess,
} from "redux/dashboard/action";

const initialState = {
  dashboards: [],
  isGetDashboardsSuccess: false,
  isGetDashboardsError: false,
};

const reducer = handleActions(
  {
    // get dashboards
    [getDashboardsRequest]: (state) => ({
      ...state,
      isGetDashboardsSuccess: false,
      isGetDashboardsError: false,
    }),
    [getDashboardsSuccess]: (state, { payload }) => ({
      ...state,
      dashboards: payload ?? {},
      isGetDashboardsSuccess: true,
      isGetDashboardsError: false,
    }),
    [getDashboardsFailure]: (state) => ({
      ...state,
      isGetDashboardsSuccess: false,
      isGetDashboardsError: true,
    }),
  },
  initialState
);

export default reducer;
