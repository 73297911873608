import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserRoleRequest,
  getUserRolesRequest,
} from "redux/roleRights/action";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { TableNoItems } from "utility/utility";
import { useTranslation } from "react-i18next";

const UserRoles = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    userRoles,
    isGetUserRolesSuccess,
    isGetUserRolesError,
    removeUserRoleSuccess,
    removeUserRoleFailure,
  } = useSelector((state) => {
    return state.roleRights;
  });

  const prevIsGetUserRolesSuccess = usePrevious(isGetUserRolesSuccess);
  const prevIsGetUserRolesError = usePrevious(isGetUserRolesError);
  const prevRemoveUserRoleSuccess = usePrevious(removeUserRoleSuccess);
  const prevRemoveUserRoleFailure = usePrevious(removeUserRoleFailure);

  const [userRolesData, setUserRolesData] = useState([]);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});

  useEffect(() => {
    document.title = t("roles_decisimo");
    setIsLoading(true);
    dispatch(getUserRolesRequest());
  }, []);

  useEffect(() => {
    if (isGetUserRolesSuccess && prevIsGetUserRolesSuccess === false) {
      setIsLoading(false);
      setUserRolesData(userRoles);
    }
  }, [isGetUserRolesSuccess]);

  useEffect(() => {
    if (
      (isGetUserRolesError && prevIsGetUserRolesError === false) ||
      (removeUserRoleFailure && prevRemoveUserRoleFailure === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetUserRolesError, removeUserRoleFailure]);

  useEffect(() => {
    if (removeUserRoleSuccess && prevRemoveUserRoleSuccess === false) {
      setUserRolesData(
        userRolesData.filter(
          (role) => role.access_role_id !== selectedRole.access_role_id
        )
      );
      setIsLoading(false);
      handleClose();
      toast.warning(t("role_deleted"), ToastOptions);
    }
  }, [removeUserRoleSuccess]);

  const handleShowDeleteModal = (selectedUserInfo) => {
    setSelectedRole(selectedUserInfo);
    setIsUserDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsUserDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedRole({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteUserRoleRequest(selectedRole.access_role_id));
  };

  return (
    <>
      <SubHeader
        title={t("user_roles")}
        actions={
          <>
            <Link to="/user-accounts">
              <button className="btn outline-header">
                <BackIcon /> <span className="ml-2">{t("back_to_users")}</span>
              </button>
            </Link>
            <Link to="/user-role">
              <button className="btn primary ml-2">
                <PlusIcon /> <span className="ml-2">{t("add_role")}</span>
              </button>
            </Link>
          </>
        }
      />
      <div className="mb-3">
        <table className="table table-hover table-fixed border-top">
          <thead className="">
            <tr>
              <th className="th-flow">{t("access_role")}</th>
              <th className="th-flow function-col text-right" />
            </tr>
          </thead>
          <tbody>
            {userRolesData?.length > 0
              ? userRolesData.map((role) => {
                  return (
                    <tr className="td-flow" key={role.access_role_id}>
                      <td>
                        <Link to={`/user-roles/${role.access_role_id}`}>
                          {role.title}
                        </Link>
                      </td>
                      <td className="d-flex align-items-center justify-content-end">
                        <div>
                          <button
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleShowDeleteModal(role)}
                            >
                              <TrashIcon /> {t("delete")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetUserRolesSuccess &&
                userRoles?.length === 0 && <TableNoItems colspan={2} />}
          </tbody>
        </table>
      </div>

      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_user_role")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedRole.title}</b> {t("user")}
            ?
          </span>
        }
        open={isUserDeleteModalOpen}
      />
    </>
  );
};

export default UserRoles;
