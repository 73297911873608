import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { ContextMenuTrigger } from "react-contextmenu";
import { useTranslation } from "react-i18next";

const ColumnInput = ({
  col,
  revisionMode = false,
  decisionTableRowId,
  setContextMenuIds,
  index,
  rowIndex,
  inputRef,
  handleDataFromChild,
}) => {
  const [numberValueA, setNumberValueA] = useState(col.value_a);
  const [numberValueB, setNumberValueB] = useState(col.value_b);
  const { t } = useTranslation();

  const handleInputChange = (event, setValue) => {
    const inputValue = event.target.value;

    if (
      col.operator === "equal_to" ||
      col.operator === "not_equal_to" ||
      col.operator === "starts_with" ||
      col.operator === "ends_with"
    ) {
      setValue(inputValue);
    } else {
      const sanitizedValue = inputValue.replace(/[^0-9.-]+/g, "");
      setValue(sanitizedValue);
    }
  };

  const handleKeyDown = (event) => {
    const val = event.target.value;

    if (event.key === "ArrowLeft" && event.target.selectionStart === 0) {
      const activeElement = document.activeElement;
      let correctLeft = inputRef.current[`value_a_${index - 1}`];
      if (activeElement) {
        const name = activeElement.name;
        if (
          name.includes("value_a") &&
          (col.operator.includes("open") || col.operator.includes("close"))
        ) {
          if (inputRef.current[`value_b_${index - 1}`]) {
            correctLeft = inputRef.current[`value_b_${index - 1}`];
          } else {
            correctLeft = inputRef.current[`value_a_${index - 1}`];
          }
        } else if (
          name.includes("value_b") &&
          (col.operator.includes("open") || col.operator.includes("close"))
        ) {
          correctLeft = inputRef.current[`value_a_${index}`];
        } else if (inputRef.current[`value_b_${index - 1}`]) {
          correctLeft = inputRef.current[`value_b_${index - 1}`];
        }
      }
      if (correctLeft) {
        correctLeft.focus();
        setTimeout(() => {
          correctLeft.setSelectionRange(0, 0);
        }, 0);
      }
    } else if (
      event.key === "ArrowRight" &&
      event.target.selectionStart === val.length
    ) {
      const activeElement = document.activeElement;
      let correctRight = inputRef.current[`value_a_${index + 1}`];
      if (activeElement) {
        const name = activeElement.name;
        if (
          name.includes("value_a") &&
          (col.operator.includes("open") || col.operator.includes("close"))
        ) {
          correctRight = inputRef.current[`value_b_${index}`];
        } else if (
          name.includes("value_b") &&
          (col.operator.includes("open") || col.operator.includes("close"))
        ) {
          correctRight = inputRef.current[`value_a_${index + 1}`];
        }
      }
      if (correctRight) {
        correctRight.focus();
        setTimeout(() => {
          correctRight.setSelectionRange(0, 0);
        }, 0);
      }
    } else if (event.key === "ArrowUp" && rowIndex > 0) {
      if (rowIndex > 0) {
        handleDataFromChild(rowIndex - 1, index);
      }
    } else if (event.key === "ArrowDown") {
      handleDataFromChild(rowIndex + 1, index);
    }
  };

  const renderCondition = (textA) => {
    return (
      <div className="input-group-prepend">
        <ContextMenuTrigger id={`${col?.dt_row_column_id}`} mouseButton={0}>
          <ContextMenuTrigger id={`${col?.dt_row_column_id}`} mouseButton={2}>
            <span
              onContextMenuCapture={() => {
                if (!revisionMode) {
                  setContextMenuIds({
                    dt_row_column_id: col.dt_row_column_id,
                    decision_table_row_id: decisionTableRowId,
                  });
                }
              }}
              onClick={() => {
                if (!revisionMode) {
                  setContextMenuIds({
                    dt_row_column_id: col.dt_row_column_id,
                    decision_table_row_id: decisionTableRowId,
                  });
                }
              }}
              className="input-group-text"
            >
              {textA}
            </span>
          </ContextMenuTrigger>
        </ContextMenuTrigger>
      </div>
    );
  };

  const renderColumn = (textA, textB, valueB = false) => {
    return (
      <>
        {inputRef ? (
          <div
            data-tip={true}
            data-for="decision-table-option"
            title={t("right_click_for_changing_type")}
            className="input-group d-flex justify-content-between decision-table-column"
          >
            {(valueB || col.operator !== "ends_with") && renderCondition(textA)}
            <div className={`col p-0`}>
              <ContextMenuTrigger
                id={`${col?.dt_row_column_id}`}
                mouseButton={2}
              >
                <input
                  onKeyDown={(e) => handleKeyDown(e)}
                  ref={(el) => {
                    inputRef.current[`value_a_${index}`] = el;
                  }}
                  onContextMenuCapture={() => {
                    if (!revisionMode) {
                      setContextMenuIds({
                        dt_row_column_id: col.dt_row_column_id,
                        decision_table_row_id: decisionTableRowId,
                      });
                    }
                  }}
                  type="text"
                  className="form-control px-2 123 border-0 rounded-0 m-0"
                  value={numberValueA}
                  onChange={(e) => handleInputChange(e, setNumberValueA)}
                  name={`field[${col.dt_row_column_id}][value_a]`}
                  disabled={revisionMode ? "disabled" : ""}
                  key={col.value_a}
                />
              </ContextMenuTrigger>
            </div>
            {!valueB && col.operator === "ends_with" && renderCondition(textA)}
            {valueB && (
              <>
                <div className="input-group-prepend input-group-append">
                  <div className="input-group-text">,</div>
                </div>
                <div className="col px-0">
                  <ContextMenuTrigger
                    id={`${col?.dt_row_column_id}`}
                    mouseButton={2}
                  >
                    <input
                      onKeyDown={(e) => handleKeyDown(e)}
                      ref={(el) => {
                        inputRef.current[`value_b_${index}`] = el;
                      }}
                      onContextMenuCapture={() => {
                        if (!revisionMode) {
                          setContextMenuIds({
                            dt_row_column_id: col.dt_row_column_id,
                            decision_table_row_id: decisionTableRowId,
                          });
                        }
                      }}
                      className="form-control px-2 pl-0 border-0 rounded-0 m-0"
                      type="text"
                      value={numberValueB}
                      onChange={(e) => handleInputChange(e, setNumberValueB)}
                      name={`field[${col.dt_row_column_id}][value_b]`}
                      disabled={revisionMode ? "disabled" : ""}
                      key={col.value_b}
                    />
                  </ContextMenuTrigger>
                </div>
                <div className="input-group-append">
                  <span className="input-group-text">{textB}</span>
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div
      data-tip={true}
      data-for="column-input-type"
      id="context-table-container"
    >
      {col.operator === "any_value" ? (
        <ContextMenuTrigger id={`${col?.dt_row_column_id}`} mouseButton={0}>
          <ContextMenuTrigger id={`${col?.dt_row_column_id}`} mouseButton={2}>
            <input
              readOnly
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder="ANY"
              ref={(el) => {
                inputRef.current[`value_a_${index}`] = el;
              }}
              name={`field[${col.dt_row_column_id}][value_a]`}
              title={t("right_click_for_changing_type")}
              className="text-center align-middle any_value w-100 outline-none"
              onContextMenuCapture={() => {
                if (!revisionMode) {
                  setContextMenuIds({
                    dt_row_column_id: col.dt_row_column_id,
                    decision_table_row_id: decisionTableRowId,
                  });
                }
              }}
              onClick={() => {
                if (!revisionMode) {
                  setContextMenuIds({
                    dt_row_column_id: col.dt_row_column_id,
                    decision_table_row_id: decisionTableRowId,
                  });
                }
              }}
            />
          </ContextMenuTrigger>
        </ContextMenuTrigger>
      ) : col.operator === "greater_than" ? (
        renderColumn(">")
      ) : col.operator === "greater_than_or_equal_to" ? (
        renderColumn("≥")
      ) : col.operator === "less_than" ? (
        renderColumn("<")
      ) : col.operator === "less_than_or_equal_to" ? (
        renderColumn("≤")
      ) : col.operator === "starts_with" ? (
        renderColumn("^")
      ) : col.operator === "ends_with" ? (
        renderColumn("$")
      ) : col.operator === "equal_to" ? (
        renderColumn("=")
      ) : col.operator === "not_equal_to" ? (
        renderColumn("≠")
      ) : col.operator === "closed_interval" ? (
        renderColumn("[", "]", true)
      ) : col.operator === "open_interval" ? (
        renderColumn("(", ")", true)
      ) : col.operator === "left_closed_right_open" ? (
        renderColumn("[", ")", true)
      ) : (
        col.operator === "left_open_right_closed" &&
        renderColumn("(", "]", true)
      )}
    </div>
  );
};

ColumnInput.propTypes = {
  col: PropTypes.object,
  revisionMode: PropTypes.bool,
  decisionTableRowId: PropTypes.any,
  setContextMenuIds: PropTypes.func,
  index: PropTypes.number,
  inputRef: PropTypes.object,
  rowIndex: PropTypes.number,
  handleDataFromChild: PropTypes.func,
};

export default memo(ColumnInput);
