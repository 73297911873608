import React, { useContext } from "react";
import PropTypes from "prop-types";
import { RevisionContext } from "context/contexts";
import HiddenInputs from "components/form/HiddenInputs";
import { recursionChildren } from "utility/utility";

const Value = ({ item, setItem }) => {
  const revisionMode = useContext(RevisionContext);
  const handleChange = (event) => {
    setItem((prevState) => {
      const prev = structuredClone(prevState);
      const data = recursionChildren(
        prev,
        item.fce_item_id,
        event.target.value
      );
      return data;
    });
  };

  return (
    <div className="input-group-prepend input-item">
      {!revisionMode && (
        <HiddenInputs
          data={[
            {
              name: `fce_itm[${item.fce_item_id}][parent_fce_item_id]`,
              value: item.parent_fce_item_id ? item.parent_fce_item_id : "null",
            },
            {
              name: `fce_itm[${item.fce_item_id}][item_type]`,
              value: "v",
            },
            {
              name: `fce_itm[${item.fce_item_id}][fce_item_id]`,
              value: item.fce_item_id,
            },
          ]}
        />
      )}
      <input
        onChange={handleChange}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        type="number"
        className="mb-1"
        defaultValue={item.numeric_value}
        name={`fce_itm[${item.fce_item_id}][numeric_value]`}
        disabled={revisionMode ? "disabled" : ""}
        step="any"
      />
    </div>
  );
};

Value.propTypes = {
  item: PropTypes.object,
  setItem: PropTypes.func,
};

export default Value;
