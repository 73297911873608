import React, { useCallback, useRef, useContext } from "react";
import ReactDOM from "react-dom/client";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useBlocker as useRouterBlocker,
} from "react-router-dom";
import Prompt from "../../components/modals/Prompt";

export function usePrompt(message, when = true, setIsEdited) {
  const rootRef = useRef(null);
  const { navigator } = useContext(NavigationContext);

  // Correct use of useRouterBlocker
  useRouterBlocker(
    useCallback(
      ({ currentLocation, nextLocation, historyAction }) => {
        // Block navigation if the 'when' condition is true and the action is not "REPLACE"
        if (!when || historyAction === "REPLACE") return false;

        const element = document.createElement("div");
        element.setAttribute("id", "prompt-dialog-container");
        element.setAttribute("aria-hidden", "true");

        const closePrompt = (shouldProceed) => {
          if (rootRef.current) {
            rootRef.current.unmount();
            rootRef.current = null;
          }
          document.body.removeChild(element);

          if (shouldProceed && typeof navigator.go === "function") {
            setIsEdited(false);
            when = false;
            navigator.go(nextLocation.pathname);
          }
        };

        document.body.appendChild(element);

        if (message) {
          rootRef.current = ReactDOM.createRoot(element);
          rootRef.current.render(
            <Prompt
              open={true}
              message={message}
              handleClose={() => closePrompt(false)}
              handleConfirm={() => closePrompt(true)}
            />
          );
        }

        return true; // Block the navigation
      },
      [message, when]
    ),
    when
  );
}
