import { createAction } from "redux-actions";

export const getTemplatesRequest = createAction("GET_TEMPLATES_REQUEST");
export const getTemplatesSuccess = createAction("GET_TEMPLATES_SUCCESS");
export const getAllTemplatesSuccess = createAction("GET_ALL_TEMPLATES_SUCCESS");
export const getTemplatesFailure = createAction("GET_TEMPLATES_FAILURE");

export const getTemplateRequest = createAction("GET_TEMPLATE_REQUEST");
export const getTemplateSuccess = createAction("GET_TEMPLATE_SUCCESS");
export const getTemplateFailure = createAction("GET_TEMPLATE_FAILURE");

export const createTemplateDecisionRequest = createAction(
  "CREATE_TEMPLATE_DECISION_REQUEST"
);
export const createTemplateDecisionSuccess = createAction(
  "CREATE_TEMPLATE_DECISION_SUCCESS"
);
export const createTemplateDecisionFailure = createAction(
  "CREATE_TEMPLATE_DECISION_FAILURE"
);

export const getTemplateDecisionsRequest = createAction(
  "GET_TEMPLATE_DECISIONS_REQUEST"
);
export const getTemplateDecisionsSuccess = createAction(
  "GET_TEMPLATE_DECISIONS_SUCCESS"
);
export const getTemplateDecisionsFailure = createAction(
  "GET_TEMPLATE_DECISIONS_FAILURE"
);

export const getTemplateDecisionRequest = createAction(
  "GET_TEMPLATE_DECISION_REQUEST"
);
export const getTemplateDecisionSuccess = createAction(
  "GET_TEMPLATE_DECISION_SUCCESS"
);
export const getTemplateDecisionFailure = createAction(
  "GET_TEMPLATE_DECISION_FAILURE"
);

export const clearTemplates = createAction("CLEAR_TEMPLATES");
