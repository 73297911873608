import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  getCountriesFailure,
  getCountriesRequest,
  getCountriesSuccess,
  getSubscriptionFailure,
  getSubscriptionRequest,
  getSubscriptionSuccess,
  updateSubscriptionFailure,
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
} from "redux/subscription/action";

function* getUserRights() {
  try {
    const response = yield call(axios.get, "/subscriptions");
    if (response.status === 200) {
      yield put(getSubscriptionSuccess(response.data));
    }
  } catch (e) {
    yield put(getSubscriptionFailure("e.message"));
  }
}

function* getCountries() {
  try {
    const response = yield call(axios.get, "/countries");
    if (response.status === 200) {
      yield put(getCountriesSuccess(response.data));
    }
  } catch (e) {
    yield put(getCountriesFailure("e.message"));
  }
}

function* updateSubscription({ payload }) {
  try {
    const response = yield call(axios.put, "/tenants/0", payload);
    if (response.status === 200) {
      yield put(updateSubscriptionSuccess());
    }
  } catch (e) {
    yield put(updateSubscriptionFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getSubscriptionRequest, getUserRights);
  yield takeLatest(updateSubscriptionRequest, updateSubscription);
  yield takeLatest(getCountriesRequest, getCountries);
}
