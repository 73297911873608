import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ReleaseEndPoint = (props) => {
  const { t } = useTranslation();

  const {
    open,
    title = t("select_decision_flow"),
    handleClose,
    handleConfirm,
    action,
  } = props;

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <>
            <Modal.Body>{action}</Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="outline"
                type="button"
                onClick={handleClose}
              >
                {t("close")}
              </Button>
              <Button variant="" className="primary" type="submit">
                {t("add")}
              </Button>
            </Modal.Footer>
          </>
        </form>
      </Modal>
    </>
  );
};

ReleaseEndPoint.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  action: PropTypes.any,
};

export default ReleaseEndPoint;
