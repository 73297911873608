import { handleActions } from "redux-actions";
import {
  getTimeZonesFailure,
  getTimeZonesRequest,
  getTimeZonesSuccess,
} from "./action";

const initialState = {
  timezones: [],
  isGetTimeZonesSuccess: false,
  isGetTimeZonesError: false,
};

const reducer = handleActions(
  {
    // get timezones
    [getTimeZonesRequest]: (state) => ({
      ...state,
      isGetTimeZonesSuccess: false,
      isGetTimeZonesError: false,
    }),
    [getTimeZonesSuccess]: (state, { payload }) => ({
      ...state,
      timezones: payload || [],
      isGetTimeZonesSuccess: true,
      isGetTimeZonesError: false,
    }),
    [getTimeZonesFailure]: (state) => ({
      ...state,
      isGetTimeZonesSuccess: false,
      isGetTimeZonesError: true,
    }),
  },
  initialState
);

export default reducer;
