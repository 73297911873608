import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createRoleRightsFailure,
  createRoleRightsRequest,
  createRoleRightsSuccess,
  deleteUserRoleFailure,
  deleteUserRoleRequest,
  deleteUserRoleSuccess,
  getRoleFailure,
  getRoleRequest,
  getRoleRightsFailure,
  getRoleRightsRequest,
  getRoleRightsSuccess,
  getRoleSuccess,
  getUserRolesFailure,
  getUserRolesRequest,
  getUserRolesSuccess,
  updateRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
} from "redux/roleRights/action";

function* getUserRights({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/access-rights?page=${payload}` : "/access-rights"
    );
    if (response.status === 200) {
      yield put(getRoleRightsSuccess(response.data));
    }
  } catch (e) {
    yield put(getRoleRightsFailure("e.message"));
  }
}

function* createUserRights({ payload }) {
  try {
    const response = yield call(axios.post, "/user-roles", payload);
    if (response.status === 201) {
      yield put(
        createRoleRightsSuccess({
          data: response.data,
          eTag: response.headers.etag,
        })
      );
    }
  } catch (e) {
    yield put(createRoleRightsFailure("e.message"));
  }
}

function* getRole({ payload }) {
  try {
    const url = `/user-roles/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getRoleSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getRoleFailure("e.message"));
  }
}

function* updateRole({ payload }) {
  try {
    const url = `/user-roles/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateRoleSuccess(response.data));
    }
  } catch (e) {
    yield put(updateRoleFailure("e.message"));
  }
}

function* deleteUserRole({ payload }) {
  try {
    const url = `/user-roles/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteUserRoleSuccess());
    }
  } catch (e) {
    yield put(deleteUserRoleFailure("e.message"));
  }
}

function* getRoles() {
  try {
    const response = yield call(axios.get, "user-roles");
    if (response.status === 200) {
      yield put(getUserRolesSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserRolesFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getRoleRightsRequest, getUserRights);
  yield takeLatest(createRoleRightsRequest, createUserRights);
  yield takeLatest(getRoleRequest, getRole);
  yield takeLatest(updateRoleRequest, updateRole);
  yield takeLatest(getUserRolesRequest, getRoles);
  yield takeLatest(deleteUserRoleRequest, deleteUserRole);
}
