import React, { useState, useMemo, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import "assets/css/autosuggest/theme.scss";
import { dbPromise } from "indexedDB";
import debounce from "lodash.debounce";

const AutoSuggestInput = ({
  vectorsData,
  defaultValue,
  inputName,
  revisionMode = false,
  tableMode = false,
  onInputChange,
  isColumn,
  placeholder,
  isLast,
  isDataSource = false,
  isFunc = false,
  isRuleSet = false,
  isModel = false,
  isFork = false,
  isAction = false,
  isRuleArray = false,
}) => {
  const [value, setValue] = useState(defaultValue || "");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  // Async function to fetch suggestions from IndexedDB
  // Async function to fetch suggestions from IndexedDB
  async function getSuggestions(inputValue) {
    const db = await dbPromise;
    const tx = db.transaction("vectors");
    const store = tx.objectStore("vectors");
    const index = store.index("searchValue");
    const searchTerm = inputValue.toLowerCase();
    const groupedResults = {};
    let count = 0; // Initialize counter
    const maxResults = 10; // Maximum number of results

    // Use a cursor to iterate over the indexed data
    await index.openCursor().then(function cursorIterate(cursor) {
      if (!cursor || count >= maxResults) return; // Stop if no more entries or maxResults reached
      if (cursor.key.includes(searchTerm)) {
        const item = cursor.value;
        const key = item.attribute_path;

        if (groupedResults[key]) {
          // Concatenate the entity if the attribute_path already exists
          groupedResults[key].entity += `, ${item.entity}`;
        } else {
          // Otherwise, create a new entry
          groupedResults[key] = {
            attribute_path: item.attribute_path,
            entity: item.entity,
            dummy_value: item.dummy_value || "",
            value: item.value,
          };
          count++;
        }
      }
      return cursor.continue().then(cursorIterate);
    });

    // Convert groupedResults object to an array
    return Object.values(groupedResults);
  }

  // Debounced function to fetch suggestions with a delay
  const debouncedFetchSuggestions = useMemo(
    () =>
      debounce(async (inputValue) => {
        if (inputValue.trim().length === 0) {
          setSuggestions([]);
          return;
        }
        setIsLoading(true);
        const fetchedSuggestions = await getSuggestions(inputValue);
        setSuggestions(fetchedSuggestions);
        setIsLoading(false);
      }, 200), // Adjust debounce time as needed
    []
  );

  const getSuggestionValue = (suggestion) => suggestion.value;

  const renderSuggestion = (suggestion) => {
    return (
      <div title={suggestion?.dummy_value || ""}>
        {suggestion.attribute_path}
        {suggestion.entity && (
          <>
            <br />
            <small className="secondary">{suggestion.entity}</small>
          </>
        )}
      </div>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (isColumn) {
      const syntheticEvent = {
        ...event,
        target: {
          ...event.target,
          value: newValue,
        },
      };
      onInputChange(syntheticEvent);
    } else {
      if (onInputChange) onInputChange(newValue);
    }
  };

  // Function called when the input value changes (and we fetch suggestions)
  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedFetchSuggestions(value);
  };

  // Clear the suggestions when needed
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const renderInputComponent = ({ key, ...inputProps }) => {
    if (
      (value && value.startsWith("$.")) ||
      ((value === "" || revisionMode) && !tableMode)
    ) {
      inputProps.className = "react-autosuggest__input";
      if (tableMode) {
        inputProps.className =
          "form-control form-control-sm form-control-plaintext";
      }
    } else {
      inputProps.className = "react-autosuggest__input form-control is-invalid";
      if (tableMode) {
        inputProps.className =
          "form-control form-control-sm form-control-plaintext is-invalid";
        if (value === "") {
          inputProps.className =
            "form-control form-control-sm form-control-plaintext";
        }
      }
    }

    return (
      <>
        <input
          key={key}
          disabled={revisionMode ? "disabled" : ""}
          {...inputProps}
        />
      </>
    );
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  // Styling for input
  const style = {
    paddingRight: "0 !important",
    fontSize: "1rem",
    backgroundColor: isLast ? "#F8F9FA" : "",
    borderLeft: isModel || isRuleArray ? "" : "none",
    borderTop: isFunc ? "none" : "",
    color: "#0f3356",
    height:
      isDataSource || isRuleSet || isModel || isFork || isAction ? "38px" : "",
    borderRadius: isModel || isRuleArray ? "4px" : "",
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      onSuggestionSelected={() => {}}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      disabled={revisionMode ? "disabled" : ""}
      inputProps={{
        style: style,
        className: `react-autosuggest__input ${isFunc ? "" : "h-100"}`,
        placeholder: placeholder
          ? placeholder
          : tableMode
          ? "$.some.attribute"
          : "",
        value: value ? value : "",
        onKeyPress: onKeyPress,
        name: inputName,
        type: "input",
        onChange: onChange,
      }}
    />
  );
};

AutoSuggestInput.propTypes = {
  vectorsData: PropTypes.array,
  defaultValue: PropTypes.string,
  inputName: PropTypes.string,
  revisionMode: PropTypes.bool,
  tableMode: PropTypes.bool,
  onInputChange: PropTypes.func,
  isColumn: PropTypes.bool,
  placeholder: PropTypes.string,
  isLast: PropTypes.bool,
  isDataSource: PropTypes.bool,
  isFunc: PropTypes.bool,
  isRuleSet: PropTypes.bool,
  isModel: PropTypes.bool,
  isFork: PropTypes.bool,
  isAction: PropTypes.bool,
  isRuleArray: PropTypes.bool,
};

export default React.memo(AutoSuggestInput);
