import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CreateNewItem = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleConfirm,
    title = t("rule_set"),
    listMode = false,
    item = {},
    keyItemId = "rule_set_id",
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
    }
  }, [open]);

  return (
    <>
      <Modal size="md" show={open} onHide={!isLoading ? handleClose : void 0}>
        {isLoading ? (
          <div className="d-flex flex-column justify-content-center align-items-center create-new-from-revision">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}</span>
            </div>
            {listMode
              ? t("create_a_copy_of", { title: item?.title })
              : t("create_new", { title: title })}
          </div>
        ) : (
          <>
            <Modal.Header>
              <h5 className="modal-title">
                {listMode
                  ? t("create_a_copy_of", { title: item?.title })
                  : t("create_new", { title: title })}
              </h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              {!listMode ? (
                t("do_you_want_to_create_a_new", { title: title })
              ) : (
                <span>
                  {t("copy_data_source")} <b>{item?.title}</b>?
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="" className="outline" onClick={handleClose}>
                {t("close")}
              </Button>
              <Button
                variant=""
                className="primary"
                type="button"
                onClick={() => {
                  setIsLoading(true);
                  handleConfirm(listMode ? item[keyItemId] : null);
                }}
              >
                {t("create")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

CreateNewItem.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  listMode: PropTypes.bool,
  keyItemId: PropTypes.string,
  item: PropTypes.object,
};

export default CreateNewItem;
