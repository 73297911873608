import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DashBoardCard = ({ title, subTitle, description = "-", titleKey }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="dashboard-card">
        <h2>{t(titleKey)}</h2>
        <p className="dashboard-card__row">
          <span className="dashboard-card__subtitle">{subTitle}</span>
          <span className="dashboard-card__desc">
            {description}
            {description !== "-" && title.includes("average response")
              ? "ms"
              : ""}
            {description !== "-" && title.includes("data source response speed")
              ? "ms"
              : ""}
            {description !== "-" && title.includes("data source success rate")
              ? "%"
              : ""}
            {description !== "-" && title.includes("data source calls")
              ? "x"
              : ""}
          </span>
        </p>
      </div>
    </>
  );
};

DashBoardCard.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  titleKey: PropTypes.string,
};

export default DashBoardCard;
