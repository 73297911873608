import { handleActions } from "redux-actions";
import {
  fetchDecisionTreesRequest,
  fetchDecisionTreesSuccess,
  fetchDecisionTreesFailure,
  fetchDecisionTreeRequest,
  fetchDecisionTreeSuccess,
  fetchDecisionTreeFailure,
  createDecisionTreeRequest,
  createDecisionTreeSuccess,
  createDecisionTreeFailure,
  updateDecisionTreeRequest,
  updateDecisionTreeSuccess,
  updateDecisionTreeFailure,
  deleteDecisionTreeRequest,
  deleteDecisionTreeSuccess,
  deleteDecisionTreeFailure,
  getRunTestResultRequest,
  getRunTestResultSuccess,
  getRunTestResultFailure,
  getTestDecisionTreeRequest,
  getTestDecisionTreeSuccess,
  getTestDecisionTreeFailure,
  getDecisionTreeRevisionsRequest,
  getDecisionTreeRevisionsSuccess,
  getDecisionTreeRevisionsFailure,
  getDecisionTreeRevisionRequest,
  getDecisionTreeRevisionSuccess,
  getDecisionTreeRevisionFailure,
  getRunTestResultStatusRequest,
  getRunTestResultStatusSuccess,
  getRunTestResultStatusFailure,
  getRunTestObjectResultRequest,
  getRunTestObjectResultSuccess,
  getRunTestObjectResultFailure,
} from "./action";

const initialState = {
  decisionTrees: [],
  selectedTree: { nodes: [] },
  decisionTree: {},
  isLoading: false,
  error: null,
  isFetchTreesSuccess: false,
  isFetchTreesError: false,
  isFetchTreeSuccess: false,
  isFetchTreeError: false,
  isCreateTreeSuccess: false,
  isCreateTreeError: false,
  isUpdateTreeSuccess: false,
  isUpdateTreeError: false,
  isDeleteTreeSuccess: false,
  isDeleteTreeError: false,
  newDecisionTreeId: null,
  revisions: [],
  revision: {},
  testResults: [],
  isFetchingRevisions: false,
  isFetchingRevision: false,
  isFetchRevisionSuccess: false,
  isRunningTest: false,
  isFetchingTestResult: false,
  fetchRevisionsError: null,
  fetchRevisionError: null,
  runTestError: null,
  fetchTestResultError: null,
  testDecisionTreeData: [],
  runTestObjectResult: null,
  isGetTestObjectResultSuccess: false,
  isGetTestObjectResultError: false,
};

const decisionTrees = handleActions(
  {
    // Fetching Trees
    [fetchDecisionTreesRequest]: (state) => ({
      ...state,
      isLoading: true,
      isFetchTreesSuccess: false,
      isFetchTreesError: false,
    }),
    [fetchDecisionTreesSuccess]: (state, { payload }) => ({
      ...state,
      decisionTrees: payload,
      isLoading: false,
      isFetchTreesSuccess: true,
    }),
    [fetchDecisionTreesFailure]: (state) => ({
      ...state,
      isLoading: false,
      isFetchTreesError: true,
    }),

    // Fetching a Single Tree
    [fetchDecisionTreeRequest]: (state) => ({
      ...state,
      isLoading: true,
      isFetchTreeSuccess: false,
      isFetchTreeError: false,
    }),
    [fetchDecisionTreeSuccess]: (state, { payload }) => ({
      ...state,
      decisionTree: payload,
      isLoading: false,
      isFetchTreeSuccess: true,
    }),
    [fetchDecisionTreeFailure]: (state) => ({
      ...state,
      isLoading: false,
      isFetchTreeError: true,
    }),

    // Creating a Tree
    [createDecisionTreeRequest]: (state) => ({
      ...state,
      isLoading: true,
      newDecisionTreeId: null,
      isCreateTreeSuccess: false,
      isCreateTreeError: false,
    }),
    [createDecisionTreeSuccess]: (state, { payload }) => ({
      ...state,
      newDecisionTreeId: payload.id,
      decisionTree: payload.data,
      isLoading: false,
      isCreateTreeSuccess: true,
    }),
    [createDecisionTreeFailure]: (state) => ({
      ...state,
      isLoading: false,
      isCreateTreeError: true,
    }),

    // Updating a Tree
    [updateDecisionTreeRequest]: (state) => ({
      ...state,
      isLoading: true,
      isUpdateTreeSuccess: false,
      isUpdateTreeError: false,
    }),
    [updateDecisionTreeSuccess]: (state, { payload }) => ({
      ...state,
      decisionTree: payload,
      isLoading: false,
      isUpdateTreeSuccess: true,
    }),
    [updateDecisionTreeFailure]: (state) => ({
      ...state,
      isLoading: false,
      isUpdateTreeError: true,
    }),

    // Deleting a Tree
    [deleteDecisionTreeRequest]: (state) => ({
      ...state,
      isLoading: true,
      isDeleteTreeSuccess: false,
      isDeleteTreeError: false,
    }),
    [deleteDecisionTreeSuccess]: (state) => ({
      ...state,
      isLoading: false,
      isDeleteTreeSuccess: true,
      isDeleteTreeError: false,
    }),
    [deleteDecisionTreeFailure]: (state) => ({
      ...state,
      isLoading: false,
      isDeleteTreeError: true,
      isDeleteTreeSuccess: false,
    }),
    // Fetching Revisions
    [getDecisionTreeRevisionsRequest]: (state) => ({
      ...state,
      isFetchingRevisions: true,
      fetchRevisionsError: null,
    }),
    [getDecisionTreeRevisionsSuccess]: (state, { payload }) => ({
      ...state,
      revisions: payload,
      isFetchingRevisions: false,
    }),
    [getDecisionTreeRevisionsFailure]: (state) => ({
      ...state,
      isFetchingRevisions: false,
      fetchRevisionsError: true,
    }),
    // Fetching a Revision
    [getDecisionTreeRevisionRequest]: (state) => ({
      ...state,
      isFetchingRevision: true,
      isFetchRevisionSuccess: false,
      fetchRevisionError: null,
    }),
    [getDecisionTreeRevisionSuccess]: (state, { payload }) => ({
      ...state,
      revision: payload,
      decisionTree: payload?.content,
      isFetchingRevision: false,
      isFetchRevisionSuccess: true,
    }),
    [getDecisionTreeRevisionFailure]: (state) => ({
      ...state,
      isFetchingRevision: false,
      isFetchRevisionSuccess: false,
      fetchRevisionError: true,
    }),
    // Running a Test
    [getRunTestResultRequest]: (state) => ({
      ...state,
      isRunningTest: true,
      runTestError: null,
    }),
    [getRunTestResultSuccess]: (state, { payload }) => ({
      ...state,
      testResults: payload,
      isRunningTest: false,
    }),
    [getRunTestResultFailure]: (state) => ({
      ...state,
      isRunningTest: false,
      runTestError: true,
    }),
    // Fetching a Test Result
    [getTestDecisionTreeRequest]: (state) => ({
      ...state,
      isFetchingTestResult: true,
      fetchTestResultError: null,
    }),
    [getTestDecisionTreeSuccess]: (state, { payload }) => ({
      ...state,
      testDecisionTreeData: payload,
      isFetchingTestResult: false,
    }),
    [getTestDecisionTreeFailure]: (state) => ({
      ...state,
      isFetchingTestResult: false,
      fetchTestResultError: true,
    }),
    // get run test result details
    [getRunTestResultRequest]: (state) => ({
      ...state,
      isGetRunTestResultSuccess: false,
      isGetRunTestResultError: false,
    }),
    [getRunTestResultSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultDetails: payload ?? [],
      isGetRunTestResultSuccess: true,
      isGetRunTestResultError: false,
    }),
    [getRunTestResultFailure]: (state) => ({
      ...state,
      isGetRunTestResultSuccess: false,
      isGetRunTestResultError: true,
    }),
    // get run test result status
    [getRunTestResultStatusRequest]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultStatus: payload,
      isGetTestResultStatusSuccess: true,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusFailure]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: true,
    }),
  },
  initialState
);

export default decisionTrees;
