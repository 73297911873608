import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const EditTitle = ({ open, handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  const { vector } = useSelector((state) => state.vectors);

  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("edit_title")}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <Modal.Body>
            <div className="card-body px-3">
              <div className="row">
                <div className="custom-file">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    required
                    defaultValue={vector?.title}
                    key={vector}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="" onClick={handleClose} className="outline my-0">
              {t("close")}
            </Button>
            <Button variant="" type="submit" className="primary my-0">
              {t("update")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

EditTitle.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default EditTitle;
