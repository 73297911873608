import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import PropTypes from "prop-types";
import CustomSelect from "../CustomSelect";

const Token = (props) => {
  const { t } = useTranslation();

  const {
    open,
    handleClose,
    handleSubmit,
    oauth2Token = {},
    type,
    title = type === "edit" ? t("edit_token") : t("add_token"),
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedBasicAuth, setSelectedBasicAuth] = useState(
    oauth2Token?.use_basic_auth ? JSON.parse(oauth2Token?.use_basic_auth) : 0
  );

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
    }
  }, [open]);

  const grantTypes = [
    { label: "Authorization Code Grant", value: "authorization_code" },
    { label: "None", value: "" },
    { label: "Implicit Grant", value: "implicit" },
    { label: "Resource Owner Password Credentials Grant", value: "password" },
    { label: "Client Credentials Grant", value: "client_credentials" },
  ];

  return (
    <>
      <Modal size="lg" show={open} onHide={!isLoading ? handleClose : void 0}>
        {isLoading ? (
          <div className="d-flex flex-column justify-content-center align-items-center create-new-from-revision">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}</span>
            </div>
          </div>
        ) : (
          <>
            <Modal.Header>
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <form
                className="add-token-form"
                onSubmit={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSubmit(e.target, oauth2Token.external_data_oauth2_id);
                }}
              >
                <Modal.Body>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <label>{t("token_name")}</label>
                        <input
                          type="text"
                          className="form-control border-radius-4"
                          name="name"
                          defaultValue={oauth2Token.name || ""}
                          placeholder={t("enter_token_name")}
                          required
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label>{t("grant_type")}</label>
                        <CustomSelect
                          defaultValue={grantTypes.filter(
                            (type) => oauth2Token.grant_type === type.value
                          )}
                          key={oauth2Token.grant_type}
                          options={grantTypes}
                          name="grant_type"
                          handleChange={() => {}}
                          isSearchable={false}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          selectStyle={{
                            dropdownIndicator: (styles) => ({
                              ...styles,
                              marginRight: "0",
                            }),
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label>{t("access_token_url")}</label>
                        <input
                          type="text"
                          className="form-control border-radius-4"
                          name="token_url"
                          defaultValue={oauth2Token.token_url || ""}
                          placeholder="https://example.com/login/oauth/access_token"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <label>{t("oauth_realm")}</label>
                        <input
                          type="text"
                          className="form-control border-radius-4"
                          name="realm"
                          defaultValue={oauth2Token.realm || ""}
                          placeholder="realm"
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label>{t("client_id")}</label>
                        <input
                          type="text"
                          className="form-control border-radius-4"
                          name="client_id"
                          defaultValue={oauth2Token.client_id || ""}
                          placeholder={t("client_id")}
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label>{t("client_secret")}</label>
                        <input
                          type="password"
                          className="form-control border-radius-4"
                          name="client_secret"
                          defaultValue={oauth2Token.client_secret || ""}
                          placeholder={t("client_secret")}
                        />
                      </div>
                      <div className="col-md-12 col-12 mb-2">
                        <p className="m-0">{t("basic_authentication")}</p>
                        <div
                          className="btn-group btn-group-toggle"
                          data-toggle="buttons"
                          defaultValue={selectedBasicAuth}
                          key={selectedBasicAuth}
                          onChange={(e) =>
                            setSelectedBasicAuth(JSON.parse(e.target.value))
                          }
                        >
                          <label
                            className={`btn outline border-radius-left-4 py-2 ${
                              parseInt(selectedBasicAuth) ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              className="field response-json"
                              name="use_basic_auth"
                              defaultChecked={selectedBasicAuth === 1}
                              key={selectedBasicAuth}
                              value={1}
                            />
                            {t("yes")}
                          </label>
                          <label
                            key={oauth2Token.batch_type_id}
                            className={`btn outline border-radius-left-4 py-2 ${
                              !parseInt(selectedBasicAuth) ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              className="field response-json"
                              name="use_basic_auth"
                              defaultChecked={selectedBasicAuth === 0}
                              value={0}
                              key={selectedBasicAuth}
                            />
                            {t("no")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <label>{t("user_name")}</label>
                        <input
                          type="text"
                          className="form-control border-radius-4"
                          name="username"
                          defaultValue={oauth2Token.username || ""}
                          placeholder={t("user_name")}
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <label>{t("password")}</label>
                        <input
                          type="password"
                          className="form-control border-radius-4"
                          name="password"
                          defaultValue={oauth2Token.password || ""}
                          placeholder={t("password")}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="" className="outline" onClick={handleClose}>
                    {t("close")}
                  </Button>
                  <Button variant="" className="primary" type="submit">
                    <SaveIcon /> {t("save_token")}
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

Token.propTypes = {
  title: PropTypes.string,
  open: PropTypes.any,
  oauth2Token: PropTypes.object,
  type: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default Token;
