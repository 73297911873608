import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dataMarketGetServiceDetailsRequest } from "redux/dataMarket/action";
import SubHeader from "../SubHeader";
import { ReactComponent as ServicesIcon } from "assets/icons/back.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { useTranslation } from "react-i18next";

const ServiceDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { dataServiceDetails } = useSelector((state) => state.dataMarket);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(dataMarketGetServiceDetailsRequest({ dama_service_id: id }));
  }, [dispatch, id]);

  return (
    <>
      {dataServiceDetails && (
        <>
          <SubHeader
            alt={t("nav_bar_services")}
            title={dataServiceDetails.title}
            icon={<ServicesIcon />}
            actions={
              <Link
                to="/data-hub"
                className="mr12"
                title={t("back_to_services")}
              >
                <button className="h-100 btn outline">
                  <BackIcon />
                </button>
              </Link>
            }
          />
          <div className="row mt-4">
            <div className="col">
              <strong>{t("service_title")}: </strong>
              {dataServiceDetails.title}
              <br />
              <strong>{t("description")}: </strong>
              {dataServiceDetails.description || t("no_description_available")}
              <br />
              {dataServiceDetails.support_email && (
                <>
                  <strong>{t("support_email")}: </strong>
                  <a href={`mailto:${dataServiceDetails.support_email}`}>
                    {dataServiceDetails.support_email}
                  </a>
                  <br />
                </>
              )}
              {dataServiceDetails.documentation_url && (
                <>
                  <strong>{t("documentation")}: </strong>
                  <a
                    href={dataServiceDetails.documentation_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("view_documentation")}
                  </a>
                  <br />
                </>
              )}
            </div>
          </div>
          {Array.isArray(dataServiceDetails.external_data) &&
            dataServiceDetails.external_data.length > 0 && (
              <div className="row mt-4">
                <div className="col">
                  <strong>{t("external_data_used")}:</strong>
                  <ul>
                    {dataServiceDetails.external_data.map((dataItem, index) => (
                      <li key={index}>{dataItem.title}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
};

export default ServiceDetails;
