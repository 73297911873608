import React, { useState } from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "../../autosuggest";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";

const NodeAction = ({
  action,
  nodeId,
  handleActionChange,
  handleActionDelete,
  vectorsData,
  isReadOnly = false,
}) => {
  const { t } = useTranslation();
  const [actionValue, setActionValue] = useState(action.action_value || "");
  const [attributePath, setAttributePath] = useState(
    action.attribute_path || ""
  );

  const handleChangeValue = (e) => {
    const updatedValue = e.target.value;
    setActionValue(updatedValue);
    handleActionChange(nodeId, action.decision_tree_node_action_id, {
      action_value: updatedValue,
    });
  };

  const handleChangeAttribute = (e) => {
    const attribute = e.target.value;
    setAttributePath(attribute);
    handleActionChange(nodeId, action.decision_tree_node_action_id, {
      attribute_path: attribute,
    });
  };

  return (
    <div className="p-2 bg-light form-row form-group">
      <div className="col-6">
        <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
          <div className="input-group-prepend">
            <button
              className="btn outline font-size-14 w-100 border-radius-left-4 bg-white data-attribute-btn border-radius-none-right-top-bottom"
              type="button"
            >
              {t("attribute")}:
            </button>
          </div>
          <AutoSuggestInput
            vectorsData={vectorsData}
            defaultValue={attributePath || ""}
            isColumn={true}
            onInputChange={handleChangeAttribute}
            inputName="attribute_path"
            className="flex-grow-1 mr-2"
            revisionMode={isReadOnly}
          />
        </div>
      </div>
      <div className="col-5">
        <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
          <div className="input-group-prepend">
            <button
              className="btn outline font-size-14 w-100 border-radius-left-4 bg-white data-attribute-btn border-radius-none-right-top-bottom"
              type="button"
              disabled={true}
            >
              =
            </button>
          </div>
          <input
            type="text"
            defaultValue={actionValue || ""}
            onChange={handleChangeValue}
            className="form-control flex-grow-1 mr-2"
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="col-1">
        {isReadOnly ? null : (
          <button
            type="button"
            className="btn outline font-size-16"
            onClick={() => {
              handleActionDelete(nodeId, action.decision_tree_node_action_id);
            }}
          >
            <DeleteIcon />
          </button>
        )}
      </div>
    </div>
  );
};

NodeAction.propTypes = {
  action: PropTypes.object,
  nodeId: PropTypes.any,
  handleActionChange: PropTypes.func,
  handleActionDelete: PropTypes.func,
  vectorsData: PropTypes.array,
  isReadOnly: PropTypes.bool,
};

export default NodeAction;
