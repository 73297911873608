import React from "react";
import * as RJD from "react-js-diagrams";
import "assets/css/decisionTreeDiagrams.scss";
import PropTypes, { node } from "prop-types";

import { connect } from "react-redux";
import { DTCustomWidgetFactory } from "components/decisionTrees/drawflow/CustomNode/DTCustomWidgetFactory";
import { DTCustomLinkFactory } from "components/decisionTrees/drawflow/CustomNode/DTCustomLinkFactory";

import DTDiagramDraw from "components/decisionTrees/drawflow/DTDiagramDraw";

class DTDiagram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      engine: null,
      decisionTree: {},
      setLinkId: props.setLinkId,
    };

    this.engine = new RJD.DiagramEngine();
    this.engine.registerLinkFactory(new RJD.DefaultLinkFactory());
    this.engine.registerNodeFactory(new RJD.DefaultNodeFactory());
    this.engine.registerLinkFactory(
      new DTCustomLinkFactory(this.state.setLinkId)
    );
    this.engine.registerNodeFactory(new DTCustomWidgetFactory());
    this.engine.registerInstanceFactory(new RJD.DefaultNodeInstanceFactory());
    this.engine.registerInstanceFactory(new RJD.DefaultPortInstanceFactory());
    this.engine.registerInstanceFactory(new RJD.LinkInstanceFactory());
  }

  render() {
    return (
      <div className="diagram-container">
        <DTDiagramDraw
          engine={this.engine}
          decisionTree={this.props.decisionTreeClone}
          handleCloneChange={this.props.handleCloneChange}
          ref={this.props.decisionTreeRef}
          isRevision={this.props.isRevision}
        />{" "}
      </div>
    );
  }
}

DTDiagram.propTypes = {
  decisionTreeClone: PropTypes.object,
  setLinkId: PropTypes.func,
  handleCloneChange: PropTypes.func,
  decisionTreeRef: PropTypes.any,
  isRevision: PropTypes.bool,
};

export default DTDiagram;
