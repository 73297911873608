import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteFunctionRequest,
  getFunctionRequest,
  getFunctionsRequest,
} from "redux/functions/action";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { Pagination, sortByTimeDesc, TableNoItems } from "utility/utility";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import CreateNewItem from "components/modals/CreateNewItem";
import { createItemCopy } from "./utils";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import ReactTooltip from "react-tooltip";
import TemplateModal from "components/modals/Template";
import { clearTemplates } from "redux/templates/action";
import { useTranslation } from "react-i18next";

const Functions = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    functionData,
    functions,
    newFunctionId,
    isGetFunctionsSuccess,
    isGetFunctionsError,
    isDeletedFunctionSuccess,
    isDeletedFunctionError,
    isGetFunctionSuccess,
    isGetFunctionError,
    isCreatedFunctionSuccess,
    isCreatedFunctionError,
  } = useSelector((state) => state.functions);

  const prevIsGetFunctionsSuccess = usePrevious(isGetFunctionsSuccess);
  const prevIsDeletedFunctionSuccess = usePrevious(isDeletedFunctionSuccess);
  const prevIsGetFunctionsError = usePrevious(isGetFunctionsError);
  const prevIsDeletedFunctionError = usePrevious(isDeletedFunctionError);
  const prevIsGetFunctionSuccess = usePrevious(isGetFunctionSuccess);
  const prevIsGetFunctionError = usePrevious(isGetFunctionError);
  const prevIsCreatedFunctionSuccess = usePrevious(isCreatedFunctionSuccess);
  const prevIsCreatedFunctionError = usePrevious(isCreatedFunctionError);

  const [functionsData, setFunctionsData] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState({});
  const [isFunctionDeleteModalOpen, setIsFunctionDeleteModalOpen] =
    useState(false);
  const [isCreateNewFromListOpen, setIsCreateNewFromListOpen] = useState(false);
  const [selectedItemFromList, setSelectedItemFromList] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);

  useEffect(() => {
    document.title = t("functions_decisimo");

    return () => {
      dispatch(clearTemplates());
    };
  }, []);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getFunctionsRequest(currentPage.toString()));
    }
  }, [currentPage]);

  useEffect(() => {
    if (isGetFunctionsSuccess && prevIsGetFunctionsSuccess === false) {
      setIsLoading(false);
      const functionsClone = structuredClone(functions);
      functionsClone?.sort(sortByTimeDesc);
      setFunctionsData(functionsClone);
    }
  }, [isGetFunctionsSuccess]);

  useEffect(() => {
    if (
      (isGetFunctionsError && prevIsGetFunctionsError === false) ||
      (isDeletedFunctionError && prevIsDeletedFunctionError === false) ||
      (isGetFunctionError && prevIsGetFunctionError === false) ||
      (isCreatedFunctionError && prevIsCreatedFunctionError === false)
    ) {
      setIsLoading(false);
      if (isCreateNewFromListOpen) {
        setSelectedItemFromList({});
        setIsCreateNewFromListOpen(false);
      } else if (isCreatedFunctionError) {
        if (isTemplatesModalOpen) {
          setIsTemplatesModalOpen(false);
        }
      }
    }
  }, [
    isCreatedFunctionError,
    isGetFunctionError,
    isGetFunctionsError,
    isDeletedFunctionError,
  ]);

  useEffect(() => {
    if (isCreatedFunctionSuccess && prevIsCreatedFunctionSuccess === false) {
      if (isTemplatesModalOpen) {
        setIsTemplatesModalOpen(false);
        navigate(`/functions/${newFunctionId}`);
      } else {
        setSelectedItemFromList({});
        setIsCreateNewFromListOpen(false);
        dispatch(getFunctionsRequest());
      }
      toast.success(t("function_created"), ToastOptions);
    }
  }, [isCreatedFunctionSuccess]);

  useEffect(() => {
    if (isGetFunctionSuccess && prevIsGetFunctionSuccess === false) {
      createItemCopy(functionData, dispatch);
    }
  }, [isGetFunctionSuccess]);

  useEffect(() => {
    if (isDeletedFunctionSuccess && prevIsDeletedFunctionSuccess === false) {
      setIsLoading(false);
      setFunctionsData(
        functionsData.filter((func) => func.fce_id !== selectedFunction.fce_id)
      );
      toast.warning(t("function_deleted"), ToastOptions);
      setSelectedFunction({});
    }
  }, [isDeletedFunctionSuccess]);

  const handleShowDeleteModal = (selectedFunctionInfo) => {
    setSelectedFunction(selectedFunctionInfo);
    setIsFunctionDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsFunctionDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedFunction({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteFunctionRequest(selectedFunction.fce_id));
    setIsFunctionDeleteModalOpen(false);
  };

  const handleCreateNewFromListConfirm = (id) => {
    dispatch(getFunctionRequest(id));
  };

  const handleOpenTemplatesModal = () => {
    setIsTemplatesModalOpen(true);
  };

  return (
    <>
      <SubHeader
        title={t("nav_bar_functions")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/function"
              className="btn primary-header border-radius-left-4"
              title={t("new_function")}
            >
              <PlusIcon /> <span className="ml-2">{t("function")}</span>
            </Link>
            <div className="btn-group">
              <button
                className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
                title={t("create_function_template")}
              >
                <span className="sr-only">{t("toggle_dropdown")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                <span>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleOpenTemplatesModal}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("create_from_template")}</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        }
      />
      <div className="mb-3 ">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-8 py-3 ">
                {t("function_name")}
              </th>
              {!isMobile && (
                <th scope="col" className="th-flow col-lg-3 py-3 ">
                  {t("last_change")}
                </th>
              )}
              <th scope="col" className="th-flow col-lg-2 py-3 text-right" />
            </tr>
          </thead>
          <tbody className="">
            {functionsData?.length > 0
              ? functionsData.map((func) => {
                  return (
                    <tr key={func.fce_id} className="td-flow">
                      <td className=" ">
                        <Link to={`/functions/${func.fce_id}`}>
                          {func.title}
                        </Link>
                      </td>
                      {!isMobile && (
                        <td className=" ">
                          <div className="align-items-center d-flex">
                            {func.dtime_modified}
                            <HelpCircle
                              data-tip={true}
                              data-for={`modified-${func.fce_id}`}
                              className="ml-2 cursor-pointer"
                            />
                            <ReactTooltip
                              type="dark"
                              place="right"
                              effect="solid"
                              id={`modified-${func.fce_id}`}
                            >
                              Modified by {func.full_name}
                            </ReactTooltip>
                          </div>
                        </td>
                      )}
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <div>
                          <button
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title={t("delete_or_copy_function")}
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>
                          <div
                            className="dropdown-menu small-table-dropdown"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                const { title, fce_id } = func;
                                setIsCreateNewFromListOpen(true);
                                setSelectedItemFromList({
                                  title,
                                  fce_id,
                                });
                              }}
                            >
                              <CopyIcon /> {t("make_a_copy")}
                            </button>
                            {isMobile && (
                              <Link
                                to={`/functions/${func.fce_id}`}
                                className="dropdown-item"
                                title={t("edit_function")}
                              >
                                <EditIcon /> {t("edit")}
                              </Link>
                            )}
                            <div className="dropdown-divider" />
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => handleShowDeleteModal(func)}
                            >
                              <TrashIcon /> {t("delete")}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetFunctionsSuccess &&
                functions?.length === 0 && <TableNoItems />}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        itemsLength={functionsData?.length}
        setCurrentPage={setCurrentPage}
      />
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_function")}
        message={
          <span>
            {t("delete_function")} <b>{selectedFunction.title}</b>
            {"?"}
          </span>
        }
        open={isFunctionDeleteModalOpen}
      />
      {isTemplatesModalOpen && (
        <TemplateModal
          open={isTemplatesModalOpen}
          handleClose={() => setIsTemplatesModalOpen(false)}
          type={"function"}
          title={t("function")}
        />
      )}
      <CreateNewItem
        handleClose={() => setIsCreateNewFromListOpen(false)}
        handleConfirm={handleCreateNewFromListConfirm}
        open={isCreateNewFromListOpen}
        listMode={true}
        keyItemId="fce_id"
        item={selectedItemFromList}
      />
    </>
  );
};

export default Functions;
