import React from "react";
import PropTypes from "prop-types";
import TableColumn from "./TableColumn";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

const DecisionTableColumns = ({
  cols,
  setCols,
  vectorsData,
  handleShowDeleteColumnModal,
}) => {
  const { t } = useTranslation();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setCols((items) => {
        const oldIndex = items.findIndex(
          (item) => item.decision_table_column_id === active.id
        );
        const newIndex = items.findIndex(
          (item) => item.decision_table_column_id === over.id
        );

        if (newIndex + 1 === cols.length) {
          return items;
        }

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <>
      <h3 className="mt-md-2 mt-4">{t("table_columns")}</h3>
      <div className="table-responsive mb-3 decision-table-settings border-radius-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <table className="table table-pretty vertical-align-middle">
            <colgroup>
              <col
                span="1"
                style={{
                  width: "2%",
                }}
              />
              <col
                span="1"
                style={{
                  width: "32%",
                }}
              />
              <col
                span="1"
                style={{
                  width: "32%",
                }}
              />
              <col
                span="1"
                style={{
                  width: "32%",
                }}
              />
              <col
                span="1"
                style={{
                  width: "2%",
                }}
              />
            </colgroup>
            <thead className="bg-transparent border-0">
              <tr>
                <th />
                <th>{t("table_title")}</th>
                <th>{t("data_attribute_path")}</th>
                <th>{t("role")}</th>
                <th className="function-col" />
              </tr>
            </thead>
            <tbody>
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={cols?.map((col) => col.decision_table_column_id)}
              >
                {cols.map((col, index) => {
                  return (
                    <TableColumn
                      key={`column-${col.decision_table_column_id}`}
                      col={col}
                      isLast={!cols[index + 1]}
                      setCols={setCols}
                      handleShowDeleteColumnModal={handleShowDeleteColumnModal}
                      vectorsData={vectorsData}
                    />
                  );
                })}
              </SortableContext>
            </tbody>
          </table>
        </DndContext>
      </div>
    </>
  );
};

DecisionTableColumns.propTypes = {
  cols: PropTypes.array,
  setCols: PropTypes.func,
  vectorsData: PropTypes.array,
  handleShowDeleteColumnModal: PropTypes.func,
};

export default DecisionTableColumns;
