import { createAction } from "redux-actions";

export const updateUserAccountPasswordRequest = createAction(
  "UPDATE_USER_ACCOUNT_PASSWORD_REQUEST"
);
export const updateUserAccountPasswordSuccess = createAction(
  "UPDATE_USER_ACCOUNT_PASSWORD_SUCCESS"
);
export const updateUserAccountPasswordFailure = createAction(
  "UPDATE_USER_ACCOUNT_PASSWORD_FAILURE"
);

export const getUserAccountsRequest = createAction("GET_USER_ACCOUNTS_REQUEST");
export const getUserAccountsSuccess = createAction("GET_USER_ACCOUNTS_SUCCESS");
export const getUserAccountsFailure = createAction("GET_USER_ACCOUNTS_FAILURE");

export const getUserAccountRequest = createAction("GET_USER_ACCOUNT_REQUEST");
export const getUserAccountSuccess = createAction("GET_USER_ACCOUNT_SUCCESS");
export const getUserAccountFailure = createAction("GET_USER_ACCOUNT_FAILURE");

export const createUserAccountRequest = createAction(
  "CREATE_USER_ACCOUNT_REQUEST"
);
export const createUserAccountSuccess = createAction(
  "CREATE_USER_ACCOUNT_SUCCESS"
);
export const createUserAccountFailure = createAction(
  "CREATE_USER_ACCOUNT_FAILURE"
);
export const createUserAccountConflict = createAction(
  "CREATE_USER_ACCOUNT_CONFLICT"
);

export const updateUserAccountRequest = createAction(
  "UPDATE_USER_ACCOUNT_REQUEST"
);
export const updateUserAccountSuccess = createAction(
  "UPDATE_USER_ACCOUNT_SUCCESS"
);
export const updateUserAccountFailure = createAction(
  "UPDATE_USER_ACCOUNT_FAILURE"
);

export const deleteUserFromAccountsRequest = createAction(
  "DELETE_USER_FROM_ACCOUNTS_REQUEST"
);
export const deleteUserFromAccountsSuccess = createAction(
  "DELETE_USER_FROM_ACCOUNTS_SUCCESS"
);
export const deleteUserFromAccountsFailure = createAction(
  "DELETE_USER_FROM_ACCOUNTS_FAILURE"
);

export const checkUserSessionStatusRequest = createAction(
  "CHECK_USER_SESSION_STATUS_REQUEST"
);
export const checkUserSessionStatusSuccess = createAction(
  "CHECK_USER_SESSION_STATUS_SUCCESS"
);
export const checkUserSessionStatusFailure = createAction(
  "CHECK_USER_SESSION_STATUS_FAILURE"
);
