// utils/compareScorecards.js
export function compareScorecards(revision1, revision2) {
  const s1 = revision1.content;
  const s2 = revision2.content;

  // Compare top-level fields
  const topChanges = getTopLevelChanges(s1, s2);

  // Compare predictors
  const { rows, predictorChangesExist } = comparePredictors(
    s1.predictors || [],
    s2.predictors || []
  );

  return {
    topChanges,
    rows, // Each row represents a bin from a predictor with old/new values
    predictorChangesExist,
  };
}

function getTopLevelChanges(s1, s2) {
  const fields = ["title", "name", "attribute_path", "calculation_type"];
  const changes = [];
  fields.forEach((field) => {
    if (s1[field] !== s2[field]) {
      changes.push({
        field,
        oldValue: s1[field],
        newValue: s2[field],
      });
    }
  });
  return changes;
}

function comparePredictors(predictors1, predictors2) {
  const pMap1 = Object.fromEntries(
    predictors1.map((p) => [p.scorecard_predictor_id, p])
  );
  const pMap2 = Object.fromEntries(
    predictors2.map((p) => [p.scorecard_predictor_id, p])
  );

  const allPredictorIds = new Set([
    ...Object.keys(pMap1),
    ...Object.keys(pMap2),
  ]);
  const rows = [];
  let predictorChangesExist = false;

  allPredictorIds.forEach((pid) => {
    const p1 = pMap1[pid] || null;
    const p2 = pMap2[pid] || null;

    let predictorStatus = "unchanged";
    if (p1 && !p2) predictorStatus = "deleted";
    else if (!p1 && p2) predictorStatus = "added";
    else if (p1 && p2) {
      if (!predictorsEqual(p1, p2)) predictorStatus = "modified";
    }

    if (predictorStatus !== "unchanged") predictorChangesExist = true;

    // Compare bins
    const binsRows = compareBins(p1, p2, predictorStatus);
    rows.push(...binsRows);
  });

  return { rows, predictorChangesExist };
}

function predictorsEqual(p1, p2) {
  return (
    p1.name === p2.name &&
    p1.attribute_path === p2.attribute_path &&
    p1.weight === p2.weight &&
    p1.weight_attribute_path === p2.weight_attribute_path
  );
}

function compareBins(p1, p2, predictorStatus) {
  const bins1 = p1 ? p1.bins || [] : [];
  const bins2 = p2 ? p2.bins || [] : [];

  const bMap1 = Object.fromEntries(bins1.map((b) => [b.scorecard_bin_id, b]));
  const bMap2 = Object.fromEntries(bins2.map((b) => [b.scorecard_bin_id, b]));

  const allBinIds = new Set([...Object.keys(bMap1), ...Object.keys(bMap2)]);
  const rows = [];

  allBinIds.forEach((bid) => {
    const b1 = bMap1[bid] || null;
    const b2 = bMap2[bid] || null;

    let binStatus = "unchanged";
    if (b1 && !b2) binStatus = "deleted";
    else if (!b1 && b2) binStatus = "added";
    else if (b1 && b2) {
      if (!binsEqual(b1, b2)) binStatus = "modified";
    }

    rows.push(buildRow(p1, p2, b1, b2, predictorStatus, binStatus));
  });

  return rows;
}

function binsEqual(b1, b2) {
  return (
    b1.name === b2.name &&
    b1.operator === b2.operator &&
    b1.score === b2.score &&
    b1.value_a === b2.value_a &&
    b1.value_b === b2.value_b
  );
}

function buildRow(p1, p2, b1, b2, predictorStatus, binStatus) {
  return {
    predictorStatus,
    binStatus,
    predictorNameOld: p1?.name || "",
    predictorNameNew: p2?.name || "",
    attributePathOld: p1?.attribute_path || "",
    attributePathNew: p2?.attribute_path || "",
    weightOld: p1?.weight !== undefined ? p1.weight : "",
    weightNew: p2?.weight !== undefined ? p2.weight : "",
    weightAttributePathOld: p1?.weight_attribute_path || "",
    weightAttributePathNew: p2?.weight_attribute_path || "",
    weightSetupOld:
      p1?.weight_vector == 1 ? p1?.weight_attribute_path : p1?.weight,
    weightSetupNew:
      p2?.weight_vector == 1 ? p2?.weight_attribute_path : p2?.weight,
    binNameOld: b1?.name || "",
    binNameNew: b2?.name || "",
    operatorOld: b1?.operator || "",
    operatorNew: b2?.operator || "",
    valueAOld: b1?.value_a || "",
    valueANew: b2?.value_a || "",
    valueBOld: b1?.value_b || "",
    valueBNew: b2?.value_b || "",
    scoreOld: b1?.score !== undefined ? b1.score : "",
    scoreNew: b2?.score !== undefined ? b2.score : "",
  };
}
