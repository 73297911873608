import { createAction } from "redux-actions";

export const getEndPointsRequest = createAction("GET_END_POINTS_REQUEST");
export const getEndPointsSuccess = createAction("GET_END_POINTS_SUCCESS");
export const getEndPointsFailure = createAction("GET_END_POINTS_FAILURE");

export const getEndPointRequest = createAction("GET_END_POINT_REQUEST");
export const getEndPointSuccess = createAction("GET_END_POINT_SUCCESS");
export const getEndPointFailure = createAction("GET_END_POINT_FAILURE");

export const getEndPointInstancesRequest = createAction(
  "GET_END_POINT_INSTANCES_REQUEST"
);
export const getEndPointInstancesSuccess = createAction(
  "GET_END_POINT_INSTANCES_SUCCESS"
);
export const getEndPointInstancesFailure = createAction(
  "GET_END_POINT_INSTANCES_FAILURE"
);

export const createEndPointRequest = createAction("CREATE_END_POINT_REQUEST");
export const createEndPointSuccess = createAction("CREATE_END_POINT_SUCCESS");
export const createEndPointFailure = createAction("CREATE_END_POINT_FAILURE");

export const updateEndPointRequest = createAction("UPDATE_END_POINT_REQUEST");
export const updateEndPointSuccess = createAction("UPDATE_END_POINT_SUCCESS");
export const updateEndPointSuccessKeyChanged = createAction(
  "UPDATE_END_POINT_SUCCESS_KEY_CHANGED"
);
export const updateEndPointFailure = createAction("UPDATE_END_POINT_FAILURE");

export const deleteEndPointRequest = createAction("DELETE_END_POINT_REQUEST");
export const deleteEndPointSuccess = createAction("DELETE_END_POINT_SUCCESS");
export const deleteEndPointFailure = createAction("DELETE_END_POINT_FAILURE");

export const createEndPointLimitedFlowRequest = createAction(
  "CREATE_END_POINT_LIMITED_FLOW_REQUEST"
);
export const createEndPointLimitedFlowSuccess = createAction(
  "CREATE_END_POINT_LIMITED_FLOW_SUCCESS"
);
export const createEndPointLimitedFlowFailure = createAction(
  "CREATE_END_POINT_LIMITED_FLOW_FAILURE"
);

export const deleteEndPointLimitedFlowRequest = createAction(
  "DELETE_END_POINT_LIMITED_FLOW_REQUEST"
);
export const deleteEndPointLimitedFlowSuccess = createAction(
  "DELETE_END_POINT_LIMITED_FLOW_SUCCESS"
);
export const deleteEndPointLimitedFlowFailure = createAction(
  "DELETE_END_POINT_LIMITED_FLOW_FAILURE"
);

export const createEndPointUserNotificationRequest = createAction(
  "CREATE_END_POINT_USER_NOTIFICATION_REQUEST"
);
export const createEndPointUserNotificationSuccess = createAction(
  "CREATE_END_POINT_USER_NOTIFICATION_SUCCESS"
);
export const createEndPointUserNotificationFailure = createAction(
  "CREATE_END_POINT_USER_NOTIFICATION_FAILURE"
);

export const deleteEndPointUserNotificationRequest = createAction(
  "DELETE_END_POINT_USER_NOTIFICATION_REQUEST"
);
export const deleteEndPointUserNotificationSuccess = createAction(
  "DELETE_END_POINT_USER_NOTIFICATION_SUCCESS"
);
export const deleteEndPointUserNotificationFailure = createAction(
  "DELETE_END_POINT_USER_NOTIFICATION_FAILURE"
);
