import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  getScorecardRevisionRequest,
  getScorecardRevisionsRequest,
  getScorecardRevision2Request,
} from "redux/scorecard/action";
import { MainContext } from "context/contexts";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import SubHeader from "components/SubHeader";
import Revisions from "components/modals/decisionTables/Revisions";
import { useTranslation } from "react-i18next";
import { compareScorecards } from "./compareScorecards";
import ScorecardRevisionHeader from "./ScorecardRevisionHeader";

const ScorecardRevisionComparison = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId, revisionId2 } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    revision,
    revision2,
    isGetScorecardRevisionSuccess,
    isGetScorecardRevisionError,
    isGetScorecardRevisionsSuccess,
    revisions,
    isGetScorecardRevision2Success,
    isGetScorecardRevision2Error,
  } = useSelector((state) => state.scorecard);

  const [comparison, setComparison] = useState(null);
  const [isRevisionsModalOpen, setIsRevisionsModalOpen] = useState(false);

  useEffect(() => {
    document.title = t("compare_scorecard_revisions");
  }, [t]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getScorecardRevisionRequest({ id, revisionId }));
    dispatch(getScorecardRevision2Request({ id, revisionId: revisionId2 }));

    return () => setIsLoading(false);
  }, [dispatch, id, revisionId, revisionId2, setIsLoading]);

  useEffect(() => {
    if (isGetScorecardRevisionError || isGetScorecardRevision2Error) {
      setIsLoading(false);
      // handle error if needed
      return;
    }

    if (
      isGetScorecardRevisionSuccess &&
      isGetScorecardRevision2Success &&
      revision &&
      revision2
    ) {
      setIsLoading(false);
      const comparisonResult = compareScorecards(revision, revision2);
      setComparison(comparisonResult);
    }
  }, [
    isGetScorecardRevisionError,
    isGetScorecardRevision2Error,
    isGetScorecardRevisionSuccess,
    isGetScorecardRevision2Success,
    revision,
    revision2,
    setIsLoading,
  ]);

  const handleShowRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getScorecardRevisionsRequest(id));
    setIsRevisionsModalOpen(true);
  };

  //when revisions loaded set loading to false
  useEffect(() => {
    if (isGetScorecardRevisionsSuccess) {
      setIsLoading(false);
    }
  }, [isGetScorecardRevisionsSuccess, setIsLoading]);

  const handleCloseRevisions = () => {
    setIsRevisionsModalOpen(false);
  };

  const settings = [
    {
      id: 1,
      content: (
        <button className="dropdown-item" onClick={handleShowRevisionsModal}>
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: false,
    },
  ];

  if (!isGetScorecardRevisionSuccess || !isGetScorecardRevision2Success) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <span>{t("loading")}...</span>
      </div>
    );
  }

  if (!comparison) {
    return null; // No comparison yet
  }

  const { topChanges, rows } = comparison;

  // Group rows by predictor. We'll use a key that combines old/new predictor names,
  // since a predictor can be added or removed. If you have a stable predictor ID, use that.
  const predictorMap = new Map();
  rows.forEach((r) => {
    // Use scorecard_predictor_id instead of name combination
    const predictorKey =
      r.scorecardPredictorId ||
      (r.predictorNameOld || "") + "##" + (r.predictorNameNew || "");

    if (!predictorMap.has(predictorKey)) {
      predictorMap.set(predictorKey, {
        predictorStatus: r.predictorStatus,
        hasChanges:
          r.predictorStatus === "modified" ||
          r.predictorStatus === "added" ||
          r.predictorStatus === "deleted",
        rows: [],
        ...r,
      });
    }

    // Update hasChanges if any bin has changes
    const group = predictorMap.get(predictorKey);
    if (
      r.binStatus === "modified" ||
      r.binStatus === "added" ||
      r.binStatus === "deleted"
    ) {
      group.hasChanges = true;
    }
    group.rows.push(r);
  });

  const predictorGroups = Array.from(predictorMap.values());

  return (
    <>
      <SubHeader
        title={t("compare_scorecard_revisions")}
        actions={
          <>
            <Link to={`/scorecards/${id}`} className="mr-2">
              <button className="btn outline" title={t("back_to_scorecard")}>
                <BackIcon />
              </button>
            </Link>
            <div className="btn-group">
              <button
                type="button"
                className="btn primary"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <SettingsIcon />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                {settings.map((setting) => (
                  <span key={setting.id}>
                    {setting.content}
                    {setting.divider && <div className="dropdown-divider" />}
                  </span>
                ))}
              </div>
            </div>
          </>
        }
      />

      <ScorecardRevisionHeader revision1={revision} revision2={revision2} />

      <div className="comparison-table-container p-4">
        {topChanges.length > 0 && (
          <div className="mb-4">
            <h3>{t("top_level_changes")}</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{t("property")}</th>
                  <th>{t("from")}</th>
                  <th>{t("to")}</th>
                </tr>
              </thead>
              <tbody>
                {topChanges.map((change, i) => (
                  <tr key={i}>
                    <td>{change.field}</td>
                    <td>
                      {change.oldValue !== undefined ? (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                          }}
                        >
                          {change.oldValue || "''"}
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {change.newValue !== undefined ? (
                        <span style={{ color: "green" }}>
                          {change.newValue || "''"}
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <h3>{t("predictors_and_bins_changes")}</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              {/* Predictor+Attribute Path */}
              <th>{t("predictor")}</th>
              {/* Bin Name */}
              <th>{t("bin_name")}</th>
              {/* Values (merged operator + values) */}
              <th>{t("bin_range")}</th>
              {/* Score */}
              <th>{t("score")}</th>
              {/* Weight at the end */}
              <th>{t("weight")}</th>
            </tr>
          </thead>
          <tbody>
            {predictorGroups.map((group, gIndex) => {
              const { rows: pRows, predictorStatus, hasChanges } = group;
              const predictorRowSpan = pRows.length;

              return pRows.map((row, index) => {
                // Apply row style based on both individual row status and group changes
                const rowStyle = getRowStyle(
                  row.binStatus || predictorStatus,
                  hasChanges
                );
                const showPredictorCell = index === 0;
                const showWeightCell = index === 0;

                // Prepare combined predictor cell content
                const predictorCellContent = renderCellDiff(
                  formatPredictorNamePath(
                    row.predictorNameOld,
                    row.predictorNameNew,
                    row.attributePathOld,
                    row.attributePathNew
                  ),
                  null,
                  predictorStatus
                );

                return (
                  <tr key={`${gIndex}-${index}`} style={rowStyle}>
                    {showPredictorCell && (
                      <td rowSpan={predictorRowSpan}>{predictorCellContent}</td>
                    )}
                    <td>
                      {renderCellDiff(
                        row.binNameOld,
                        row.binNameNew,
                        row.binStatus
                      )}
                    </td>
                    <td>
                      {renderCellDiff(
                        formatValues(
                          row.operatorOld,
                          row.valueAOld,
                          row.valueBOld
                        ),
                        formatValues(
                          row.operatorNew,
                          row.valueANew,
                          row.valueBNew
                        ),
                        row.binStatus
                      )}
                    </td>
                    <td>
                      {renderCellDiff(
                        row.scoreOld,
                        row.scoreNew,
                        row.binStatus
                      )}
                    </td>
                    {showWeightCell && (
                      <td rowSpan={predictorRowSpan}>
                        {renderCellDiff(
                          row.weightSetupOld,
                          row.weightSetupNew,
                          predictorStatus
                        )}
                      </td>
                    )}
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={revisions}
        linkTo="scorecards"
        open={isRevisionsModalOpen}
        elemId={parseInt(id)}
        leftRevisionId={parseInt(revisionId)}
        rightRevisionId={parseInt(revisionId2)}
      />
    </>
  );
};

function formatPredictorNamePath(oldName, newName, oldPath, newPath) {
  const nameChanged = oldName !== newName;
  const pathChanged = oldPath !== newPath;

  const status = nameChanged || pathChanged ? "modified" : "";

  return (
    <div>
      <div>
        {nameChanged ? (
          <>
            <span style={{ textDecoration: "line-through", color: "red" }}>
              {oldName}
            </span>
            <span style={{ color: "green", marginLeft: "5px" }}>{newName}</span>
          </>
        ) : (
          oldName
        )}
      </div>
      <div className="text-muted" style={{ fontStyle: "italic" }}>
        {pathChanged ? (
          <>
            <span style={{ textDecoration: "line-through", color: "red" }}>
              {oldPath}
            </span>
            <span style={{ color: "green", marginLeft: "5px" }}>{newPath}</span>
          </>
        ) : (
          oldPath
        )}
      </div>
    </div>
  );
}

function renderCellDiff(oldVal, newVal, status) {
  // If oldVal/newVal are React elements (like from formatPredictorNamePath), handle carefully:
  const oldStr = typeof oldVal === "object" ? "" : oldVal;
  const newStr = typeof newVal === "object" ? "" : newVal;

  if (status === "added") {
    return <span style={{ color: "green" }}>{newVal || oldVal}</span>;
  }
  if (status === "deleted") {
    return (
      <span style={{ color: "red", textDecoration: "line-through" }}>
        {oldVal}
      </span>
    );
  }
  if (status === "modified" && oldStr !== newStr) {
    // If oldVal/newVal are elements for predictor, we must display them carefully:
    if (typeof oldVal === "object" || typeof newVal === "object") {
      // For complex elements, we can't strikethrough easily. We can show oldVal in red, newVal in green
      return (
        <>
          <span
            style={{
              color: "red",
              display: "block",
              marginBottom: "5px",
              textDecoration: "line-through",
            }}
          >
            {oldVal}
          </span>
          <span style={{ color: "green" }}>{newVal}</span>
        </>
      );
    } else {
      return (
        <>
          <span
            style={{
              textDecoration: "line-through",
              color: "red",
              marginRight: 5,
            }}
          >
            {oldVal || "''"}
          </span>
          <span style={{ color: "green" }}>{newVal || "''"}</span>
        </>
      );
    }
  }
  // unchanged
  return oldVal || newVal || "";
}

function formatValues(operator, vA, vB) {
  if (!operator) return "";
  const isInterval = [
    "closed_interval",
    "open_interval",
    "left_closed_right_open",
    "left_open_right_closed",
  ].includes(operator);
  if (isInterval) {
    const open =
      operator === "closed_interval" || operator === "left_closed_right_open"
        ? "["
        : "(";
    const close =
      operator === "closed_interval" || operator === "left_open_right_closed"
        ? "]"
        : ")";
    return `${open} ${vA || ""}, ${vB || ""} ${close}`;
  }
  const opSymbol = getOpSymbol(operator);
  return `${opSymbol} ${vA || ""}`;
}

function getOpSymbol(operator) {
  switch (operator) {
    case "equal_to":
      return "=";
    case "not_equal_to":
      return "≠";
    case "greater_than":
      return ">";
    case "less_than":
      return "<";
    case "greater_than_or_equal_to":
      return "≥";
    case "less_than_or_equal_to":
      return "≤";
    case "closed_interval":
      return "[..]";
    case "open_interval":
      return "(..)";
    default:
      return operator || "";
  }
}

function getRowStyle(status, hasGroupChanges = false) {
  // If the predictor group has changes, use a lighter highlight for the entire group
  if (hasGroupChanges) {
    switch (status) {
      case "added":
        return { backgroundColor: "#eaf6ec" }; // lighter green
      case "deleted":
        return { backgroundColor: "#fcefef" }; // lighter red
      case "modified":
        return { backgroundColor: "#fff8e6" }; // lighter yellow
      default:
        return { backgroundColor: "#fff8e6" }; // light yellow for unchanged rows in modified group
    }
  }

  // Original highlighting for specific changed rows
  switch (status) {
    case "added":
      return { backgroundColor: "#d4edda" };
    case "deleted":
      return { backgroundColor: "#f8d7da" };
    case "modified":
      return { backgroundColor: "#fff3cd" };
    default:
      return {};
  }
}

export default ScorecardRevisionComparison;
