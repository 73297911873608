import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createTemplateDecisionRequest } from "redux/templates/action";
import usePrevious from "../../utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "../toastify";
import { MainContext } from "../../context/contexts";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const maxLength = 120000;

const DecisionDesigner = ({ isShowSuccess, setIsShowSuccess }) => {
  const { setIsLoading } = useContext(MainContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [textareaValueLength, setTextareaValueLength] = useState(0);

  const {
    errorStatus,
    isCreateTemplateDecisionSuccess,
    isCreateTemplateDecisionRequest,
    isCreateTemplateDecisionError,
  } = useSelector((state) => state.templates);

  const prevIsCreateTemplateDecisionSuccess = usePrevious(
    isCreateTemplateDecisionSuccess
  );

  const prevIsCreateTemplateDecisionError = usePrevious(
    isCreateTemplateDecisionError
  );

  useEffect(() => {
    if (
      isCreateTemplateDecisionSuccess &&
      prevIsCreateTemplateDecisionSuccess === false
    ) {
      setIsLoading(false);
      setIsShowSuccess(true);
      toast.success(t("work_assigned"), ToastOptions);
    }
  }, [isCreateTemplateDecisionSuccess]);

  useEffect(() => {
    if (isCreateTemplateDecisionError && prevIsCreateTemplateDecisionError) {
      if (errorStatus?.status === 400) {
        toast.warning(t("input_text_warning"), ToastOptions);
      } else if (errorStatus?.status === 402) {
        toast.warning(t("payment_required_warning"), ToastOptions);
      } else if (errorStatus?.status === 409) {
        toast.warning(t("conflict_warning_message"), ToastOptions);
      }
    }
  }, [errorStatus]);

  const textareaChange = (e) => {
    setTextareaValueLength(e.target.value?.length);
  };

  const onHandleDecision = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(createTemplateDecisionRequest(data));
  };

  return (
    <div className="decision-design-content h-100">
      {isShowSuccess ? (
        <div className="decision-design-success-content h-100">
          <h2>
            {t("decision_designer")} <br /> {t("processing_input")}
          </h2>
          <p className="py-4">
            {t("need_information_decision")} <br /> {t("once_completed")},
            {t("notify_email")}
          </p>
        </div>
      ) : (
        <>
          <div className="decision-design-title">
            <h2>
              {t("quick_project")} <br /> {t("translating_decision")}
            </h2>
            <p>{t("decision_text")}</p>
          </div>
          <form onSubmit={onHandleDecision}>
            <div className="decision-textarea position-relative">
              <textarea
                onChange={textareaChange}
                placeholder={t("decision_logic_company")}
                name="decision_strategy"
                className="form-control"
                minLength={301}
                maxLength={maxLength}
              />
              <div className="textarea-length">
                {textareaValueLength}/{maxLength}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Button
                type="submit"
                title={textareaValueLength < 301 ? t("text_area_warning") : ""}
                className="decision-design-btn cursor-pointer"
                disabled={
                  textareaValueLength < 301 || isCreateTemplateDecisionRequest
                }
              >
                {isCreateTemplateDecisionRequest ? (
                  <div
                    className="spinner-border spinner-border-sm "
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  t("generate")
                )}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

DecisionDesigner.propTypes = {
  isShowSuccess: PropTypes.bool,
  setIsShowSuccess: PropTypes.func,
};

export default DecisionDesigner;
